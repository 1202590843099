import "../../App.css";

import {
  BrowserRouter,
  Switch,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { MfctContext } from "../../context";
import { useEffect, useMemo, useState } from "react";
import { getAuthUser, projectInit } from "../../service/mftc-api";
import {  getConditions } from "../../tools/tpl";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import { setBackground } from "../../service/background";

import Home from "./pages/home/Home";
import NewApp from "../pro/pages/newapp/NewApp";
import Users from "./pages/users/Users";
import Apps from "./pages/apps/Apps";
import Modules from "./pages/modules/Modules";
import Chat from "../pro/pages/chat/Chat";
import Meet from "../pro/pages/meet/Meet";
import Contents from "../pro/pages/contents/Contents";

import SetTemplate from "../pro/pages/template/SetTemplate";
import Templates from "../pro/pages/templates/Templates";
import Visio from "../../components/visio/Visio";

import SetPage from "../pro/pages/page/SetPage";

import SetContent from "../pro/pages/content/SetContent";
import NotFound from "./pages/notfound/NotFound";
import ContentSchemas from "../pro/pages/contents/ContentSchemas";
import WebPush from "./pages/webpush/WebPush";
import PaymentProcess from "../common/pages/payment_process/PaymentProcess";

import Subscriptions from "./pages/subscriptions/Subscriptions";
import Parameters from "../pro/pages/parameters/Parameters";
import Page from "../client/pages/page/Page";
import PopupPageEditor from "../pro/pages/popupeditor/PopupPageEditor";
import PopupTemplateEditor from "../pro/pages/popupeditor/PopupTemplateEditor";




var caches = {}
const cache = {
  set :function  (key,value,force){
    console.log(" set Cache " + key + " " + force,value ) 
    if(!(key in caches ) || force )  { caches[key]=JSON.stringify(value);  
    }
  else   console.log(" already Cached " ) 
} ,
  get :function (key) {
    //return 
    console.log(key,caches)
    if(caches[key]) return JSON.parse(caches[key]);
  
  },
  remove :function(key){
    if(key in caches )    delete caches[key]

  }

} 

var modules=[]


function AppAdmin() {

  console.log("AppAdmin")
  const [authUser, setAuthUser] = useState(getAuthUser());
  const [project, setProject] = useState();
  const [templates, setTemplates] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [apps, setApps] = useState([]);
//  const [modules, setModules] = useState([]);
const setModules = (value)=>{ modules=value}
  const [contents, setContents] = useState([]);
  const[appContents,setAppContents]= useState({})
  const [pages, setPages] = useState([]);
  const[appPages,setAppPages]= useState({})
  const [appSchemas,setAppSchemas] = useState({})
  const[moduleContents,setModuleContents]= useState({})
  const[moduleTemplates,setModuleTemplates]= useState({})


  async function getProject() {
    console.log(
      "getProject",
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME
    );
    const p = await projectInit(
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME
    );
    console.log("project", p);

   await getTemplatesandClasses(p)
    setProject(p);

  }

  async function getTemplatesandClasses(p ) {
    const _project = p ?? project

    var _templates = await _project.api("/templates"); /// attention 
    var _schemas = await _project.api("/get_schemas");
    var _modules = await _project.api("/get_modules");
    var _apps = await _project.api("/get_myapps", { body: {} });
  

    setApps(_apps??[]);
    setSchemas(_schemas??[]);
    setTemplates(_templates??[]);
    setModules(_modules??[]); 

    console.log("getTemplatesandClasses ok");

  }

  async function getSchema(content_id, force) {

    if (!force && schemas.find((c) => c.content_id + "" === content_id + ""))
      return schemas.find((c) => c.content_id + "" === "" + content_id);
    var _content = await project.api("/get_content/:content_id", {       // Un schema est un content
      args: { content_id },
    });

    if (schemas.find((c) => c.content_id + "" === "" + content_id))
    setSchemas(
        schemas.map((c) => (c.content_id + "" === "" + content_id ? _content : c))
      );
    else setSchemas([...schemas, _content]);
    return _content;
  }

  async function getTemplate(template_id, force) {
    var template = templates.find(
      (t) => t.template_id + "" === "" + template_id
    );
    var save = false;
    var found = !!template
    if (force || template) {

      template = await project.api("/pro/template/:template_id", {
        args: { template_id },
      });
      save = true;
    }
    if (template ) {
     // template.classeProps = getClasseProps(template.class_id, null, classes);
      template.conditions = getConditions(template.structure);
      save = true;
    }

    if (save) {
      if (found)
        setTemplates(
          templates.map((t) =>
            t.template_id + "" === "" + template_id ? template : t
          )
        );
      else setTemplates([...templates, template]);
    }
    return template;
  }


async function updateTemplate(template){
if(templates.find(
    (t) => t.template_id + "" === "" + template.template_id
  ))
  setTemplates(
    templates.map((t) =>
      t.template_id + "" === "" + template.template_id ? template : t
    )
  );
  else
  setTemplates([...templates, template] );
}




  async function getContent(content_id, force) {
    if (!force && contents.find((c) => c.content_id + "" === content_id + ""))
      return contents.find((c) => c.content_id + "" === "" + content_id);
   
      console.log("not found", contents);
      var _content = await project.api("/get_content/:content_id", {
      args: { content_id },
    });
    console.log("found", _content);
    if (contents.find((c) => c.content_id + "" === "" + content_id))
      setContents(
        contents.map((c) => (c.content_id + "" === "" + content_id ? _content : c))
      );
    else setContents([...contents, _content]);
    return _content;
  }

  async function updateContent(content){
    if(contents.find(
        (c) => c.content_id + "" === "" + content.content_id
      ))
      setContents(
        contents.map((c) =>
          c.content_id + "" === "" + content.content_id ? content : c
        )
      );
      else
      setContents([...contents, content] );
    }


    async function updateModule(module,del){ 

      if(del){
        setModules(
          modules.filter((c) =>
            c.module_id !== module.module_id    )
        );
      }
      else
      if(modules.find(
          (c) => c.module_id + "" === "" + module.module_id
        ))
        setModules(
          modules.map((c) =>
            c.module_id + "" === "" + module.module_id ? module : c
          )
        );
        else
        setModules([...modules, module] );
      }
  
      async function getPagesByApp(app_id,domain) {


        if(domain) { 
          app_id= apps.find(app=>app.domain===domain).app_id
        }
        
        if(appPages[app_id]){
       
         return pages.filter(c=>c.app_id +""===""+app_id )
        }
         var _pages = await project.api("/get_mypages/:app_id", {
           args: { app_id },
         });
  
         setPages([...pages ,..._pages])
       var   _appPages={...appPages}
       _appPages[app_id] = true
       setAppPages(_appPages)
       return _pages;
         }
    

    const getApps = () => {
      return apps;

    };
  
  function getModules(){
      return modules;
    }
    function getModule(module_id){
      return modules.find(t=>t.module_id === parseInt( module_id));
    }
function getContents(){
  return contents;
}
function getSchemas(){
  return schemas;
}

function getTemplates(){
return templates;
}
async function getSchemasByApp(app_id){

  if(appSchemas[app_id]){

    return schemas.filter(c=>!c.app_id ||   parseInt( c.app_id) ===parseInt(app_id))
   }
    var _schemas = await project.api("/app/:app_id/get_schemas", {
      args: { app_id },
    });
  var  newschemas = [...schemas ,..._schemas]
    setSchemas(newschemas)

    setAppSchemas((s)=>({...s,[app_id]:true}))
    return newschemas.filter(c=>!c.app_id || parseInt( c.app_id) ===parseInt(app_id));

 }

  async function getContentsByApp(app_id) {
    if (appContents[app_id]) {
      return contents.filter((c) => parseInt(c.app_id)=== parseInt(app_id));
    }
    var _contents = await project.api("/app/:app_id/get_contents", {
      args: { app_id },
    });

    setContents([...contents, ..._contents]);
    var _appContents = { ...appContents };
    _appContents[app_id] = true;
    setAppContents(_appContents);
    return _contents;
  }

  async function getTemplatesByModule(module_id) {

    if(moduleTemplates[module_id]){
   
     return templates.filter(c=>c.module_id +""===module_id)
    }
     var _templates = await project.api("/module/:module_id/templates", {
       args: { module_id },
     });
   
     setContents([...templates ,..._templates])
   var   _moduleTemplates={...moduleTemplates}
   _moduleTemplates[module_id] = true
   setModuleTemplates(_moduleTemplates)
   return templates;
     }
  async function getContentsByModule(module_id) {

    if(moduleContents[module_id]){
   
     return contents.filter(c=>c.module_id +""===module_id)
    }
     var _contents = await project.api("/module/:module_id/contents", {
       args: { module_id },
     });
   
     setContents([...contents ,..._contents])
   var   _moduleContents={...moduleContents}
   _moduleContents[module_id] = true
   setModuleContents(_moduleContents)
   return _contents;
     }



/*

  const value = useMemo(() => {
    return ;
  }, [authUser, project, classes, templates]);
*/

  useEffect(() => {
    setBackground();
    console.log("getProject");
    getProject();
  
  }, [authUser]);

  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);


  const LoadApp = () => {
    const { domain } = useParams();
    useEffect(() => {
      // Initialisation de l'application et de ses pages au debut
      var app = apps.find((app) => app.domain === domain);
      if (app) getPagesByApp(app.app_id).then(() => {});
    }, []);

    return <></>;
  };


if(!project) return <></>
  return (
    <MfctContext.Provider value={{
      authUser,
      setAuthUser,
      project,
      getProject,
      getTemplate,

    //  templates,
      setTemplates,
      updateTemplate,
      setContents,
     // contents,
      getContent,   
      setSchemas,

    //  schemas,
    getApps,
    updateModule,
    getModules,
    getModule,
      getTemplates,
      getSchemas,
      getContents,
      getSchema,
      getContentsByApp,
      getSchemasByApp,
      getPagesByApp,
      getContentsByModule,
      updateContent,
      getTemplatesandClasses,
      getTemplatesByModule,
      cache
    }}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/webpush" element={<WebPush />} />
          <Route path="/newapp" element={<NewApp />} />
          <Route path="/users" element={<Users />} />
          <Route path="/parameters" element={<Parameters />} />
          <Route path="/subscriptions" element={<Subscriptions />} />

          <Route path="/modules" element={<Modules />} />
          <Route path="/modules/:module_id" element={<Modules />} />
          <Route path="/modules/:module_id/:what" element={<Modules />} />
          <Route path="/modules/new" element={<Modules />} />


          <Route path="/module/:module_id/template/add" element={<SetTemplate key={"new"}   />} />
          <Route path="/module/:module_id/template/:template_id" element={<SetTemplate   key={"set"}/>} />


          <Route path="/module/:module_id/page/add" element={<SetPage  key={"new"} />} />
          <Route path="/module/:module_id/page/add/:type" element={<SetPage  key={"new"} />} />
          <Route path="/module/:module_id/page/:page_id" element={<SetPage   key={"set"}/>} />


<Route path="/pageeditor" element={<PopupPageEditor />} />
<Route path="/templateeditor" element={<PopupTemplateEditor />} />


          <Route path="/apps" element={<Apps />} />
          <Route path="/apps/:app_id" element={<Apps />} />
          <Route path="/apps/:app_id/:what" element={<Apps />} />

          <Route path="/schemas" element={<ContentSchemas  />} />
          <Route path="/app/:app_id/contents" element={<Contents  />} />
          <Route path="/schema/add" element={<SetContent  is_schema={true}   key={"new"} />} />
          <Route path="/app/:app_id/schema/add" element={<SetContent is_schema={true} key={"new"}  />} />
          <Route path="/app/:app_id/content/add" element={<SetContent  key={"new"} />} />  
          <Route path="/app/:app_id/contentTemplate/:template_id/add" element={<SetContent  key={"new"} />} />
          <Route path="/module/:module_id/contentTemplate/:template_id/add" element={<SetContent  key={"new"} />} />
          <Route path="/app/:app_id/content/add/:schema_id" element={<SetContent  key={"new"} />} />
          <Route path="/content/:content_id" element={<SetContent   key={"set"}/>} />
          <Route path="/contentTemplate/:content_id" element={<SetContent   key={"set"}/>} />

          <Route path="/app/:app_id/page/add" element={<SetPage  key={"new"} />} />
          <Route path="/app/:app_id/page/add/:type" element={<SetPage  key={"new"} />} />
          <Route path="/app/:app_id/page/:page_id" element={<SetPage   key={"set"}/>} />


          <Route path="/templates" element={<Templates />} />
          <Route path="/template/add" element={<SetTemplate  key={"new"} />} />
          <Route path="/app/:app_id/template/add" element={<SetTemplate  key={"new"} />} />
          <Route path="/app/:app_id/template/add/:schema_id" element={<SetTemplate  key={"new"} />} />
          <Route path="/template/:template_id" element={<SetTemplate  key={"set"} />} />
        
        
          {apps &&
            pages &&
            apps.map((app, i) => {
              var _page = pages.find((page) => app.app_id === page.app_id);

              if (!_page) return <></>;
              console.log("Nouvelle Route Application" + app.domain);
              _page.app = app;
              return (
                <Route
                  path={`/domain/${app.domain}`}
                  element={<Page pageDefault={_page} key={app.domain} />}
         
                />
              );
            })}
          {pages &&
            apps &&
            pages.map((page, i) => {
              var app = apps.find((app) => app.app_id === page.app_id);

              if (!app) return <></>;
              page.app = app;

              console.log(
                "Nouvelle Route Application Page " +
                  app.domain +
                  "/" +
                  page.slug
              );
              return (
                <Route
                  path={`/domain/${app.domain}/${page.slug}`}
                  element={({ match }) => (<Page pageDefault={page} key={page.page_id + Object.values(match.params).join(",")} />)}
       
                />
              );
            })}

          <Route path="/domain/:domain" element={<LoadApp key={"domain"} />} />
          <Route
            path="/domain/:domain/:slug"
            element={<LoadApp key={"domainslug"} />}
          />
          <Route
            path="/domain/:domain/:slug/:id"
            element={<LoadApp key={"domainslugid"} />}
          />

          <Route
            path="/template/add/:modele_template_id"
            element={<SetTemplate  key={"new"} />}
          />
                    <Route
            path="/template/addsub/:modele_template_id/:sub_id"
            element={<SetTemplate  key={"new"} />}
          />

          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:account_id" element={<Chat />} />
          <Route path="/meet" element={<Meet />} />

        
          <Route path="/visio" element={<Visio />} />
          <Route path="/domain/:domain/payment_process" element={<PaymentProcess />} />
          <Route path="*" element={<Home />} />
          <Route path="404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MfctContext.Provider>
  );
}

export default AppAdmin;
