import React, { useEffect, useMemo, useState, useContext } from "react";


import { Link, useParams } from "react-router-dom";


import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setBackground } from "../../../../service/background";
import Header1 from "../../../../components/Header1";
import '../../../../App.css';
import { MfctContext } from "../../../../context";

import AppPages from "../../../pro/pages/apps/AppPages";
import AppUsers from "../../../pro/pages/apps/AppUsers";
import AppRoles from "../../../pro/pages/apps/AppRoles";
import AppSettings from "../../../pro/pages/apps/AppSettings";
import AppContents from "../../../pro/pages/apps/AppContents";
import AppTemplates from "../../../pro/pages/apps/AppTemplates";
import AppSchemas from "../../../pro/pages/apps/AppSchemas";


function Apps() {

  const { app_id, what } = useParams();
  console.log("App What", { app_id, what });
  const [apps, setApps] = useState([]);
  const [show, setShow] = useState(
    app_id ? { app: parseInt(app_id), what } : null
  );

  const [tabs, setTabs] = useState([
   
    { what: "Pages", text: "Pages", icon: fa.faBookOpen },

    { what: "Contents", text: "Contenus", icon: fa.faPager },
    { what: "Schémas", text: "Schemas", icon: fa.faPager },
    { what: "Templates", text: "Templates", icon: fa.faPager },
    { what: "Navigation", text: "Navigation", icon: fa.faLink },
  ]);
  const [tabsSide, setTabsSide] = useState([
    { what: "Settings", text: "Paramètres", icon: fa.faGear },
    { what: "Users", text: "Utilisateurs", icon: fa.faUsers },
    { what: "Roles", text: "Rôles", icon: fa.faLock },

  ]);
  const { project, getProject, setAuthUser, authUser } =
    useContext(MfctContext);

  const getApps = async (unselect) => {
    if (unselect) setShow();
    var _apps = await project.api("/get_myapps", { body: {} });
    console.log(_apps);
    setApps(
      _apps.map((app) => {
        return {
          ...app,
          isModifiable:
            (process.env.REACT_APP_IS_TEST === "true") === (app.is_test === 1),
        };
      })
    );
  };

  const share = async (app) => {
    if (navigator.share) {
      try {
        await navigator
          .share({
            url: "https://" + app.domain + ".appisyou.com",
            title: app.name,
            text: "Installe cette appli c'est de la bombe !",
          })
          .then(
            () => 1
            //alert("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        //alert(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      // alert(  "Web share is currently not supported on this browser. Please provide a callback");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setBackground();
  }, [show]);

  useEffect(() => {
    if (project) {
      getApps();
    }
  }, [project]);
  return (
    <div className=" text-white">
      <Header1   title={"Applications"}   linkLeft={"/"} linkRight={"/newapp"}  iconRight={fa.faPlus} classRight={"p-2 rounded-full border border-white "  } />
    

      <div className="w-full flex flex-col p-4">
        {apps &&
          apps
            .filter((app) => !show || show.app === app.app_id)
            .map((app, i) => (
              <div  className="flex flex-col w-full" key={i} >
                <div
                className="rounded-xl border-t border-zinc-500 w-full  text-black flex flex-col items-start p-4 my-2 bg-white"
             
              >
                <div className="flex flex-row   w-full  justify-center items-start">
                  <img
                    src={
                      process.env.REACT_APP_MFTC_SERVER_URL_FILES +
                      `profiles/${app.domain}/user.png`
                    }
                    className=" m-1 w-28 h-28 rounded-sm"
                    alt="logo"
                  />
                  <div className="flex flex-col grow">
                    <div className="flex flex-row">
                      <span className="mx-4 text-2xl font-extrabold text-left grow">
                        {" "}
                        {app.name}
                      </span>

                      <div className="flex flex-col items-center justify-center border-l border-zinc-200 pl-2">
                        {" "}
                        <a
                          href={project.isDev ? `/?domain=${app.domain}` : `https://${app.domain}.appisyou.com/`}
                          target="_blank"
                          className="mx-0"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={fa.faLink}
                            className=" rounded-full border border-white  "
                          ></FontAwesomeIcon>
                        </a>
                        <div className="hidden" onClick={() => share(app)}>
                          <FontAwesomeIcon
                            icon={fa.faShare}
                            className=" rounded-full border border-white  "
                          ></FontAwesomeIcon>
                        </div>
                        {tabsSide.map((tab, i) => (
                  <Link to={   app.app_id === show?.app && show?.what === tab.what? "/apps": `/apps/${ app.app_id }/${tab.what}` } key={i} > <div
                      key={i}
                      onClick={ () =>
                        setShow(
                          app.app_id === show?.app && show?.what === tab.what
                            ? null
                            : { app: app.app_id, what: tab.what }
                        )
                      }
                      className={
                        " p-1 rounded-md " +
                        (app.app_id === show?.app && show?.what === tab.what
                          ? " bg-zinc-200 "
                          : "")
                      }
                    >
                      <FontAwesomeIcon
                        icon={tab.icon}
                        className=" rounded-full border border-white  "
                      ></FontAwesomeIcon>
                    </div></Link>
                  ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full text-sm flex flex-row items-center justify-center">
                  {tabs.map((tab, i) => (
                  <Link to={   app.app_id === show?.app && show?.what === tab.what? "/apps": `/apps/${ app.app_id }/${tab.what}` } key={i} > <div
                      key={i}
                      onClick={ () =>
                        setShow(
                          app.app_id === show?.app && show?.what === tab.what
                            ? null
                            : { app: app.app_id, what: tab.what }
                        )
                      }
                      className={
                        " p-1 rounded-md " +
                        (app.app_id === show?.app && show?.what === tab.what
                          ? " bg-zinc-200 "
                          : "")
                      }
                    >
                      <FontAwesomeIcon
                        icon={tab.icon}
                        className=" rounded-full border border-white  "
                      ></FontAwesomeIcon>
                      <div className="text-xs md:text-sm">{tab.text}</div>
                    </div></Link>
                  ))}
                  {false && (
                    <div className="flex flex-row p-1 m-1">
                      {" "}
                      <Link to={`/app/${app.app_id}/contents`}>
                        Contenus
                      </Link>{" "}
                    </div>
                  )}
                </div>
                </div>

                {show?.what === "Pages" && show?.app === app.app_id && (
                  <AppPages app={app} />
                )}
                {show?.what === "Users" && show?.app === app.app_id && (
                  <AppUsers app={app} />
                )}
                {show?.what === "Roles" && show?.app === app.app_id && (
                  <AppRoles app={app} />
                )}
                {show?.what === "Settings" && show?.app === app.app_id && (
                  <AppSettings getApps={getApps} app={app} />
                )}
                {show?.what === "Contents" && show?.app === app.app_id && (
                  <AppContents app={app} />
                )}
                 {show?.what === "Templates" && show?.app === app.app_id && (
                  <AppTemplates app={app} />
                )}
                 {show?.what === "Schémas" && show?.app === app.app_id && (
                  <AppSchemas app={app} />
                )}
              </div>
            ))}
      </div>

      <ToastContainer />
    </div>
  );
}

export default Apps;
