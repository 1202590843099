import { data } from "../service/data";

const sizes = {
  full: 100,
  fit: 0,
  screen: 100,
  xs: 10,
  sm: 20,
  lg: 30,
  xl: 50,
};

const getCombinations = (places, objects, filter, make) => {
  var results = [];

  for (let arrayPlace of places) {
    var subResults = [{}];
    for (let place of arrayPlace) {
      var newResults = [];
      for (let result of subResults) {

        for (let obj of objects) {

           if( filter && !filter(place,obj ) ) continue;
            
            newResults.push([...result, {...place,obj}  ])
        }
      }
      subResults = newResults;
    }

    results = [...results, ...subResults];
  }
if( make) results = results.map(make)
  return results;
};




const getAvailablesPlace = (structure, id) => {
  var result = [];

  if (structure.schema_id || structure.sizes) {
    result.push({
      id,
      schema_id: structure.schema_id,
      sizes: structure.sizes,
      isList: structure.isList,
    });
  } else if (structure.children) {
    structure.children.forEach((childStr, i) => {
      result = [...result, ...getAvailablesPlace(childStr, id + "_" + i)];
    });
  }

  return result;
};

const genlistTemplates = async (schema, options) => {
  var result = [];

  var tpl_dispositions = await data.getTemplates(
    (tpl) => tpl.schema_id === 484
  );

  var tpl_complets = tpl_dispositions.filter((tpl) => tpl.layout === "in");

  var tpl_groupes = tpl_dispositions.filter((tpl) => tpl.layout !== "in");

  result = tpl_complets.map((tpl) => ({
    ...tpl.structure,
    availablePlaces: getAvailablesPlace(tpl.structure, ""),
  }));

  return result;
};

const generateTemplateBySchema = async (schema, options) => {
  var children = [
    {
      className: "font-bold  p-6 text-3xl ",
      tag: "text",
      text: schema.title,
      children: [],
    },
  ];
  for (let prop of schema.content.schema) {
    var childLabel = {
      className: "pt-2 text-xs border-t border-slate-500 w-full",
      tag: "text",
      text: prop.name,
      children: [],
    };
    var childDisplay = { className: "p-2 ", children: [] };

    var childSchema = await data.getSchema(prop.schema_id);

    if (childSchema.content.is_value) {
      // TEXT ,IMG, ICON date nombre ETC..

      childDisplay = {
        ...childDisplay,
        tag: "text",
        text: "-  Value - ",
        schema_prop: prop.name,
      };
      switch (prop.code) {
        case "icon":
          childDisplay.tag = "icon";
          break;
        case "image":
          childDisplay.tag = "img";
          break;

        default:
      }
    } else {
      // CHOISIR UN SOUS TEMPLATE  DU SCHEMA

      childDisplay = {
        ...childDisplay,
        tag: "text",
        text: `-  ${prop.name} DISPLAY TODO - ` + JSON.stringify(prop),
        schema_prop: prop.name,
        isList:
          prop.type_nombre === "I" ||
          prop.type_nombre === "L" ||
          prop.type_nombre === "F",
      };
    }

    children.push({
      className: "p-2 flex flex-col w-full",
      children: [childLabel, childDisplay],
    });
  }

  var newTemplate = {
    template_id: 404, //  A ENLEVER !!!
    schema_id: schema.content_id,
    title: "Display Template - " + schema.title,
    structure: {
      className:
        "w-full p-2 bg-white flex flex-col  items-start justify-start  gap-1",
      children,
    },
  };

  newTemplate = data.save_template(newTemplate);
  return newTemplate;
};

const generateTemplateListBySchema = async (schema, options) => {
  var newTemplate;

  return newTemplate;
};

export {
  genlistTemplates,
  generateTemplateBySchema,
  generateTemplateListBySchema,
  getCombinations,
};
