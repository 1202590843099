import { useEffect } from "react";
import { useState } from "react";
import { data } from "../../service/data";
import InputData from "./InputData";
import VarContent from "../VarContent";

const schemas = {};

const InputForSchemaProp = ({
  id,
  name,
  schema_id,
  label,
  onChange,
  value,
  className,
  placeholder,
  context,
  options,
}) => {
  const [prop, setProp] = useState();
  const [schema, setSchema] = useState();
  useEffect(() => {
    const loadProp = async () => {
      if (!schemas[schema_id]) {
        schemas[schema_id] = await data.getContent(schema_id);
      }
      if (schemas[schema_id]) {
        var _prop = schemas[schema_id].content.schema.find(
          (p) => p.name === name
        );
        if (_prop) {
          if (!schemas[_prop.schema_id]) {
            schemas[_prop.schema_id] = await data.getContent(_prop.schema_id);
          }
          if (schemas[_prop.schema_id]) {
            setSchema(schemas[_prop.schema_id]);
            setProp(_prop);
          }
        }
      }
    };

    loadProp();
  }, [name, schema_id]);

  if (!prop) return <></>;

  return (
    <>
      <InputData
        id={id}
        prop={{ ...prop, name: label ?? prop.name }}
        schema={schema}
        value={value}
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        context={context}
        options={ options}
      />
    </>
  );
};
export default InputForSchemaProp;
