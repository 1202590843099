
import { useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isAfter,
    isBefore,
    isEqual,
    isSameMonth,
    isToday,
    parse,
    startOfToday,
} from 'date-fns';

import fr from 'date-fns/locale/fr';
import { faChevronLeft, faChevronRight, faClose } from '@fortawesome/free-solid-svg-icons';




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const InputPeriodDate = ({ id, label, onChange, value, className }) => {

    let ref1 = useRef();
    let ref2 = useRef();
    let today = startOfToday()
    let [selectedDay, setSelectedDay] = useState(today)

    let [start, setStart] = useState();
    let [end, setEnd] = useState();

    let [selecting, setSelecting] = useState()


    let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

    //const [showDatePicker, setShowDatePicker] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);



    let days = eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: endOfMonth(firstDayCurrentMonth),
    })
    function previousYear() {
        let firstDayPreviousYear = add(firstDayCurrentMonth, { years: -1 })
        setCurrentMonth(format(firstDayPreviousYear, 'MMM-yyyy'))
    }

    function nextYear() {
        let firstDayNextYear = add(firstDayCurrentMonth, { years: 1 })
        setCurrentMonth(format(firstDayNextYear, 'MMM-yyyy'))
    }


    function previousMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    function nextMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    function handleDayClick(day) {
        const formattedDate = format(day, 'yyyy-MM-dd');

        if (selecting === "start") {
            setStart(day);
            ref1.current.value = formattedDate;
            setSelecting("end");
            setEnd('');

        } else {

            if (start && day < start) {

                setEnd(start);
                ref2.current.value = format(start, 'yyyy-MM-dd');
                setStart(day);
                ref1.current.value = formattedDate;
            } else {
                setEnd(day);
                ref2.current.value = formattedDate;
            }
            setSelecting("start");
        }
    }
    function handleSelecting() {
        setSelecting();

    }



    return (
        <>
            <div className={"relative " + className}>
                <div className='flex w-full '>
                    <input
                        ref={ref1}
                        type="date"
                        id={id ?? "input_" + label}
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={value}
                        className={classNames(
                            "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer",
                            selecting === "end" && "border border-green-500 text-green-500"
                        )}
                        placeholder=" "
                        onClick={() => setSelecting("start")}
                    />
                    <label
                        htmlFor={id ?? "input_" + label}
                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                        {label}
                    </label>
                    <input
                        ref={ref2}
                        type="date"
                        id={id ?? "input_" + label}
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={value}
                        className={classNames(
                            "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer",
                            selecting === "start" && "border border-green-500 text-green-500"
                        )}
                        placeholder=" "
                        onClick={() => setSelecting("end")}
                    />
                    <label
                        htmlFor={id ?? "input_" + label}
                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                        {label}
                    </label>
                </div>

                {(selecting || inputFocused) && (
                    <div className="pt-16 flex flex-row gap-6 ">
                        <div className="">
                            <div className="relative flex justify-center">
                                <FontAwesomeIcon icon={faClose} className='p-1 -mr-3 text-lg absolute -top-6 -right-2 ' onClick={handleSelecting} />
                                <div className="p-5 w-96 shadow">
                                    <div className="flex items-center">
                                        <div
                                            type="button"
                                            onClick={previousYear}
                                            className="-my-1.5   flex flex-none items-center justify-center  text-gray-400  p-2 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300 mr-0.5"
                                        >
                                            <span className="sr-only">Previous year</span>
                                            <FontAwesomeIcon icon={faChevronLeft} className='p-1 -mr-3 ' />
                                            <FontAwesomeIcon icon={faChevronLeft} className='p-1' />
                                        </div>
                                        <div
                                            type="button"
                                            onClick={previousMonth}
                                            className="-my-1.5 flex flex-none items-center justify-center  text-gray-400  p-2 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300"
                                        >
                                            <span className="sr-only">Previous month</span>
                                            <FontAwesomeIcon icon={faChevronLeft} className='p-1' />
                                        </div>
                                        <h2 className="flex-auto font-semibold text-gray-900">
                                            {format(firstDayCurrentMonth, 'MMMM yyyy ', { locale: fr }).toUpperCase()}
                                        </h2>

                                        <div
                                            onClick={nextMonth}
                                            type="button"
                                            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center  text-gray-400  p-2 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300"
                                        >
                                            <span className="sr-only">Next month</span>
                                            <FontAwesomeIcon icon={faChevronRight} className='p-1' />
                                        </div>
                                        <div
                                            onClick={nextYear}
                                            type="button"
                                            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center  text-gray-400  p-2 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300"
                                        >
                                            <span className="sr-only">Next year</span>
                                            <FontAwesomeIcon icon={faChevronRight} className='p-1 -mr-3 ' />
                                            <FontAwesomeIcon icon={faChevronRight} className='p-1 ' />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
                                        <div className='mt-2' >Dim</div>
                                        <div className='mt-2' >Lu</div>
                                        <div className='mt-2' >Mar</div>
                                        <div className='mt-2' >Mer</div>
                                        <div className='mt-2' >Jeu</div>
                                        <div className='mt-2' >Ven</div>
                                        <div className='mt-2' >Sam</div>



                                        {days.map((day, dayIdx) => (
                                            <div
                                                key={day.toString()}
                                                className={classNames(
                                                    dayIdx === 0 && colStartClasses[getDay(day)],
                                                    'py-1.5 text-sm'
                                                )}
                                            >
                                                <div
                                                    type="button"
                                                    onClick={() => {
                                                        setSelectedDay(day);
                                                        handleDayClick(day); // Appeler handleDayClick lors du clic sur un jour
                                                    }} className={classNames(

                                                        isEqual(day, selectedDay) && 'text-white',


                                                        isEqual(day, start) && ' text-white bg-green-600 rounded-l-full ',

                                                        isAfter(day, start) && isBefore(day, end) && 'bg-green-100',
                                                        isEqual(day, end) && ' text-white bg-green-600 rounded-r-full ',

                                                        !isEqual(day, selectedDay) &&
                                                        isToday(day) && 'text-red-500',

                                                        !isEqual(day, selectedDay) &&
                                                        !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',


                                                        !isEqual(day, selectedDay) &&
                                                        !isToday(day) &&
                                                        !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',

                                                        isEqual(day, selectedDay) && isToday(day) && 'bg-red-500',

                                                        isEqual(day, selectedDay) &&
                                                        !isToday(day) && 'bg-gray-900',

                                                        !isEqual(day, selectedDay) && 'hover:bg-gray-200',

                                                        (isEqual(day, selectedDay) || isToday(day)) &&
                                                        'font-semibold',
                                                        'mx-auto flex h-8 w-full items-center justify-center '
                                                    )}
                                                >
                                                    <time dateTime={format(day, 'yyyy-MM-dd')}>
                                                        {format(day, 'd')}
                                                    </time>
                                                </div>

                                                {/* <div className="w-1 h-1 mx-auto mt-1">

                                                    <div className="w-1 h-1 rounded-full bg-sky-500"></div>

                                                </div> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                )}
            </div>
        </>
    )
}


let colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
]
export default InputPeriodDate

