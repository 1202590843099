import React, { useEffect, useMemo, useState,useContext } from 'react';


import { Link } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";

function AppUsers({app}) {


const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const [users,setUsers]= useState([])
const getUsers = async()=>{

  var _users = await project.api("/get_myusers/:app_id",{args:{app_id:app.app_id}})
  console.log( _users)
  setUsers(_users);
}



useEffect(()=>{
if(project)
{  getUsers()

}
},[project])
 return (

      
     <div  className='w-full flex flex-col py-4 bg-white px-4 rounded-xl text-black md:grid md:grid-cols-2 xl:grid-cols-4  gap-3  text-sm' >
   { users && users.map((user, i) => (
              <div
                className="flex justify-between items-center border-t border-zinc-500 w-full"
                key={i}
              >
                  <div ><Image src={ user.profile?.imageUrl}  className=' m-1 w-8 h-8 rounded-full'/> </div>
                  <div className="flex-grow p-1">{(user.profile.first_name??'') +' '+ (user.profile.last_name??'') +' '+ (user.profile.email??'')}</div>
       
               
                <div className="w-16 flex flex-row justify-center" >
               <Link to={`/chat/${user.account_id}`}><FontAwesomeIcon  icon={fa.faMessage} className='ml-1 rounded-full p-1 text-yellow-700 '  ></FontAwesomeIcon></Link>
                </div>
              </div>
            ))}
     
    </div>
  );
}

export default AppUsers;
