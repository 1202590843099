import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const ButtonTool = ({
    label,
    icon,
    onClick,
    onMouseOver,
    nolabelActive,
    badge,
    active,
    side = "left",
    defaultClass = " border-white  bg-black ",
    activeClass = " bg-opacity-90 text-black bg-white",
    inactiveClass = " text-white bg-black bg-opacity-30",
  }) => {
    var sides = {
      left: {
        parent: " flex-row ",
        default: "  absolute ",
        active: "  right-14 ",
        hover: "  group-hover:right-14  right-80  ",
      },
      topleft: {
        parent: " flex-row ",
        default: "  absolute ",
        active: "  right-14  bottom-12",
        hover:
          "  group-hover:right-14  right-80  group-hover:bottom-12  -bottom-12  ",
      },
      right: {
        parent: " flex-row-reverse ",
        default: " text-left absolute ",
        active: "  left-14 ",
        hover: "  group-hover:left-14  -left-20  ",
      },
      top: {
        parent: " flex-row ",
        default: " absolute ",
        active: "  bottom-12  right-0 ",
        hover: "  group-hover:bottom-12 right-0  -bottom-12  ",
      },
      bottom: {
        parent: " flex-row ",
        default: " absolute ",
        active: " top-12 ",
        hover: "  group-hover:top-12  -top-10  ",
      },
    };
    return (
      <div
        className={
          "flex items-center justify-center text-xs text-white group relative" +
          sides[side].parent
        }
      >
        { ( !active || !nolabelActive) && (
          <div
            className={
              "appbutton  bg-black bg-opacity-70  p-2 rounded-lg text-xs  transition-all   duration-400 " +
              sides[side].default +
              ((false && active )
                ? " block opacity-100 " + sides[side].active
                : "  group-hover:block group-hover:opacity-100 opacity-0 hidden " +
                  sides[side].hover)
            }
          >
            {label}
          </div>
        )}
        <FontAwesomeIcon
          icon={icon}
          className={
            "appbutton   z-20 border bg-opacity-30 border-opacity-50 transition-all duration-200   rounded-full  p-2 h-5 w-5  m-1 mx-2 " +
            defaultClass +
            (active ? activeClass : inactiveClass)
          }
          onClick={onClick}
          onMouseOver={onMouseOver}
        />
        {  badge >0 && <div className="rounded-full bg-green-700 bg-opacity-100 w-4 h-4 items-center justify-center flex text-white text-xs -ml-4 -mt-6 ">{badge}</div>   }
      </div>
    );
  };




  export default ButtonTool;