import React from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';


const InputTextCodeEditor = ({id,label,onChange,value,className,bg="inherit",infos,language}) => {
return (
    <>
    <div className={"relative bg-inherit " +className } >
     <div htmlFor={ "input_"+label } className={"text-left  text-gray-500 " +bg }>{label}</div>
    <CodeEditor
      value={value}
      className="w-full rounded-md"
      language={language}
      
      placeholder="Please enter JS code."
      onChange={(evn) => onChange(evn.target.value)}
      padding={15}
      style={{
        backgroundColor: "#050525",color :"white",caretColor: "white",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
    />
    {infos  &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{infos}</div>}
    </div>
    
</> 
)
}
export default InputTextCodeEditor