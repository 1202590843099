import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";
import InputText from "../../../../../components/inputs/InputText";
import InputSelect from "../../../../../components/inputs/InputSelect";
import InputTextArea from "../../../../../components/inputs/InputTextArea";
import InputSelectPropSchema from "../../../../../components/inputs/InputSelectPropSchema";


const schema_labelData = ({ label, onChange,value,className})=>{

  return <div className="flex flex-col items-center justify-center w-full"><InputText className={"w-full " +className} label={label} onChange={onChange} value={value}   /> 
  <span  className="italic text-xs">Schémal Label</span></div>

}
const propData = ({ label, onChange,value,className})=>{

  return  <InputSelectPropSchema
  label={label}
  className={"text-black w-full my-1 " +className}
  value={value}
  onChange={(schema_prop) =>onChange(schema_prop) 
  }
  schemas={[]}
  parentSchema_prop={value}
  placeholder={"Choisir le schéma"}
  options={[]}
/>

}
const textData = ({ label, onChange,value,className})=>{

  return <InputText className={className} label={label} onChange={onChange} value={value}   /> 

}
const expressionData = ({ label, onChange,value,className})=>{

  return <InputText className={className} label={label} onChange={onChange} value={value}   /> 

}


const textAreaData = ({ label, onChange,value,className})=>{

  return <InputTextArea className={className} label={label} onChange={onChange} value={value}   /> 

}
const eventHandlerData =( { label,onChange,value,className}) =>{
  return <InputTextArea   className={className} label={label + `(e)`} onChange={onChange} value={value}    /> 

}

const computeData =( { label,onChange,value,className}) =>{
  return <InputTextArea   className={className} label={label } onChange={onChange} value={value}    /> 
}




const selectData = ({ label, onChange,value,options,className})=>{
  return <InputSelect   className={className} label={label} onChange={onChange} value={value} options={options}   /> 
}




const typeDatas = { textData,schema_labelData,propData,expressionData, computeData,selectData ,textAreaData ,eventHandlerData}

const ShowTagStructure= ((  {setShow  ,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools} )=>{

const [attrDatas,_setAttrDatas] = useState( getStructure(template.structure,selected)?.attrDatas??[])

const setAttrDatas=(_attrDatas)=>{

  updateTemplateStructure(  {...getStructure(template.structure,selected),attrDatas:_attrDatas},selected)
  _setAttrDatas(_attrDatas)

}

const setAttr=(key,value)=>{
    
  setAttrDatas( {...attrDatas,[key]:{...attrDatas[key] , value  }})
}

const addAttr=(attr)=>{
    
  setAttrDatas( {...attrDatas,[attr.key]:attr})
}
const removeAttr=(key)=>{

 var _attrDatas= attrDatas
 delete _attrDatas[key]
 setAttrDatas( {..._attrDatas})

}

const [newAttr,setNewAttr]=useState({})



// si Schema_label ou Schema_prop sont définis 
// setValue(e.target.value)
// value

//  setContent("xxx","xxxx")    content[”zzzz"]


    if(!selected ) return <></>  
      return  <div id={"ShowTagStructure"} className=" flex-none w-80  m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
    
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faPen}></FontAwesomeIcon>
      Paramètres Tag 
        <FontAwesomeIcon icon={fa.faClose} onClick={ ()=> setShow((show) => ({ ...show, Tag: !show.Tag })) }  className="appbutton"  ></FontAwesomeIcon>
        </div>
     <div className="p-2 bg-white w-full flex flex-col"> 
     <InputText label={"TagName"} value={ getStructure(template.structure,selected).tagName} 
     onChange={(tagName)=>  updateTemplateStructure(
      {...getStructure(template.structure,selected),tagName},selected)} />
      {
     Object.keys( attrDatas).map( (key,id)=>{
      const attr = attrDatas[key];
var Component = attr  ? typeDatas[attr.type] : null

  return <div key={id}  className="flex flex-col w-full mt-2">
   
    <div  className="flex flex-row w-full"> 
{
          Component &&
    <Component  className={"grow"} label={key} onChange={ (value)=> { 
      setAttr(key,value);


    }
 } 

  value={attr.value }
    />
    
}   <FontAwesomeIcon  icon={fa.faClose} className={"text-white rounded-full m-1 bg-red-700 p-1"} onClick={()=>removeAttr(key)} />
  </div> 
 </div>


} )
        

      }
  


  <div   className="flex flex-col w-full p-2 rounded-t-xl bg-green-600 text-white text-xs text-center mt-4" >Nouvel attribut</div>
    <div  className="flex flex-row w-full mt-2 items-center justify-center"> 

     <InputText id="newAttr.key" label={ "Nouvel attribut"} className={"grow"} value={newAttr.key} onChange={ (key)=>{  

setNewAttr({...newAttr,key})
   
      
    }  }   />
  <InputSelect id="newAttr.type" label={ "type"}  value={  newAttr.type   } options={[ "-" ,...Object.keys(typeDatas).map(key=>({label:key.replace("Data",""), value:key}))] } className={" grow"} onChange={(type)=>{
   setNewAttr({...newAttr,type})
        }} />
<FontAwesomeIcon   icon={fa.faPlus} className={"text-white  rounded-full  p-2 m-1 " +( newAttr.key && newAttr.type  ?"bg-green-600":"bg-gray-600")} onClick={()=>{
  addAttr(  newAttr )
  document.getElementById("newAttr.key").value=""
  document.getElementById("newAttr.type").value="-"
  setNewAttr({})
  }} /></div> 

    </div>
    </div>
    })
    
  

    export default ShowTagStructure