import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState, useEffect } from "react";
import { getStructure } from "../../../../../tools/tpl";
import availableClasses from "./tailwind_classes/allClasses.js";

let classesBuffer = [];
let sousCategoryClasses = "";

const ToolAlign = ({ updateStructure, structure, idObj, selectTpl }) => {
    const [show, setShow] = useState(false);
    const classes = useMemo(() => {
        var _structure = getStructure(structure, idObj);
        var parts = _structure.className
            .split(" ")
            .filter((classe) => classe && classe !== "");
        return parts;
    }, [structure, idObj]);


    const setTool = (newclass) => {
        var _classes = classes;
        if (newclass.split("-").includes("justify")) {
            sousCategoryClasses = "Justify Content";
        } else {
            sousCategoryClasses = "Align Items";
        }
        if (!_classes.includes(newclass)) {
            _classes = _classes.filter((c) => !availableClasses[sousCategoryClasses].includes(c));
            if (!_classes.includes("flex")) {
                _classes.push("flex");
                _classes.push("flex-col");
            }
            updateStructure(
                { className: [..._classes, newclass].join(" ") },
                idObj
            );
        }
        sousCategoryClasses = "";
    };



    const [filter, setFilter] = useState();

    const removeClasse = (classe) => {
        var _classes = classes.filter((_classe) => _classe !== classe);

        updateStructure({ className: _classes.join(" ") }, idObj);
    };

    const addClasse = (classe) => {
        var _classes = classes;

        console.log(classes)

        _classes = _classes.filter((c) => !availableClasses[sousCategoryClasses].includes(c));

        _classes.push(classe);

        updateStructure({ className: _classes.join(" ") }, idObj);
    };

    const hoverStart = (classe) => {
        var obj = document.getElementById(idObj);
        // console.log("hoverStart", obj, classe);
        if (obj) classesBuffer = obj.classList;
        if (obj){
            if(!classes.includes("flex")){
                obj.classList.add("flex");
                obj.classList.add("flex-col");
            }
            obj.classList.add(classe);
        }
    };

    const hoverEnd = (classe) => {
        var obj = document.getElementById(idObj);
        // console.log("hoverEnd", obj, classe);
        if (obj) obj.classList.remove(classe);
        if (obj) obj.classList = classesBuffer;
        classesBuffer = [];
    };


    return (
        <>
            {show && (
                <div
                    className={
                        "w-full h-full justify-between flex flex-col relative  mr-14"
                    }
                >
                    <div
                        className="w-full flex-nowrap text-start grow overflow-y-scroll "
                        style={{ maxHeight: "40vh" }}
                    >
                        <div className=" w-full flex-nowrap text-start ">
                            <div>

                                <div
                                    className={
                                        "  inline-flex mt-2 appbutton border-2  border-white py-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("justify-between")}
                                    onMouseOver={() => hoverStart("justify-between")}
                                    onMouseOut={() => hoverEnd("justify-between")}
                                >
                                    <div
                                        className="pr-1 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="p-1 ml-2 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="pl-1 py-2 ml-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-flex appbutton border-2 px-1 border-white py-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("justify-around")}
                                    onMouseOver={() => hoverStart("justify-around")}
                                    onMouseOut={() => hoverEnd("justify-around")}
                                >
                                    <div
                                        className="pr-1 py-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="p-1 ml-2 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="pl-1 py-2 ml-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-flex appbutton border-2 px-1 border-white py-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("justify-evenly")}
                                    onMouseOver={() => hoverStart("justify-evenly")}
                                    onMouseOut={() => hoverEnd("justify-evenly")}
                                >
                                    <div
                                        className="pr-1 py-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="p-1 ml-1 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="pl-1 py-2 ml-1 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-flex appbutton border-2 pr-2 border-white py-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("justify-start")}
                                    onMouseOver={() => hoverStart("justify-start")}
                                    onMouseOut={() => hoverEnd("justify-start")}
                                >
                                    <div
                                        className="pr-1 py-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="p-1 ml-1 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="pl-1 py-2 ml-1 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-flex appbutton border-2 px-2 border-white py-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("justify-center")}
                                    onMouseOver={() => hoverStart("justify-center")}
                                    onMouseOut={() => hoverEnd("justify-center")}
                                >
                                    <div
                                        className="pr-1 py-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="p-1 ml-1 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="pl-1 py-2 ml-1 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-flex appbutton border-2 pl-2 border-white py-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("justify-end")}
                                    onMouseOver={() => hoverStart("justify-end")}
                                    onMouseOut={() => hoverEnd("justify-end")}
                                >
                                    <div
                                        className="pr-1 py-2 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="p-1 ml-1 py-2  bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                    <div
                                        className="pl-1 py-2 ml-1 bg-white"
                                        style={{ marginRight: "2px" }}
                                    ></div>
                                </div>


                                <div
                                    className={
                                        "  inline-block  mt-3 flex-col appbutton border-2  border-white  p-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("items-start")}
                                    onMouseOver={() => hoverStart("items-start")}
                                    onMouseOut={() => hoverEnd("items-start")}
                                >
                                    <div
                                        className="pt-1 mr-4  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                    <div
                                        className="pt-1 pr-6  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                    <div
                                        className="pt-1 mr-3  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-block  mt-3 flex-col appbutton border-2  border-white  p-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("items-center")}
                                    onMouseOver={() => hoverStart("items-center")}
                                    onMouseOut={() => hoverEnd("items-center")}
                                >
                                    <div
                                        className="pt-1 mx-2  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                    <div
                                        className="pt-1 pr-6  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                    <div
                                        className="pt-1 mx-1  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                </div>

                                <div
                                    className={
                                        "  inline-block mt-3  flex-col appbutton border-2  border-white  p-1  mx-2 text-xs bg-black text-white bg-opacity-30 "
                                    }
                                    onClick={() => setTool("items-end")}
                                    onMouseOver={() => hoverStart("items-end")}
                                    onMouseOut={() => hoverEnd("items-end")}
                                >
                                    <div
                                        className="pt-1 ml-4  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                    <div
                                        className="pt-1 pr-6  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                    <div
                                        className="pt-1 ml-3  bg-white"
                                        style={{ marginBottom: "2px" }}
                                    ></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={
                    " inline-flex appbutton border-2  border-white  p-2  m-2 text-xs" +
                    (show
                        ? " bg-white text-black bg-opacity-80 "
                        : " bg-black text-white bg-opacity-30 ")
                }
                onClick={() => setShow(!show)}
            >
                Alignement{'>'}
            </div>
        </>
    );
};

export default ToolAlign;