import React, { useEffect, useMemo, useState,useContext } from 'react';

import { setToken } from '../../../../service/mftc-api';
import '../../../../App.css';
import { Link,useNavigate } from 'react-router-dom';
import { MfctContext } from "../../../../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import UpdateSW from '../../../../UpdateSW';

function Install() {
  const navigate = useNavigate ();
const [showHowToInstalliOS,setShowHowToInstalliOS]=useState(false);
const [connectWindow,setConnectWindow]=useState(null)



const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

  const [ deferredPrompt,setDeferredPrompt]=useState();
useEffect(()=>{
  window.addEventListener('beforeinstallprompt', (e) => {
      setDeferredPrompt( e);
  });
},[]);

const InstallButton= ({title1,title2,callback})=>{
  return   <div  className=" flex flex-row m-4 justify-center items-center bg-zinc-900 border border-zinc-400 text-white p-2 rounded-md" onClick={callback}> 
  <FontAwesomeIcon  icon={fa.faDownload} className='text-white text-4xl p-1 mr-1' />
  <div  className='flex flex-col' >
<span className='text-xs '> {title1 ?? "Install with" } </span>
<span className='text-xl font-bold '>{title2 ?? "AppIsYou"}  </span>
  </div>

  </div>
}


const install = async()=>{
 
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
        setDeferredPrompt();
    }
}


useEffect(()=>   
{
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  console.log('isStandalone',isStandalone)
  console.log('window.navigator.standalone ',window.navigator.standalone )
  
  if (window.navigator.standalone || isStandalone) {
    navigate("/")

} 
}
,[])

const showIosInstallModal=useMemo(()=> {
  // detect if the device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  
  
  // show the modal only once


  const shouldShowModalResponse =  isIos() ;

  return shouldShowModalResponse;
},[]);

  return (
    <div className="App "> 
    <header className="min-h-screen text-zinc-100 flex flex-col justify-center items-center relative" >
          <div className='h-1/2 w-full -z-10 absolute top-0 '  style={{
      background: project?.status?.primary_color ?? "#EEE"}}></div>
        <div className='h-1/2 w-full -z-10 absolute top-1/2 '  style={{
      background: project?.status?.secondary_color ?? "#333"}}></div>
    Landing Page {project && project.status && project.status.name}
      <img src={  process.env.REACT_APP_MFTC_SERVER_URL_FILES+ "profile/user.png" +(project?.domain ? `?domain=${project.domain}`:"")} className="App-logo rounded-xl border-8 border-zinc-950" alt="logo" />
      <p> 
     { (deferredPrompt) &&      <InstallButton  callback={install} />
     }
     
     {!showHowToInstalliOS && showIosInstallModal && 
      <InstallButton  callback={()=>setShowHowToInstalliOS(true)} />}
     {  showHowToInstalliOS && <p>tap the “share” icon, and then tap on “Add to home screen”</p> }
      </p>

     
    </header>
 <UpdateSW />
  </div>)
}

export default Install;
