


class ApplicationClass {

  static classe_id =  4;
 static props = [
          {
              "name": "Name"
          },
          {
              "name": "Logo",
              "type": "image-app",
              "classe_id": null
          },
          {
              "name": "Description",
              "type": "long text",
              "classe_id": null
          },
          {
              "name": "Produit",
              "type": "classe",
              "classe_id": "12",
              "type_nombre": "I"
          },
          {
              "name": "primary_color",
              "type": "color",
              "classe_id": null
          },
          {
              "name": "secondary",
              "type": "color",
              "classe_id": null
          },
          {
              "name": "page",
              "type": "classe",
              "classe_id": "9",
              "type_nombre": "I",
              "max": "4"
          },
          {
              "name": "installer",
              "type": "action",
              "classe_id": null,
              "type_nombre": "O"
          },
          {
              "name": "partager",
              "type": "action",
              "classe_id": null
          },
          {
              "name": "User",
              "type": "classe",
              "classe_id": "3",
              "type_nombre": "O"
          }
      ]



    constructor({ app,id, project,setContent,loadRequired} )
    {

      this.app=app;
      this.app_id=id;

      this.project = project;
      this.content= {};
      this.setContent = setContent;
      this.loadRequired=loadRequired;
    }

     async init(){
      if(!this.app)
      {
        var _app = await this.project.api("/get_app/:app_id", {
            args: { app_id: this.app_id },
          });
          console.log("App ", _app);
          this.app = _app;
      }
          this.content= {
            "Name": _app.name,
            "Logo":
              process.env.REACT_APP_MFTC_SERVER_URL_FILES +
              `profiles/${_app.domain}/user.png`,
            "Domain": _app.domain,
            "Description": _app.description,
            "primary_color": _app.profile.primary_color,
            "secondary_color": _app.profile.secondary_color,
            "page" : [],
            "Produit":[],
            "installer":this.installer,
            "partager": this.partager,
          "User":null  };
         if(this.loadRequired?.includes("page")) await this.pageLoad();
         if(this.loadRequired?.includes("User")) await this.UserLoad();
         if(this.loadRequired?.includes("Produit")) await this.ProduitLoad();

        if( this.setContent)  this.setContent(this.content)
     }

     addContent(prefix,content,loadRequired=[]){
      for (const [key, value] of Object.entries(content)) {
        var parts = key.split(".");
        if(parts.length>1 &&  !loadRequired.includes(parts[0] )) return;
        this.content[ `${prefix}.${key}`  ]=value
    }
    }

/* Loaders */

async pageLoad(){
   var _pages = [];
   _pages = await this.project.api("/get_mypages/:app_id",{args:{app_id:this.app_id}})

for(var i=0; i<_pages.length; i++){
var page =_pages[i]
var pageObject = await  this.project.getObject({className: "PageClass",id:page.page_id, page });

 this.content["page"].push(  pageObject) 
 this.addContent(`pages[${i}]`, pageObject.content)
 
}

  

}
async UserLoad(){
  var user =null
  user = this.project.getAuthUser();
  if(user){
    var userObject = await  this.project.getObject( {className: "UserClass",id:user.user_id, user});

 this.content["User"]=  userObject
 this.addContent(`User`,userObject.content)
  }
  
}
async ProduitLoad(){
  
}
/* Actions */

installer(){}

partager(){

       }




    getContent(){

    }

} 



export default{ ApplicationClass};