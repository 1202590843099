import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";



const ShowTemplateStructure= ((  { setIsMod,isMod,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,key_schemas} )=>{
    /* 
    console.log("ShowTemplateStructure 1 "  )
    const [_structure,setStructure] = useState(getStructure(template.structure,selected))
    
    const update=async (structure)=>{
      setStructure(structure)
       await updateTemplateStructure(structure,selected)
    }
    */
    if(!selected ) return <></>  
      return  <div id={"ShowTemplateStructure"} className=" flex-none w-80  m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
    
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faPen}></FontAwesomeIcon>
      Paramètres Elément 
        <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setIsMod(!isMod)}  className="appbutton"  ></FontAwesomeIcon>
        </div>
     <div className="p-2 bg-white w-full flex flex-col"> <TemplateStructure   key={"paramTemplateStructure" }  template={ getStructure( template.structure,selected) } 
    setStructure={async (structure)=>{
      
       await updateTemplateStructure(structure,selected)
    }  }
    shows={{ Name: true, Labels: false, Style: null, Click: false, Vars: false }}
    templates={templates} 
    schemas={schemas}
    globalSchema={globalSchema}
    id={selected}
    selected={selected}
    app_id={template.app_id}      
    content={content}   
    key_schemas={key_schemas} 
    />
    </div>
    <div className="flex-none  w-full flex-nowrap text-center  ">
    {Object.values(ComponentStructTools).map((ComponentStructTools, i) => (
          <ComponentStructTools
            structure={ template.structure}
            updateStructure={updateTemplateStructure}
            selectTpl={selectTpl}
            idObj={selected}
            key={i}
          />
        ))}
        </div>
    </div>
    })
    
  

    export default ShowTemplateStructure