import React, { useEffect, useState, useContext } from 'react';
import { MfctContext } from "../../../../context";
import Image from '../../../../components/Image';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../../App.css';

function AppUsers({ app }) {
  const { project } = useContext(MfctContext);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [visibleRole, setVisibleRole] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const getRoles = async () => {
    var _roles = await project.api("/get_myroles/:app_id", { args: { app_id: app.app_id } });
    setRoles(_roles);
  }

  const getUsers = async () => {
    var _users = await project.api("/get_myusers/:app_id", { args: { app_id: app.app_id } });
    console.log(_users);
    setUsers(_users);
    // Initialiser l'état visible pour chaque utilisateur
    setVisibleRole(new Array(_users.length).fill(false));
  }

  useEffect(() => {
    if (project) {
      getUsers();
      getRoles();
    }
  }, [project]);

  const delRole = (user, role_id) => {


    var roles = user.profile.roles ?? [];

    roles = roles.filter(role_idC => role_idC !== role_id)

    setUsers(users => users.map(userCandidate => userCandidate.account_id === user.account_id ? { ...userCandidate, profile: { ...userCandidate.profile, roles } } : userCandidate))
  }

  const addRole = (user, role_id) => {

    var roles = user.profile.roles ?? [];


    roles.push(role_id)

    setUsers(users => users.map(userCandidate => userCandidate.account_id === user.account_id ? { ...userCandidate, profile: { ...userCandidate.profile, roles } } : userCandidate))
  }

  const saveUser = async (user) => {

    await project.api("/set_account", { body: user });

  }
  const toggleRoles = (index, roleId) => {
    const updatedVisibleRole = [...visibleRole];
    updatedVisibleRole[index] = !updatedVisibleRole[index];

    const isSelected = selectedRoles.includes(roleId);

    if (isSelected) {
      // Si le rôle est déjà sélectionné, supprimez-le de la liste des rôles sélectionnés
      setSelectedRoles(selectedRoles.filter(selectedRoleId => selectedRoleId !== roleId));
    } else {
      // Sinon, ajoutez-le à la liste des rôles sélectionnés
      setSelectedRoles([...selectedRoles, roleId]);
    }

    setVisibleRole(updatedVisibleRole);
  }

  return (
    <div className='w-full flex flex-col py-4 bg-white px-4 rounded-xl text-black  text-sm'>
      {users && users.map((user, i) => (
        <div key={i}>


          <div className="flex flex-col items-center border rounded-xl border-zinc-500 w-full mb-2 ">
            <div className="flex justify-between items-center mb-2 w-full ">
              <div><Image src={user.profile?.imageUrl} className='m-1 w-8 h-8 rounded-full' /></div>
              <div className="flex-grow p-1">{(user.profile.first_name ?? '') + ' ' + (user.profile.last_name ?? '') + ' ' + (user.profile.email ?? '')}</div>
              <div className="w-16 flex flex-row justify-center items-center align-middle text-green-700 gap-2">

                <Link to={`/chat/${user.account_id}`}>
                  <FontAwesomeIcon icon={fa.faMessage} className='tooltip ml-1 rounded-full p-1 text-yellow-700' title="chat" />

                </Link>
              </div>
            </div>
            {
              <div className=' px-2 text-xs border-t w-full border-dotted border-zinc-500 py-2 text-start flex flex-wrap items-center'>
                <div className='p-2 font-bold '>Roles</div>
                {roles.filter(role => user.profile.roles && user.profile.roles.find(r_id => role.role_id === r_id)).map(role => (
                  <span key={role.role_id} className='mr-2 inline-block px-2 py-1 border-2 rounded-full bg-blue-500 text-white  ' onClick={() => delRole(user, role.role_id)}>
                    {role?.content.title}


                  </span>


                ))}

                <FontAwesomeIcon
                  icon={visibleRole[i] &&
                    [i] ? fa.faChevronLeft : fa.faChevronRight}
                  onClick={() => toggleRoles(i)}
                  title="Administrer les rôles"
                  className="tooltip  text-xs p-1 w-2 h-2  border border-gray-500 rounded-full mx-1  "
                />
                {visibleRole[i] &&
                  <>
                    {roles.filter(role => !user.profile.roles || !user.profile.roles.find(r_id => role.role_id === r_id)).map(role => (
                      <span key={role.role_id} className='mr-2 inline-block px-2 py-1 border-2 rounded-xl border-gray-500 ' onClick={() => addRole(user, role.role_id)}>
                        {role.content.title}
                      </span>
                    ))}
                  </>
                }
                <div className=' grow    flex justify-end'   > <div onClick={() => saveUser(user)} className='p-1 bg-green-700 text-white  rounded-full px-2'> Enregistrer Rôles </div>     </div>
              </div>
            }



          </div>

        </div>
      ))}
    </div>
  );
}

export default AppUsers;
