import React, { useEffect, useMemo, useState, useContext } from "react";

import { setToken } from "../../../../service/mftc-api";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

import * as serviceWorkerRegistration from "../../../../serviceWorkerRegistration";
import LandingPage from "../landingpage/LandingPage";

import Image from "../../../../components/Image";
import "../../../../App.css";
import { MfctContext } from "../../../../context";
import Notifications from "../../../../components/permissions/Notifications";
import { data } from "../../../../service/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header1 from "../../../../components/Header1";
import Milestones from "./Milestones";

function Home() {
  const navigate = useNavigate();
  const [showHowToInstalliOS, setShowHowToInstalliOS] = useState(false);
  const [connectWindow, setConnectWindow] = useState(null);

  const { project, getProject, setAuthUser, authUser } =
    useContext(MfctContext);
const [ menuVisible ,setMenuVisible]= useState()
  const [deferredPrompt, setDeferredPrompt] = useState();
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      setDeferredPrompt(e);
    });
  }, []);

  useEffect(() => {
    document.title = "Home";
  }, []);

  const install = async () => {
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === "accepted") {
      setDeferredPrompt();
    }
  };

  const connect = async () => {
    var myUrl = window.location.href;
    var urlauth = process.env.REACT_APP_URL_AUTH;
    var parts = urlauth.split(":300");
    if (parts.length > 1) {
      var parts0 = myUrl.split(":300");
      parts[0] = parts0[0];
      urlauth = parts.join(":300");
    }

    var wconnect = window.open(urlauth, "appisyou_auth");

    window.addEventListener(
      "message",
      function (e) {
        // e.data hold the message from child
        console.log(e.data);
        if (e.data.token && e.data.authUser) {
          setToken(e.data.token, e.data.authUser);

          setAuthUser(e.data.authUser);
          wconnect.close();
        }
      },
      false
    );
  };

  const logout = async () => {
    serviceWorkerRegistration.unregister();
    setToken();

    setAuthUser();
    //window.location.href ="/"
  };

  const showIosInstallModal = useMemo(() => {
    // detect if the device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    // check if the device is in standalone mode
    const isInStandaloneMode = () => {
      return "standalone" in window.navigator && window.navigator.standalone;
    };

    // show the modal only once

    const shouldShowModalResponse = isIos() && !isInStandaloneMode();

    return shouldShowModalResponse;
  }, []);

  return (
    <div className="App w-full text-white safearea     ">
      <div className="bg-gradient-to-r from-green-500 hidden"></div> 
       <Header1  title={"AppIsYou"}   logo ={ <Image type="logo" className="w-8 h-8 rounded-xl " alt="logo" />}  
       
        iconRight={ authUser ? fa.faGear :  fa.faRightToBracket}
        linkRight={ authUser ? "/parameters" : null}
        clickRight={ authUser ? null : connect}
       />
      
<div className="w-full flex flex-col md:flex-row "    >

<div className=" flex flex-col ">       
    {authUser && (
        <div className="flex flex-col text-xs w-full">
<div
            onClick={()=>setMenuVisible(!menuVisible)}
                className=" md:hidden  w-full p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-right  "
              >
                <FontAwesomeIcon  icon={fa.faBurger}  className="mx-2 appbutton "/>
              
              </div>
          <div className={ "p-2  flex-col w-full  justify-between items-center"+ ( menuVisible ? " flex ":" hidden md:flex") }>
          {data.isGranted(authUser, null, "app", "read") && (
              <Link
                to="/"
                className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left  appbutton "
              >
                <FontAwesomeIcon  icon={fa.faProjectDiagram}  className="mx-2"/>
                Milestones
              </Link>
            )}
            {data.isGranted(authUser, null, "app", "read") && (
              <Link
                to="/apps"
                className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left  appbutton "
              >
                <FontAwesomeIcon  icon={fa.faMobile}  className="mx-2"/>
                Applications
              </Link>
            )}
            {data.isGranted(authUser, null, "user", "*") && (
              <Link
                to="/users"
                className="  w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left   appbutton "
              >
                   <FontAwesomeIcon  icon={fa.faUserCircle}  className="mx-2"/>
                Utilisateurs
              </Link>
            )}
            <Link
              to="/users"
              className=" hidden w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left   appbutton "
            >
                <FontAwesomeIcon  icon={fa.faUserCircle}  className="mx-2"/>
              Clients
            </Link>
            {data.isGranted(authUser, null, "user", "*") && (
              <Link
                to="/webpush"
                className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left   appbutton "
              >
                   <FontAwesomeIcon  icon={fa.faBell}  className="mx-2"/>
                WebPush
              </Link>
            )}
            {data.isGranted(authUser, null, "schema", "*") && (
              <Link 
                to="/schemas"
                className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left   appbutton "
              >
                    <FontAwesomeIcon  icon={fa.faFolder}  className="mx-2"/>
                Schémas de données
              </Link>
            )}
            {data.isGranted(authUser, null, "app", "*") && (
              <div className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left  appbutton ">
                   <FontAwesomeIcon  icon={fa.faHouse}  className="mx-2"/>
                <Link to="/hostings">Hébergements</Link>
              </div>
            )}
                       {data.isGranted(authUser, null, "app", "*") && (
              <div className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left  appbutton ">
                   <FontAwesomeIcon  icon={fa.faGears}  className="mx-2"/>
                <Link to="/deployments">Déploiements</Link>
              </div>
            )}
            {data.isGranted(authUser, null, "template", "*") && (
              <Link
                to="/templates"
                className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left  appbutton "
              >
                   <FontAwesomeIcon  icon={fa.faWindowRestore}  className="mx-2"/>
                Templates
              </Link>
            )}
                        {data.isGranted(authUser, null, "action", "*") && (
              <Link
                to="/actions"
                className=" w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left  appbutton "
              >
                   <FontAwesomeIcon  icon={fa.faPlayCircle}  className="mx-2"/>
                Actions
              </Link>
            )}
          </div>
        </div>
      )}
      {!authUser && <LandingPage />}

    </div>
    <div  className=" flex flex-col grow"> 
      
      
     

<Milestones/>

       </div>
    </div>
    </div>
  );
}

export default Home;
