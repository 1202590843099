import { io, connect } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
//const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

var URL = process.env.REACT_APP_MFTC_SERVER_URL_FILES;
var parts = window.location.href.split(":30");
if (parts.length > 1) {
  var _parts = URL.split(":30");
  _parts[0] = parts[0];
  URL = _parts.join(":30");
}
const socket = connect(URL, {
  autoConnect: false,
});

var watchers = {};

const watch = (event, callback) => {
  if(!event) return 
  var id = "watch-" + Date.now();

  if (Object.keys(watchers).length === 0) socket.connect();

  watchers[id] = { event, callback };
  socket.on(event, callback);
  return id;
};

const unwatch = (id) => {
  if(!id ||  !watchers[id] || !watchers[id].event) return 
  socket.off(watchers[id].event, watchers[id].callback);

  delete watchers[id];

  if (Object.keys(watchers).length === 0) socket.disconnect();
};

const emit = (channel, data) => {
  if (Object.keys(watchers).length === 0) socket.connect();

  socket.emit(channel, data);

  if (Object.keys(watchers).length === 0) socket.disconnect();
};

export { socket, watch, unwatch, emit };
