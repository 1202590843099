import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getStructure } from "../../../../../tools/tpl";
import { data } from "../../../../../service/data";


const ToolWizardAdd = ({ updateStructure, structure, idObj, selectTpl }) => {


const addWizard=async ()=>{

   var title = window.prompt("Donner un nom à votre template modèle ", "Modèle "+ Date.now())
if( !title) return
   var template = {title, schema_id:"285", structure: getStructure(structure,idObj)  }

await data.save_template(template )
}

  return (
    <>
    
      <div
        className={
          " inline-flex appbutton border-2  border-white  p-2  m-2 text-xs rounded-full " +
          (
            " bg-black text-white bg-opacity-30 ")
        }
        onClick={addWizard}
      >
        <FontAwesomeIcon   icon={ fa.faMagic} />
        <FontAwesomeIcon  className=" w-3 h-3 -ml-0 -mt-1" icon={ fa.faPlus} />
      </div>
    </>
  );
};
export default ToolWizardAdd;
