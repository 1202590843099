import React, { useEffect, useMemo, useState,useContext } from 'react';
import { Link,useParams } from 'react-router-dom';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Content from '../../../../components/Content';
import { addWorkingStone } from '../../../../service/milestone';

function AppSchemas({app}) {


const [contents,setContents]= useState([])
const { project, getProject,setAuthUser,authUser,     getGlobalData} = useContext (MfctContext);
const [schema,setSchema]=useState();
const [schemas, setSchemas] = useState([]);



useEffect(()=> {  
  addWorkingStone( app,"Schemas","app" )   }   ,[app])

const getContents = async()=>{
  await getGlobalData()
  // todo use getfilterrecords 
  var _schemas=  await project.api("/get_schemas/:app_id",{args:{app_id:app.app_id} })

  var _contents = _schemas.filter(t=>t.app_id===app.app_id);
  console.log( _contents)
    setContents(_contents);
}

const  deleteContent = async(content)=>{
  if (!window.confirm("Are you sure you want to delete '" + content.name+"'?")) return
   await project.api("/delete_content/:content_id",{args:{content_id:content.content_id}})
await    getContents();
}

useEffect(()=>{
if(project)
  getContents()
 
},[project])
 return (
    <div className="App w-full"> 

            <div  className='w-full my-2 flex flex-wrap py-2  rounded-xl ' >
   {  contents.filter(c => !schema?.content_id || c.schema_id===schema.content_id).map((content,i)=>
  <div className="w-1/2 lg:w-1/3  xl:w-1/4 p-2 " key={i} >
     <Content className={"bg-white w-full text-black  border-none "} content={content }  key={i} deleteContent={deleteContent} isEditable={true}   schema={schemas.find(s=>s.content_id===content.schema_id)   } />
</div>
   ) 
     }
     </div>   
      <Link to={   `/app/${app.app_id}/schema/add`   }>
      <div  className=' text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer Schéma</div></Link>     
      
    </div>
  );
}

export default AppSchemas;