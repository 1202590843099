import React, { useEffect, useMemo, useState,useContext } from 'react';


import { Link } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header1 from '../../../../components/Header1';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import VarContent from '../../../../components/VarContent';


function Users() {

const [users,setUsers]= useState([])
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const [roles, setRoles] = useState([]);
const [visibleRole, setVisibleRole] = useState([]);
const [selectedRoles, setSelectedRoles] = useState([]);

const [filterRole, setFilterRole] = useState(''); // Ajout du filtre par rôle


const getRoles = async () => {
  var _roles = await project.api("/get_myroles/:app_id", { args: { app_id: 56} });
  setRoles(_roles);
}


const getUsers = async () => {
  try {
    var _users = await project.api('/get_users', { body: {} });
   

    setUsers(_users);
        // Initialiser l'état visible pour chaque utilisateur
        setVisibleRole(new Array(_users.length).fill(false));
    // Initialisation des utilisateurs filtrés avec tous les utilisateurs
    
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const  deleteAccount = async(user)=>{
  if (!window.confirm("Are you sure you want to delete '" + user.profile.email+"'?")) return
   await project.api("/delete_user/:user_id",{args:{user_id:user.user_id}})
await    getUsers();
}


const delRole = (user, role_id) => {

  var roles = user.profile?.roles ?? [];

  roles = roles.filter(role_idC => role_idC !== role_id)

  setUsers(users => users.map(userCandidate => userCandidate.user_id === user.user_id ? { ...userCandidate,modified:true, profile: { ...userCandidate.profile??{}, roles } } : userCandidate))
}

const addRole = (user, role_id) => {

  var roles = user.profile?.roles ?? [];


  roles.push(role_id)

  setUsers(users => users.map(userCandidate => userCandidate.user_id === user.user_id ? { ...userCandidate,modified:true, profile: { ...userCandidate.profile??{}, roles } } : userCandidate))
}

const saveUser = async (user) => {

  await project.api("/set_user_profile", { body:{ user_id : user.user_id, profile:{ roles:user.profile.roles } } });
  setUsers(users => users.map(userCandidate => userCandidate.user_id === user.user_id ? { ...userCandidate,modified:false} : userCandidate))

}
const toggleRoles = (index, roleId) => {
  const updatedVisibleRole = [...visibleRole];
  updatedVisibleRole[index] = !updatedVisibleRole[index];

  const isSelected = selectedRoles.includes(roleId);

  if (isSelected) {
    // Si le rôle est déjà sélectionné, supprimez-le de la liste des rôles sélectionnés
    setSelectedRoles(selectedRoles.filter(selectedRoleId => selectedRoleId !== roleId));
  } else {
    // Sinon, ajoutez-le à la liste des rôles sélectionnés
    setSelectedRoles([...selectedRoles, roleId]);
  }

  setVisibleRole(updatedVisibleRole);
}
// Fonction pour filtrer les utilisateurs par rôle
const filteredUsers = useMemo(() => {
  if (filterRole) {
   return  users.filter((user) => user.role === filterRole);

  } else {
    // Si aucun filtre n'est sélectionné, afficher tous les utilisateurs
   return (users);
  }
},[filterRole,users]);

// Réinitialiser le filtre
const resetFilter = () => {
  setFilterRole('');
 
};

const refreshUsers = () => {
  getUsers();
};

useEffect(()=>{
if(project)
 {
  getRoles()
  getUsers()
 }
},[project])
 return (
  <div className="App ">
     <Header1   linkLeft={"/"} title={"Utilisateurs AppIsYou"}     />

    <div className="w-full flex flex-col p-2 text-sm text-zinc-800 p-4">
      {/* Filtres */}
  
      {/* Tableau des utilisateurs */}
      <div className="w-full bg-white rounded-md p-2">
            <div className="flex justify-between border-b border-zinc-500">
            <div className="w-8 m-1">User</div>
            <div className="flex-grow p-2">Infos</div>
           
 
              <div className="w-16 p-2">Actions</div>
            </div>
            {filteredUsers.map((user, i) => (
              <div
                className="flex flex-col justify-start items-center border-t border-zinc-500 w-full"
                key={i}
              ><div
              className="flex justify-start items-start w-full"
            
            >
                  <div  ><Image src={ user.profile?.imageUrl}  className=' m-1 w-8 h-8 rounded-full'/> </div>
                  <div className="flex-grow justify-start text-left p-2">{ (user.email??'') +" " + (user.profile.first_name??'') +' '+ (user.profile.last_name??'') +' '}</div>
         
               
                <div className="w-16 flex flex-row justify-center" >
                <FontAwesomeIcon  icon={fa.faRemove} className='ml-1 rounded-full p-1 bg-red-700 text-white hidden' onClick={()=>deleteAccount(user)} ></FontAwesomeIcon>

                </div>
</div><div
              className=" border-t border-zinc-100 flex justify-between items-center w-full p-2"
            
            >
              
              {
              <div className=' px-2 text-xs border-t w-full border-dotted border-zinc-500 py-2 text-start flex flex-wrap items-center'>
                <div className='p-2 font-bold '>Rôles</div>
                {roles.filter(role => user.profile.roles && user.profile.roles.find(r_id => role.role_id === r_id)).map(role => (
                  <span key={role.role_id} className=' appbutton mr-2 inline-block px-2 py-1 border-2 rounded-full bg-blue-500 text-white  ' onClick={() => delRole(user, role.role_id)}>
                    {role?.content.title}


                  </span>


                ))}

                <FontAwesomeIcon
                  icon={visibleRole[i] &&
                    [i] ? fa.faChevronLeft : fa.faChevronRight}
                  onClick={() => toggleRoles(i)}
                  title="Administrer les rôles"
                  className="tooltip  text-xs p-1 w-2 h-2  border border-gray-500 rounded-full mx-1  "
                />
                {visibleRole[i] &&
                  <>
                    {roles.filter(role => !user.profile.roles || !user.profile.roles.find(r_id => role.role_id === r_id)).map(role => (
                      <span key={role.role_id} className='mr-2 inline-block px-2 py-1 border-2 rounded-xl border-gray-500 appbutton ' onClick={() => addRole(user, role.role_id)}>
                        {role.content.title}
                      </span>
                    ))}
                  </>
                }
                <div className=' grow    flex justify-end'   >{ user.modified && <div onClick={() => saveUser(user)} className='p-1 bg-green-700 text-white  rounded-full px-2'> Enregistrer Rôles </div>   }  </div>
              </div>
            }
               </div>
              </div>
            ))}
          </div>
        
    </div>

</div>
  );
}

export default Users;
