import React, { useEffect, useMemo, useState,useContext } from 'react';


import { Link } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import { data } from '../../../../service/data';

function AppRoles({app}) {


const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const [roles,setRoles]= useState([])
const getRoles = async()=>{

  var _roles = await project.api("/get_myroles/:app_id",{args:{app_id:app.app_id}})
  console.log( _roles)
  setRoles(_roles);
}

const newRole = async()=>{

  await project.api("/set_role", { body:{ content : {title : "Nouveau Role" ,permissions :  [  ]     } ,app_id : app.app_id    } });

  await getRoles()
}


const deleteRole =async(role_id)=>{

  await project.api("/delete_role/:role_id", { args:{  role_id  } });
  await getRoles()

}


useEffect(()=>{
if(project)
{  getRoles()

}
},[project])
 return (

      <>
     <div  className='w-full flex flex-col  py-4  px-4 rounded-xl text-black   md:grid md:grid-cols-2 xl:grid-cols-4  gap-3  text-sm' >
   { roles && roles.map((role, i) => (
              <div
                className="flex justify-between  bg-white items-center border-t border-zinc-500 w-full p-4 flex-col rounded-xl"
                key={i}
              >
                  <div >{ role.content.title   }</div>
               
       
               
                <div className="w-16 flex flex-row justify-center" >
                {   data.isGranted( authUser, role,"role","edit")    && 
<Link
  to={ `/setRole/${role.role_id}`  }
  className="mx-2 bg-green-700 p-1 px-3 rounded-full"
>
  <FontAwesomeIcon icon={fa.faEdit} />{" "}
</Link>}

{ 
data.isGranted( authUser, role,"role","delete")     && (
  <div
    className="mx-2 bg-red-700  p-1 px-3 rounded-full"
    onClick={() => deleteRole(role.role_id)}
  >
    <FontAwesomeIcon icon={fa.faRemove} />
  </div>
)}
                </div>
              </div>
            ))}
       
    </div>
    <div onClick={newRole} className=' text-white m-4 bg-orange-700  p-1 px-3 rounded-full mb-6 flex flex-row justify-center items-center ' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer Rôle</div> 
  
    </>
  );
}

export default AppRoles;
