
import { useState } from 'react';
import InputDate from './imputDate/InputDate';
import InputTime from './InputTime';








const InputDateTime = ({ id, label, onChange, value, className }) => {


    var parts = (value ?? " ").split(" ");

    var date = parts[0];
    var time = parts[1] ?? "";
    const onChangeTime = (time) => {



        //  window.alert(time + "   vs " + value)
        onChange(date + " " + time)
    }


    const onChangeDate = (date) => {

        // window.alert(date + "   vs " + value)
        // onChange()
        onChange(date + " " + time)

    }








    return (
        <div className='flex flex-row w-full'>
            <InputDate id={id + '_date'} label={'Date'} onChange={onChangeDate} value={date} className={'w-1/2'} />
            <InputTime id={id + '_time'} label={'Heure'} onChange={onChangeTime} value={time} className={'w-1/2'} />

        </div>
    )
}


export default InputDateTime

