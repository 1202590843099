import { faCircleDown, faClose, faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputText from "./inputs/InputText";
import InputSelect from "./inputs/InputSelect";


function ClasseStructure({ classe, setObjects, classes }) {

  console.log()

const addChildren = ()=>{

var objects = classe.content.objects


objects.push({  })
setObjects(objects )


  }

  const delChildren =(i)=>{
    var objects =classe.content.objects.filter((c,idx)=> idx !== i) 
    setObjects(objects )
    
  }

  return <div className='flex flex-col w-full border-l border-zinc-400  pl-4 py-2 items-start' >
    { classes.length>0 &&  classe.content.objects.map((obj, i) =>


<div key={i}  className="flex flex-col md:flex-row my-2 w-full border-b border-zinc-300" >
<InputText   label={"Nom"}  value={obj.name} className={"grow "} onChange={ (value)=>{
  obj.name = value;
   var objects =  classe.content.objects.map((c, index) => i === index ? obj : c)
   setObjects(objects)
}   }  />

<InputSelect  value={obj.type==="classe" ? (  obj.type +"-"+ obj.classe_id )  : obj.type  }   onChange={ (value)=>{
      if( value.includes("classe-"))
      {
        var parts = value.split("-");
        obj.type = parts[0]
        obj.classe_id= parts[1];
      }
else { obj.type = value;
obj.classe_id= null;
}
   var objects =  classe.content.objects.map((c, index) => i === index ? obj : c)
   setObjects(objects)
}   }  options={  [...[{value:"text",label:"Text"},
{value:"long text",label:"Long Text"},
{value:"numeric",label:"Numérique"},
{value:"boolean",label:"Vrai/Faux"},
{value:"link",label:"Link"},
{value:"color",label:"Couleur"},
{value:"icone",label:"Icone"},
{value:"date",label:"Date"},
{value:"image",label:"Image"},
{value:"image-user",label:"Image User"},
{value:"image-app",label:"Image App"},
{value:"geohash",label:"Point Geo"},
{value:"video",label:"Video"},
{value:"video-stream",label:"Video Stream"},
{value:"audio",label:"Audio"},
{value:"audio stream",label:"Audio Stream"},
{value:"action",label:"Action"}],
...classes.map((classe, index) =>  { return {value:"classe-" +classe.classe_id , label: `< ${classe.name} >`}} ) ] } />
  

<InputSelect   value={obj.type_nombre}    onChange={ (value)=>{
  obj.type_nombre = value;
   var objects =  classe.content.objects.map((c, index) => i === index ? obj : c)
   setObjects(objects)
}   }      options={ [{value:"",label:"Required"},
{value:"O",label:"Optional"},
{value:"F",label:"Tableau Fixe"},
{value:"L",label:"Tableau Limite"},
{value:"I",label:"Tableau Illimité"}
]}
/>
{
  ( obj.type_nombre==="F" ||  obj.type_nombre==="L")  && 
   <>
   <input  type="number"   defaultValue={obj.length} className="w-12"  placeholder={ obj.type_nombre==="F" ? "longueur":"min"  }  onChange={ (e)=>{
  obj.length = e.target.value;
   var objects =  classe.content.objects.map((c, index) => i === index ? obj : c)
   setObjects(objects)
}   } />
{   
 obj.type_nombre==="L" &&   
<input  type="number"   defaultValue={obj.max}  className="w-12"  placeholder="max"  onChange={ (e)=>{
  obj.max = e.target.value;
   var objects =  classe.content.objects.map((c, index) => i === index ? obj : c)
   setObjects(objects)
}   } />

}
</>
}

<FontAwesomeIcon    icon={faClose}  onClick={()=>delChildren(i)} className="w-8 h-8 text-red-600 text-base "/> 


</div>)
 
      
    }
 <FontAwesomeIcon    icon={faPlusCircle}  onClick={addChildren} className="w-8 h-8 text-green-600 text-xl "/> 


  </div>
}

export default ClasseStructure;