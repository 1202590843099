import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";

const availableClasses = [
"w-0",
"w-px",
"w-0.5",
"w-1",
"w-1.5",
"w-2",
"w-2.5",
"w-3",
"w-3.5",
"w-4",
"w-5",
"w-6",
"w-7",
"w-8",
"w-9",
"w-10",
"w-11",
"w-12",
"w-14",
"w-16",
"w-20",
"w-24",
"w-28",
"w-32",
"w-36",
"w-40",
"w-44",
"w-48",
"w-52",
"w-56",
"w-60",
"w-64",
"w-72",
"w-80",
"w-96"
];


const ToolStyle = ({ updateStructure, structure, idObj, selectTpl }) => {
  const [show, setShow] = useState();

  const classes = useMemo(() => {
    console.log(structure, idObj);
    var _structure = getStructure(structure, idObj);

    var parts = _structure.className
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [structure, idObj]);

  const [filter, setFilter] = useState();

  const removeClasse = (classe) => {
    var _classes = classes.filter((_classe) => _classe !== classe);

    updateStructure({ className: _classes.join(" ") }, idObj);
  };

  const addClasse = (classe) => {
    var _classes = classes;


    updateStructure({ className: [..._classes,...classe].join(" ") }, idObj);
  };

  const hoverStart = (classe) => {
    var obj = document.getElementById(idObj);
    console.log("hoverStart", obj, classe);
    if (obj) obj.classList.add(...classe);
  };

  const hoverEnd = (classe) => {
    var obj = document.getElementById(idObj);
    console.log("hoverEnd", obj, classe);
    if (obj) obj.classList.remove(...classe);
  };

  return (
    <>
      {show && (
        <div
          className={
            "bg-white  justify-between flex flex-col relative  "
          }
        >
          <div className="w-full flex-nowrap text-start ">
            {" "}
            {classes &&
              classes.map((classe, i) => (
                <div
                  key={i}
                  onClick={() => removeClasse(classe)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-green-500 border-2"
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + classe}
                </div>
              ))}
          </div>

          <div
            className="w-full flex-nowrap text-start grow overflow-y-scroll "
          
          >
            <div className="  flex-col-reverse flex  ">
              {
                availableClasses
                  
                  .map((wclasse, i) => (
                   <div className="flex flex-row "> {   
                    availableClasses
                  
                    .map((hclasse, i) =>
                   <div
                      key={i}
                      onClick={() => addClasse([hclasse.replace("w-", "px-")  , wclasse.replace("w-", "py-")]) }
                      onMouseOver={() => hoverStart([hclasse.replace("w-", "px-")  , wclasse.replace("w-", "py-")])}
                      onMouseOut={() => hoverEnd([hclasse.replace("w-", "px-")  , wclasse.replace("w-", "py-")])}
                      className=" appbutton h-2 w-2 border border-white bg-green-500"
                      
                    >
                      
                    </div>)

              }
                    </div>
                  ))}
            </div>
          </div>


        </div>
      )}
      <div
        className={
          " inline-flex appbutton border-2  border-white  p-2  m-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
        Spacing
      </div>
    </>
  );
};
export default ToolStyle;
