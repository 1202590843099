import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";



const ShowTemplateEvent =( { setShow ,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,key_schemas} )=>{

    if(!selected ) return <></>  

    return  <div id={"ShowTemplateEvent"} className=" flex-none w-80  bg-white m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
  
    <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
    <FontAwesomeIcon icon={fa.faPlay}></FontAwesomeIcon>
    Click / Liens / Évènements
      <FontAwesomeIcon icon={fa.faClose}   onClick={ ()=> setShow((show) => ({ ...show, Event: !show.Event })) }  className="appbutton"  ></FontAwesomeIcon>
      </div>
    <TemplateStructure key={"clickTemplateStructure" }  template={ getStructure(template.structure,selected)} 
  setStructure={ (structure)=> updateTemplateStructure(structure,selected)  }
  shows={{ Name: false, Labels: false, Style: null, Click: true, Vars: true }}
  templates={templates} 
  schemas={schemas}
  globalSchema={globalSchema}
  id={selected}
  selected={selected}
  schema_id = { template.schema_id }
  app_id={template.app_id}          
  content={content}
  key_schemas={key_schemas}
  /></div>
  }

export default ShowTemplateEvent


