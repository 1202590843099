const initPortalSession =async (project,returnUrl)=>{

    returnUrl = returnUrl ?? window.location.href
  var result = await  project.api("/create-portal-session",{body:{ returnUrl}});
  if(result.redirect) {

   window.location.href=result.redirect;
   
  }
  }




export { initPortalSession}