import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

const ShowComponentAddTools = ({template,ComponentAddTools,setShow,updateTemplateStructure,selectTpl,selected}) => {
    if(!selected ) return <></>  
    return (
      <div id={"ShowComponentAddTools"} className="flex-none  w-80  m-2 flex-nowrap text-center  border border-white rounded-xl shadow">
          <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
  <FontAwesomeIcon icon={fa.faPlus}></FontAwesomeIcon>
  Ajouter / Insérer 
    <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Plus: !show.Plus })) }  className="appbutton"  ></FontAwesomeIcon>
  </div>{Object.values(ComponentAddTools).map((ComponentAddTool, i) => (
          <ComponentAddTool
            structure={template.structure}
            updateStructure={updateTemplateStructure}
            selectTpl={selectTpl}
            idObj={selected}
            key={i}
          />
        ))}{" "}
      </div>
    );
  };

export default ShowComponentAddTools