// Fonction pour encoder des coordonnées latitude/longitude en géohash
function encodeGeohash(latitude, longitude, precision = 20) {
    const BASE32 = '0123456789bcdefghjkmnpqrstuvwxyz';
    let geohash = '';
    let minLat = -90, maxLat = 90;
    let minLon = -180, maxLon = 180;
    let isEven = true;
    let bit = 0, ch = 0;
  
    while (geohash.length < precision) {
        let mid;
  
        if (isEven) {
            mid = (minLon + maxLon) / 2;
            if (longitude > mid) {
                ch |= 1 << (4 - bit);
                minLon = mid;
            } else {
                maxLon = mid;
            }
        } else {
            mid = (minLat + maxLat) / 2;
            if (latitude > mid) {
                ch |= 1 << (4 - bit);
                minLat = mid;
            } else {
                maxLat = mid;
            }
        }
  
        isEven = !isEven;
  
        if (bit < 4) {
            bit++;
        } else {
            geohash += BASE32[ch];
            bit = 0;
            ch = 0;
        }
    }
  
    return geohash;
  }
  
  // Fonction pour décoder un géohash en coordonnées latitude/longitude
  function decodeGeohash(geohash) {
    const BASE32 = '0123456789bcdefghjkmnpqrstuvwxyz';
    let isEven = true;
    let minLat = -90, maxLat = 90;
    let minLon = -180, maxLon = 180;
  
    for (let i = 0; i < geohash.length; i++) {
        const chr = geohash.charAt(i);
        const idx = BASE32.indexOf(chr);
        for (let j = 4; j >= 0; j--) {
            const bit = idx >> j & 1;
            if (isEven) {
                if (bit === 1) {
                    minLon = (minLon + maxLon) / 2;
                } else {
                    maxLon = (minLon + maxLon) / 2;
                }
            } else {
                if (bit === 1) {
                    minLat = (minLat + maxLat) / 2;
                } else {
                    maxLat = (minLat + maxLat) / 2;
                }
            }
            isEven = !isEven;
        }
    }
  
    const latitude = (minLat + maxLat) / 2;
    const longitude = (minLon + maxLon) / 2;
  
    return [latitude, longitude];
  }



  export {

decodeGeohash,
encodeGeohash

   }