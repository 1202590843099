


const InputButton= ({label,onClick,className}) => {
return (
    <>
    <div className={"relative " +className } >
    <button type="text"  onClick={onClick }  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-white  rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer bg-slate-800 " placeholder=" " >
        {label}
        </button>
   </div>
</> 
)
}
export default InputButton