import {  useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { useServiceWorker } = require("./useServiceWorker");

function UpdateSW() {


    const notify = () => toast( <div>
      A new version app 
      <button className="p-1  " onClick={() => reloadPage()}>REFRESH</button>
    </div>,{
        autoClose:false,
         });

    const { waitingWorker, showReload, reloadPage } = useServiceWorker();
    // decides when to show the toast
    useEffect (() => {
      if ((showReload && waitingWorker)) {
      
        notify()
  
      } 
    }, [waitingWorker, showReload, reloadPage]);


  return <ToastContainer />
  
  }

  export default UpdateSW;