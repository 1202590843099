import { useState } from "react";




const InputMedia = ({label,onChange,value,className,domain}) => {

    const [file, setFile] = useState();
    
    
    function handleImgChange(e) {
        console.log(e.target.files);

        setFile(URL.createObjectURL(e.target.files[0]));
        onChange(   e.target.files[0])
    }
  


return (
    <>
    <div   onClick={ onChange  ? ()=>{ document.getElementById("image-upload-" + label ).click()}:null}  className={"  " +className +"  overflow-hidden shadow flex flex-row items-center  justify-center  text-sm text-gray-900 bg-transparent rounded-lg  appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"  } >

    {value &&
   <img id="previewing" className=" w-full object-contain  bg-black" src={file ??    process.env.REACT_APP_MFTC_SERVER_URL_FILES +
    `profiles/${domain}/medias/${value}` } alt="media"/> }
{!value && <div className=" p-4 ">Ajouter </div>}
     <input type="file" accept={"video/mp4,audio/mp3,image/png,image/jpeg"} name="image" id={"image-upload-"+label} className="hidden" onChange={handleImgChange} required />
    
</div>
</> 
)
}
export default InputMedia