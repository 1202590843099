import React, { useEffect, useMemo, useState,useContext } from 'react';

import { Link,useParams } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from '../../../../components/Content';

import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import { getFilterRecord } from '../../../../service/localdata';

function Contents() {

  const { app_id } = useParams();
const [contents,setContents]= useState([])
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const getContents = async()=>{


  var _contents = await await getFilterRecord((content)=>!content.is_schema && !content.app_id, "content");

  console.log( _contents)
    setContents(_contents);
}

const  deleteContent = async(content)=>{
  if (!window.confirm("Are you sure you want to delete '" + content.title+"'?")) return
   await project.api("/delete_content/:content_id",{args:{content_id:content.content_id}})
await    getContents();
}

useEffect(()=>{
if(project)
  getContents()
 
},[project])
 return (
    <div className="App"> 
       <div className='flex flex-row w-full justify-start p-2'>
   <Link to="/"> <FontAwesomeIcon  icon={fa.faArrowLeft} className='mx-4'></FontAwesomeIcon> </Link> Contents
          </div>

     <div  className='w-full flex flex-col p-2' >
   {  contents.map((content,i)=>
     <div className='border-t border-zinc-500 w-full   flex flex-col items-center  '   key={i} >
     <Content content={content } deleteContent={deleteContent} isEditable={true}/>

    </div> ) 
     }
     </div>   
      <Link to={`/app/${app_id}/content/add`}>
      <div  className=' text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer un contenu</div></Link>     
      
    </div>
  );
}

export default Contents;