import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContext } from "react";
import { MfctContext } from "../context";

import { Link } from 'react-router-dom';

import { data } from '../service/data';



function ContentSchema({ content, deleteContent , isEditable }) {

  const {authUser} = useContext(MfctContext);

    return <div className=' p-2    bg-white rounded-xl flex border w-full border-zinc-500 shadow text-sm font-bold items-center '>

      <div className='flex gap-2'> <FontAwesomeIcon  className=" h-6" icon={ content.content.icone  ? fa[ content.content.icone]: fa.faQuestionCircle} />
      <div>{  isEditable &&   content.content.title  + `(${content.content_id})`}</div>
      </div>
    
   <div className='grow flex flex-row justify-end  items-center text-xs text-white my-1'>
   <Link to={`/templates/?schema_id=${content.content_id}`} className='mx-1 bg-green-700 text-white p-1 px-3 rounded-full' ><FontAwesomeIcon icon={fa.faWindowRestore} /> </Link>
   { data.isGranted( authUser, content,"content","edit")   && isEditable &&   <Link to={`/content/${content.content_id}`} className='mx-1 bg-blue-700 p-1 px-3 rounded-full' ><FontAwesomeIcon icon={fa.faEdit} /> </Link>}
      { data.isGranted( authUser, content,"content","delete")   &&  deleteContent && <div className='mx-2 bg-red-700  p-1 px-3 rounded-full' onClick={() => deleteContent(content)}><FontAwesomeIcon icon={fa.faRemove} /></div>}
    </div> 
  </div>

}

export default ContentSchema;
