import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { data } from "../../service/data";

const InputPage = ({
  label,
  onChange,
  value,
  className,
  placeholder,
  domain,
  filter,
}) => {
  const [pages, setPages] = useState();

  useEffect(() => {
    const getPages = async () => {
      const app = await data.getAppByDomain(domain);
      const _pages = await data.getPages(
        filter && ((page) => page.app_id === app.app_id)
      );
      setPages(_pages);
    };

    getPages();
  }, [domain, filter]);

  if (!pages) return <></>;

  return (
    <>
      <InputSelect
        label={label}
        className={className}
        placeholder={placeholder}
        value={value?.page_id}
        onChange={(page_id) => {
          if (page_id) page_id = parseInt(page_id);
          var page = pages.find((p) => p.page_id === page_id);
          onChange({
            page_id,
            content_id: null,
            typecontent: "page",
            content: {
              Icone: page.content.icone,
              Label: page.content.label,
              Titre: page.title,
              Slug: page.slug,
              Navigate: `/${page.slug}`,
            },
          });
        }}
        options={pages.map((p) => {
          return { label: p.title, value: p.page_id };
        })}
      />
    </>
  );
};
export default InputPage;
