import React, { useEffect, useMemo, useState, useContext, useRef } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../../App.css";
import { MfctContext } from "../../../../context";

import Header1 from "../../../../components/Header1";

import InputSelect from "../../../../components/inputs/InputSelect";
import { showModalLoading, showModalOK } from "../../../../components/modal";
import InputTextArea from "../../../../components/inputs/InputTextArea";

function Hostings() {
  const sides = [
    ["backend", "branch"],
    ["front", "branchFront"],
    ["workBuild", "branchWorkBuild"],
  ];
  const navigate = useNavigate();
  const { app_id } = useParams();

  const { project, getProject, setAuthUser, authUser } =
    useContext(MfctContext);

  const [branches, setBranches] = useState([]);
  const [branchesFront, setBranchesFront] = useState([]);
  const [update, setUpdate] = useState([]);

  const [ssh_users, setSshUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [products, setProducts] = useState([]);
  const [showAccount, setShowAccount] = useState();
  const [showNewSite, setShowNewSite] = useState();
  const [newAccountName, setNewAccountName] = useState();

  const [xpCommand, setXpCommand] = useState({});
  const [output, setOutput] = useState({});
  // const [action, setAction] = useState();

  async function getAccounts(loading = true) {
    if (loading) showModalLoading();
    try {
      const _result = await project.api("/always/accounts");
      setProducts(_result.products);
      let accountsWithSites = [];

      for (let account of _result.accounts) {
        try {
          let sites = await project.api("/always/account/:account/sites", {
            args: { account: account.name },
          });
          // Filter accounts to only include those with at least one site having working_directory === "backend"
          sites = sites.filter((site) => site.working_directory === "backend");
          var infos_git = {};
          if (sites.length > 0) {
            account = { ...account, backend: sites[0] };
            for (let side of sides) {
              try {
                const result = await project.api(`/github/github`, {
                  body: {
                    request: "get_branch",
                    account: account.name,
                    side: side[0],
                  },
                });

                if (result) {
                  infos_git = { ...infos_git, [side[1]]: result.branch };
                }
              } catch (err) {
                console.log(err);
              }
            }

            try {
              const remoteCommitsResult = await project.api(`/github/github`, {
                body: {
                  request: "check_remote_commits",
                  account: account.name,
                },
              });
              if (remoteCommitsResult) {
                infos_git = { ...infos_git, ...remoteCommitsResult };
              }
            } catch (err) {
              console.log(
                `Erreur lors de la récupération des remoteCommits pour ${account.name}:`,
                err
              );
            }
            try {
              const reflog = await project.api(`/github/github`, {
                body: {
                  request: "get_reflog",
                  account: account.name,
                },
              });
              if (reflog) {
                infos_git = { ...infos_git, ...reflog };
              }
            } catch (err) {
              console.log(
                `Erreur lors de la récupération des reflogs pour ${account.name}:`,
                err
              );
            }
            console.log({ account, infos_git });
            accountsWithSites.push({ ...account, ...infos_git });
          } else if (account.name.includes("aiyprod")) {
            accountsWithSites.push({ ...account, backend: null });
          }
        } catch (err) {
          console.log(err);
        }
      }
      setAccounts(accountsWithSites);

      // Incrémenter le chiffre et créer le nouveau nom de compte
      let newAccountNumber = getLastNumber(accountsWithSites) + 1;
      setNewAccountName(`aiyprod${newAccountNumber}`);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
    if (loading) showModalLoading(true);
  }

  function getLastNumber(accountsList) {
    let maxNumber = 0;
    if (accountsList.length === 1) return 1;
    for (let account of accountsList) {
      let parts = account.name.split("aiyprod");
      let accountNumber = parseInt(parts[1]);
      if (accountNumber > maxNumber) maxNumber = accountNumber;
    }
    return maxNumber;
  }

  /**************************************************************************************************************************************************/
  /*                                                  GITHUB                                                                                       */
  /**************************************************************************************************************************************************/

  async function getGitBranches() {
    try {
      var _result = await project.api(`/github/github`, {
        body: { request: "get_branches" },
      });
      if (_result) setBranches(_result.branches);
    } catch (error) {}
  }
  async function getGitBranchesFront() {
    try {
      var _result = await project.api(`/github/github`, {
        body: { request: "get_branches_front" },
      });
      if (_result) setBranchesFront(_result.branches);
    } catch (error) {}
  }
  async function getGitBranch(account) {
    try {
      var _result = await project.api(`/github/github`, {
        body: { request: "get_branch", account: account },
      });
      console.log(_result);
    } catch (error) {
      console.error("Error fetching branch:", error);
    }
  }

  async function changeBranch(account, key, branch, side) {
    await getAction(account, "changeBranch", { branch, side });
  }

  async function updateBackend(accountName) {
    if (!window.confirm("Are you sure you want to update server backend?")) {
      return;
    }
    console.log(accountName);
  }
  const actionsLocal = ["git status", "gitfetch", "make_pull"];
  const actions = [
    "restart",
    "git status",
    "gitfetch",
    "make_pull",
    "emptyAccount",
  ];
  const actionsInit = ["initAccount", "emptyAccount"];
  // "deleteAccount"
  const produits = ["10G", "100G"];

  ////////////////////////////////////GET ACTION !!!///////////////////
  async function getXP(account, i) {
    var xpStored = JSON.parse(localStorage.getItem("xpCommand") ?? "{}");
    localStorage.setItem(
      "xpCommand",
      JSON.stringify({ ...xpStored, [account.name]: xpCommand[account.name] })
    );
    var computeXPCommand = xpCommand[account.name][i];

  var parts= computeXPCommand.split("?$prompt[")
  for(let j=1; j<parts.length; j+=2) {
   
    var subparts = parts[j].split("]");
    var field = subparts.shift()
    var val = window.prompt( "Saisir " + field );
    parts[j] = val + subparts.join("]")
  }
  computeXPCommand =parts.join(" ");
    showModalLoading();
    try {
      const outp = await project.api(`/hosting/action`, {
        body: {
          request: "xpcommand",
          account: account.name,
          command: computeXPCommand,

          accountID: account.id,
        },
      });
      setOutput((output) => ({
        ...output,
        [account.name]: outp.xpcommand + "\n\n " + (output[account.name] ?? ""),
      }));

      showModalOK();
    } catch (err) {
      console.log(`Erreur lors de l'action pour ${account.name}:`, err);
    }
    showModalLoading(true);
  }

  async function getAction(account, action, props = {}) {
    if (
      !window.confirm(`Are you sure you want to ${action} for ${account.name}?`)
    ) {
      return;
    }
    showModalLoading();
    try {
      const reflog = await project.api(`/hosting/action`, {
        body: {
          request: action,
          account: account.name,
          siteID: account.backend?.id,
          accountID: account.id,
          ...props,
        },
      });

      await getAccounts(false);
      showModalOK();
    } catch (err) {
      console.log(`Erreur lors de l'action pour ${account.name}:`, err);
    }
    showModalLoading(true);
  }

  async function getAccount() {
    try {
      var _result = await project.api("/always/accounts");
      setProducts(_result.products);
      let filteredAccounts = _result.accounts;

      for (let account of filteredAccounts) {
        await getSites(account);
      }

      setAccounts(filteredAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  }

  async function getSshUsers() {
    var _result = await project.api("/always/ssh_users");
    setSshUsers(_result);
    console.log(JSON.stringify(_result));
  }

  const showCreateSite = async (account) => {
    setShowNewSite(account);
  };

  async function createBuildApp(account) {
    var inputElement = document.getElementById("site_name_" + account.name);
    if (!inputElement) {
      console.error("Input element not found for account: ", account.name);
      return;
    }

    var site_name = inputElement.value;

    var site_data = {
      domain: site_name,
    };
    try {
      // Remplacer dynamiquement :account dans l'URL de l'API
      var _result = await project.api(
        `/always/account/:account/create_build_app`,
        {
          args: { account: account.name },
          body: site_data,
        }
      );
      delete account.sites;
      await showSites(account);
    } catch (error) {
      console.error("Error creating site:", error);
    }
  }

  async function deleteSite(site_id, account) {
    // console.log("site_id:", site_id, "account_name:", account_name);
    if (!window.confirm("Are you sure you want to delete")) {
      return;
    }
    try {
      // Remplacer dynamiquement :account dans l'URL de l'API
      var _result = await project.api(`/always/account/:account/delete_site`, {
        args: { account: account.name },
        body: { site_id },
      });
      delete account.sites;
      await showSites(account);
    } catch (error) {
      console.error("Error deleting site:", error);
    }
  }

  async function deleteDatabase(database_id, account) {
    // console.log("database_id:", database_id, )
    if (!window.confirm("Are you sure you want to delete")) {
      return;
    }
    try {
      // Remplacer dynamiquement :account dans l'URL de l'API
      var _result = await project.api(
        `/always/account/:account/delete_database`,
        {
          args: { account: account.name },
          body: { database_id },
        }
      );
      delete account.sites;
      await showSites(account);
    } catch (error) {
      console.error("Error deleting site:", error);
    }
  }

  async function connectionSsh(site_name, account) {
    // console.log("site name :" + site_name + ", account :" + account.name);
    var _result = await project.api(`/always/account/:account/ssh_connection`, {
      args: { account: account.name },
      body: { site_name },
    });
  }

  async function initAccount(accountName) {
    var _result = await project.api(`/always/account/:account/init_account`, {
      args: { account: accountName },
    });
  }

  async function showSites(account) {
    setShowAccount(account);
    if (account.sites) return;
    var sites = await project.api("/always/account/:account/sites", {
      args: { account: account.name },
    });
    // console.log(JSON.stringify(sites))
    var databases = await project.api("/always/account/:account/databases", {
      args: { account: account.name },
    });
    //rajouter la propriété database à chaque objet site du tableau sites
    //boucler sur le tableau d'objet sites et chercher la correspondance sur database.name qui inclue site.name
    sites.forEach((site) => {
      // Filtrer les bases de données correspondantes
      const matchingDatabase = databases.filter((database) =>
        database.name.includes(`${account.name}_${site.working_directory}`)
      );
      // Ajouter les bases de données correspondantes à l'objet site
      site.database = matchingDatabase;
    });

    setAccounts((accounts) =>
      accounts.map((a) => (a.id === account.id ? { ...account, sites } : a))
    );
    // console.log(JSON.stringify(databases))

    setShowNewSite(account);
  }
  async function getSites(account) {
    try {
      var sites = await project.api("/always/account/:account/sites", {
        args: { account: account.name },
      });
      sites = sites.filter((site) => site.working_directory === "backend");
      setAccounts((accounts) =>
        accounts.map((a) =>
          a.id === account.id ? { ...account, site: sites[0] } : a
        )
      );
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  }

  const setAccount = (account, props) => {
    setAccounts((accounts) =>
      accounts.map((a) => (a.name === account.name ? { ...account, ...props } : a))
    );
  };

  async function createAccount() {
    if (!window.confirm("Are you sure you want to create a new Account?")) {
      return;
    }
    showModalLoading();
    try {
      // Remplacer dynamiquement :account dans l'URL de l'API
      var _result = await project.api(`/always/create_account`, {
        body: { accountName: newAccountName },
      });
      // if(_result) await initAccount(accountName)
      await getAccounts();
      showModalOK();
    } catch (error) {
      console.error("Error creating account:", error);
    }
    showModalLoading(true);
  }

  function changeValue() {}

  async function restartSite(account) {
    //  console.log("site_id:", siteID, "account_name:", account.name);
    if (!window.confirm("Are you sure you want to restart the site?")) {
      return;
    }
    try {
      // Remplacer dynamiquement :account dans l'URL de l'API
      var _result = await project.api(`/always/account/:account/site_restart`, {
        args: { account: account.name },
        body: { siteID: account.backend.id },
      });
      // delete account.sites;
      // await showSites(account);
    } catch (error) {
      console.error("Error deleting site:", error);
    }
  }

  useEffect(() => {
    document.title = "Hébergements";
    getAccounts();
    getGitBranches();
    getGitBranchesFront();
  }, []);

  return (
    <div className="safearea App">
      <Header1 title={"Hébergements"} linkLeft={"/"}>
        <div className="  mx-2 p-1 px-2 " onClick={() => createAccount()}>
          Nouvel Account : {newAccountName}
          <FontAwesomeIcon icon={fa.faPlusCircle} />
        </div>
        <div className="flex w-full  my-1  mx-4 shadow-xl rounded-full ">
          <input
            type="text"
            id={"searchBar"}
            className="w-full px-4  rounded-3xl text-zinc-700 p-2"
          />

          <div className=" flex items-center justify-start w-full m-1">
            <div className="grow flex items-end justify-end"></div>
          </div>
        </div>
      </Header1>
      <div className="overflow-x-auto p-2">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 border-y border-gray-400"></th>
              <th className="py-2 border-y border-gray-400">Account Name</th>
              <th className="py-2 border-y border-gray-400"> <FontAwesomeIcon  icon={fa.faGears}  className="mx-2"/></th>
              <th className="py-2 border-y border-gray-400">Back</th>
              <th className="py-2  border-y border-gray-400">Front</th>
              <th className="py-2 border-y border-gray-400">Work Build</th>
              <th className="py-2 border-y border-gray-400">Actions</th>
            </tr>
          </thead>
          <tbody>
            {accounts.length > 0 ? (
              accounts.map((account) => (
                <React.Fragment key={account.id}>
                  <tr className="border-t">
                    <td className="py-2 text-center border-y border-gray-400">
                      <div
                        className={
                          "w-6 h-6 rounded-full m-1 " +
                          (account.backend ? "bg-green-500" : "bg-red-500")
                        }
                      >
                        {" "}
                      </div>
                    </td>
                    <td className="py-2 text-center border-y border-gray-400">
                      {account.name}
                    </td>
                    <td className="py-2 text-center border-y border-gray-400">

              <Link to={`/deployments?server_account=${account.name}`}>      <FontAwesomeIcon  icon={fa.faGears}  className="mx-2"/></Link>
                      {/* {account.backend && (
                        <div className="flex justify-center">
                          <div>
                            {account.remoteCommits !== undefined &&
                            account.remoteCommits !== null
                              ? account.remoteCommits
                              : "No remote commits available"}
                          </div>
                        </div>
                      )} */}
                    </td>

                    {sides.map((side, k) => (
                      <td
                        key={side[0]}
                        className="p-2 text-center  border-y border-gray-400"
                      >{ account[side[1]] &&  account[side[1]]==="none" ?  
                      <>Non installé </>
                      :(  account[side[1]]==="nogit" ?
                        <>Git Non initialisé </>
                        :  (
                          <div className="flex justify-center gap-1 items-center">
                            <InputSelect
                              id={account.name + "branch" + side[0]}
                              className={"w-"}
                              label={side[0] + " Branch"}
                              onChange={(newBranch) =>
                                setAccount(account, {
                                  ["newBranch" + side[0]]:
                                    newBranch === account[side[1]]
                                      ? null
                                      : newBranch,
                                })
                              }
                              options={
                                side[0] === "back" ? branches : branchesFront
                              }
                              placeholder={"Changer de branche"}
                              value={
                                account["newBranch" + side[0]] ??
                                account[side[1]] ??
                                "main"
                              }
                            ></InputSelect>

                            <div>
                              <FontAwesomeIcon
                                icon={fa.faRecycle}
                                className={` border rounded p-2 text-white ${
                                  account["newBranch" + side[0]] 
                                    ? "bg-green-400 cursor-pointer appbutton"
                                    : "bg-gray-400 cursor-not-allowed"
                                }`}
                                onClick={() =>
                                  account["newBranch" + side[0]] &&
                                  changeBranch(
                                    account,
                                    side[1],
                                    account["newBranch" + side[0]],
                                    side[0]
                                  )
                                }
                              />
                            </div>
                          </div>)
                        )}
                      </td>
                    ))}

                    <td className="p-2 border-y border-gray-400">
                      <div className="flex justify-center gap-1 items-center">
                        <InputSelect
                          id="branch"
                          className={"w-1/2"}
                          label={""}
                          onChange={(action) =>
                            setAccount(account, { action: action })
                          }
                          options={
                            account.name === "LOCALHOST"
                              ? actionsLocal
                              : account.backend
                              ? actions
                              : actionsInit
                          }
                          placeholder={"Choisir une action"}
                          value={account.action ?? ""}
                        ></InputSelect>
                        <div
                          className={` border rounded p-2 text-white ${
                            account.action
                              ? "bg-green-400 cursor-pointer appbutton"
                              : "bg-gray-400 cursor-not-allowed"
                          }`}
                          onClick={() =>
                            account.action && getAction(account, account.action)
                          }
                        >
                          GO
                        </div>
                        <div
                          className={` border  p-2 rounded-full text-white ${"bg-blue-400 cursor-pointer appbutton"}`}
                          onClick={() => {
                            var xpInstructs = xpCommand[account.name];
                            if (xpInstructs) {
                              xpInstructs = null;
                            } else {
                              xpInstructs = JSON.parse(
                                localStorage.getItem("xpCommand") ?? "{}"
                              )[account.name] ?? [" "];
                            }

                            setXpCommand((xpc) => ({
                              ...xpc,
                              [account.name]: xpInstructs,
                            }));
                            setOutput((xpc) => ({
                              ...xpc,
                              [account.name]: null,
                            }));
                          }}
                        >
                          XP
                        </div>
                      </div>
                    </td>
                  </tr>{" "}
                  {xpCommand[account.name] && (
                    <tr>
                      <td
                        colSpan="7"
                        className="py-2 text-center border-y border-gray-400"
                      >
                        <div className="`w-full flex ">
                          <div className="`w-full flex flex-col">
                            {xpCommand[account.name].map((value, j) => {
                              if (value === "") return <></>;
                              return (
                                <div className="`w-1/2 flex p-1 m-1 bg-gray-50 rounded">
                                  <div className="`w-full flex flex-col">
                                    <InputTextArea
                                      className={"w-full bg-white "}
                                      id={"xp_" + account.name + "_" + j}
                                      value={value}
                                      onChange={(value) => {
                                        setXpCommand((xpc) => ({
                                          ...xpc,
                                          [account.name]: xpc[account.name].map(
                                            (v, vj) => (vj === j ? value : v)
                                          ),
                                        }));
                                      }}
                                    />

                                    <div className="text-xs italic">
                                      Jokers :{" "}
                                      {[
                                        "$folder_backend",
                                        "$folder_front",
                                        "$folder_workBuild",
                                        "$repo_backend",
                                        "$repo_front",
                                        "$timestamp",
                                        "?$prompt[ ]"
                                      ].map((joker, i) => (
                                        <span
                                          key={i}
                                          onClick={() => {
                                            var value =
                                              xpCommand[account.name][j];
                                            value += " " + joker;

                                            setXpCommand((xpc) => ({
                                              ...xpc,
                                              [account.name]: xpc[
                                                account.name
                                              ].map((v, vj) =>
                                                vj === j ? value : v
                                              ),
                                            }));
                                            document.getElementById(
                                              "xp_" + account.name + "_" + j
                                            ).value = value;
                                          }}
                                          className="p-2 m-2 app-button"
                                        >
                                          {joker}
                                        </span>
                                      ))}{" "}
                                    </div>
                                  </div>
                                  <FontAwesomeIcon
                                    className="rounded-full bg-blue-500 p-2 m-2 text-white"
                                    onClick={() => getXP(account, j)}
                                    icon={fa.faPlayCircle}
                                  />
                                </div>
                              );
                            })}

                            <FontAwesomeIcon
                              className="bg-green-500 p-2 m-2 text-white"
                              onClick={() =>
                                setXpCommand((xpc) => ({
                                  ...xpc,
                                  [account.name]: [...xpc[account.name], " "],
                                }))
                              }
                              icon={fa.faPlus}
                            />
                          </div>
                          {output[account.name] && (
                            <div className="grow whitespace-pre-line text-left text-sm p-2 border-l border-blue-400 ">
                              {output[account.name]}
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
    
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="py-2 text-center">
                  No accounts available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="p-2 w-full flex justify-center">
        <div className="bg-white shadow-md rounded-lg p-4 flex justify-around items-center w-1/3">
          <div className="">
            Initialiser un nouvel Account : {newAccountName}
          </div>
          <div
            className="appbutton bg-blue-400 cursor-pointer border rounded p-2  w-1/4 text-white"
            onClick={() => createAccount()}
          >
            Créer
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hostings;

//ajouter les fonctionnalités (penser à RM, git stash, ect...)
//mdp généré envoyé par mail aux admins (chercher les admins)
//mdp => configs des comptes sur appisyou/backend dossier accounts, un fichier json par account avec le résultat du getaccount de alwaysdata + mdp , nom du fichier prod*
