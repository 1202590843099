import React, { useEffect, useMemo, useState, useContext } from 'react';

import { setToken } from '../../service/mftc-api';
import '../../App.css';

import { Link, useParams } from 'react-router-dom';

import { MfctContext } from "../../context";
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../Loader';
import VideoPlayer from './components/VideoPlayer';
import Options from './components/Options';
import { ContextProvider } from './components/Context';


function Visio({ endCall,me,idToCall}) {

  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  

  return (!project ? <Loader /> :
   
        <ContextProvider id={me } ><div>
        <div className='w-full flex flex-col' >
          <div className=''>  </div>
          <VideoPlayer endCall={endCall}  idToCall={idToCall}/>
          <Options />
     
        </div>
      </div></ContextProvider>

  );




}

export default Visio;
