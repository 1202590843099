import englandImage from './drapeaux/england.webp';
import france from './drapeaux/france.webp';
import andorra from './drapeaux/andorra.webp';
import argentine from './drapeaux/argentine.webp';
import austria from './drapeaux/austria.webp';
import belgique from './drapeaux/belgique.webp';
import bolivia from './drapeaux/bolivia.webp';
import brazil from './drapeaux/brazil.webp';
import bulgarie from './drapeaux/bulgarie.webp';
import germany from './drapeaux/germany.webp';
import chypre from './drapeaux/chypre.webp';
import croatie from './drapeaux/croatie.webp';
import Danemark from './drapeaux/Danemark.webp';
import spain from './drapeaux/spain.webp';
import Estonie from './drapeaux/Estonie.webp';
import finland from './drapeaux/finland.webp';
import greece from './drapeaux/greece.webp';
import hungary from './drapeaux/hungary.webp';
import ireland from './drapeaux/ireland.webp';
import italy from './drapeaux/italy.webp';
import latvia from './drapeaux/latvia.webp';
import lithuania from './drapeaux/lithuania.webp';
import luxembourg from './drapeaux/luxembourg.webp';
import malta from './drapeaux/malta.webp';
import paysBas from './drapeaux/paysBas.webp';
import poland from './drapeaux/poland.webp';
import portugal from './drapeaux/portugal.webp';
import czechRepublic from './drapeaux/czechRepublic.webp';
import romania from './drapeaux/romania.webp';
import slovakia from './drapeaux/slovakia.webp';
import slovenia from './drapeaux/slovenia.webp';
import sweden from './drapeaux/sweden.webp';
import monaco from './drapeaux/monaco.webp';
import liechtenstein from './drapeaux/liechtenstein.webp';
import vaticanCity from './drapeaux/vaticanCity.webp';
import russia from './drapeaux/russia.webp';
import unitedStates from './drapeaux/unitedStates.webp';
import chile from './drapeaux/chile.webp';
import colombia from './drapeaux/colombia.webp';
import ecuador from './drapeaux/ecuador.webp';
import guyana from './drapeaux/guyana.webp';
import paraguay from './drapeaux/paraguay.webp';
import peru from './drapeaux/peru.webp';
import suriname from './drapeaux/suriname.webp';
import uruguay from './drapeaux/uruguay.webp';
import venezuela from './drapeaux/venezuela.webp';
import algeria from './drapeaux/algeria.webp';
import angola from './drapeaux/angola.webp';
import benin from './drapeaux/benin.webp';
import botswana from './drapeaux/botswana.webp';
import burkinaFaso from './drapeaux/burkinaFaso.webp';
import burundi from './drapeaux/burundi.webp';
import cameroon from './drapeaux/cameroon.webp';
import capeVerde from './drapeaux/capeVerde.webp';
import chad from './drapeaux/chad.webp';
import comoros from './drapeaux/comoros.webp';
import congo from './drapeaux/congo.webp';
import djibouti from './drapeaux/djibouti.webp';
import egypt from './drapeaux/egypt.webp';
import guinee from './drapeaux/guinee.webp';
import eritrea from './drapeaux/eritrea.webp';
import ethiopia from './drapeaux/ethiopia.webp';
import gabon from './drapeaux/gabon.webp';
import gambia from './drapeaux/gambia.webp';
import ghana from './drapeaux/ghana.webp';
import guinea from './drapeaux/guinea.webp';
import guineaBissau from './drapeaux/guineaBissau.webp';
import ivoryCoast from './drapeaux/ivoryCoast.webp';
import kenya from './drapeaux/kenya.webp';
import lesotho from './drapeaux/lesotho.webp';
import liberia from './drapeaux/liberia.webp';
import libya from './drapeaux/libya.webp';
import madagascar from './drapeaux/madagascar.webp';
import malawi from './drapeaux/malawi.webp';
import mali from './drapeaux/mali.webp';
import mauritania from './drapeaux/mauritania.webp';
import mauritius from './drapeaux/mauritius.webp';
import morocco from './drapeaux/morocco.webp';
import mozambique from './drapeaux/mozambique.webp';
import namibia from './drapeaux/namibia.webp';
import niger from './drapeaux/niger.webp';
import nigeria from './drapeaux/nigeria.webp';
import rwanda from './drapeaux/rwanda.webp';
import saoTomeAndPrince from './drapeaux/saoTomeAndPrince.webp';
import senegal from './drapeaux/senegal.webp';
import seychelles from './drapeaux/seychelles.webp';
import sierraLeone from './drapeaux/sierraLeone.webp';
import somalia from './drapeaux/somalia.webp';
import southAfrica from './drapeaux/southAfrica.webp';
import sudan from './drapeaux/sudan.webp';
import southSudan from './drapeaux/southSudan.webp';
import swaziland from './drapeaux/swaziland.webp';
import tanzania from './drapeaux/tanzania.webp';
import togo from './drapeaux/togo.webp';
import tunisia from './drapeaux/tunisia.webp';
import uganda from './drapeaux/uganda.webp';
import zambia from './drapeaux/zambia.webp';
import zimbabwe from './drapeaux/zimbabwe.webp';

import saudiArabia from './drapeaux/saudiArabia.webp';
import bahrain from './drapeaux/bahrain.webp';
import emirates from './drapeaux/emirates.webp';
import iran from './drapeaux/iran.webp';
import iraq from './drapeaux/iraq.webp';
import israel from './drapeaux/israel.webp';
import jordan from './drapeaux/jordan.webp';
import kuwait from './drapeaux/kuwait.webp';
import lebanon from './drapeaux/lebanon.webp';
import oman from './drapeaux/oman.webp';
import palestine from './drapeaux/palestine.webp';
import qatar from './drapeaux/qatar.webp';
import syria from './drapeaux/syria.webp';
import yemen from './drapeaux/yemen.webp';
import china from './drapeaux/china.webp';
import india from './drapeaux/india.webp';
import japan from './drapeaux/japan.webp';
import indonesia from './drapeaux/indonesia.webp';
import vietnam from './drapeaux/vietnam.webp';
import tailand from './drapeaux/tailand.webp';
import malasia from './drapeaux/malasia.webp';
import singaphore from './drapeaux/singaphore.webp';
import philippines from './drapeaux/philippines.webp';
import pakistan from './drapeaux/pakistan.webp';
import bangladesh from './drapeaux/bangladesh.webp';
import nepal from './drapeaux/nepal.webp';
import australia from './drapeaux/australia.webp';
import newZeland from './drapeaux/newZeland.webp';
import fidji from './drapeaux/fidji.webp';
import newGuinez from './drapeaux/newGuinez.webp';








export const InputPhoneNumberPrefixes = [

    { name: 'Royaume-Uni', code: '+44', suffix: englandImage },
    { name: 'France', code: '+33', suffix: france },
    { name: 'Allemagne', code: '+49', suffix: germany },
    { name: 'Autriche', code: '+43', suffix: austria },
    { name: 'Belgique', code: '+32', suffix: belgique },
    { name: 'Bulgarie', code: '+359', suffix: bulgarie },
    { name: 'Chypre', code: '+357', suffix: chypre },
    { name: 'Croatie', code: '+385', suffix: croatie },
    { name: 'Danemark', code: '+45', suffix: Danemark },
    { name: 'Espagne', code: '+34', suffix: spain },
    { name: 'Estonie', code: '+372', suffix: Estonie },
    { name: 'Finlande', code: '+358', suffix: finland },
    { name: 'Grèce', code: '+30', suffix: greece },
    { name: 'Hongrie', code: '+36', suffix: hungary },
    { name: 'Irlande', code: '+353', suffix: ireland },
    { name: 'Italie', code: '+39', suffix: italy },
    { name: 'Lettonie', code: '+371', suffix: latvia },
    { name: 'Lituanie', code: '+370', suffix: lithuania },
    { name: 'Luxembourg', code: '+352', suffix: luxembourg },
    { name: 'Malte', code: '+356', suffix: malta },
    { name: 'Pays-Bas', code: '+31', suffix: paysBas },
    { name: 'Pologne', code: '+48', suffix: poland },
    { name: 'Portugal', code: '+351', suffix: portugal },
    { name: 'République tchèque', code: '+420', suffix: czechRepublic },
    { name: 'Roumanie', code: '+40', suffix: romania },
    { name: 'Slovaquie', code: '+421', suffix: slovakia },
    { name: 'Slovénie', code: '+386', suffix: slovenia },
    { name: 'Suède', code: '+46', suffix: sweden },
    { name: "Monaco", code: "+377", suffix: monaco },
    { name: "Liechtenstein", code: "+423", suffix: liechtenstein },
    { name: "Andorre", code: "+376", suffix: andorra },
    { name: "Saint-Marin", code: "+378" },
    { name: "Vatican", code: "+379", suffix: vaticanCity },
    { name: "Russie", code: "+7", suffix: russia },
    { name: "États-Unis", code: "+1", suffix: unitedStates },
    { name: "Argentine", code: "+54", suffix: argentine },
    { name: "Bolivie", code: "+591", suffix: bolivia },
    { name: "Brésil", code: "+55", suffix: brazil },
    { name: "Chili", code: "+56", suffix: chile },
    { name: "Colombie", code: "+57", suffix: colombia },
    { name: "Équateur", code: "+593", suffix: ecuador },
    { name: "Guyana", code: "+592", suffix: guyana },
    { name: "Paraguay", code: "+595", suffix: paraguay },
    { name: "Pérou", code: "+51", suffix: peru },
    { name: "Suriname", code: "+597", suffix: suriname },
    { name: "Uruguay", code: "+598", suffix: uruguay },
    { name: "Venezuela", code: "+58", suffix: venezuela },

    { name: "Algérie", code: "+213", suffix: algeria },
    { name: "Angola", code: "+244", suffix: angola },
    { name: "Bénin", code: "+229", suffix: benin },
    { name: "Botswana", code: "+267", suffix: botswana },
    { name: "Burkina Faso", code: "+226", suffix: burkinaFaso },
    { name: "Burundi", code: "+257", suffix: burundi },
    { name: "Cameroun", code: "+237", suffix: cameroon },
    { name: "Cap-Vert", code: "+238", suffix: capeVerde },
    { name: "République centrafricaine", code: "+236" },
    { name: "Tchad", code: "+235", suffix: chad },
    { name: "Comores", code: "+269", suffix: comoros },
    { name: "Congo (Brazzaville)", code: "+242", suffix: congo },
    { name: "Congo (RDC)", code: "+243", suffix: congo },
    { name: "Djibouti", code: "+253", suffix: djibouti },
    { name: "Égypte", code: "+20", suffix: egypt },
    { name: "Guinée équatoriale", code: "+240", suffix: guinee },
    { name: "Érythrée", code: "+291", suffix: eritrea },
    { name: "Éthiopie", code: "+251", suffix: ethiopia },
    { name: "Gabon", code: "+241", suffix: gabon },
    { name: "Gambie", code: "+220", suffix: gambia },
    { name: "Ghana", code: "+233", suffix: ghana },
    { name: "Guinée", code: "+224", suffix: guinea },
    { name: "Guinée-Bissau", code: "+245", suffix: guineaBissau },
    { name: "Côte d'Ivoire", code: "+225", suffix: ivoryCoast },
    { name: "Kenya", code: "+254", suffix: kenya },
    { name: "Lesotho", code: "+266", suffix: lesotho },
    { name: "Liberia", code: "+231", suffix: liberia },
    { name: "Libye", code: "+218", suffix: libya },
    { name: "Madagascar", code: "+261", suffix: madagascar },
    { name: "Malawi", code: "+265", suffix: malawi },
    { name: "Mali", code: "+223", suffix: mali },
    { name: "Mauritanie", code: "+222", suffix: mauritania },
    { name: "Île Maurice", code: "+230", suffix: mauritius },
    { name: "Maroc", code: "+212", suffix: morocco },
    { name: "Mozambique", code: "+258", suffix: mozambique },
    { name: "Namibie", code: "+264", suffix: namibia },
    { name: "Niger", code: "+227", suffix: niger },
    { name: "Nigeria", code: "+234", suffix: nigeria },
    { name: "Rwanda", code: "+250", suffix: rwanda },
    { name: "São Tomé-et-Príncipe", code: "+239", suffix: saoTomeAndPrince },
    { name: "Sénégal", code: "+221", suffix: senegal },
    { name: "Seychelles", code: "+248", suffix: seychelles },
    { name: "Sierra Leone", code: "+232", suffix: sierraLeone },
    { name: "Somalie", code: "+252", suffix: somalia },
    { name: "Afrique du Sud", code: "+27", suffix: southAfrica },
    { name: "Soudan", code: "+249", suffix: sudan },
    { name: "Soudan du Sud", code: "+211", suffix: southSudan },
    { name: "Swaziland", code: "+268", suffix: swaziland },
    { name: "Tanzanie", code: "+255", suffix: tanzania },
    { name: "Togo", code: "+228", suffix: togo },
    { name: "Tunisie", code: "+216", suffix: tunisia },
    { name: "Ouganda", code: "+256", suffix: uganda },
    { name: "Zambie", code: "+260", suffix: zambia },
    { name: "Zimbabwe", code: "+263", suffix: zimbabwe },

    { name: "Arabie saoudite", code: "+966", suffix: saudiArabia },
    { name: "Bahreïn", code: "+973", suffix: bahrain },
    { name: "Émirats arabes unis", code: "+971", suffix: emirates },
    { name: "Iran", code: "+98", suffix: iran },
    { name: "Irak", code: "+964", suffix: iraq },
    { name: "Israël", code: "+972", suffix: israel },
    { name: "Jordanie", code: "+962", suffix: jordan },
    { name: "Koweït", code: "+965", suffix: kuwait },
    { name: "Liban", code: "+961", suffix: lebanon },
    { name: "Oman", code: "+968", suffix: oman },
    { name: "Palestine", code: "+970", suffix: palestine },
    { name: "Qatar", code: "+974", suffix: qatar },
    { name: "Syrie", code: "+963", suffix: syria },
    { name: "Yémen", code: "+967", suffix: yemen },
    { name: "Chine", "code": "+86", suffix: china },
    { name: "Inde", "code": "+91", suffix: india },
    { name: "Japon", "code": "+81", suffix: japan },
    { name: "Indonésie", "code": "+62", suffix: indonesia },
    { name: "Vietnam", "code": "+84", suffix: vietnam },
    { name: "Thaïlande", "code": "+66", suffix: tailand },
    { name: "Malaisie", "code": "+60", suffix: malasia },
    { name: "Singapour", "code": "+65", suffix: singaphore },
    { name: "Philippines", "code": "+63", suffix: philippines },
    { name: "Pakistan", "code": "+92", suffix: pakistan },
    { name: "Bangladesh", "code": "+880", suffix: bangladesh },
    { name: "Népal", "code": "+977", suffix: nepal },
    { name: "Australie", code: "+61", suffix: australia },
    { name: "Nouvelle-Zélande", code: "+64", suffix: newZeland },
    { name: "Fidji", code: "+679", suffix: fidji },
    { name: "Papouasie-Nouvelle-Guinée", code: "+675", suffix: newGuinez },
]