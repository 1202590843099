import { useState } from 'react';
import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isEqual,
    isSameMonth,
    isToday,
    parse,
    startOfToday,
} from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import fr from 'date-fns/locale/fr';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Calendar = ({ onChange }) => {
    let today = startOfToday();
    let [selectedDay, setSelectedDay] = useState(today);
    let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

    let days = eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: endOfMonth(firstDayCurrentMonth),
    });

    function previousYear() {
        let firstDayPreviousYear = add(firstDayCurrentMonth, { years: -1 });
        setCurrentMonth(format(firstDayPreviousYear, 'MMM-yyyy'));
    }

    function nextYear() {
        let firstDayNextYear = add(firstDayCurrentMonth, { years: 1 });
        setCurrentMonth(format(firstDayNextYear, 'MMM-yyyy'));
    }

    function previousMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    }

    function nextMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    }

    function handleDayClick(day) {
        setSelectedDay(day);
        onChange(day); // Appeler la fonction onChange lorsqu'un jour est sélectionné
    }

    return (
        <div className="">
            <div className="p-5 w-96 shadow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white  rounded-lg z-50">
                <div className="flex items-center">
                    <div
                        type="button"
                        onClick={previousYear}
                        className="-my-1.5   flex flex-none items-center justify-center  text-gray-400  p-3 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300 mr-0.5"
                    >
                        <span className="sr-only">Previous year</span>
                        <FontAwesomeIcon icon={faChevronLeft} className='p-1 -mr-2 ' />
                        <FontAwesomeIcon icon={faChevronLeft} className='p-1' />
                    </div>
                    <div
                        type="button"
                        onClick={previousMonth}
                        className="-my-1.5 flex flex-none items-center justify-center  text-gray-400  p-3 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300"
                    >
                        <span className="sr-only">Previous month</span>
                        <FontAwesomeIcon icon={faChevronLeft} className='p-1' />
                    </div>
                    <h2 className="flex-auto font-semibold text-gray-900">
                        {format(firstDayCurrentMonth, 'MMMM yyyy ', { locale: fr }).toUpperCase()}
                    </h2>

                    <div
                        onClick={nextMonth}
                        type="button"
                        className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center  text-gray-400  p-3 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300"
                    >
                        <span className="sr-only">Next month</span>
                        <FontAwesomeIcon icon={faChevronRight} className='p-1' />
                    </div>
                    <div
                        onClick={nextYear}
                        type="button"
                        className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center  text-gray-400  p-3 rounded focus:text-gray-400 hover:text-black  dark:text-gray-100 bg-blue-300"
                    >
                        <span className="sr-only">Next year</span>
                        <FontAwesomeIcon icon={faChevronRight} className='p-1 -mr-2 ' />
                        <FontAwesomeIcon icon={faChevronRight} className='p-1 ' />
                    </div>
                </div>
                <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
                    <div>Dim</div>
                    <div>Lu</div>
                    <div>Mar</div>
                    <div>Mer</div>
                    <div>Jeu</div>
                    <div>Ven</div>
                    <div>Sam</div>
                </div>
                <div className="grid grid-cols-7 mt-2 text-sm">
                    {days.map((day, dayIdx) => (
                        <div
                            key={day.toString()}
                            className={classNames(
                                dayIdx === 0 && colStartClasses[getDay(day)],
                                'py-1.5'
                            )}
                        >
                            <div
                                type="button"
                                onClick={() => {
                                    setSelectedDay(day);
                                    handleDayClick(day); // Appeler handleDayClick lors du clic sur un jour
                                }} className={classNames(

                                    isEqual(day, selectedDay) && 'text-white',

                                    !isEqual(day, selectedDay) &&
                                    isToday(day) && 'text-red-500',

                                    !isEqual(day, selectedDay) &&
                                    !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',


                                    !isEqual(day, selectedDay) &&
                                    !isToday(day) &&
                                    !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',

                                    isEqual(day, selectedDay) && isToday(day) && 'bg-red-500',

                                    isEqual(day, selectedDay) &&
                                    !isToday(day) && 'bg-gray-900',

                                    !isEqual(day, selectedDay) && 'hover:bg-gray-200',

                                    (isEqual(day, selectedDay) || isToday(day)) &&
                                    'font-semibold',
                                    'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                                )}
                            >
                                <time dateTime={format(day, 'yyyy-MM-dd')}>
                                    {format(day, 'd')}
                                </time>
                            </div>

                            {/* <div className="w-1 h-1 mx-auto mt-1">

                                                    <div className="w-1 h-1 rounded-full bg-sky-500"></div>

                                                </div> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
let colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
]

export default Calendar;