import { useEffect } from "react"
import { SocketContext } from "./Context"
import { useContext } from "react"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as fa from '@fortawesome/free-solid-svg-icons';

const VideoPlayer = ({ account, endCall, idToCall }) => {
    const {
        initiateCall,
        acceptInitiateCall,
        refuseInitiateCall,
        leaveCall,
        cancelCall,
        devices,
        setDeviceId,
        deviceId,
        myVideo,

        userVideo,
        userStream,

        setStream,
        stream,
        status,
        me,
        idCaller,

    } = useContext(SocketContext)



    const [minimized, setMinimized] = useState(false)

    const [settingsDisplay, setSettingsDisplay] = useState(false);
    const [coverDisplay, setCoverDisplay] = useState(true)

    const [myStyle, setMyStyle] = useState({})
    const [userStyle, setUserStyle] = useState({})

    const handleCameraChange = async (event) => {
        setDeviceId(event.target.value);
        setStream()


    };



    useEffect(() => {

        return () => {
            if (status == "callOpened") leaveCall();
            if (status == "initiateCall") cancelCall();

        }
    }, [])

    useEffect(() => {

        if (stream) {
            myVideo.current.srcObject = stream;
            // myVideo.current.play();
            console.log(stream.getVideoTracks());
            setConstraints()
            window.addEventListener("resize", () => { console.log("Resize"); setConstraints(); });
        }
        return () => {
            window.removeEventListener("resize", () => { console.log("Resize"); setConstraints(); });
        }
    }, [stream])

    const setConstraints = (scales = [0, 0]) => {

        var newScales = [scales[0], scales[1]]
        var tabs = [["myVideo", setMyStyle, stream], ["userVideo", setUserStyle, userStream]]
        for (var i = 0; i < tabs.length; i++) {
            var id = tabs[i][0];
            var _stream = tabs[i][2];

            console.log("setConstraints", id)
            if (!_stream) {
                console.log("_stream", _stream)
                continue;
            }
            var track = _stream.getVideoTracks()[0];
            var settings = track.getSettings();

            var width = settings.width;
            var height = settings.height;
            if (!width) {
                console.log("width", _stream)
                continue;
            }
            if (!track) {
                console.log("track", _stream)
                continue;
            }
            const constraints = {
                //    width: window.innerWidth,
                //    height: window.innerHeight,
                aspectRatio: window.innerWidth / window.innerHeight,
            };
            var obj = document.getElementById(id)
            if (!obj) {
                console.log("obj", id)
                continue;
            }
            //  if(id=="myVideo")  track.applyConstraints(constraints);
            obj.width = width;
            obj.height = height;
            var container = obj.parentNode;
            console.log(container);
            console.log(width, container.parentNode.offsetWidth, height, container.parentNode.offsetHeight)

            const scaleX = container.parentNode.offsetWidth / width
            const scaleY = container.parentNode.offsetHeight / height
            var scale = coverDisplay ? Math.max(scaleX, scaleY) : Math.min(scaleX, scaleY)
            newScales[i] = scale
            const styler = {
                left: "50%",//(container.parentNode.offsetWidth*0.5) + "px",
                top: "50%",//(container.parentNode.offsetHeight*0.5) + "px",
                height: height + "px",
                width: width + "px",
                transform: "scale(" + scale + ") translate("
                    + (-width / scale) * 0.5 + "px," + (-height / scale) * 0.5 + "px)"
            }

            console.log(styler);

            var setStyle = tabs[i][1]
            setStyle(styler)

        }

        if (JSON.stringify(scales) !== JSON.stringify(newScales)) 
        {
            console.log("Rappels");
            setTimeout(() => setConstraints(newScales), 100)
        }

    }

    useEffect(() => {
        setConstraints()
    },
        [coverDisplay, minimized,status,userStream])


    useEffect(() => {

        if (userStream) {
            console.log(" get userStream", userStream)
            userVideo.current.width = window.innerWidth;
            userVideo.current.height = window.innerHeight;
            console.log(" get userVideo", userVideo.current)
            userStream.getTracks().forEach(function (track) {
                console.log(track);
                console.log(track.getConstraints());
            });
            userVideo.current.srcObject = userStream;
            //  userVideo.current.play();
        }

        //setConstraints()
    }, [userStream])

    return (
        <div className="flex flex-col justify-center items-center fixed w-screen h-screen left-0 top-0"  >
            {/* user's video */}
            {
                userStream && (
                    <div className={" fixed top-0 left-0 w-screen h-screen bg-black  flex flex-col justify-center items-center "}>

                        <div className=" absolute top-0 left-0  transition-all duration-75" style={userStyle} >
                            <video id="userVideo" playsInline ref={userVideo} autoPlay />
                        </div>
                    </div>
                )
            }
            {/* my video */}
            {
                (
                    <div className={" fixed  transition-all duration-300 bg-black  -scale-x-100 flex flex-row items-center justify-center  " + (userStream || minimized ? "right-2 bottom-2 border border-white  w-[20vw] h-[20vh] overflow-hidden rounded-md " : "right-0 bottom-0 w-screen h-screen")}>
                        <div className=" absolute top-0 left-0  transition-all duration-75" style={myStyle}>
                            <video id="myVideo" playsInline muted ref={myVideo} autoPlay />
                        </div>
                    </div>
                )
            }
            <div className={" px-4 w-screen flex flex-row  items-center  justify-center  fixed  bottom-0 p-4 "} >
                {status == "" &&
                    <><FontAwesomeIcon icon={fa.faPhone} className='mx-6 w-10 h-10 text-white text-xs  bg-green-700 p-2 rounded-full' onClick={() => { initiateCall(idToCall); }} />
                        <FontAwesomeIcon icon={fa.faClose} className='mx-6 w-10 h-10 text-white text-2xl  bg-red-700 p-2 rounded-full' onClick={() => { endCall(); }} />

                    </>
                }

                {(status == "initiateCall") && <FontAwesomeIcon icon={fa.faPhone} className='text-red-600 text-2xl border border-red-700 p-2 rounded-full' onClick={() => { cancelCall(); }} />}
                {(status == "onInitiateCall") && <>

                    <FontAwesomeIcon icon={fa.faPhone} className='text-green-600 animate-pulse text-2xl border-2 border-green-700 p-2 rounded-full mx-2' onClick={() => { acceptInitiateCall(); }} />
                    <FontAwesomeIcon icon={fa.faPhone} className='text-red-600 text-2xl border-2 border-red-700 p-2 rounded-full mx-2' onClick={refuseInitiateCall} />
                </>}
                {(status == "callOpened") && <FontAwesomeIcon icon={fa.faPhone} className='text-red-600 text-2xl border border-red-700 p-2 rounded-full' onClick={leaveCall} />}


            </div>


            <div className='text-white  bg-black p-2 rounded-full z-20 fixed right-2  text-xs top-12 '  >{status}...</div>
            <div className="fixed right-2  flex flex-col ">
                <FontAwesomeIcon icon={fa.faCameraAlt} className='text-white text-xl  bg-black p-2 rounded-full z-20  my-2 ' onClick={() => { setSettingsDisplay(!settingsDisplay) }} />
                <FontAwesomeIcon icon={fa.faArrowDownWideShort} className='text-white text-xl  bg-black p-2 rounded-full z-20  my-2 ' onClick={() => { setCoverDisplay(!coverDisplay) }} />

                {!userStream && <FontAwesomeIcon icon={fa.faMobileScreen} className='text-white text-xl border bg-black border-white p-2 rounded-full my-2' onClick={() => { setMinimized(!minimized) }} />}
            </div>
            {
                settingsDisplay &&
                <div className="border border-white p-4 flex flex-col items-center justify-center z-30 rounded relative">
                    <label htmlFor="cameraSelect">Choose a camera:</label>
                    <select id="cameraSelect" onChange={handleCameraChange} value={deviceId || ''}>
                        {devices.map((camera) => (
                            <option key={camera.deviceId} value={camera.deviceId}>
                                {camera.label || `Camera ${camera.deviceId}`}
                            </option>
                        ))}
                        <option key={''} value={''}>
                            Par défaut
                        </option>
                    </select>
                    <textarea value={JSON.stringify(stream?.getVideoTracks()[0]?.getSettings())} className="bg-transparent w-full h-40 p-2 text-xs"></textarea>
                    <FontAwesomeIcon icon={fa.faClose} className='text-white text-xs border bg-transparent border-white p-2 rounded-full absolute top-2 right-2' onClick={() => { setSettingsDisplay(!settingsDisplay) }} />
                </div>

            }
        </div>
    )
}
export default VideoPlayer