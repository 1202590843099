import { useEffect, useMemo } from "react"
import { useState } from "react"
import { data } from "../../service/data"



const InputPropSchema = ({label,onChange,value,className,placeholder,context}) => {
  
  const [_value,setValue]=useState(value)   

  const [schemaProps,setSchemaProps]=useState([])
    const propsBySchema  = async (schema_id)=>{
        var _result = []
        var schema = await data.getContent(schema_id )
        if( schema )
        schema.content.schema.forEach(c=>{
    
      _result.push({...c, value: c.name  , label:  `<${ c.name}>` })
         

        })
      
      setSchemaProps( _result);
      }

    useEffect( ()=>{
      
      if( context && context.schema_id)
        {
      propsBySchema(context.schema_id)
       
       
  
       }
       
       
      }
       
,[context])


return (
    <>
    <div className={"relative " +className } >
    <select  onChange={(e)=>{
          var propName = e.target.value

        
              var prop =schemaProps?.find(s=>s.name ===propName)        
                onChange(prop)                                         
          }}   
        
        value={  _value?.name  } id={"input_"+label} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" >
   
    {placeholder && <option value={null} className="   text-opacity-50   text-zinc-500    italic"   >-- {placeholder} --</option>  }
   {schemaProps && schemaProps.map((option,i)=>  <option key={i} value={option.name}>{option.label?? option.name}</option>)}

    </select>
    <label htmlFor={ "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
</div>
</> 
)
}
export default InputPropSchema