import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useParams,useSearchParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputText from "../../../../components/inputs/InputText";
import InputSelect from "../../../../components/inputs/InputSelect";

import "../../../../App.css";

import InputBoolean from "../../../../components/inputs/InputBoolean";
import InputIcon from "../../../../components/inputs/InputIcon";
import TemplateEdition from "../../../../components/edition/TemplateEdition";
import ContentEdition from "../../../../components/edition/ContentEdition";
import TemplateTree from "../../../../components/templates/TemplateTree";
import { getProps, loadTemplate, structureClean, structurePutIn, structureReplace } from "../../../../tools/tpl";
import Loader from "../../../../components/Loader";
import { MfctContext } from "../../../../context";



//var backupTemplate 
function PopupPageEditor() {
  const urlSearchString = window.location.search;

  const params = new URLSearchParams(urlSearchString);

  const editor_id= params.get("editor_id")


  const { project,cache} = useContext(MfctContext);
  const [dragged,setDragged]= useState()
 
  const [data, setData] = useState();
  const [explodeTree, setExplodeTree] = useState();
  const [showVariables, setShowVariables] = useState();
  const setPage = (page) => {
    saver("page",savePage,page  )
    window.opener.postMessage({editor_id, editor: true, setPage: true, page });
  };


  const [backupTemplate,setBackupTemplate]=useState()

  const [saveAll, setSaveAll] = useState({})
  const [loading, setLoading] = useState(false);
const cancelling =async ()=>{
 
  window.opener.postMessage({editor_id, editor: true, cancelling: true });
        setSaveAll({})
      
      }
      
  const saving =async ()=>{
    setLoading(true);
        var keys =  Object.keys(saveAll)
        for(var i= 0; i<keys.length; i++)
        {
       
          const { func ,obj} = saveAll[keys[i]]
          console.log("Enrgistrement " + keys[i], obj)
          await func(obj)
        }
        savePage()
          setSaveAll({})
          setLoading(false);
          toast( "Successfully saved '" );
        }
        
        const saver =(key,func,obj)=>{
        if( !func)
        {
            var  v= saveAll;
            delete v[key];
            setSaveAll(v)
        }
        else
        setSaveAll(v=>({...v, [key]:{func,obj}  }))
    
        }
  



const _setDragged=(obj,template_id,id)=>{
 console.log("_setDragged", obj,template_id,id)
if(backupTemplate){
console.log("backupTemplate already here !!", backupTemplate)
return 
  }  if(template_id)
  {
setBackupTemplate ( {
 template : data.templates.find((t) => t.template_id+"" ===""+template_id),
 id,
 obj,
 pos:{cancel:true}
})
console.log("backupTemplate", backupTemplate)
  }
  setDragged(obj)
}


const [propsTemplates,setPropsTemplates] = useState()




useEffect(()=>{


const   computeProps = async()=>{
  if(!data || !data.page) return 
console.log("Recalculating props...")

var tpl_ids = []

if(  data.page.content.header && parseInt("0" + data.page.content.header)>0) tpl_ids.push(parseInt(data.page.content.header))
if(  data.page.content.footer && parseInt("0" + data.page.content.footer)>0) tpl_ids.push(parseInt(data.page.content.footer))
 data.page.content.body.forEach(body=>{if(body.template_id  && parseInt("0" +body.template_id)>0) tpl_ids.push(parseInt(body.template_id )) })

var _propsTemplates ={}
 for(var i=0; i<tpl_ids.length; i++){
  console.log("getting  template " + tpl_ids[i])
var _template = await loadTemplate( tpl_ids[i],data.templates,project)
console.log(_template)
_propsTemplates[tpl_ids[i]]= await getProps(_template.structure,cache ,data.templates,project,tpl_ids[i]);
 }
console.log("_propsTemplates",_propsTemplates )
 setPropsTemplates(_propsTemplates)
}


computeProps()

},[data])


const addTemplateContent =async (template_id,onChange)=>{


var content =  await project.api("/set_content", { body: {
  title : "Data Bloc Template - " + template_id,
  template_id , app_id :data.page.app_id,content:{}
} });
window.alert( JSON.stringify(content) );
updateContent(content)
onChange(content.content_id);

}


const dragging= ( pos)=>{



  if(!backupTemplate || JSON.stringify(backupTemplate.pos)===JSON.stringify(pos)) return 
  console.log("dragging",pos );
 
  if(pos.cancel)
  { 
    setBackupTemplate ( b=>({...b,pos}))
    console.log("Cancel ",pos );
    //On remet comme au debut
                        setTemplates(
                          data.templates.map((t) =>
                            t.template_id +""===""+
                            backupTemplate.template.template_id
                              ?  backupTemplate.template
                              : t
                          )
                        ) 
                   
  
  }
  else  if(pos.end)
  {   
     selectTpl(backupTemplate.new_id )
     setBackupTemplate ( )
    console.log("End of backupTemplate ")
    setDragged()

     // C'est fini




  }
  else if (pos.parent  || pos.sibling)
  {
      // On bouge l'element
     
      setBackupTemplate ( b=>({...b,new_id: pos.data_id+(pos.parent ?".0":"")  }))

       pos.data_id=pos.data_id.split(backupTemplate.id+".").join("").split(backupTemplate.id).join("") 
      var structure = structurePutIn( 
        structureReplace(
        backupTemplate.template.structure,{empty:true},backupTemplate.obj.id.split(backupTemplate.id+".").join("").split(backupTemplate.id).join("") ) , backupTemplate.obj.structure, pos)
    
        setTemplates(
          data.templates.map((t) =>
            t.template_id ===
            backupTemplate.template.template_id
              ?  {...backupTemplate.template,structure: structureClean(structure )}
              : t
          )
        ) 
  }
}


const VarContent = ({data,className})=>{


if( data && typeof data === "object"){

  if(Array.isArray(data))
  {
    return   <div className={  "flex flex-col"}>{ data.map(((value,i)=> <div  className={ className +" ml-1 pl-1 border-l border-zinc-500 flex flex-row items-start justify-start"} key={i} >
  <div className="font-bold">{ i} : </div>
 <VarContent data = { value} /> 
  </div>))} </div>
  }
else
{
    return   <div  className={ "flex flex-col"}>{  Object.keys(data).map((key=> <div  className={ className + " ml-1 pl-1 border-l border-zinc-500 flex fex-row items-start justify-start"} key={key} >
  <div className="font-bold">{key} : </div>
 <VarContent data = { data[key]} /> 
  </div>))} </div>
}



}
return    <>{data}</>


}

  const setSelected = (selected) => {
    window.opener.postMessage({editor_id, editor: true, setSelected: true, selected });
  };
  const setSelectedBloc = (selectedBloc) => {
    setData((d) => ({ ...d, selectedBloc }));
    window.opener.postMessage({editor_id,
      editor: true,
      setSelectedBloc: true,
      selectedBloc,
    });
  };
  const setSelectedTemplate = (selectedTemplate) => {
    window.opener.postMessage({editor_id,
      editor: true,
      setSelectedTemplate: true,
      selectedTemplate,
    });
  };

  const setBlocVisible = (id) => {
    window.opener.postMessage({editor_id, editor: true, setBlocVisible: true, id });
  };

  const setBaseContent = (simulatedContent) => {
    window.opener.postMessage({editor_id,
      editor: true,
      setBaseContent: true,
      simulatedContent,
    });
  };

  const setInternal = (key, value) => {
    window.opener.postMessage({editor_id, editor: true, setInternal: true, key, value });
  };
  const setTemplates = (templates) => {
  setData(data=>({...data,templates}) )
    window.opener.postMessage({editor_id, editor: true, setTemplates: true, templates });
  };

  const updateContent = (content) => {
    window.opener.postMessage({editor_id, editor: true, updateContent: true, content });
  };

  const cancelPage = () => {
    window.opener.postMessage({editor_id, editor: true, cancelPage: true });
  };






  const savePage = () => {
 
    window.opener.postMessage({editor_id,
      editor: true,
      savePage: true,
      page: data.page,
    });
    toast( "Successfully saved '" );
  };

  const [explode, setExplode] = useState();

  const [tabShow, setTabShow] = useState("template");

  const selectTpl = (id, tpl) => {
    var post = {editor_id, editor: true };

    post = {
      ...post,
      setSelectedBloc: true,
      selectedBloc: id.split(".")[0] + "-edit",
    };

    if (tpl) {
      post = { ...post, setSelectedTemplate: true, selectedTemplate: tpl };

      /****     RQajouter dans le receive message */
      if (tpl.schema_label) {
        var obj2 = document.getElementById(tpl.schema_label + "-schema_label");
        if (obj2) {
          console.log("Scroll to", obj2.id, obj2);
          obj2.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          obj2.focus();
        }
      }
    }

    post = { ...post, setSelected: true, selected: id };
    window.opener.postMessage(post);
  };


const addBloc= (pos)=>{




    setPage({
      ...data.page,
      content: {
        ...data.page.content,
        body: [
          ...data.page.content.body.filter((c,i)=>i<pos),
          { template_id: 0 },
          ...data.page.content.body.filter((c,i)=>i>=pos),
        ],
      },
    });

    setTimeout(()=> { selectTpl("bloc_"+ pos + "-edit");  setSelectedBloc("bloc_"+ pos + "-edit")},500);
}


  useEffect(() => {
    const getData = async function (e) {
      if (!e.data.editor) return;
      console.log("origin", e.origin);

      setData((d) => ({ ...d, ...e.data }));
    };
    window.addEventListener("message", getData, );
    window.opener.postMessage({editor_id, editor: true, getData: true });

    return () => {
      window.removeEventListener("message", getData);
    };
  }, []);

  const navigate = useNavigate();

  const TabSwitch = () => {
    return (
      <div className=" p-1 text-sm flex flex-row items-center justify-center">
        <div
          className={
            "p-2 " +
            (tabShow === "template"
              ? " bg-slate-500 text-white font-bold "
              : "")
          }
          onClick={() => setTabShow("template")}
        >
          Template
        </div>
        <div
          className={
            "p-2 " +
            (tabShow === "donnees" ? " bg-slate-500 text-white font-bold " : "")
          }
          onClick={() => setTabShow("donnees")}
        >
          Données
        </div>
      </div>
    );
  };

  return (
    <div className="App  text-zinc-600 text-sm mb-10">
 { loading && <Loader />   }
      {data &&
        data.page.isReady &&
        data.headerTemplates &&
        data.footerTemplates &&
        data.contentTemplates && (
          <header className=" ">
            <div className="flex flex-col w-screen">
              <div className="w-full flex flex-row    bg-zinc-100 rounded-t-xl  z-50 px-2 ">
                <div
                  id="page-edit"
                  className={
                    " flex border-x-2 p-2 border-zinc-600 my-2  flex-col "
                  }
                >
                  <div
                    className="font-bold p-1 text-xl text-left w-full sticky top-0  "
                    onClick={() => {
                      setSelectedBloc();
                      setExplode();
                    }}
                  >
                    Page
                  </div>
                  {!data.selectedBloc && (
                    <>
                      <div className=" flex w-full flex-row items-center ">
                        <div className=" flex    gap-2  flex-col grow">
                          <InputText
                            label={"Titre"}
                            className={" my-2 w-full"}
                            value={data.page.title}
                            onChange={(value) => {
                              setPage({ ...data.page, title: value });
                            }}
                          />

                          <InputText
                            label={"Type"}
                            className={" my-2 w-full"}
                            value={data.page.type}
                            onChange={(value) => {
                              setPage({ ...data.page, type: value });
                            }}
                          />
                        </div>
                        <FontAwesomeIcon
                          icon={fa.faDownLong}
                          className="mx-4 text-green-700 "
                          onClick={() => setExplode(!explode)}
                        ></FontAwesomeIcon>
                      </div>

                      <div className="w-full flex    gap-2  flex-col grow">
                        <InputText
                          label={"Label"}
                          className={" my-2 w-full"}
                          value={data.page.content.label}
                          onChange={(value) => {
                            setPage({
                              ...data.page,
                              content: { ...data.page.content, label: value },
                            });
                          }}
                        />
                        <InputText
                          label={"Slug"}
                          className={" my-2 w-full"}
                          value={data.page.slug}
                          onChange={(value) => {
                            setPage({ ...data.page, slug: value });
                          }}
                        />
                        <InputBoolean
                          labelTrue={"Authentification requise"}
                          labelFalse={"Authentification non requise"}
                          className={" my-2 w-full text-black"}
                          value={data.page.is_auth}
                          onChange={(value) => {
                            setPage({ ...data.page, is_auth: value });
                          }}
                        />
                        <InputIcon
                          label={"Icone"}
                          className={" my-2 "}
                          value={data.page.content.icone ?? "faQuestion"}
                          onChange={(value) => {
                            setPage({
                              ...data.page,
                              content: { ...data.page.content, icone: value },
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={
                    "  border-x-2 p-2 border-zinc-600 my-2    h-screen overflow-y-auto relative pb-12"
                  }
                >
                 
                  <div className={" flex   flex-col items-start w-80   relative  "}>
                  <div className="font-bold p-1 text-xl text-left w-full sticky top-0  ">
                    Tree
                  </div>
                    <TemplateTree
                      selectTpl={selectTpl}
                      id={"header"}
                      explodeTree={explodeTree}
                      schemas={data.schemas}
                      selected={data.selected}
                      template={
(
                        backupTemplate && backupTemplate.template.template_id +""===""+ data.page.content.header
? backupTemplate.template
:
                        data.templates.find(
                          (t) =>
                            t.template_id + "" === "" + data.page.content.header
                        ))?.structure ?? {}
                      }
                      setSelected={selectTpl}
                      setStructure={  (structure) =>
                        setTemplates(
                          data.templates.map((t) =>
                            t.template_id ===
                            data.page.content.header
                              ? {...t,structure}
                              : t
                          )
                        )    }
                        dragged={dragged}
                        setDragged={(_id)=>_setDragged(_id, data.page.content.header,"header" )}
                        dragging={dragging }
                    />
<div className="w-full p-2  hover:bg-green-500 my-0 font-bold opacity:0 hover:opacity-100  hover:shadow h-0 text-white hover:my-1 overflow-hidden hover:h-8 transition-all delay-500 duration-300 "  onClick={()=>addBloc(0)}>
 Bloc <FontAwesomeIcon icon={fa.faPlus}  /> 
   </div>
                    {data.page.content.body.map((body, i) => (
                      <div className="flex flex-col w-full" key={i}>
                        <TemplateTree
                          selectTpl={selectTpl}
                          id={"bloc_" + i}
                          explodeTree={explodeTree}
                          schemas={data.schemas}
                          selected={data.selected}
                          template={
(
                        backupTemplate && backupTemplate.template.template_id +""===""+ body.template_id
                        ? backupTemplate.template
                        :
                            data.templates.find(
                              (t) =>
                                t.template_id + "" === "" + body.template_id
                            ))?.structure ?? {}
                          }
                          setSelected={selectTpl}
                          setStructure={  (structure) =>
                            setTemplates(
                              data.templates.map((t) =>
                                t.template_id ===
                                body.template_id
                                  ? {...t,structure}
                                  : t
                              )
                            )    }

                            dragged={dragged}
                            setDragged={(_id)=>_setDragged(_id, body.template_id,"bloc_" + i)}
                            dragging={dragging }
                        />
<div className="w-full p-2  hover:bg-green-500 my-0 font-bold opacity:0 hover:opacity-100  hover:shadow h-0 text-white hover:my-1 overflow-hidden hover:h-8 transition-all delay-500 duration-300 "  onClick={()=>addBloc(i+1)} >
 Bloc <FontAwesomeIcon icon={fa.faPlus} /> 
   </div>

                      </div>
                    ))}


                    <TemplateTree
                      selectTpl={selectTpl}
                      id={"footer"}
                      schemas={data.schemas}
                      selected={data.selected}
                      template={
(
                        backupTemplate && backupTemplate.template.template_id +""===""+data.page.content.footer
                        ? backupTemplate.template
                        :

                        data.templates.find(
                          (t) =>
                            t.template_id + "" === "" + data.page.content.footer
                        ))?.structure ?? {}
                      }
                      setSelected={selectTpl}
                      setStructure={  (structure) =>
                        setTemplates(
                          data.templates.map((t) =>
                            t.template_id ===
                            data.page.content.footer
                              ? {...t,structure}
                              : t
                          )
                        )    }
                        dragged={dragged}
                        setDragged={(_id)=>_setDragged(_id, data.page.content.footer,"footer" )}
                        dragging={dragging }
                    />


                  </div>
                </div>

                <div className="flex flex-col grow  items-start justify-start">
                  {true && (
                    <div
                      id="header-edit"
                      className={
                        " flex border-x-2 p-2 border-zinc-600 my-2  flex-col  relative" +
                        (data.selectedBloc === "header-edit"
                          ? " bg-slate-200 "
                          : "") + 
                          ( data.selectedBloc !== "header-edit" && " hidden")
                      }
                      onClick={() => {
                        if(data.selectedBloc !== "header-edit" ){
                        setSelectedBloc("header-edit");
                        setBlocVisible("bloc_header");
                        }
                      }}
                    >
                      <div className="font-bold p-1 text-xl text-left  sticky top-0  ">
                        Header
                      </div>
                      {data.selectedBloc === "header-edit" && (
                        <>
                          <div className=" flex ">
                            <div className="flex flex-col w-1/2 border-r border-black p-2">
                              <div className="flex -flex-row w-full">
                                <InputSelect
                                  className={" my-2 w-full"}
                                  label={"header"}
                                  options={data.headerTemplates}
                                  value={data.page.content.header}
                                  trueValue={true}
                                  placeholder={"Choisis ton entête de page"}
                                  onChange={(value) => {
                                    setPage({
                                      ...data.page,
                                      content: {
                                        ...data.page.content,
                                        header: value,
                                      },
                                    });
                                  }}
                                />
                                <a
                                  href={`/template/${data.page.content.header}`}

                                  className="p-2 text-xl"
                                  target="_blank"
                                  rel="noreferrer"
                                  
                                >
                                  <FontAwesomeIcon icon={fa.faEdit} />
                                </a>
                              </div>
{ parseInt(data.page.content.header)>0 &&
                              <TemplateEdition
                                id={"header"}
                                simulatedContent={data.baseContent}
                                key={data.page.content.header +"head"}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={data.selected}
                                template_id={data.page.content.header}
                                liveTemplate={  data.templates.find((t) =>
                                  t.template_id ===data.page.content.header)  }
                                setSelected={selectTpl}
                                updateLiveTemplate={(updatedTemplate) =>
                                  setTemplates(
                                    data.templates.map((t) =>
                                      t.template_id ===
                                      updatedTemplate.template_id
                                        ? updatedTemplate
                                        : t
                                    )
                                  )
                                }
                                setTemplates={setTemplates}
                                saver={saver}
                              />}
                            </div>
                           {
                           data.page.content.header && (propsTemplates && propsTemplates[parseInt(data.page.content.header)]) && propsTemplates[parseInt(data.page.content.header)].length>0 && 
                           
                           <div className="flex flex-col  w-1/2 p-2">
                             
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Data Header"}
                                  options={data.contents.filter(c=> parseInt(c.template_id)===parseInt(data.page.content.header)).map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.Titre,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={data.page.content.headerContent_id}
                                  onChange={(headerContent_id) => {
                                    setPage({
                                      ...data.page,
                                      content: {
                                        ...data.page.content,
                                        headerContent_id,
                                      },
                                    });
                                  }}
                                />

                                {data.page.content.headerContent_id && (
                                 
                                    <FontAwesomeIcon  onClick={() => {
                                      setPage({
                                        ...data.page,
                                        content: {
                                          ...data.page.content,
                                          headerContent_id:null,
                                        },
                                      });
                                    }}  className="p-2 text-xl" icon={fa.faClose} />
                                 
                                )}
                                {!data.page.content.headerContent_id && (

                                    <FontAwesomeIcon
                                    onClick={ ()=>addTemplateContent(data.page.content.header, (headerContent_id) => {
                                      setPage({
                                        ...data.page,
                                        content: {
                                          ...data.page.content,
                                          headerContent_id,
                                        },
                                      });
                                    } )}
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                           
                                )}
                              </div>

                              {parseInt(data.page.content.headerContent_id)>0 && (
                                <ContentEdition
                                key={ data.page.content.headerContent_id}
                                  defaultApp={data.app}
                                  dataOnly={true}
                                  content_id={
                                    data.page.content.headerContent_id
                                  }
                                  updateLiveContent={updateContent}
                                  saver={saver}
                                  data_schema={propsTemplates[parseInt(data.page.content.header)]}
                                />
                              )}
                            </div>


                      }
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {data.page.content.body.map((body, i) => (
                    <div
                      key={i}
                      id={"bloc_" + i + "-edit"}
                      className={
                        " grow flex   flex-col  items-start " +
                        (data.selectedBloc === "bloc_" + i + "-edit"
                          ? " bg-slate-200 "
                          : "") + 
                         ( data.selectedBloc !== "bloc_" + i + "-edit" && " hidden")
                      }
                      onClick={() => {
                        if(data.selectedBloc !== "bloc_" + i + "-edit"){
                        setBlocVisible(body.label ?? "bloc_" + i);
                        setSelectedBloc("bloc_" + i + "-edit");
                        }
                      }}
                    >
                      <div className="font-bold p-1 text-xl text-left flex justify-between w-full">
                      <span>Bloc {i}</span>
                      <FontAwesomeIcon className=" bg-red-400 text-white p-2 h-4 w-4 rounded-full "     icon={fa.faMinus} 
                      
                      onClick={  ()=>{
                        setPage({
                          ...data.page,
                          content: { ...data.page.content, body: data.page.content.body.filter((c,index)=>i!==index ) },
                        })
                      } }
                      />   
                      </div>

                      {data.selectedBloc === "bloc_" + i + "-edit" && (
                        <div className=" flex  flex-col  border-x-2 p-2 border-zinc-600 my-2 w-full ">
                          <InputText
                            className={"mt-2 "}
                            label={"label"}
                            value={body.label}
                            onChange={(label) => {
                              var _body = [...data.page.content.body];
                              _body[i].label = label;

                              setPage({
                                ...data.page,
                                content: { ...data.page.content, body: _body },
                              });
                            }}
                          />

                          <div className="flex ">
                            <div className="flex flex-col w-1/2">
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={" mt-2 w-full"}
                                  label={"Bloc " + (i + 1)}
                                  options={data.contentTemplates}
                                  placeholder={"Choisir Template"}
                                  value={body.template_id}
                                  onChange={(value) => {
                                    var _body = [...data.page.content.body];
                                    if (value !== "-- Supprimer --") {
                                      _body[i].template_id = value;
                                    } else {
                                      _body = _body.filter(
                                        (b, idx) => i !== idx
                                      );
                                    }
                                    setPage({
                                      ...data.page,
                                      content: {
                                        ...data.page.content,
                                        body: _body,
                                      },
                                    });
                                  }}
                                />

                                <a
                                  href={`/template/${body.template_id}`}
                                  className="p-2 text-xl"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <FontAwesomeIcon icon={fa.faEdit} />
                                </a>
                              </div>
{   parseInt( body.template_id)>0 &&
                              <TemplateEdition
                                id={"bloc_" + i}
                                key={body.template_id + "_" + i}
                                simulatedContent={data.baseContent}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={data.selected}
                                template_id={body.template_id}
                                setSelected={selectTpl}
                                liveTemplate={  data.templates.find((t) =>
                                  t.template_id ===body.template_id)  }
                                updateLiveTemplate={(updatedTemplate) =>
                                  setTemplates(
                                    data.templates.map((t) =>
                                      t.template_id ===
                                      updatedTemplate.template_id
                                        ? updatedTemplate
                                        : t
                                    )
                                  )
                                }
                                setTemplates={setTemplates}
                                saver={saver}
                              />}
                            </div>
                            {body.template_id && (propsTemplates && propsTemplates[parseInt(body.template_id)]) &&  propsTemplates[parseInt(body.template_id)].length>0 &&
                            <div className="flex flex-col w-1/2">
        
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Contenu de Données " + (i + 1)}
                                  options={data.contents.filter(c=> parseInt(c.template_id)===parseInt(body.template_id)).map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.Titre ,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={body.content_id}
                                  onChange={(value) => {
                                    var _body = [...data.page.content.body];
                                    _body[i].content_id = value;
                                    setPage({
                                      ...data.page,
                                      content: {
                                        ...data.page.content,
                                        body: _body,
                                      },
                                    });
                                  }}
                                />
                                {body.content_id && (

                                                                      <FontAwesomeIcon  onClick={() =>{
                                                                        var _body = [...data.page.content.body];
                                                                        _body[i].content_id = null;
                                                                        setPage({
                                                                          ...data.page,
                                                                          content: {
                                                                            ...data.page.content,
                                                                            body: _body,
                                                                          },
                                                                        });
                                                                      }}  className="p-2 text-xl" icon={fa.faClose} />
                                )}
                                {!body.content_id && (
                                 
                                    <FontAwesomeIcon
                                    onClick={ ()=>addTemplateContent(body.template_id,
                                      (value) => {
                                        var _body = [...data.page.content.body];
                                        _body[i].content_id = value;
                                        setPage({
                                          ...data.page,
                                          content: {
                                            ...data.page.content,
                                            body: _body,
                                          },
                                        });
                                      }
                                    )}
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                                 
                                )}
                              </div>

                              {parseInt(body.content_id)>0 && (
                                <ContentEdition
                                key={ body.content_id}
                                dataOnly={true}
                                  defaultApp={data.app}
                                  content_id={body.content_id}
                                  updateLiveContent={updateContent}
                                  saver={saver}
                                  data_schema={propsTemplates[parseInt(body.template_id)]}
                                />
                              )}
                            </div>
}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  {true && (
                    <>

                      <div
                        id={"footer-edit"}
                        className={
                          " flex  border-x-2 p-2 border-zinc-600 my-2 flex-col w-full" +
                          (data.selectedBloc === "footer-edit"
                            ? " bg-slate-200 "
                            : "")+ 
                            ( data.selectedBloc !== "footer-edit" && " hidden")
                        }
                        onClick={() => {
                          if(data.selectedBloc !== "footer-edit"){
                          setBlocVisible("bloc_footer");
                          setSelectedBloc("footer-edit");
                          }
                        }}
                      >
                        <div className="font-bold p-1 text-xl text-left w-full">
                          Footer
                        </div>

                        {data.selectedBloc === "footer-edit" && (
                          <>
                            <div className=" flex ">
                            <div className="flex flex-col">
                            {(
                              <div className="flex flex-row w-full">
                                <InputSelect
                                  className={" my-2 w-full"}
                                  label={"footer"}
                                  options={data.footerTemplates}
                                               value={data.page.content.footer}
                                  trueValue={true}
                                  placeholder={"Choisis ton pied de page"}
                                  onChange={(value) => {
                                    setPage({
                                      ...data.page,
                                      content: {
                                        ...data.page.content,
                                        footer: value,
                                      },
                                    });
                                  }}
                                />
                                <a
                                  href={`/template/${data.page.content.footer}`}
                                  className="p-2 text-xl"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <FontAwesomeIcon icon={fa.faEdit} />
                                </a>
                              </div>
                            )}
                 
                            {
                              parseInt(data.page.content.footer)>0 &&   <TemplateEdition
                                id={"footer"}
                                key={data.page.content.header +"foot"}
                                simulatedContent={data.baseContent}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={data.selected}
                                template_id={data.page.content.footer}
                                setSelected={selectTpl}
                                liveTemplate={  data.templates.find((t) =>
                                  t.template_id ===data.page.content.footer)  }
                                updateLiveTemplate={(updatedTemplate) =>
                                  setTemplates(
                                    data.templates.map((t) =>
                                      t.template_id ===
                                      updatedTemplate.template_id
                                        ? updatedTemplate
                                        : t
                                    )
                                  )
                                }
                                setTemplates={setTemplates}
                                saver={saver}
                              />}
                            </div>
                            { data.page.content.footer && (propsTemplates && propsTemplates[parseInt(data.page.content.footer)]) && propsTemplates[parseInt(data.page.content.footer)].length>0 &&
                           <div className="flex flex-col">
                        
                           { (
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Data Footer"}
                                  options={data.contents.filter(c=> parseInt(c.template_id)===parseInt(data.page.content.footer)).map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.Titre,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={data.page.content.footerContent_id}
                                  onChange={(value) => {
                                    setPage({
                                      ...data.page,
                                      content: {
                                        ...data.page.content,
                                        footerContent_id: value,
                                      },
                                    });
                                  }}
                                />
                                {data.page.content.footerContent_id && (
                             
                                    <FontAwesomeIcon icon={fa.faClose}   
                                    onChange={(value) => {
                                      setPage({
                                        ...data.page,
                                        content: {
                                          ...data.page.content,
                                          footerContent_id: null,
                                        },
                                      });
                                    }}
                                      />
                            
                                 
                                )}
                                {!data.page.content.footerContent_id && (
                                  <Link
                                    to={`/app/${
                                      data.page.app_id ?? data.app_id
                                    }/contentTemplate/${
                                      data.page.content.footer
                                    }/add`}
                                    className=""
                                  >
                                    <FontAwesomeIcon
                                    onClick={ ()=>addTemplateContent(data.page.content.footer,
                                      (value) => {
                                        setPage({
                                          ...data.page,
                                          content: {
                                            ...data.page.content,
                                            footerContent_id: value,
                                          },
                                        });
                                      }


                                    )}
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                                  </Link>
                                )}
                              </div>
                            )}
                            {
                              parseInt(data.page.content.footerContent_id)>0 && (
                                <ContentEdition dataOnly={true}
                               key={data.page.content.footerContent_id}
                                  defaultApp={data.app}
                                  content_id={
                                    data.page.content.footerContent_id
                                  }
                                  updateLiveContent={updateContent}
                                  saver={saver}
                                  data_schema={propsTemplates[parseInt(data.page.content.footer)]}
                                />
                              )}</div>
                                }
                              </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-col w-auto h-full overflow-auto items-start justify-start mb-40 p-2" style={{maxWidth:"500px",}}>       
         
                <div className="font-bold p-1 text-xl text-left  sticky top-0 left-0 " onClick={  ()=>setShowVariables(!showVariables)  }>
                        Variables 
                      </div>
                      { showVariables &&             
<div className='flex flex-col items-start justify-start' >
  <h1>baseContent</h1>
<VarContent data={data.baseContent} />

<h1>contents    {data.selectedBloc}</h1> 
{  data.contents.map(content => {

if ((data.selectedBloc === "footer-edit" && parseInt(data.page.content.footerContent_id)===parseInt(content.content_id)) ||
(data.selectedBloc === "header-edit" && parseInt(data.page.content.headerContent_id)===parseInt(content.content_id)) || 
( data.selectedBloc?.split( "bloc_").length>1 &&    parseInt(data.page.content.body[parseInt(data.selectedBloc?.split( "bloc_")[1].split("-edit" )[0])].content_id)=== parseInt(content.content_id)  )

||false
)



return <VarContent  data={content} className=" py-1 border-t border-zinc-500" />
else return <></>
})}

</div>
      }

                </div>
              </div>

              <div className="p-4 bg-white w-full  fixed bottom-0 left-0 z-40 flex justify-end z-50">
                <div
                  type="button"
                  className="border border-1 p-2 m-1 first-line:rounded"
                  onClick={cancelPage}
                >
                  Annuler
                </div>
                {
             Object.values(saveAll).length>0 &&  <div
                  type="button"
                  className=" bg-red-600 text-white rounded  p-2 m-1 first-line:rounded relative"
                  onClick={cancelling}
                  id="upload"
                >
                  Abandonner Brouillon 
                  <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                </div>
}
                {
             Object.values(saveAll).length>0 &&  <div
                  type="button"
                  className=" bg-black text-white rounded  p-2 m-1 first-line:rounded relative"
                  onClick={saving}
                  id="upload"
                >
                  Enregistrer Page
                  <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                </div>
}
   



              </div>
            </div>
          </header>
        )}
    </div>
  );
}

export default PopupPageEditor;
