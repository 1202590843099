import { useEffect } from "react";
import { useState } from "react";



const InputBoolean = ({labelTrue,labelFalse,onChange,value,className,size,classNameActive,classNameBarOn,classNameBarOff,classNameOn,classNameOff,enabled=true,infos}) => {

    const [blnOn,setblnOn] = useState( (value==="1") || (value===1) || (value ===true))

    const switchValue= ()=>{
    if(!enabled) return
      setblnOn(!blnOn);
      onChange(blnOn    ?  0:1);
    }
    
    size = size ?? 16;
    
    useEffect(()=>{
setblnOn((value==="1") || (value===1) || (value ===true))
    },[value])



return (

    <div  onClick={switchValue} className={"flex justify-center items-center   p-2  text-xs text-gray-900 bg-transparent rounded-lg border borsder-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer " +className  + (!enabled ? " opacity-50":"")} >
     

   <div className={'flex flex-row w-12  mx-1'}  >
          <div className={'  w-12 h-6 rounded-full  border-4 border-zinc-800 ' + (blnOn ? '  bg-white ' + classNameBarOn: '  bg-zinc-800 ' +classNameBarOff)}  ></div>
          <div className={' w-6 h-6 rounded-full border-2 border-zinc-800 bg-white transition-all duration-300 ' + (blnOn ? ' -ml-6 ' +classNameOn:' -ml-12 ' +classNameOff) }></div>
        </div>
       { blnOn && <div className={ blnOn ?classNameActive :"" } >{labelTrue}</div> }
      { !blnOn && <div className={ !blnOn ?classNameActive :"" } >{labelFalse}</div>  }
      { !blnOn && !labelFalse && <div className={ !blnOn ?classNameActive  + " line-through " :"" } >{labelTrue}</div>  }

   
      {infos &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{infos}</div>}
</div>

)
}
export default InputBoolean