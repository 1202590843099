import * as fa from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo, useState } from "react"
import InputColor from "./InputColor"

import InputSelect from "./InputSelect";


const availableClasses = [  
    "flex","flex-row","flex-col","w-full","py-40",
    "justify-center","justify-end","justify-start","justify-between","justify-around",
    "min-h-screen", "h-screen","w-screen",
    "items-center","items-end","items-start","items-between","items-around",
"text-xl","text-xs","text-sm",
"p-1", "p-2", "p-3", "p-4",
"font-bold","italic",
"m-1", "m-2", "m-3",
"w-1", "w-2", "w-3",
"border","border-2","border-3",
"border-red-100","border-red-200","border-red-300", "border-red-400",
"border-blue-100","border-blue-200","border-blue-300", "border-blue-400"
 ]
 availableClasses.sort((a,b)=>a<b ? -1:1)
function getAllTailwindClasses() {
    const tailwindConfig = require('tailwindcss/defaultConfig');

    const allClasses = [];
console.log("tailwindConfig",tailwindConfig)
    // Parcourir les différentes sections du fichier de configuration de Tailwind
    for (const section in tailwindConfig.theme) {
        const sectionConfig = tailwindConfig.theme[section];
        // Vérifier si la section contient des classes
        if (typeof sectionConfig === 'object') {
            for (const className in sectionConfig) {
                // Si la classe est un tableau, cela signifie qu'il y a des variantes pour cette classe
                if (Array.isArray(sectionConfig[className])) {
                    sectionConfig[className].forEach(variant => {
                        allClasses.push(`${className}:${JSON.stringify(variant)}`);
                    });
                } else {
                    allClasses.push(section +"-"+ className);
                }
            }
        }
        else
        {
            allClasses.push(section +"-"+JSON.stringify(sectionConfig));
        }
    }

    return allClasses;
}


const tailwindClasses = getAllTailwindClasses ();
function getAllCSSPropertiesAndValues() {
    const allPropertiesAndValues = {};

    // Créez un élément div pour lequel vous pouvez obtenir les styles calculés
    const element = document.createElement('div');
    document.body.appendChild(element);

    // Obtenez les styles calculés pour l'élément
    const computedStyle = window.getComputedStyle(element);

    // Parcourez les propriétés de style calculées
    for (const propertyName in computedStyle) {
        // Filtrer les propriétés qui commencent par des chiffres (non standard)
        if (!isNaN(propertyName.charAt(0))) continue;

        // Obtenez la valeur de la propriété
        const propertyValue = computedStyle.getPropertyValue(propertyName);

        // Stockez la propriété et sa valeur dans l'objet allPropertiesAndValues
        allPropertiesAndValues[propertyName] = propertyValue;
    }

    // Supprimez l'élément div après utilisation
    document.body.removeChild(element);

    return Object.keys( allPropertiesAndValues).map(key=>( key+":"+allPropertiesAndValues[key] ));
}

// Utilisation de la fonction pour obtenir les propriétés CSS et leurs valeurs
const cssPropertiesAndValues = getAllCSSPropertiesAndValues();

const getPartValue=(value, attr)=>{

var parts = value.split(" ");
for(var i=0;i<parts.length;i++)
{
    var  element = parts[i];
    if(element.includes(attr+"=") ){
       return element.replace(  attr+"=",""  )
    }
}
};
const  addPartValue=(value,attr,newvalue)  =>{

var ok=false
var parts = value.split(" ");
for(var i=0;i<parts.length;i++)
{
    var  element = parts[i];
    if(element.includes(attr+"=") ){

       parts[i]=attr+"="+newvalue;
       ok=true;
       break;
    }
}
if(!ok ) parts.push( attr+"="+newvalue );
console.log(parts.join(" "));
return parts.join(" ")
}




const InputStyle = ({id,label,onChange,value,className,bg="bg-white"}) => {

const [focus,setFocus]=useState()

const [tool,setTool]=useState()


const blurStyle=()=>{ 
    if(!tool) setFocus(false)
}


const ChooseColor =({value,attr,onChange})=>{ 
    var color =  getPartValue(value,attr)
    return <InputColor key={attr} value={color??"#000000"} onChange={(newvalue)=>{onChange(  addPartValue(value,attr,newvalue)   )    }} />  
    }
    

const bgColor=()=>{

    return ChooseColor({value,attr:"backgroundColor",onChange})
   
   }

const fontSize=(r)=>{
var  v= Math.max( parseInt(  getPartValue(value,"fontSize")) ??10,3);
   v+=r
    onChange( addPartValue(value,"fontSize",v+"px")) 
}

const classes = useMemo(( )  => { 

if (!value) return []

var parts =   value.split(" ").filter( classe =>  classe && classe !=="" )



return parts;


},



[value] )



const textColor=()=>{

 return ChooseColor({value,attr:"color",onChange})

}


const removeClasse=(  classe )=>{

    var _classes = classes.filter(  _classe => _classe !== classe  )

     onChange( _classes.join(" ") )
}

const addClasse=(  classe )=>{

    var _classes = classes

    _classes.push(classe)

     onChange( _classes.join(" ") )
}

const runTool=(tool)=>{
    if(tool.action){
tool.action()
setTool(tool);
setTimeout(() => {
   setFocus(true) 
}, 20);
    }
    else
    setTool(tool);

}

const tools = [...[{icon: fa.faPen  ,component:textColor },
{icon: fa.faFill ,component:bgColor },
{icon: fa.faA,subicon: fa.faPlus    ,action:()=>fontSize(4)  },
{icon: fa.faA ,subicon: fa.faMinus ,action:()=>fontSize(-4)  },
]
,
...Array(18).fill({icon:fa.faQuestion   })];
return (
    <> <label htmlFor={ "input_"+label } className={"absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 " +bg }>{label}</label>

    <div className={"relative bg-inherit " +className }   style={{maxWidth:"50vw"}}  onBlur={()=>  { setTimeout(blurStyle,100)}} >
    <textarea onFocus={()=>{setFocus(true);setTool()   }}      id={id ?? "input_"+label} onChange={(e)=>onChange(e.target.value)}  rows={Math.max(1, value?.split("\n").length+1 ?? 0 )  } value={value}  className="block px-2.5 pb-2.5 pt-4 w-96 text-sm text-gray-900 bg-inherit rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
   
<div className=" w-full flex-nowrap text-start ">
{ 
   classes &&  classes.map(  (classe,i) => <div key={i}   onClick={()=>removeClasse(classe)}   className=" inline-block text-xs p-1 px-2 m-1 mb-1 bg-white rounded-full border-blue-500 border-2"  >{classe}</div>     )
}
</div>


<div className=" w-full flex-nowrap text-start ">
{ 
   availableClasses &&  availableClasses.filter( classe => !classes.find( c=> c===classe  )   ). map(  (classe,i) => <div key={i}    onClick={()=>addClasse(classe)}   className=" inline-block text-xs p-1 px-2 m-1 mb-1 bg-white rounded-full border-green-500 border-2"  >{classe}</div>     )
}


</div>
{ 

//<InputSelect      value={"" }   onChange={()=>{}}    options={cssPropertiesAndValues.map(c=>({label:c,value:c}))} /> 
 }

{
/* (focus || tool)&& 
<div  className="w-full flex flex-wrap justify-center "    >
  { tools.map( (v,i) => <div  key={i}  className=" shadow rounded border border-zinc-200 relative">
    <FontAwesomeIcon icon={ v.icon }   className="h-6 w-6 p-2"    onClick={()=>{runTool(v);  }} />
    {
        v.subicon &&  <FontAwesomeIcon  icon={ v.subicon }  className="text-xs absolute top-1 right-1 "       />
    }
  </div> )}

    </div>

*/
}
{ 
///tool?.component && <>Tool {  tool.component()  }</>

}

</div>
</> 
)
}
export default InputStyle