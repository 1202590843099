import React, { useEffect, useMemo, useState,useContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputText from '../../../../components/inputs/InputText';
import InputImage from '../../../../components/inputs/InputImage';
import InputColor from '../../../../components/inputs/InputColor';
import InputSelect from '../../../../components/inputs/InputSelect';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import InputButton from '../../../../components/inputs/InputButton';


import '../../../../App.css';
import { MfctContext } from "../../../../context";
import HeaderBack from '../../../../components/HeaderBack';
import Image from '../../../../components/Image';
import Loader from '../../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faAppleWhole, faCheckCircle, faMobileAndroid, faTabletAndroid } from '@fortawesome/free-solid-svg-icons';

function WebPush({}) {

const [loading,setLoading]= useState()

const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);
const [message,setMessage]=useState({});
const [result,setResult]=useState({});
const [tokens,setTokens]=useState([]);
const [excludes,setExcludes]=useState(["EXPIRED","INVALID"]);

const sendMessage=async (e)=>{
  e.preventDefault();
setLoading(true)
var _result =   await project.api("/send_notif", { body:{...message,subscriptions: tokens.filter( t=>t.checked   )   } }); 
setResult(_result)
setLoading(false)
}


const apps = useMemo(()=>{

  var result =[]
  tokens?.forEach(t=> {
    if(  !result.find(r=>r.app_id===  t.app_id  )) result.push(t)
  })

  return result

},[tokens])


useEffect(()=>{
  if(!project) return;
  var getTokens= async ()=>{
    var _r = await project.api("/get_tokens");
    setTokens(_r.map(r=>{return  {...r,checked:true} }));
  
}
getTokens();

},[project])



 return (
  <div className=" text-white">
  <HeaderBack   title={"Envoi de notifications"}   />

  <div  className='my-4 w-full flex flex-col p-8 bg-white  rounded-xl mb-24 text-sm' >
     <div  className='w-full flex flex-col  gap-3  ' >
        <InputText label={"sujet"} value={message.subject} onChange={(value=>setMessage((m)=>{return {...m, subject:value}}) ) }  />

       <InputTextArea label={"text"}  value={message.text}   onChange={(value=>setMessage((m)=>{return {...m, text:value}}) ) }  />
       <div  className='p-2 text-white border-b border-zinc-200  flex flex-row w-full  items-center justify-end'>
       <div className={'p-2 text-white bg-zinc-500 p-2 text-xs rounded-full mx-1' + (excludes.includes("INVALID")?" line-through ":"")} 
          onClick={()=>{
if(excludes.includes("INVALID"))
setExcludes( excludes.filter(e=>e!=='INVALID'))
else setExcludes( [...excludes,'INVALID'])

       }} >INVALID</div> 
       <div className={'p-2 text-white bg-zinc-500 p-2 text-xs rounded-full  mx-1' + (excludes.includes("EXPIRED")?" line-through ":"")} 
                 onClick={()=>{
                  if(excludes.includes("EXPIRED"))
                  setExcludes( excludes.filter(e=>e!=='EXPIRED'))
                  else setExcludes( [...excludes,'EXPIRED'])
                  
                         }} 
                         >EXPIRED</div>        
       <div className='p-2 text-black '>Actions</div>
       <FontAwesomeIcon icon={faCheckCircle  } className={' p-2 bg-green-500 rounded-full border-2    '}  onClick={()=>{setTokens( tokens.map(token=>{

        return {...token, checked:true}
       }))}      }/>  
              <FontAwesomeIcon icon={faCheckCircle  } className={' p-2 bg-gray-500 rounded-full border-2    '}  onClick={()=>{setTokens(tokens.map(token=>{

return {...token, checked:false}
}))}      }/>  
       </div>

{  
     apps.map((app, index)=><div className='flex flex-row w-full relative justify-start items-start  border-t-2 border-zinc-200 '  key={index}>
       <div className='flex flex-col items-center justify-center w-20 sticky top-10  justify-start items-start'>
 <img
                    src={
                      process.env.REACT_APP_MFTC_SERVER_URL_FILES +
                      `profiles/${app.device?.domain ?? "pro"}/user.png`
                    }
                    className=" m-1 w-12 h-12 rounded-xl"
                    alt="logo"
                  />
                  <div className='text-black text-xs w-full text-center overflow-ellipsis whitespace-nowrap' >{app.device?.app_name ?? "admin"  }</div>
                  </div>
 <div className='grow flex flex-col'>{ 
tokens?.filter(t=>t.app_id===app.app_id && (!t.status || !excludes.includes(t.status))).map((token,i)=>

<div  onClick={()=>{ 
  
  setTokens((ts)=>ts.map((t,j)=>{ return  {...t,checked: token.token_id===t.token_id ? !t.checked :t.checked }   }      ) )}}  className={'p-2 text-black   border-zinc-200  flex flex-row w-full  items-center justify-between' +(  i>0  ? ' border-t':'')}  key={i} > 
 

                  <Image src={  token.device?.imageUrl }  className=' m-1 w-8 h-8 rounded-full'/> 
                  <div   className='grow' >{  token.device?.first_name ?? "admin"}     </div>

<div   className='' >{  token.status }     </div>
<div   className='' > 
 { token.device.userAgent?.includes("Android") &&  <FontAwesomeIcon icon={faMobileAndroid  } className='w-6 h-6' />    } 
 { token.device.userAgent?.includes("Macintosh") &&  <FontAwesomeIcon icon={faAppleAlt  } className='w-6 h-6' />    } 
 { token.device.userAgent?.includes("iPhone") &&  <FontAwesomeIcon icon={faAppleWhole  } className={' px-1 py-2  rounded border-2' +    (   token.device.userAgent.includes("like Mac OS Xs")   ?' border-red-600 ':'  border-zinc-600 ')  }/>    }   </div>
<div>  </div>

<div  className={' mx-4 p-2 text-white rounded-full'  +( token.checked ?  ' bg-green-400 ': ' bg-gray-600 ') }> M </div>

 </div>


)}
</div>
     
    </div>)           }

    </div>
 
        <div className='fixed bottom-4 p-4 font-bold left-0 flex flex-col w-full'><InputButton label={"Envoyer notif "  }   onClick={sendMessage} /> 
    
      </div>

<div className='flex flex-col w-full text-black p-4' >   {  JSON.stringify(result)}</div>

      </div>
     { loading && <Loader   /> }
      </div>
  );
}

export default WebPush;
