import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { retrieveProject } from "../../service/mftc-api";
import EntityTemplateSelect from "./EntityTemplateSelect";
import { data } from "../../service/data";





const InputEntity = ({
  label,
  onChange,
  value,
  className,
  schema,
  placeholder,
  domain,
  context,
  entity
}) => {
  const [records, setRecords] = useState();

  useEffect(() => {
    const getRecords = async () => {

      


    
      const _records =    await  data.loadEntities(entity,domain,context) 


      setRecords(_records);
    };

    getRecords();
  }, [entity,domain,context]);

  if (!records) return <></>;
if  (schema.content?.display_template_id ) return <EntityTemplateSelect 
label={label}
className={className}
placeholder={placeholder}
value={value}
context={context}
onChange={(record_id) => {
  onChange(record_id);
}}
options={["",...records]}
template_id={schema.content?.display_template_id}

/>
  return (
    <>
      <InputSelect
        label={label}
        className={className}
        placeholder={placeholder}
        value={value}
        context={context}
        onChange={(record_id) => {
          onChange(record_id);
        }}
        options={["",...records]}
      />
    </>
  );
};
export default InputEntity;
