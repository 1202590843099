import { useEffect, useState } from "react"



const UserPin = ({id,user,root_id}) => {
const [ style,setStyle] =useState({})
const [ imgStyle,setImgStyle] =useState({})

const root = document.getElementById(root_id)

function getAbsolutePos( offset) {
    if(!root) return{left:0,top:0}
    var rootRect = root.getBoundingClientRect();
    var offsetRect = offset.getBoundingClientRect();
    return { left : offsetRect.left - rootRect.left+ user.pos*20,
         top:  offsetRect.top - rootRect.top - 40> 0 ?  offsetRect.top - rootRect.top : offsetRect.top - rootRect.top +54+ offsetRect.height  ,
         flip  :   offsetRect.top - rootRect.top-40 <0 };
}


useEffect(()=>{

var obj = document.getElementById(id);
if(obj)
{
    const  { top ,left,flip} = getAbsolutePos(obj);
 setStyle({ top : (   top )+ "px", left : (left+ 0*obj.clientWidth) +"px",zIndex:1000,backgroundColor:user.bgColor, transform:flip ?" rotate(135deg)": " rotate(-45deg)" })
 setImgStyle({ transform:flip ? " rotate(225deg)":" rotate(45deg)"  })
 console.log("Syle -------------" , { top : top+ "px", left : (left+ 0.5*obj.clientWidth) +"px"})
}
 else
 {
setStyle({ display : "none"})
console.log("no Syle for id -------------" , id)
}




},[id,user])


return (
    <div className={" absolute -mt-12 h-10 w-10 rounded-full transition-all duration-500 rounded-bl-none p-1 shadow-lg overflow-hidden bg-black"}  style={style} >
        <img  className={" w-full h-full object-cover   rounded-full"} src={user?.imageUrl ??  process.env.REACT_APP_MFTC_SERVER_URL_FILES + "medias/user.png"  }  style={imgStyle} alt={"user"} /></div>
)
}
export default UserPin