import { createContext, useState, useRef, useEffect } from 'react';
import { socket } from '../../../service/socket';
import Peer from 'simple-peer';
import { requestPermissions } from '../../../service/permissions';



const SocketContext = createContext();

const ContextProvider = ({ id, children }) => {
    const [status, setStatus] = useState('');
    const [stream, setStream] = useState();
    const [userStream, setUserStream] = useState();

    const [me, setMe] = useState(id);
    const [mySignal, setMySignal] = useState();
    const [userSignal, setUserSignal] = useState();
    const [idCaller, setIdCaller] = useState()
    const myVideo = useRef();
    const userVideo = useRef();
    const connectionRef = useRef();
    const [deviceId, setDeviceId] = useState()
    const [streams, setStreams] = useState([]);
    const [devices, setDevices] = useState();

    const getDeviceList = async () => {
        try {

            const granted = await requestPermissions(["camera", "microphone"]);
            console.log("granted", granted)
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
            setDevices(videoDevices);
            if (videoDevices.length > 0) {
                console.log("deviceid", videoDevices[0].deviceId,"vs" ,deviceId);
                if (!deviceId) {
                    stopStream(stream)
                    setStream()
                    setDeviceId(videoDevices[0].deviceId);
                }
            }
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };


    useEffect(() => {
        if (stream && !devices) getDeviceList();

    }, [stream, devices, deviceId])



    useEffect(() => {
        var currentStream;

        if (stream || !myVideo.current) return;
        const getStream = async () => {
            const constraints = {
                video: { deviceId: deviceId ? { exact: deviceId } : undefined, facingMode: 'user' },
                audio: true
            };
            const _stream = await navigator.mediaDevices.getUserMedia(constraints)
            console.log("SETTINGS ", _stream.getVideoTracks()[0].getSettings());
            setStream(_stream);
            if (!deviceId) setDeviceId(_stream.getVideoTracks()[0]?.getSettings()?.deviceId)

            console.log("myVideo", myVideo, "stream", _stream)
            if (myVideo.current) myVideo.current.srcObject = _stream;
            return _stream
        }


        getStream().then(_stream => currentStream = _stream);


    }, [myVideo, stream, deviceId]);

useEffect(()=>{
return ()=>{
   if(stream) stopStream(stream)
}
},[stream])

    useEffect(() => {
        // socket.on('me', (id) => setMe(id));
        socket.emit('me', { id });
        socket.on('initiateCall', onInitiateCall);
        socket.on('cancelCall', onCancelCall);
        socket.on('refuseInitiateCall', onRefuseInitiateCall)
        socket.on('leaveCall', onLeaveCall);

        return () => {
            socket.emit('endme', { id });
            socket.off('initiateCall', onInitiateCall);
            socket.off('cancelCall', onCancelCall);
            socket.off('refuseInitiateCall', onRefuseInitiateCall)
            socket.off('leaveCall', onLeaveCall);
        }
    }, []);

    const onInitiateCall = ({ from, signal }) => {
        console.log('onInitiateCall', { from, signal })
        setStatus('onInitiateCall');
        setIdCaller(from)
        setUserSignal(signal)
    };

    const onCancelCall = async () => {
        setStatus('');
        setIdCaller();
        setMySignal()
        setUserSignal()
        if (connectionRef.current) {
            connectionRef.current.destroy();
        }
    }


    const refuseInitiateCall = () => {
        setStatus('refuseInitiateCall')
        socket.emit('refuseInitiateCall', { userToCall: idCaller, from: me })
        setIdCaller('')
        setStatus('');
        setMySignal()
        setUserSignal()

    }
    const onRefuseInitiateCall = () => {
        setStatus('')
        setIdCaller()
        setMySignal()
        setUserSignal()
        if (connectionRef.current) {
            connectionRef.current.destroy();
        }
    }

    const initiateCall = (idUser) => {
        setStatus('initiateCall')
        setIdCaller(idUser)

        const peer = new Peer({ initiator: true, trickle: false, stream });


        peer.on('signal', (data) => {
            // On recupere son signal et on l'envoie 
            console.log('mySignal', data);
            setMySignal(data);
            socket.emit('initiateCall', { userToCall: idUser, from: me, signal: data })
        });

        peer.on('stream', (currentStream) => {
            setUserStream(currentStream)
            console.log(" obtain initiator userStream", currentStream)
            if (userVideo.current) {
                console.log(" get userStream", currentStream)
                userVideo.current.srcObject = currentStream;
                // userVideo.current.play();
            }
            setStatus('callOpened')
        });

        socket.on('acceptInitiateCall', ({ userToCall, signal, from }) => {
            setStatus('onAcceptInitiateCall')
            peer.signal(signal);
            // On reçoit le signal de l'interlocuteur et on le traite ici 
        });


        var events = ["close", "connect", "error", "end", "pause", "resume", "readable"]
        events.forEach(event =>
            peer.on(event, (data) => { console.log(event + " peer", data) }))
        connectionRef.current = peer;
        console.log('initiateCall stream', stream)
    }

    const acceptInitiateCall = () => {
        setStatus('acceptInitiateCall')
        console.log('acceptInitiateCall ', idCaller)
        //  if (connectionRef.current) return

        const peer = new Peer({ initiator: false, trickle: false, stream });

        console.log('acceptInitiateCall stream', stream)
        peer.on('signal', (data) => {
            // On recupere sa conf de signal et on l'envoie à son interlocuteur
            console.log("acceptInitiateCall Signal", data)
            socket.emit('acceptInitiateCall', { userToCall: idCaller, signal: data, from: me });
        });

        peer.on('stream', (currentStream) => {

            console.log(" obtain invited userStream", currentStream)

            setUserStream(currentStream)
            if (userVideo.current) {
                console.log(" get userStream", currentStream)

                userVideo.current.srcObject = currentStream;
            }
            setStatus('callOpened')
        });

        var events = ["close", "connect", "error", "end", "pause", "resume", "readable"]
        events.forEach(event =>
            peer.on(event, (data) => { console.log(event + " peer", data) }))

        socket.on('openCall', (signal) => {
            peer.signal(signal);
            // On reçoit le signal de l'interlocuteur et on le traite ici 
            console.log("onOpenCall", signal)
            setStatus('onOpenCall');


        });
        connectionRef.current = peer;
        connectionRef.current.signal(userSignal);
    };
    const cancelCall = () => {
        setStatus('');
        socket.emit('cancelCall', { idCaller, from: me });
        if (connectionRef.current) connectionRef.current.destroy();
        connectionRef.current = null;
    };

    const leaveCall = () => {
        socket.emit('leaveCall', { idCaller, from: me });
       onLeaveCall();
    };
    const onLeaveCall = () => {
        setIdCaller()
        setMySignal()
        setUserSignal()
        setStatus('')
        setUserStream()
        console.log('leaveCall ')
        if (connectionRef.current) connectionRef.current.destroy();
        connectionRef.current = null;
    };

    const stopStream = (currentStream) => {
        console.log('stopStream', currentStream, stream, myVideo.current?.srcObject)
        if (currentStream) {

            currentStream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }

    return (
        <SocketContext.Provider value={{
            initiateCall,
            acceptInitiateCall,
            refuseInitiateCall,
            leaveCall,
            cancelCall,

            myVideo,
            userVideo,
            userStream,
            devices,
            setDeviceId,
            deviceId,
            setStream,
            stream,
            status,
            me,
            idCaller,
        }}
        >
            {children}
        </SocketContext.Provider>
    );
};
export { ContextProvider, SocketContext };
