import { useContext, useEffect, useState } from "react";
import Image from "../Image";

import { data } from "../../service/data";
import { retrieveProject } from "../../service/mftc-api";





function    UserThumb ({ className, user_id, noname,onClick,size=" w-5 h-5 "})  {
 const project = retrieveProject()
const [user,setUser] = useState({name :"user_"+ user_id,img:null}); 

const getUser =async ()=>{
  if(!user_id) return 
  var user = null;
var stock = localStorage.getItem("userthumb_" + user_id);
if( stock ) user = JSON.parse(stock)
//await data.getUser(user_id);
if(!user) 
user = await project.api("/get_user_thumb/:user_id",{args:{user_id}})
if(user){
  localStorage.setItem("userthumb_" + user_id,JSON.stringify(user));
  setUser(user)
}
}


useEffect(()=>{


getUser()

},[])


  return   <div className={" appbutton flex " + className} onClick={onClick}> <Image src={user.img} className={size + " rounded-full  " + ( noname  ? "" :"mx-1")}   />{!noname && `@${user.name}` }   </div>

} 

export default UserThumb;
