import * as fa from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo, useState } from "react"
import { getStructure } from "../../../../../tools/tpl"




const AddToolChild = ({id,structure, updateStructure, selectTpl ,idObj}) => {


const selectTool=()=>{
    hoverEnd()
   var _structure = getStructure(structure,idObj) 
   console.log("select ", structure,_structure,idObj)
   if( !_structure.children)_structure.children=[]
   var newid = idObj + "." +   _structure.children.length
   _structure.children.push({ tag:"div",className:"new p-2 " ,children:[]})
   updateStructure(_structure,idObj,newid )


}

const hoverStart = () => {
    hoverEnd(idObj)
    
    var obj = document.getElementById(idObj);
if(obj){
   var  newdiv = document.createElement("div");
   
    newdiv.id = "newObj-_AddToolChild";
    newdiv.className= " m-0 p-0 bg-orange-400 text-white opacity-0 transition-all";
    newdiv.style.fontSize="0px"
    newdiv.innerHTML ="+"
   
setTimeout(()=>{
  if(newdiv) {
    newdiv.style.fontSize="20px"
    newdiv.className= " m-2 p-2 bg-orange-400 text-white opacity-100 transition-all";}},10)
    obj.appendChild(newdiv)
   
}
  };

  const hoverEnd = (idObj) => {
    var newdiv = document.getElementById( "newObj-_AddToolChild");

    if(newdiv &&(!idObj || newdiv.idObj!==idObj)) newdiv.parentNode.removeChild(newdiv)


  };


return (
    
<div className="inline-flex flex-row  p-2">
<div className="appbutton border-2 border-white bg-black bg-opacity-30 p-2"    
onClick={()=>selectTool()}  
   
onMouseOver={()=>hoverStart()}
onMouseOut={()=>hoverEnd()}

>
<FontAwesomeIcon  icon={fa.faPlus} className=" bg-white text-black w-3 h-3 p-1 rounded-sm" 



/>

</div>
</div>)

}
export default AddToolChild