import { useState } from "react";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const InputAudio = ({label,onChange,value,className,domain}) => {

    const [file, setFile] = useState();
    
    
    function handleImgChange(e) {
        console.log(e.target.files);

        setFile(URL.createObjectURL(e.target.files[0]));
        onChange(   e.target.files[0])
    }
  


return (
    <>
    <div  className={"  " +className +"  p-4 py-14 flex flex-row items-center  justify-center  text-sm text-gray-900 bg-transparent rounded-lg  appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"  } >

    {value &&
   <audio id="previewing" className=" w-full object-contain " src={file ??    process.env.REACT_APP_MFTC_SERVER_URL_FILES +
    `profiles/${domain}/medias/${value}` } alt="media" controls 
     /> }
{!value && <div className=" p-4 "   onClick={ onChange  ? ()=>{ document.getElementById("image-upload-" + label ).click()}:null}  >Importer</div>}
{!value && <FontAwesomeIcon   icon={fa.faMicrophone}  className="text-2xl" />}
     <input type="file" accept={"audio/mp3,audio/wav,audio/ogg"} name="image" id={"image-upload-"+label} className="hidden" onChange={handleImgChange} required />
    
</div>
</> 
)
}
export default InputAudio