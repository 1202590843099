import React, { useEffect, useMemo, useState, useContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputText from '../../../../components/inputs/InputText';
import InputImage from '../../../../components/inputs/InputImage';
import InputColor from '../../../../components/inputs/InputColor';
import InputSelect from '../../../../components/inputs/InputSelect';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import InputButton from '../../../../components/inputs/InputButton';


import '../../../../App.css';
import { MfctContext } from "../../../../context";
import { data } from '../../../../service/data';
import VarContent from '../../../../components/VarContent';
import InputData from '../../../../components/inputs/InputData';

function AppNavigation({ app, getApps }) {


  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  const [mods, setMods] = useState(app);
  const [pages, setPages] = useState([]);
  const [data_policies,setDataPolicies] = useState([]);
  const [popup_consents,setPopupConsents] = useState([]);
  const [popup_consent_details,setPopupConsentDetails] = useState([]);

  const [roles,setRoles]= useState([])




  const saveApp = async (e) => {
    e.preventDefault();




    var newapp = await project.api("/set_app", { body: mods });
    toast("Successfully saved '" + app.name);
    console.log(newapp)
    await getApps();
  }


  const getPages = async () => {

    var _roles = await project.api("/get_myroles/:app_id",{args:{app_id:app.app_id}})
    console.log( _roles)



    if( !mods.profile.roles) {
      setMods({...mods,profile:{...mods.profile,roles:{}}})
 }
 
    setRoles([{ role_id:"default",content:{title:"Par Défaut"} },..._roles]);

    var _pages = await project.api("/get_mypages/:app_id", { args: { app_id: app.app_id } })

    setPages(_pages);

    var _dps= await data.getContents( (c)=>c.schema_id===410) 
     setDataPolicies(_dps)


     var _tpls= await data.getTemplates( (c)=>c.schema_id===410) 
     setPopupConsents(_tpls)

     var _tplds= await data.getTemplates( (c)=>c.schema_id===410) 
     setPopupConsentDetails(_tplds)



  }

const setStartPage= (role_id,starts)=>{

if(role_id==="default")
  setMods((m) => ({
    ...m, profile: { ...m.profile, ...starts }, 
  }))
  else

  {

var startpages= mods.profile.roles[role_id]?? {}
startpages= {...startpages,...starts}
setMods((m) => ({
  ...m, profile: { ...m.profile, roles:{...m.profile.roles,[role_id?? "default"]:startpages} }, 
}))
  }

}

  useEffect(() => {
    if (project) {
      getPages()

    }
  }, [project])


  return (

    <div className='w-full flex flex-col py-4 bg-white text-black px-4 rounded-xl' >
      <div className='w-full  p-2 font-bold border-b border-gray-200 my-4 text-lg'> Pages de démarrage</div>
      <div className='w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  gap-3 py-1 ' >  
      <div className=' text-gray-700 p-2 font-bold border-b border-gray-200 col-span-2 lg:col-span-1' >Non connecté</div>
       <InputSelect
          label={"Landing Page Web Site"}
          value={ mods.profile.landing_page_id}
          options={pages.map(p => ({ value: p.page_id, label: p.title }))}
          onChange={(landing_page_id) => setStartPage("default",{landing_page_id})

          }
          placeholder={"- choisir une page -"}
        />
        <InputSelect
          label={"Welcome Mobile Screen"}
          value={mods.profile.welcome_screen_id }
          options={pages.map(p => ({ value: p.page_id, label: p.title }))}
          onChange={(welcome_screen_id) => setStartPage("default",{welcome_screen_id})
          }
          placeholder={"- choisir un screen  -"}
        />
        </div>
    { roles.map(role =>
      <div className='w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  gap-3 py-1  '  key={role.role_id}>
      <div className=' text-gray-700  p-2 font-bold border-b border-gray-200  col-span-2 lg:col-span-1' >{  role.content.title  }</div>
      
        <InputSelect
          label={"Home Page  Web Site"}
          value={role.role_id==="default" ?mods.profile.home_page_id : mods.profile.roles[role.role_id]?.home_page_id}
          options={pages.map(p => ({ value: p.page_id, label: p.title }))}
          onChange={(home_page_id) => setStartPage(role.role_id,{home_page_id})
          }
          placeholder={"- choisir une page -"}
        />

        <InputSelect
          label={"Home Mobile Screen"}
          value={role.role_id==="default" ?mods.profile.home_screen_id : mods.profile.roles[role.role_id]?.home_screen_id}
          options={pages.map(p => ({ value: p.page_id, label: p.title }))}
          onChange={(home_screen_id) => setStartPage(role.role_id,{home_screen_id})
          }
          placeholder={"- choisir un screen  -"}
        />
        </div>) }



      <div className='w-full text-black p-2 font-bold border-b border-gray-200 my-4 text-lg'> Data Consent</div>
      <div className='w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3  ' >





      <InputSelect
          label={"Data Policy"}
          value={mods.profile.data_policy}
          options={data_policies.map(p => ({ value: p.content_id, label: p.title }))}
          onChange={(data_policy) =>
            setMods((m) => ({
              ...m, profile: { ...m.profile, data_policy }, 
            }))
          }
          placeholder={"- choisir une data police  -"}
        />



<InputSelect
          label={"Template Pop Up"}
          value={mods.profile.popup_consent}
          options={popup_consents.map(p => ({ value: p.template_id, label: p.title }))}
          onChange={(popup_consent) =>
            setMods((m) => ({
              ...m, profile: { ...m.profile, popup_consent }, 
            }))
          }
          placeholder={"- choisir un template  -"}
        />
<InputSelect
          label={"Template Details"}
          value={mods.profile.popup_consent_details}
          options={popup_consent_details.map(p => ({ value: p.template_id, label: p.title }))}
          onChange={(popup_consent_details) =>
            setMods((m) => ({
              ...m, profile: { ...m.profile, popup_consent_details }, 
            }))
          }
          placeholder={"- choisir un template  -"}
        />



      </div>
      {
        true
        &&
        <div className='flex flex-col w-full p-2'><InputButton label={"Enregistrer"} onClick={saveApp} />
       
        </div>}
    </div>
  );
}

export default AppNavigation;
