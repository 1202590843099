import React, { useEffect, useMemo, useState,useContext } from 'react';


import { Link } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header1 from '../../../../components/Header1';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";


function Users() {

const [users,setUsers]= useState([])
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);


const [filteredUsers, setFilteredUsers] = useState([]);
const [filterRole, setFilterRole] = useState(''); // Ajout du filtre par rôle

const getUsers = async () => {
  try {
    var _users = await project.api('/get_accounts', { body: {} });
    var _apps = await project.api("/get_myapps", { body:{} })
   _users =_users.map(user=> {
var app = _apps.find(app=>app.app_id==user.app_id);
    if(user.profile?.imageUrl )
    {
          var url= process.env.REACT_APP_MFTC_SERVER_URL_FILES + user.profile.imageUrl 
          url+=`?domain=${app.domain}`
          user.profile.imageUrl =url;
    }

    return {...user,
      app}
   }
   )
   
   
    setUsers(_users);
    // Initialisation des utilisateurs filtrés avec tous les utilisateurs
    setFilteredUsers(_users);
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const  deleteAccount = async(user)=>{
  if (!window.confirm("Are you sure you want to delete '" + user.profile.email+"'?")) return
   await project.api("/delete_account/:account_id",{args:{account_id:user.account_id}})
await    getUsers();
}


// Fonction pour filtrer les utilisateurs par rôle
const filterUsers = () => {
  if (filterRole) {
    const filtered = users.filter((user) => user.role === filterRole);
    setFilteredUsers(filtered);
  } else {
    // Si aucun filtre n'est sélectionné, afficher tous les utilisateurs
    setFilteredUsers(users);
  }
};

// Réinitialiser le filtre
const resetFilter = () => {
  setFilterRole('');
  setFilteredUsers(users);
};

const refreshUsers = () => {
  getUsers();
};

useEffect(()=>{
if(project)
 {
  
  getUsers()
 }
},[project])
 return (
  <div className="App ">
     <Header1   title={"Comptes Clients"}   linkLeft={"/"} linkRight={"/newapp"}   />

    <div className="w-full flex flex-col p-2 text-sm text-zinc-800 p-4">
      {/* Filtres */}
  
      {/* Tableau des utilisateurs */}
      <div className="w-full bg-white rounded-md p-2">
            <div className="flex justify-between border-b border-zinc-500">
            <div className="w-8 m-1">User</div>
            <div className="flex-grow p-2">Infos</div>
            <div className="w-8 m-1">Logo</div>
              <div className="w-20 p-2">App</div>
   
 
              <div className="w-16 p-2">Actions</div>
            </div>
            {filteredUsers.map((user, i) => (
              <div
                className="flex justify-between items-center border-t border-zinc-500 w-full"
                key={i}
              >
                  <div ><Image src={ user.profile?.imageUrl}  className=' m-1 w-8 h-8 rounded-full'/> </div>
                  <div className="flex-grow p-2">{(user.profile.first_name??'') +' '+ (user.profile.last_name??'') +' '+ (user.profile.email??'')}</div>
             <div ><Image src={  process.env.REACT_APP_MFTC_SERVER_URL_FILES + `profiles/${  user.app.domain  }/user.png`}  className=' m-1 w-8 h-8 rounded-sm'/> </div>
 
                  <div className="w-20 p-2">{user.app.name}</div>
           
               
                <div className="w-16 flex flex-row justify-center" >
                <FontAwesomeIcon  icon={fa.faRemove} className='ml-1 rounded-full p-1 bg-red-700 text-white hidden' onClick={()=>deleteAccount(user)} ></FontAwesomeIcon>
                <Link to={`/chat/${user.account_id}`}><FontAwesomeIcon  icon={fa.faMessage} className='ml-1 rounded-full p-1 text-yellow-700 '  ></FontAwesomeIcon></Link>
                </div>
              </div>
            ))}
          </div>
        
    </div>

</div>
  );
}

export default Users;
