import { data } from "../service/data";
import { createClass } from "./tpl";


const cache_font = {}

const loadFontAsync = async (fontUrl,fontName,className)=> {
    const link = document.createElement('link');
    link.href = fontUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);

   createClass(className,  ` font-family: '${fontName}', 'sans-serif';`   )
  }




  const loadAppFonts=async(app_id)=>{

    if(!cache_font[app_id])
{

    cache_font[app_id] =await  data.getContents((c=>c.schema_id+""==="709" && c.app_id+""===""+app_id))

}

for (let font of cache_font[app_id]) {

await loadFontAsync(font.content.Url, font.content.Name,font.content.className)

  }

  }
  export {
    loadFontAsync,
    loadAppFonts
   }