import React, { useContext, useEffect, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../../App.css";

import TemplateEdition from "../../../../components/edition/TemplateEdition";

import TemplateTree from "../../../../components/templates/TemplateTree";
import { getProps, loadTemplate, structureClean, structurePutIn, structureReplace } from "../../../../tools/tpl";
import Loader from "../../../../components/Loader";
import { data } from "../../../../service/data";
import { MfctContext } from "../../../../context";
import { setRecord } from "../../../../service/localdata";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import ContentEdition from "../../../../components/edition/ContentEdition";

function PopupTemplateEditor() {
  const { template_id } = useParams()



  const [baseContent, setBaseContent] = useState({
    "App.Name": "...",
  });


  const [app, setApp] = useState();

  const [contents, setContents] = useState([]);
  const [template, setTemplate] = useState();
  const [templates, setTemplates] = useState([]);
  const [selectedBloc, setSelectedBloc] = useState();
  const [selected, setSelected] = useState();


  const [selectedTemplate, setSelectedTemplate] = useState();

  const { project, cache, authUser } = useContext(MfctContext);
  const [schemas, setSchemas] = useState([]);
  const [dragged, setDragged] = useState()
  const [dragOverZone, setDragOverZone] = useState();

  const [draggedZone, setDraggedZone] = useState()


  const [saveAll, setSaveAll] = useState({})
  const [loading, setLoading] = useState(false);

const [testContent,setTestContent] = useState()

const updateTestContent=async (tc)=>{


await data.updateContent(tc)
  setTestContent(tc);
}







  useEffect(() => {

    const dataInit = async () => {

      await data.init("template", template_id, true, project, { setApp, setTemplates, setTemplate, setContents, setUser, setSaveAll, setSchemas }, { session_id: "S" + authUser.user_id },true)

      // Recuperer la page * 
      let template = await data.getTemplate(template_id)
      console.log(template,template_id)
      document.title = "⚙TPL " + template.title;
      // Recuperer l'app 
      if (template.app_id) {

        let _app = await data.getApp(template.app_id)

        let _schemas = await data.getSchemasByApp(_app.app_id)


      setTestContent({    content_id:"content_template_test_"+template_id ,template_id,test:true,app_id:_app.app_id, content:{domain: _app.app_id} })
      }
      else
      {
        await data.getSchemas()
        setTestContent({    content_id:"content_template_test_"+template_id,template_id, test:true ,content:{} })
    }
    // Recuperer les templates
      let _templates = await data.getTemplates()
      // Recuperer les contents 
      let _contents = await data.getContents()
      // Recuperer les schemas 



    }

    dataInit();
    return async () => {
      await data.close()
    }

  }, [])



  const setInternal = (key, value) => {

  };

  const cancelling = async () => {


    data.cancelTemplate(template)


  }

  const saving = async () => {
    setLoading(true);
    await data.saveAll()
    setLoading(false);
    toast("Successfully saved '");
  }





  const _setDragged = (obj, template_id, id) => {


    setDragged(obj)
  }


  const [propsTemplates, setPropsTemplates] = useState()



  const cleanStructure = (structure, id) => {


    if ("children" in structure) structure.children = structure.children.filter(child => {

      return !child.dragged
    }).map((child, i) => {


      return cleanStructure(child, id + "." + i);
    })

    return structure

  }

  const selectTpl = (id, tpl, noEmit) => {


    if (tpl) {


      /****     RQajouter dans le receive message */
      if (tpl.schema_label) {
        var obj2 = document.getElementById(tpl.schema_label + "-schema_label");
        if (obj2) {
          console.log("Scroll to", obj2.id, obj2);
          obj2.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          obj2.focus();
        }
      }
      setSelectedTemplate(tpl)
    }

    setSelectedBloc(id.split(".")[0] + "-edit")
    setSelected(id)

    if (!noEmit) data.emit({ user: { selected: id, selectedBloc: id.split(".")[0] + "-edit", selectedTemplate: tpl } })
  };
  const setUser = async (user) => {

    if (user.session_id === "S" + authUser.user_id) {

      if (user.selected) selectTpl(user.selected, user.selectedTemplate, true)

    }

  }

  useEffect(() => {


    const computeProps = async () => {

      if (!template) return
      console.log("Recalculating props...")
      var _propsTemplates = []
      _propsTemplates= await getProps(template.structure, cache, templates, project, template.template_id);





      if( template.schema_id)
      {
      
       _propsTemplates.push({ name: schemas.find(s=>s.content_id+""===""+ template.schema_id )?.title?? "S " +template.schema_id  ,  self:true, schema_id : template.schema_id   })

      }

      console.log("_propsTemplates", _propsTemplates)


      
      setPropsTemplates(_propsTemplates)

    }

    computeProps()

  }, [template])


  const addTemplateContent = async (template_id, onChange) => {

    if (!app) return;
    var content = await project.api("/set_content", {
      body: {
        title: "Data Bloc Template - " + template_id,
        template_id, app_id: app.app_id, content: {}
      }
    });
    await setRecord(content, "content")
    await data.updateContent(content)
    onChange(content.content_id);

  }


  const dragging = (pos) => {


    if (pos.end) {


      setDragged()

      // C'est fini


    }

  }


  const VarContent = ({ data, className }) => {


    if (data && typeof data === "object") {

      if (Array.isArray(data)) {
        return <div className={"flex flex-col"}>{data.map(((value, i) => <div className={className + " ml-1 pl-1 border-l border-zinc-500 flex flex-row items-start justify-start"} key={i} >
          <div className="font-bold">{i} : </div>
          <VarContent data={value} />
        </div>))} </div>
      }
      else {
        return <div className={"flex flex-col"}>{Object.keys(data).map((key => <div className={className + " ml-1 pl-1 border-l border-zinc-500 flex fex-row items-start justify-start"} key={key} >
          <div className="font-bold">{key} : </div>
          <VarContent data={data[key]} />
        </div>))} </div>
      }



    }
    return <>{data}</>


  }










  return (
    <div className="App  text-zinc-600 text-sm mt-16 relative">
      {loading && <Loader />}
      {template &&
        (
          <header className=" ">
            <div className="flex flex-col w-screen">
              <div className="w-full flex flex-row    bg-zinc-100 rounded-t-xl  z-50 px-2 ">






                <div
                  className={
                    "  border-x-2 p-2 border-zinc-600 my-2    h-screen overflow-y-auto relative pb-12"
                  }
                >

                  <div className={" flex   flex-col items-start    relative  "}>
                    <div className="font-bold p-1 text-xl text-left w-full sticky top-0  ">
                      Tree

                    </div>
                    <TemplateTree

                    
                      selectTpl={selectTpl}
                      id={"-root"}
                      hue={0}
                      schemas={schemas}
                      selected={selected}
                      setDraggedZone={setDraggedZone}
                      draggedZone={draggedZone}
                      setDragOverZone={setDragOverZone}
                      dragOverZone={dragOverZone}
                      template={template?.structure ?? {}
                      }
                      setSelected={selectTpl}
                      setStructure={(structure, parentStructure, dropped) => {
                        if (dropped) structure = cleanStructure(structure, "footer");
                        data.updateTemplate(
                          { ...template, structure })
                      }}
                      dragged={dragged}
                      setDragged={(_id) => _setDragged(_id, template.template_id, "-root")}
                      dragging={dragging}

                    />
                  </div>
                </div>

                <div className="flex flex-col   items-start justify-start">

                  <div className=" flex  flex-col  border-x-2 p-2 border-zinc-600 my-2 w-full ">


                    <TemplateEdition
                   

                      id={"-root"}
                      simulatedContent={baseContent}
                      key={"-root"}
                      setSimulatedContent={setBaseContent}
                      setInternal={setInternal}
                      selected={selected}
                      template_id={template.template_id}
                      liveTemplate={template}
                      setSelected={selectTpl}
                      updateLiveTemplate={(updatedTemplate) =>
                        data.updateTemplate(updatedTemplate)

                      }
                      templates={templates}

                    />
                  </div>




                </div>
    {  testContent &&  propsTemplates && propsTemplates.length>0 &&
                            <div className="flex flex-col ">
        

                                <ContentEdition
                                key={"testContent"}
                                defaultContent={ testContent }
                                _schemas={schemas}
                                dataOnly={true}
                                  defaultApp={data.app}
                                 
                                  updateLiveContent={updateTestContent}
                            
                                  data_schema={propsTemplates}
                                />
                            
                            </div>
}

              </div>
            </div>


            <div className="p-2 bg-white w-full fixed top-0 left-0 flex justify-between z-50   ">
              <div className="flex">
                Edition


              </div>

              <div className="flex " >{
                Object.values(saveAll).length > 0 && <div
                  type="button"
                  className=" bg-red-600 text-white text-xs rounded-full  p-2 m-1 first-line:rounded relative"
                  onClick={cancelling}
                  id="upload"
                >
                  Abandonner Brouillon
                  <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                </div>
              }
                {
                  Object.values(saveAll).length > 0 && <div
                    type="button"
                    className=" bg-black text-white text-xs rounded-full  p-2 m-1 first-line:rounded relative"
                    onClick={saving}
                    id="upload"
                  >
                    Enregistrer Template
                    <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                  </div>
                }

                <Link to={`/template/${template.template_id}`} target={"viewtemplate" + template.template_id}   >

                  <FontAwesomeIcon
                    icon={fa.faEye}
                    className=" bg-blue-600 text-xs text-white rounded-full  p-2 m-1 first-line:rounded relative"
                  />

                </Link>

                <FontAwesomeIcon
                  icon={fa.faClose}
                  onClick={() => window.close()}
                  className="bg-zinc-900 text-white rounded-full text-xs p-2 m-1"
                />


              </div>
            </div>

          </header>
        )}
    </div>
  );
}

export default PopupTemplateEditor;
