import React, { useEffect, useMemo, useState, useContext } from "react";


import { Link, useParams } from "react-router-dom";


import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModulePagesPages from "./ModulePages";
import AppUsers from "./AppUsers";

import AppContents from "./AppContents";
import AppRoles from "./AppRoles";
import { setBackground } from "../../../../service/background";
import Header1 from "../../../../components/Header1";
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Image from "../../../../components/Image";
import ModuleSettings from "./ModuleSettings";
import ModulePages from "./ModulePages";
import ModuleSchemas from "./ModuleSchemas";
import ModuleTemplates from "./ModuleTemplates";


function Modules() {

  const { project, getProject, setAuthUser, authUser,getModules,updateModule } =
  useContext(MfctContext);




const sortModules=(mds) => {

mds.sort((mA,mB )=> parseInt(mA.content.ordre?? mA.module_id)> parseInt(mB.content.ordre?? mB.module_id) ? 1: -1     )

return mds
}
  const { module_id, what } = useParams();
  console.log("Modules What", { module_id, what });

  const [modules, setModules] = useState(  sortModules (getModules()));
  const [show, setShow] = useState(
    module_id ? { module: parseInt(module_id), what } : null
  );








  const [tabs, setTabs] = useState([
   
    { what: "Pages", text: "Pages", icon: fa.faBookOpen },
    { what: "Templates", text: "Templates", icon: fa.faLayerGroup },
    { what: "Schemas", text: "Schémas", icon: fa.faLayerGroup },
    { what: "Contents", text: "Contenus", icon: fa.faPager },
    { what: "Roles", text: "Rôles", icon: fa.faLock },

  ]);
  const [tabsSide, setTabsSide] = useState([
    { what: "Settings", text: "Paramètres", icon: fa.faGear },
   // { what: "Users", text: "Utilisateurs", icon: fa.faUsers },
   

  ]);
 
const sortModule=async (i,pos)=>{

    var i_= i+pos;
  if(i_<0 || i_>= modules.length) return
  
  
var _moduleA = modules[i];
var _moduleB = modules[i+pos]
  
    var orderA = _moduleA.content.ordre ?? _moduleA.module_id
    var orderB = _moduleB.content.ordre ?? _moduleB.module_id

 var _mA =  await  project.api("/set_module",{body:{ ..._moduleA,content:{ ..._moduleA.content,ordre: orderB  }}}) //unsecure !!
 var _mB =  await  project.api("/set_module",{body:{..._moduleB,content:{ ..._moduleB.content,ordre: orderA  }}})

 updateModule(_mA)
 updateModule(_mB)
 setModules(sortModules( getModules())  )
}

  const newModule = ()=>{
   setShow({module:"new",what:"Settings"})
   setModules( [  {module_id:"new" , name:"Nouveau module", content:{} }, ...modules   ])

  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setBackground();
  }, [show]);

  return (
    <div className=" text-white">
      <Header1   title={"Modules"}   linkLeft={"/"} clickRight={newModule   }  iconRight={fa.faPlus} classRight={"p-2 rounded-full border border-white "  } />
    

      <div className="w-full flex flex-col p-4">
        {modules &&
          modules
            .filter((module) => !show || show.module === module.module_id)
            .map((module, i) => (
              <div  className="flex flex-col w-full" key={i} >
                <div
                className="rounded-xl border-t border-zinc-500 w-full  text-black flex flex-col items-start p-4 my-2 bg-white"
             
              >
                <div className="flex flex-row   w-full  justify-center items-start">
                  <Image  
                    src={
                     module.content.imageUrl  }
                     type="app"
                    className=" m-1 w-28 h-28 rounded-xl   shadow-xl"
                    alt="logo"
                  />
                  <div className="flex flex-col grow">
                    <div className="flex flex-row">
                      <span className="mx-4 text-2xl font-extrabold text-left grow">
                     
                        {module.name    }

                      </span>

                      <div className="flex flex-col items-center justify-center border-l border-zinc-200 pl-2">
                      
                       
                      
                        {tabsSide.map((tab, i) => (
                  <Link to={   module.module_id === show?.module && show?.what === tab.what? "/modules": `/modules/${ module.module_id }/${tab.what}` } key={i} > <div
                      key={i}
                      onClick={ () =>
                        setShow(
                          module.module_id === show?.module && show?.what === tab.what
                            ? null
                            : { module: module.module_id, what: tab.what }
                        )
                      }
                      className={
                        " p-1 rounded-md " +
                        (module.module_id === show?.module && show?.what === tab.what
                          ? " bg-zinc-200 "
                          : "")
                      }
                    >
                      <FontAwesomeIcon
                        icon={tab.icon}
                        className=" rounded-full border border-white  "
                      ></FontAwesomeIcon>
                    </div></Link>
                  ))}
{i>0  &&    <FontAwesomeIcon    icon={fa.faArrowCircleUp}  onClick={()=>sortModule(i,-1)} className=" text-green-600 p-1 "/> }
 { i<modules.length-1 && <FontAwesomeIcon    icon={fa.faArrowCircleDown}  onClick={()=>sortModule(i,1)} className="p-1  text-green-600  "/> }
              
              
              
               

                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full text-sm flex flex-row items-center justify-center">
                  {tabs.map((tab, i) => (
                  <Link to={   module.module_id === show?.module && show?.what === tab.what? "/modules": `/modules/${ module.module_id }/${tab.what}` } key={i} > <div
                      key={i}
                      onClick={ () =>
                        setShow(
                          module.module_id === show?.module && show?.what === tab.what
                            ? null
                            : { module: module.module_id, what: tab.what }
                        )
                      }
                      className={
                        " p-1 rounded-md " +
                        (module.module_id === show?.module && show?.what === tab.what
                          ? " bg-zinc-200 "
                          : "")
                      }
                    >
                      <FontAwesomeIcon
                        icon={tab.icon}
                        className=" rounded-full border border-white  "
                      ></FontAwesomeIcon>
                      <div className="text-xs md:text-sm">{tab.text}</div>
                    </div></Link>
                  ))}
               
                </div>
                </div>
              
                {show?.what === "Pages" && show?.module === module.module_id && (
                  <ModulePages module={module}  />
                )}
               
                {show?.what === "Roles" && show?.module === module.module_id && (
                  <AppRoles module={module} />
                )}
                {show?.what === "Settings" && show?.module === module.module_id && (
                  <ModuleSettings module={module} setModules={setModules} setTab={setShow} />
                )}
                {show?.what === "Contents" && show?.module === module.module_id && (
                  <AppContents app={module.app} />
                )}
                 {show?.what === "Schemas" && show?.module === module.module_id && (
                  <ModuleSchemas app={module.app} />
                )}
                {show?.what === "Templates" && show?.module === module.module_id && (
                  <ModuleTemplates module={module} />
                )}
              </div>
            ))}
      </div>

      <ToastContainer />
    </div>
  );
}

export default Modules;
