import "../../App.css";

import {
  BrowserRouter,
  useNavigate,
  Switch,
  Routes,
  Route,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { MfctContext } from "../../context";
import { useEffect, useMemo, useState } from "react";
import { getAuthUser, projectInit } from "../../service/mftc-api";
import Install from "./pages/install/Install";
import DashBoard from "./pages/dashboard/Dashboard";
import Chat from "./pages/chat/Chat";
import Meet from "./pages/meet/Meet";
import Loader from "../../components/Loader";
import Home from "./pages/home/Home";
import Account from "./pages/account/Account";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import Page from "./pages/page/Page";
import NotFound from "./pages/notfound/NotFound";
import PaymentProcess from "../common/pages/payment_process/PaymentProcess";
import { deleteRecord, setRecord } from "../../service/localdata";


var globalDataLoading;
var getProjectLoading;



const templates = {};
function AppClient() {
  console.log(window.location.search);
  var parts = window.location.search.split("?domain=");
  const domain = parts.length > 1 ? parts[1] : null;
  //window.alert(window.location.search)
  const [authUser, setAuthUser] = useState(getAuthUser());
  const [project, setProject] = useState();
  const [account, setAccount] = useState();
  const [pages, setPages] = useState([]);

  const [contents, setContents] = useState([]);
  const[appContents,setAppContents]= useState({})
  const[landing_page,setLandingPage]= useState({})
  const[home_page,setHomePage]= useState({})
  const[welcome_screen,setWelcomeScreen]= useState({})
  const[home_screen,setHomeScreen]= useState({})

  async function getGlobalData(p) {
    if (globalDataLoading) {
      console.log("globalDataLoading already loaded");
      return;
    }
    console.log("globalDataLoading loading");
    globalDataLoading = true;
    p = p ?? project;
    var timestamp = localStorage.getItem("timestamp");
    var body = {};
    if (timestamp) body.timestamp = timestamp;
    var global = await p.api("/get_global_data", { body });

    console.log("Données globales récupérées avec succès");
    if (!timestamp) timestamp = "0000-00-00 00:00:00";
    var tables = Object.keys(global);

    for (let i = 0; i < tables.length; i++) {
      let table = tables[i];
      let records = global[table];
      for (let j = 0; j < records.length; j++) {
        if (timestamp < records[j].updated_at)
          timestamp = records[j].updated_at;

        if (records[j].active === 0) {
          console.log("delete ", table, records[j]);

          await deleteRecord(records[j][table + "_id"], table);
        } else {
          console.log("Add", table, records[j]);

          await setRecord(records[j], table);
        }
      }
    }

    localStorage.setItem("timestamp", timestamp);
    globalDataLoading = false;
    console.log("globalDataLoading terminated");
  }

  async function getProject() {
    if (getProjectLoading) {
      console.log("getProjectLoading already loaded");
      return;
    }
    getProjectLoading = true;
    if (project && (project.authUser_id === authUser?.user_id ?? 0)) return;
    console.log(
      "getProject",
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME,
      domain
    );
    const p = await projectInit(
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME,
      null,
      domain
    );

await     getGlobalData(p)
    if (p.app?.profile?.secondary_color) {
      document.body.style.backgroundColor = p.app?.profile?.secondary_color;
    }

    p.authUser_id = authUser?.user_id ?? 0;
    if (p.pages) {
     
     
     
      
var  _landing_page =  p.pages.find(page=> page.page_id === parseInt(p.app.profile.landing_page_id)   ) ?? p.pages[0]
var  _home_page =  p.pages.find(page=> page.page_id === parseInt( p.app.profile.home_page_id )  ) ?? _landing_page
var  _welcome_screen =  p.pages.find(page=> page.page_id ===  parseInt(p.app.profile.welcome_screen_id)   ) ?? _landing_page
var  _home_screen =  p.pages.find(page=> page.page_id ===  parseInt(p.app.profile.home_screen_id)   ) ?? _welcome_screen
setLandingPage(_landing_page)
setHomePage(_home_page)
setWelcomeScreen(_welcome_screen)
setHomeScreen(_home_screen)
      setPages(p.pages);
    }

if(p.contents){

  setContents(p.contents)
  setAppContents({ [p.app.app_id]:true})
}



    if (authUser && !account) {
      var _account = await p.api("/connect_app");

      if (
        _account.profile?.imageUrl &&
        !_account.profile?.imageUrl.includes("https://")
      ) {
        var url =
          process.env.REACT_APP_MFTC_SERVER_URL_FILES +
          _account.profile.imageUrl;
        const domain = localStorage.getItem("domain");
        if (domain) url += `?domain=${domain}`;
        _account.profile.imageUrl = url;
      }
      setAccount(_account);
      console.log("account", _account);
    }



    setProject(p);
    getProjectLoading = false;
  }

  async function getContentsByApp(app_id) {

    if(appContents[app_id]){
   
     return contents.filter(c=>c.app_id +""===app_id+"")
    }
     var _contents = await project.api("/app/:app_id/get_contents", {
       args: { app_id },
     });
   
     setContents([...contents ,..._contents])
   var   _appContents={...appContents}
   _appContents[app_id] = true
   setAppContents(_appContents)
   return _contents;
     }



  const value = useMemo(() => {
    return {
      authUser,
      account,
      project,
      setAuthUser,
      getProject,
      getTemplate,
 
      setAccount,
      getContentsByApp,
    };
  }, [authUser, account, project]);

  useEffect(() => {
    getProject();
  }, [authUser]);

  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);

  async function getTemplate(template_id) {
    if (templates[template_id]) return templates[template_id];
    var _template = await project.api("/client/template/:template_id", {
      args: { template_id },
    });
    templates[template_id] = _template;
    return _template;
  }

  const Init = () => {

      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches;
      console.log("isStandalone", isStandalone);
      console.log("window.navigator.standalone ", window.navigator.standalone);

      if (window.navigator.standalone || isStandalone) {

return  !authUser ? <Page  pageDefault={welcome_screen  }  />    : <Page  pageDefault={home_screen  }  />


      } else {


return  !authUser ? <Page pageDefault={landing_page  } />    : <Page  pageDefault={home_page  }  />
      
      }

  };

  if (!project) return <Loader />;
  return (
    <MfctContext.Provider value={value}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Init />} />
          <Route path="/" element={<Init />} />
          {   pages.map((page, i) => (
            <Route
              key={"Route" +page.id}
              path={"/" + page.slug}
              
              element={<Page pageDefault={page} key={page.page_id } />}
            />
          ))}
         {
         /* process.env.REACT_APP_ENV === "dev" && (
            <Route path="/domain/:domain" element={<Page  />} />
          )
        */
        }
          {
          /*
          process.env.REACT_APP_ENV === "dev" && (
            <Route path="/domain/:domain/:slug" element={<Page />} />
          )
          */
          }      

          <Route path="/chat" element={<Chat />} />
          <Route path="/account" element={<Account />} />
          <Route path="/meet" element={<Meet />} />
          <Route path="/page/:page_id" element={<Page />} />
          <Route path="/payment_process" element={<PaymentProcess />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MfctContext.Provider>
  );
}

export default AppClient;




/*





*/