import {
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowUpShortWide,
  faClose,
  faPlusCircle,
  faSignOut,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";

import * as fa from "@fortawesome/free-solid-svg-icons";

function TemplateTree({
  template,
  internals,
  domain,
  globalSchema,
  schemas,
  addLittleBrother,
  explodeTree,
  setStructure,
  unWrapFunc,
  upSortFunc,
  downSortFunc,
  deleteFunc,
  childBecomeSiblingFunc,
  createSubTemplateFunc,
  unChildFunc,
  templates,
  id,
  selected,
  selectTpl,
  shows,
  setDragged,
  dragged,
  setDraggedZone,
  draggedZone,
  setDragOverZone,
  dragOverZone,
  dragging,
  isEditable,
  hue,
  isLast
}) {



  const color= "hsl(" + hue + "  50% 50%)"


  const childColor ="hsl(" + (hue+45) + "  50% 50%)"


  const [explodemyTree,setExplodemyTree]=useState(true)


  const addChildren = () => {

    
    var children = ("children" in template && template.children) ?? [];

    console.log(  "addChildren",  template, children);
    //if(  dragged)window.alert("addChildren"   + JSON.stringify(dragged)  )
    var _dragged = dragged
    children.unshift(  _dragged?.structure ??   {
      tag: "div",
      className: "",
      children: [],
      new: true,
      brandnew: true,
    });
    
    setStructure({ ...template, children },null,!!_dragged );
    if(_dragged)
    {  setDraggedZone()
      setDragged()
    }
  };

  const addChildrenLast = () => {
    var children = ("children" in template && template.children) ?? [];
var _dragged = dragged
    console.log(template, children);
    children.push(   _dragged?.structure ??  {
      tag: "div",
      className: "",
      children: [],
      new: true,
      brandnew: true,
    });
    setStructure({ ...template, children },null,!!_dragged);
    if(_dragged)
    {  setDraggedZone()
      setDragged()
    }

  };

  const addChildrenAfter = (i) => {

    //if(  dragged) window.alert("addChildrenAfter")
    var children = ("children" in template && template.children) ?? [];

    console.log(template, children);

    setStructure({
      ...template,
      children: [
        ...children.filter((_, j) => j <= i),
        dragged?.structure ??  { tag: "div", className: "", children: [], new: true, brandnew: true },
        ...children.filter((_, j) => j > i),
      ],
    },null,!!dragged);
  };


  const unWrapDiv = (i) => {
    var childWrapped = template.children[i].children;
    var childrenBefore = template.children.filter((c, idx) => idx < i);
    var childrenAfter = template.children.filter((c, idx) => idx > i);
    setStructure({
      ...template,
      children: [...childrenBefore, ...childWrapped, ...childrenAfter],
    });
  };

  const sortChildren = (i, pos) => {
    var i_ = i + pos;
    if (i_ < 0 || i_ >= template.children.length) return;

    var children = ("children" in template && template.children) ?? [];

    children = children.map((c, j) => {
      if (j === i) return children[i_];
      if (j === i_) return children[i];
      return c;
    });
    setStructure({ ...template, children });
  };


  function dragStart(event) {
    setStructure({...template,dragged: true});
    if (!dragged && (id.split(".").length > 1 || id.split("_")[0]==="bloc")) {
      console.log(
        "dragStart",
        selected,
        id,

      );
      setDragged({
        id,
        structure: {...JSON.parse( JSON.stringify( template)),dropped: true},
      });

    }
  }

  var oldcolor 

  const hoverStart = () => {
    hoverEnd()
    var obj = document.getElementById(id);
    if(obj) {
     // obj.classList.add("hoverElement");
      if(!oldcolor)  oldcolor = obj.style.backgroundColor
      obj.style.backgroundColor =color
    }
  };

  const hoverEnd = () => {
    var obj = document.getElementById(id);
    if(obj) {
    //  obj.classList.remove("hoverElement");
      obj.style.backgroundColor =oldcolor
      oldcolor=null
    }
  };




  function dragEnd(event) {
  if( !draggedZone){
   
    delete template.dragged 
    setStructure({...template});
  }
  else 
  {
   
  }
  setDraggedZone()
  setDragged()
 }


  const childOfChildBecomeChild = (i, tpl) => {
    var childrenBefore = template.children.filter((c, idx) => idx <= i);
    var childrenAfter = template.children.filter((c, idx) => idx > i);
    setStructure({
      ...template,
      children: [...childrenBefore, tpl, ...childrenAfter],
    });
  };


  const unChildren = (i) => {
    childBecomeSiblingFunc(template.children[i]);
  };

  const delChildren = (i) => {
    var children = (("children" in template && template.children) ?? []).filter(
      (c, idx) => idx !== i
    );
    setStructure({ ...template, children: children });
    selectTpl("")
  };

  useEffect(() => {
    if (template.brandnew) {
      setTimeout(() => {
        delete template.brandnew;
        setStructure(template);
      }, 500);
    }
    if (template.new && id === selected) {
      delete template.new;
      setStructure(template);
    }

    if (template.dropped  && !dragged) {
      delete template.dropped ;
      setStructure(template);
      selectTpl(id, template)
    }



  }, [template, id, selected,dragged]);

  const isSelected = useMemo(() => {
    return id === selected;
  }, [id, selected]);

  const idPrevious = useMemo(() => {
    var parts = id.split(".");

    parts[parts.length - 1] = parseInt(parts[parts.length - 1]) + 1;

    return parts.join(".");
  }, [id]);

  const isShowed = useMemo(() => {
    return (true || 
        id.split(".").length === 1 ||
      (selected && selected.split(".")[0] === id.split(".")[0]) ||
      explodeTree
    );
  }, [id, selected]);
  var parts = id.split(".");
  var breadcrumbs = [];
  while (parts.length > 1) {
    parts.pop();
    breadcrumbs.unshift(parts.join("."));
  }

  const draggedSameBloc = useMemo(   ()=>{
  
    if(!dragged || id===selected  || (selected &&  id.split(selected).length>1 ) ) return 
  
return dragged.id.split(".")[0]===id.split(".")[0] && dragOverZone===id

  },[id,dragged,dragOverZone])

  


  return (
    <>
      <div
        id={id + "-draggable text-white"}
        style={{    minWidth: template.brandnew ? "0px" :"40px",
         width: template.brandnew ? "0%": "100%"
  }}
        className={
          "flex w-full  transition-all duration-1000  flex-col -reverse  border-l text-sm text-white items-center " +
          (isSelected && explodemyTree && " border-white border rounded-sm ") +
          (id.split(".").length === 1 && "") +
          (dragged && isSelected && "  opacity-25 ") + 
          (dragged  && !isSelected && id.split(selected).length>1 && " hidden")
        }
 
    //  onDragEnter={()=>setDraggedZone(id)}
    //  onDragLeave={()=>{  setTimeout( ()=>{ if(id===draggedZone) setDraggedZone()   }  ,200)   }}
        
      >
        { isShowed && (
          <>
    

            <div className=" appbutton w-full my-2 flex flex-col justify-center items-center px-1"
            

            onMouseOver={hoverStart}
            onMouseOut={hoverEnd}
            draggable={isEditable && !explodemyTree && isSelected && (id.split(".").length > 1 || id.split("_")[0]==="bloc" )}
   
            onDrag={isSelected ? dragStart : null}
            onDragEnd={ dragged?.id ===id  ||  isSelected ? dragEnd : null}
            >
              <div
                className={
                  " relative zone  flex flex-col justify-start p-1 w-full border-4   transition-all duration-500  " +
                  (id.split(".").length === 1 && " font-bold ") +
                  (isSelected && " text-white  ") +
                  (template.brandnew && "  text-white")
                }
                style={{
                  borderColor: template.brandnew  ?  "none":  color,
                  backgroundColor: template.brandnew  ?  "orange":  (isSelected ? color : " rgba(0,0,0,0.5)"),
                }}
                onClick={() => {    hoverEnd();selectTpl(id, template);setExplodemyTree(isSelected  ?   !explodemyTree:false)    } }

               onDragOver={(e)=> setDragOverZone(id)}


              >
                {id.split(".").length === 1 && id + " : "}{" "}
             
                {template.tagName ?? ( template.tag ?? "div")}{" "}
                {schemas &&
                  template.schema_id &&
                  " - " +
                    schemas.find(
                      (s) => s.content_id + "" === "" + template.schema_id
                    )?.title}
                {template.new && (
                  <FontAwesomeIcon
                    icon={fa.faStar}
                    className="text-yellow-600  absolute -top-2  -right-2 text-sm"
                  />
                )}
                  {isEditable && explodemyTree && isSelected && (
                  <FontAwesomeIcon
                    icon={fa.faTrash}
                    onClick={deleteFunc}
                    className="appbutton bg-red-600 text-white absolute -bottom-4 rounded-full p-2 -right-4 w-4 h-4 border-2 border-white  "
                  />
                )}
                 {!explodemyTree && isSelected && (
                  <FontAwesomeIcon
                    icon={fa.faMousePointer}
                    className=" text-white absolute top-1   left-1 w-4 h-4 "
                  />
                )}


                {  template.children && template.children.length>0  && 
                <div className="text-xs w-4 h-4 rounded-full bg-white text-white flex justify-center items-center absolute -bottom-2 right-6 border border-white" 
                style={{

fontSize: "0.5rem",
                  backgroundColor: color 
                }}
                
                >{  template.children.length  }</div>  }
              </div>
              
            </div>


          </>
        )}
        <div className={"px-8 w-full transition-all flex items-center flex-col-reverse justify-center duration-500  overflow-hidden " +  ((explodemyTree || dragged) ? " smax-h-screen" :" max-h-0")}   >
        { isEditable && !dragged &&
               
                "children" in template && (
                  <div className={"  transition-all duration-1000 overflow-hidden " 
         
          
         }  
         style={{   height:   (  
         (isSelected || selected === id + ".0")  ?   " 40px":" 0px" )}}
         >
                 <FontAwesomeIcon
                    icon={fa.faPlus}
                    className={
                      "  p-2 text-white   rounded-t-full"
                    }
                    style={{ backgroundColor: childColor }}
                    onClick={addChildren}
                  /></div>
                )} 
                 {
                 // Zone de drop 
                   !isSelected &&
                
                "children" in template && (
                  <div  className={"flex w-full transition-all duration-500 items-center justify-center overflow-hidden  "     }
                   style={{ backgroundColor:draggedZone===id+".addChildren" ?childColor :"transparent",
                   height: draggedSameBloc || id+".0"===dragOverZone     ?( draggedZone===id+".addChildren" ?"120px" :"40px"   ) :"0px" ,}} 
                   onDragEnter={()=>setDraggedZone(id+".addChildren")}
                   onDragLeave={()=>{  setTimeout( ()=>{ if(id+".addChildren"===draggedZone) setDraggedZone()   }  ,200)   }}
                   onDrop={(e)=>{ e.preventDefault();addChildren();}}
                   onDragOver={(e) =>{  e.preventDefault();  }}
                   ><FontAwesomeIcon
                    icon={fa.faArrowsUpDownLeftRight}
                    className={
                      "  p-2 text-white   rounded-t-full "
                    }
                 
                    style={{ backgroundColor: childColor ,  pointerEvents: 'none'}}
                  
                  /></div>
                )}        
                
              
                
                {"children" in template  &&
            template.children.map((tpl, i) => (
              <TemplateTree
                globalSchema={globalSchema}
                schemas={schemas}
                template={tpl}
                domain={domain}
                isLast= { i=== template.children.length-1}
                key={i}
                isEditable={isEditable}
                internals={internals}
                deleteFunc={() => delChildren(i)}
                unWrapFunc={() => unWrapDiv(i)}
                downSortFunc={() => sortChildren(i, 1)}
                upSortFunc={() => sortChildren(i, -1)}
                unChildFunc={() => unChildren(i)}
                explodeTree={explodeTree}
                createSubTemplateFunc={createSubTemplateFunc}
                childBecomeSiblingFunc={(tpl) =>
                  childOfChildBecomeChild(i, tpl)
                }
                templates={templates}
                setStructure={(structure, parentStructure,dropped) => {
                  var children = template.children.map((c, index) =>
                    i === index ? structure : c
                  );
                  setStructure({ ...template, children }, parentStructure,dropped);
                }}
                id={id + "." + i}
                selectTpl={selectTpl}
                selected={selected}
                shows={shows}
                hue={hue+45}
                setDragged={setDragged}
                dragged={dragged}
                dragging={dragging}
                setDraggedZone={setDraggedZone}
                draggedZone={draggedZone}
                setDragOverZone={setDragOverZone}
                dragOverZone={dragOverZone}
                addLittleBrother={() => addChildrenAfter(i)}
              />
            ))}
          {isShowed && (
            <div
              id={id + "_dropZone"}
              className="w-full h-0 transition-all"
            ></div>
          )}
        {isEditable && !dragged &&
          isSelected  &&
          "children" in template &&
          template.children.length > 0 && (
            <FontAwesomeIcon
              icon={fa.faPlus}
              className={  " appbutton p-2 text-white rounded-b-full "
              }
              style={{ backgroundColor: childColor }}
              onClick={addChildrenLast}
            />
          )}
         
               </div>
   
      </div>
      {isEditable && !dragged &&
    //    id.split(".").length > 1 &&
   //     (isSelected || selected === idPrevious ) &&
         (<div className={"  transition-all duration-1000 overflow-hidden " 
         
          
         }  
         style={{   height:   (  id.split(".").length > 1 &&
         (isSelected || selected === idPrevious ) ?   " 40px":" 0px" )}}
         >
          <FontAwesomeIcon
            icon={fa.faPlus}
            className={    " appbutton p-2 text-white"   + (  isLast ? " rounded-b-full ":" rounded-full ") }
            style={{ backgroundColor: color,
         
            
            
            }}
            onClick={addLittleBrother}
          /></div>
        )}
              {
              //Zone de drop 
              
              
            
   
              isEditable &&  dragged && !isSelected && 
         (            <div  className={"flex w-full items-center transition-all  duration-1000 justify-center overflow-hidden "     }
         style={{ backgroundColor:draggedZone===id+".addLittleBrother" ?color :"transparent",
        

        height: ( (draggedSameBloc && !isSelected && id.split(".").length > 1) ||  (  idPrevious===dragOverZone )) ?( draggedZone===id+".addLittleBrother" ?"120px" :"40px"   ) :"0px" ,
        }} 

         onDragEnter={()=>setDraggedZone(id+".addLittleBrother")}
         onDragLeave={()=>{  setTimeout( ()=>{ if(id+".addLittleBrother"===draggedZone) setDraggedZone()   }  ,200)   }}
         onDragOver={(e) =>{  e.preventDefault();  }}
         onDrop={(e)=>{ e.preventDefault();addLittleBrother();}} >
          <FontAwesomeIcon
                    
            icon={fa.faArrowsUpDownLeftRight}
            className={    " m-2 p-2 text-white"   + (  isLast ? " rounded-t-full ":" rounded-full ") }
            style={{ backgroundColor: color ,  pointerEvents: 'none'}}
    
          /></div>
        )}
    </>
  );

  //else
  //return <span >{endpoint} </span>
}

export default TemplateTree;
