
import React from 'react';
import ReactDOM from 'react-dom/client';
//import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as process from 'process';
import AppAdmin from './version/admin/App';/* __VERSION_ADMIN__ */
import AppPro from './version/pro/App';/* __VERSION_PRO__ */
import AppClient from './version/client/App';/* __VERSION_CLIENT__ */
import { getApp } from './service/mftc-api';
import { createClass } from './tools/tpl';





function setViewportHeight() {
  // Calculer la hauteur exacte visible sur l'écran
  const vh = window.innerHeight ;
  createClass( "h-visible",`height :${vh}px ; `  )
  createClass( "min-h-visible",`min-height :${vh}px ; `  )

}

// Exécuter au chargement de la page
window.addEventListener('load', setViewportHeight);
// Mettre à jour à chaque redimensionnement de la fenêtre
window.addEventListener('resize', setViewportHeight);


const app= getApp()  
window.global = window;
window.process = process;
window.Buffer = [];
console.log("index.js loaded !!")
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   { app==="pro" &&  <AppPro key={"appPro"} /> /* __VERSION_PRO__ */ } 
   { app==="client" && <AppClient key={"appClient"}  /> /* __VERSION_CLIENT__ */}
   { app==="admin" && <AppAdmin  key={"appAdmin"} /> /* __VERSION_ADMIN__ */}
  </React.StrictMode>
); 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
console.log("index.js serviceWorkerRegistration !!")
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.log("index.js reportWebVitals !!")
/*
openssl req -x509 -nodes -days 365 -newkey rsa:2048 \          
  -keyout server_dev.key -out server_dev.crt \           
  -subj "/C=US/ST=CA/L=Irvine/O=Acme Inc./CN=localhost" \
  -reqexts v3_req -reqexts SAN -extensions SAN \
  -config conf_x509.txt

*/
// openssl req -newkey rsa:4096 -keyout key.pem -out cert.pem -config conf_x509.txt