import React, { useEffect, useMemo, useState ,useContext} from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../../../../App.css';
import { MfctContext } from '../../../../context';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/Loader';
import { setToken } from '../../../../service/mftc-api';
import Notifications from '../../../../components/permissions/Notifications';
import InputBoolean from '../../../../components/inputs/InputBoolean';
import HeaderBack from '../../../../components/HeaderBack';
import { setOptions } from 'leaflet';
import InputText from '../../../../components/inputs/InputText';
function Account() {

const { project, account, setAuthUser,setAccount} = useContext (MfctContext);
const [showNotif,setShowNotif] = useState(false) 
const [ subscription,setSubscription ] = useState( localStorage.getItem( 'subscription'))
  const [file, setFile] = useState();
const [profile,setProfile] = useState({})
  const navigate = useNavigate();
const[progression,setProgression] = useState()

useEffect(() => {

if(account) {

setFile(account.profile?.imageUrl );
setProfile(account.profile?? {})
}

},[account]);

const logout=async()=>{
setToken (null, null) 
setAuthUser()
navigate("/home")
}

function onUploadProgress(progressEvent){
    const { loaded, total } = progressEvent;
    let precentage = Math.floor((loaded * 100) / total);
    setProgression({precentage,loaded, total})
    console.log(precentage);

  if( precentage===100)
  {

  }
}


  function handleImgChange(e) {
      console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));

 project.uploadFile( e.target.files, onUploadProgress ).then( results=>{

  if( results && results.length===1){
    console.log( results[0]);
                 setProfile( {...profile,imageUrl: results[0] }  )
                 setFile(results[0] )

  }
 })


}



const updateAccount=async (e)=>{
  e.preventDefault();

  
 var _account =    await project.api("/set_account", { body:{...account,profile} }); 
 setAccount(_account)

}


  const switchNotification = (value)=>{

    if(value){
      setShowNotif(true)
    }
    else if(subscription)
    {
     
          project
        .api("/update_tokenpush", { body: { subscription:  JSON.parse(subscription) ,unsubscribe:true} })
        .then((result) => {
          console.log(result);
          localStorage.removeItem("subscription");
          setSubscription();
        
        });
      
      
    }
  }






  return (   !account ? <Loader /> : 
    <div className=" text-zinc-600 text-sm">

     {  <header className=" min-h-screen relative">
    
    <HeaderBack  title={"Profil"}   />
    
  <div className='z-1 w-full bg-white mt-10'>    

       <form id="uploadimage"  className="flex flex-col items-center p-4 w-full" onSubmit={updateAccount} action="" method="post" encType="multipart/form-data">
     
       <div className='w-full flex items-center flex-col justify-center'>

       <div  className="   w-40 h-40  m-2 bg-slate-400 rounded-full" >

         <img id="previewing" className="w-full h-full object-cover rounded-full" src={file}   onClick={()=>{ document.getElementById("image-upload").click()}}  alt="image profil"/>
         <input type="file" name="image" id="image-upload" className="hidden" onChange={handleImgChange}  />
</div>
<div  className='w-full h-full p-2  top-0 left-0 flex flex-row items-center justify-center'>  <label  >Edit Your Image</label></div>

  </div>  
     
    
 <InputText label={"Nom"} className={"w-full my-1"}  value={profile.last_name} onChange={(last_name)=> { setProfile({...profile,last_name})}  }    /> 
  
 <InputText label={"Prénom"} className={"w-full my-1"}  value={profile.first_name} onChange={(first_name)=> { setProfile({...profile,first_name})}  }    /> 
 
        
    <button type="submit" className="border border-1 p-2 rounded" id="upload">Mettre à jour</button>

<InputBoolean labelTrue={"Notifications activées"} labelFalse={"Notifications désactivées"} value={subscription && subscription!=="NO"}  className={"w-full"}  onChange={  switchNotification}  />  
   
</form>
  
<div className={ "bg-white text-red-900 p-2 my-3 w-full  border-t border-black text-sm text-center p-4"  }  onClick={logout}   > Déconnexion </div>

</div>
      </header> }

{ showNotif && <Notifications  isConnected={true} project={project} noTimeOut={true} close={()=>setShowNotif(false)}  />
}
 </div>
  );
}

export default Account;
