


class UserClass {

    static classe_id =  3;
  static props = [
            {
                "name": "Name"
            },
            {
                "name": "Email"
            },
            {
                "name": "Image",
                "type": "image-user"
            },
            {
                "name": "Profil",
                "type": "link",
                "classe_id": null
            }
        ];



    constructor({user,id,project,setContent,loadRequired}
       )
    {
       this.user=user;
       this.user_id=id;

        this.project = project;
        this.content= {};
        this.setContent = setContent;
        this.loadRequired = loadRequired;
      
    }

     async init(){
      if(!this.user)
      {
      
        var _user = await this.project.api("/get_user/:user_id", {
            args: { user_id: this.user_id },
          });
      
          this.user = _user;
      }
          this.content= {
            "Name": null,
            "Email":[],
            "Image":[],
            "Profil" : null};



        if( this.setContent)  this.setContent(this.content)
     }

     addContent(prefix,content,loadRequired=[]){
      for (const [key, value] of Object.entries(content)) {
        var parts = key.split(".");
        if(parts.length>1 &&  !loadRequired.includes(parts[0] )) return;
        this.content[ `${prefix}.${key}`  ]=value
    }
    }

/* Loaders */


/* Actions */



    getContent(){

    }

} 



export default{ UserClass};