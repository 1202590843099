







const InputEnumSelect = ({id,label,onChange,value,className,values,  bg="bg-white"}) => {


const removeValue=(  v )=>{

    var _values = (value ?? []) .filter(  val => v !== val  )

     onChange( _values )
}

const addValue=(  v )=>{

    var _values = value ?? []

    _values.push(v)

     onChange(_values )
}


return (
    <> <label htmlFor={ "input_"+label } className={"absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 " +bg }>{label}</label>

    <div className={"relative bg-inherit w-full " +className }   >
    
<div className=" w-full flex-nowrap text-start ">
{ 
     (value ?? []).map(  (v,i) => <div key={i}   onClick={()=>removeValue(v)}   className="appbutton  inline-block font-bold text-xs p-1 px-2 m-1 mb-1 bg-white rounded-full border-green-500 border-2"  >{v}</div>     )
}



{ 
   values &&  values.filter( v => ! (value ?? []).find( c=> c===v  )   ).map(  (v,i) => <div key={i}    onClick={()=>addValue(v)}   className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 bg-white rounded-full border-gray-500 border"  >{v}</div>     )
}


</div>

</div>
</> 
)
}
export default InputEnumSelect