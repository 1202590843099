
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState, useRef } from 'react';







function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const InputPeriodTimeScroll = ({ id, label, onChange, value, className }) => {
    let ref1 = "start";
    let ref2 = "end";
    let [selectedHour, setSelectedHour] = useState()


    let [start, setStart] = useState();
    let [end, setEnd] = useState();

    const [lastSelectedInput, setLastSelectedInput] = useState(null);


    const [showHourPicker, setShowHourPicker] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);


    const handleTimeClick = (time) => {

        if (time === start) {
            setStart('');
            setLastSelectedInput(ref1);

            return;
        }
        if (time === end) {
            setEnd('');
            setLastSelectedInput(ref2);

            return;
        }

        if (lastSelectedInput === ref1) {
            if (start && end) {
                setEnd('');

            }
            setStart(time);

            setLastSelectedInput(ref2);
        } else if (lastSelectedInput === ref2) {
            if (time < start) {
                setEnd(start);

                setStart(time);

            } else {
                setEnd(time);

            }
            setLastSelectedInput(ref1);
        }
    };

    function handleSelecting() {

        setLastSelectedInput(null);
    }

    const creneaux = [...Array(24 * 4)].map((_, step) => {
        var hour = Math.floor(step / 4) % 24;
        var minute = Math.floor(step % 4) * 15
        return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
    })




    return (
        <>

            <div className={"relative justify-center  items-center flex flex-col " + className}>
                <div className='flex flex-row w-full gap-2 '>
                </div>
                <div className="relative w-full ">

                    <div className=" top-full w-full bg-white   overflow-x-auto relative p-2">
                        <div className="w-full flex flex-row relative " _className="grid grid-cols-4  md:grid-cols-4   lg:grid-cols-4  ">
                            {
                                !lastSelectedInput && !start && <div

                                    onClick={() => setLastSelectedInput("start")}
                                    className={'text-white bg-green-600 rounded-l-full text-center p-2 my-1 cursor-pointer w-16 mr-1'}  >--:--</div>
                            }
                            {creneaux.map((time) => (
                                <div
                                    key={time}
                                    onClick={() => handleTimeClick(time)}
                                    className={classNames(
                                        "transition-all duration-500  ",

                                        time === start && ' mr-1 text-white bg-green-600 rounded-l-full',
                                        time === end && 'text-white bg-green-600 rounded-r-full',
                                        time === selectedHour && 'text-red-500',
                                        'text-center py-2 my-1 cursor-pointer',
                                        time === start && time === end && 'bg-green-600', // Surligner toute la plage horaire sélectionnée
                                        time > start && time < end && 'bg-green-100', // Surligner les heures entre le début et la fin


                                        start === time && " sticky left-0 top-0",
                                        end === time && " sticky right-0 top-0",
                                        start && time < start && "hidden ",
                                        end && time > end && "hidden ",
                                        ((!lastSelectedInput &&
                                            time !== start && time !== end) ||
                                            (start && end && time > start && time < end) ||
                                            (start && time < start) ||
                                            (end && time > end)) ? "w-0  px-0 opacity-0 visible-0 " : "opacity-100 w-16 px-2"    // enleve ce qu'il y a apres la fin
                                    )}

                                >
                                    {time}
                                </div>
                            ))}
                            {
                                !lastSelectedInput && !end && <div

                                    onClick={() => setLastSelectedInput("end")}
                                    className={'text-white bg-green-600 rounded-r-full text-center p-2 my-1 cursor-pointer w-16'}  >--:--</div>
                            }

                        </div>
                    </div>
                    <label
                        htmlFor={id ?? "input_" + label}
                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                        {label}
                    </label>
                </div>




            </div>
        </>
    )
}



export default InputPeriodTimeScroll

