import ApplicationClass from "./ApplicationClass";
import ContenuClass from "./ContenuClass";
import PageClass from "./PageClass";
import UserClass from "./UserClass";

const  getObject=async (args)=>{

if(args.project.Objects[args.className+"_"+args.id]) return args.project.Objects[args.className+"_"+args.id];
var obj 
switch( args.className){
  case  "ApplicationClass":
 obj = new ApplicationClass( args)
break;
  case "PageClass":
 obj = new PageClass( args)
    break;
    case "UserClass":
 obj = new UserClass( args)
      break;
      case "ContenuClass":
        obj = new ContenuClass( args)
             break;
       

    default :
    return; 
}

   args.project.Objetcs[args.className+"_"+args.id] = obj;
   await obj.init();
   return obj;

}

const getProps = (classe_id)=>{

   switch( classe_id){
      case  "4":
     return ApplicationClass.props;
    
      case "9":
   return PageClass.props;
        
        case "3":
   return UserClass.props
          
          case "7":
         return ContenuClass.props;
                 
           
    
        default :
        return   []; 
    }

}


const  getClasseProps=(classe_id, prefix, classes, parents) =>{
   var props = [];
   parents = parents ?? [];
   
   if(classe_id ==="4" && parents.find(id=> classe_id===id )) return props;
   getProps(classe_id+"").forEach((elt) => {
     var element = { ...elt };
     if (prefix) element.name = prefix + "." + element.name;
     let elarray = {};
     switch (element.type_nombre) {
       case "F":
         elarray = { ...element, isArray: true };
         props.push(elarray);
         for (var i = 0; i <= elarray.length; i++) {
           var eltx = {
             ...element,
             name: element.name + `[${i < elarray.length ? i : "*"}]`,
           };
           props.push(eltx);
           if (eltx.type === "classe"    ) {
             var subprops = getClasseProps(eltx.classe_id, eltx.name, classes, [
               ...parents,
               classe_id,
             ]);
             subprops.forEach((sp) => props.push(sp));
           }
         }
         break;
       case "L":
         elarray = { ...element, isArray: true };
         props.push(elarray);
         for (var j = 0; j <= elarray.max; j++) {
           var eltj = {
             ...element,
             name: element.name + `[${j < elarray.max ? j : "*"}]`,
           };
           props.push(eltj);
           if (eltj.type === "classe") {
             var subprops = getClasseProps(eltj.classe_id, eltj.name, classes, [
               ...parents,
               classe_id,
             ]);
             subprops.forEach((sp) => props.push(sp));
           }
         }
         break;
       case "I":
         elarray = { ...element, isArray: true };
         props.push(elarray);
         var eltk = { ...element, name: element.name + `[*]` };
         props.push(eltk);
         if (eltk.type === "classe") {
           var subprops = getClasseProps(eltk.classe_id, eltk.name, classes, [
             ...parents,
             classe_id,
           ]);
           subprops.forEach((sp) => props.push(sp));
         }
         break;
       default:
         props.push(element);
         if (element.type === "classe") {
           var subprops = getClasseProps(
             element.classe_id,
             element.name,
             classes,
             [...parents, classe_id]
           );
           subprops.forEach((sp) => props.push(sp));
         }
     }
   });
   return props;
 }

 


export default{ getObject, getProps,getClasseProps};



/*
[
    {
        "classe_id": 1,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Conversations",
        "content": {
            "objects": [
                {
                    "name": "Dernier message",
                    "type": "classe",
                    "classe_id": "2",
                    "is_array": true,
                    "min": "0",
                    "max": "4",
                    "type_nombre": "I"
                }
            ]
        },
        "description": "conversation avec les clients"
    },
    {
        "classe_id": 2,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "LastMessage ",
        "content": {
            "objects": [
                {
                    "name": "Client",
                    "type": "classe",
                    "classe_id": "3"
                },
                {
                    "name": "Application",
                    "type": "classe",
                    "classe_id": "4"
                },
                {
                    "name": "Message",
                    "type": "classe",
                    "classe_id": "5"
                }
            ]
        },
        "description": "Dernier message"
    },
    {
        "classe_id": 3,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "User",
        "content": {
            "objects": [
                {
                    "name": "Name"
                },
                {
                    "name": "Email"
                },
                {
                    "name": "Image",
                    "type": "image-user"
                },
                {
                    "name": "Profil",
                    "type": "link",
                    "classe_id": null
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 4,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Application",
        "content": {
            "objects": [
                {
                    "name": "Name"
                },
                {
                    "name": "Logo",
                    "type": "image-app",
                    "classe_id": null
                },
                {
                    "name": "Description",
                    "type": "long text",
                    "classe_id": null
                },
                {
                    "name": "Produit",
                    "type": "classe",
                    "classe_id": "12",
                    "type_nombre": "I"
                },
                {
                    "name": "primary_color",
                    "type": "color",
                    "classe_id": null
                },
                {
                    "name": "secondary",
                    "type": "color",
                    "classe_id": null
                },
                {
                    "name": "page",
                    "type": "classe",
                    "classe_id": "9",
                    "type_nombre": "I",
                    "max": "4"
                },
                {
                    "name": "installer",
                    "type": "action",
                    "classe_id": null,
                    "type_nombre": "O"
                },
                {
                    "name": "partager",
                    "type": "action",
                    "classe_id": null
                },
                {
                    "name": "User",
                    "type": "classe",
                    "classe_id": "3",
                    "type_nombre": "O"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 5,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Message",
        "content": {
            "objects": [
                {
                    "name": "Author",
                    "type": "classe",
                    "classe_id": "3"
                },
                {
                    "name": "Date",
                    "type": "date"
                },
                {
                    "name": "Contenu"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 6,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Conversation",
        "content": {
            "objects": [
                {
                    "name": "Messages",
                    "type": "classe",
                    "classe_id": "5",
                    "type_nombre": "I"
                },
                {
                    "name": "Interlocuteur",
                    "type": "classe",
                    "classe_id": "3",
                    "type_nombre": "R"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 7,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Contenu",
        "content": {
            "objects": [
                {
                    "name": "Date",
                    "type": "date",
                    "type_nombre": "R"
                },
                {
                    "name": "Titre",
                    "type_nombre": "R"
                },
                {
                    "name": "Texte",
                    "type": "long text",
                    "type_nombre": "I"
                },
                {
                    "name": "Image",
                    "type": "image",
                    "type_nombre": "I"
                },
                {
                    "name": "Editeur",
                    "type": "classe",
                    "classe_id": "3"
                },
                {
                    "name": "App",
                    "type": "classe",
                    "classe_id": "4"
                },
                {
                    "name": "Link",
                    "type": "link",
                    "classe_id": null,
                    "type_nombre": "I"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 8,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Rendez-vous",
        "content": {
            "objects": [
                {
                    "name": "Debut",
                    "type": "date"
                },
                {
                    "name": "Fin",
                    "type": "date"
                },
                {
                    "name": "Titre"
                },
                {
                    "name": "Utilisateur",
                    "type": "classe",
                    "classe_id": "3"
                },
                {
                    "name": "Application",
                    "type": "classe",
                    "classe_id": "4"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 9,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Page",
        "content": {
            "objects": [
                {
                    "name": "App",
                    "type": "classe",
                    "classe_id": "4"
                },
                {
                    "name": "Contenu",
                    "type": "classe",
                    "classe_id": "7",
                    "type_nombre": "I"
                },
                {
                    "name": "Header",
                    "type": "classe",
                    "classe_id": "10"
                },
                {
                    "name": "Footer",
                    "type": "classe",
                    "classe_id": "11"
                },
                {
                    "name": "Url",
                    "type": "link",
                    "classe_id": null
                },
                {
                    "name": "slug"
                },
                {
                    "name": "label"
                },
                {
                    "name": "icone",
                    "type": "icone",
                    "classe_id": null
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 10,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Header",
        "content": {
            "objects": [
                {
                    "name": "Title"
                },
                {
                    "name": "App",
                    "type": "classe",
                    "classe_id": "4"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 11,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Footer",
        "content": {
            "objects": [
                {
                    "name": "Textes",
                    "type_nombre": "L",
                    "max": "4",
                    "length": "2"
                },
                {
                    "name": "App",
                    "type": "classe",
                    "classe_id": "4"
                }
            ]
        },
        "description": null
    },
    {
        "classe_id": 12,
        "owner_id": 1,
        "group_owner_id": null,
        "active": 1,
        "name": "Produit Abonnement",
        "content": {
            "objects": [
                {
                    "name": "Titre"
                },
                {
                    "name": "Description",
                    "type": "long text",
                    "classe_id": null
                },
                {
                    "type": "numeric",
                    "classe_id": null,
                    "name": "Prix"
                },
                {
                    "name": "Command",
                    "type": "action",
                    "classe_id": null
                },
                {
                    "name": "Detail",
                    "type": "long text",
                    "classe_id": null,
                    "type_nombre": "L",
                    "max": "4"
                }
            ]
        },
        "description": null
    }
]
*/