import React, { useEffect, useMemo, useState,useContext } from 'react';


import '../../../../App.css';
import { Link } from 'react-router-dom';
import { MfctContext } from "../../../../context";
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socket } from '../../../../service/socket';
import Visio from '../../../../components/visio/Visio';
import { showModalOK } from '../../../../components/modal';
function Chat() {



const { project,authUser,account} = useContext (MfctContext);
const [msgs, setMsgs] = useState([])
const [callActivated,setCallActivated] = useState();

const getMsgs = async()=>{
  showModalOK();
  if(!account  ) return;
console.log('getMsgs',account);
  var _msgs = await project.api("/get_msgs", { body:{account_id:account.account_id} })
  console.log( _msgs)
    setMsgs(_msgs);
    setTimeout(reScroll,10)
}

useEffect(()=>{
  getMsgs()
},[ account]);

const reScroll= async () => {

  document.getElementById("scrollContainer").scrollTop =  document.getElementById("scrollContainer").scrollHeight;

}

const updateMsg=async(new_msg)=>{
 
  setMsgs([...msgs.map(c => c), new_msg]);
  setTimeout(reScroll,10)
}
useEffect(() => {
if(!account  ) return;
socket.on('updateChat/' + account.account_id , updateMsg);

  return () => {
    if(!account  ) return;
    socket.off('updateChat/' + account.account_id , updateMsg);

  };

}, [ account, msgs]);

const sendMsg = async () => {

  var text = document.getElementById("new_msg").value;

  if (text) {

    document.getElementById("new_msg").value = "";
    var result = await project.api("/send_msg", { body: {  owner_id: authUser.user_id, account_id:account.account_id,app_id: account.app_id , pro_id:account.pro_id,content: { text } } })
    await getMsgs();
  }
}


  return (
    <div id="scrollContainer" className="App h-screen overflow-y-auto">
            <div className={'flex flex-row w-full justify-start p-2 fixed top-0 left-0 z-10 items-center' + (callActivated? " bg-black bg-opacity-30 text-white ":" bg-white " )}>
   { !callActivated && <Link to="/dashboard"> <FontAwesomeIcon  icon={fa.faArrowLeft} className='mx-4'></FontAwesomeIcon> </Link> }
          <img src={  process.env.REACT_APP_MFTC_SERVER_URL_FILES + "profile/user.png"} className="w-8 h-8 mx-4 border border-white rounded-full" alt="logo" /> Chat
          
          {account?.account_id}
          { !callActivated   &&  <FontAwesomeIcon icon={fa.faVideoCamera} className='mx-4' onClick={()=>setCallActivated(true)}></FontAwesomeIcon> }
      
          </div>
      
          { !callActivated &&
              <div className='flex flex-col  justify-center items-center p-4  mb-20  mt-20'>
          <div className='flex flex-col w-full justify-start items-start '>
<img src={process.env.REACT_APP_MFTC_SERVER_URL_FILES + "profile/user.png"} className="w-20 h-20  rounded-full -top-6 right-2" alt="logo" />
<div   className='text-3xl font-bold'  >{' Bienvenue'}  </div>
</div>
{
   msgs.map( (msg,i) => <div key={i} className='w-full'>
{
  msg.owner_id !==authUser.user_id ? 
  <div className='  flex flex-col w-full justify-end items-end '>
<div className=' mt-8 flex flex-col text-right  text-sm p-4 f shadow-md rounded-xl bg-orange-500 text-zinc-100 relative'>
{msg.content.text}
<img src={  process.env.REACT_APP_MFTC_SERVER_URL_FILES + "profile/user.png"} className="w-8 h-8 mx-4 absolute rounded-full -top-6 right-2" alt="logo" /> 
</div>
</div>:
  <div className='  flex flex-col w-full justify-start items-start '>
<div className=' flex flex-col text-left  text-sm p-4 shadow-md rounded-xl bg-white-500 text-zinc-800 relative'>
{msg.content.text}</div>
</div>
}

   </div>
    )

}


</div>}
{
         
         callActivated &&
 
         <div  id="Visio" className='flex flex-col  justify-center items-center p-4   mt-20 mb-20 z-10 '>
    <Visio   idToCall={`pro_${account.pro_id}` }  me={`account_${account.account_id}`}   endCall={ ()=>setCallActivated(false)}/>
   
    </div>}


{
         
         !callActivated &&      

        <div className={" px-4 w-full flex flex-col  items-start  justify-center  fixed  bottom-0 p-4 bg-white"} >


          <div className={"  w-full flex flex-row  items-center  justify-center  "} >
            <input id="new_msg" placeholder='Envoie ton commentaire' className=' text-black flex-grow  m-2 rounded-lg bg-transparent border border-zinc-500 p-2' />
            <div className='m-2 p-2 font-bold bg-blue-800 text-white  rounded-md' onClick={sendMsg}  > Envoyer</div> </div>
        </div>
}
 
    </div>
  );
}

export default Chat;
