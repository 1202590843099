import { useEffect,useRef } from "react"
import { genUid, getSuids } from "../../service/suid";




const InputSelectToggle = ({id, label,onChange,value,className,options, valueType,context}) => {
    const divRef = useRef(null);
const uid = genUid(10)
useEffect(()=>{

    if (divRef.current && value) {


        var index = options.findIndex(option=>   value=== (option.value?? option )  )
        if(index>-1)
            { 
                var obj = document.getElementById(uid+index)

                divRef.current.style.left =(obj.offsetLeft)+ "px"
                   divRef.current.style.top =obj.offsetTop+ "px"
              
            }

      }

},[value])



return (
    <>
    <div className={"flex p-1 rounded-full relative   bg-gray-400 " +className } >
    <div  className={" rounded-full absolute top-0 left-0 w-full bg-red-300 "  }>
        <div ref={divRef}  className={" transition-all ease-in-out   absolute p-1 rounded-full px-2  bg-white text-transparent shadow  -z-0 " +(  value? " ":"hidden"  ) } >{value}</div></div>
 
   {options && options.map((option,i)=>  <div className={"appbutton  p-1 rounded-full px-2  "}  id={uid + i} key={i} onClick={()=>onChange(option.value?? option)} >{option.label?? option.value?? option}</div>)}
 
   
</div>
</> 
)
}
export default InputSelectToggle