import React, { useEffect, useMemo, useState, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputText from "../inputs/InputText";
import InputBoolean from "../inputs/InputBoolean";
import InputSelect from "../inputs/InputSelect";

import "../../App.css";
import { MfctContext } from "../../context";
import InputData from "../../components/inputs/InputData";
import InputIcon from "../../components/inputs/InputIcon";
import { getProps, loadTemplate } from "../../tools/tpl";
import { getFilterRecord, getRecord } from "../../service/localdata";
import { data } from "../../service/data";
import VarContent from "../VarContent";
import InputTextArea from "../inputs/InputTextArea";
import InputMultiSelect from "../inputs/InputMultiSelect";
import InputDataSource from "../inputs/InputDataSource";

function ContentEdition({
  defaultContent,
  defaultApp,
  content_id,
  app_id,
  domain,
  template_id,
  updateLiveContent,
  cancelContent,
  saveContent,
  is_schema,
  dataOnly,
  schema_id,
  data_schema,
  _schemas,
  nosave,
}) {
  const {
    project,
    getTemplates,
    getContent,
    cache,
    getApps,
    updateContent,
 
  } = useContext(MfctContext);
  const templates = getTemplates();

  const apps = getApps();
  const [progression, setProgression] = useState();
  const [optionValues, setOptionValues] = useState({});
  const [template, setTemplate] = useState();

  const [content, setContent] = useState();

  const [app, setApp] = useState();

  const [schemas, setSchemas] = useState(_schemas);

  const [showSettings, setShowSettings] = useState(false);



  const importData= async ()=>{

    function isDate(text) {
      const timestamp = Date.parse(text);
      return !isNaN(timestamp);
    }
    function isFrenchDate(text) {
      const dateRegex = /^([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
    
      if (!dateRegex.test(text)) {
        return false; // Ne correspond pas au format JJ/MM/AAAA
      }
    
      // Extraction des valeurs pour créer un objet Date
      const [day, month, year] = text.split("/").map(Number);
      const date = new Date(year, month - 1, day);
    
      // Vérification que les valeurs correspondent bien à une date valide
      return (
        date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year
      );
    }
    function isTime(text) {
      // Regex pour heure au format HH:MM ou HH:MM:SS
      const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;
      return timeRegex.test(text);
    }
    function isNumber(text) {
      return !isNaN(text);
    }
     var textData = window.prompt("Saisir les donnees")

     try{
var _data = JSON.parse(textData)


var listData =[]
if(Array.isArray(_data)) listData= _data;
 else listData =[_data] 
var keys = {}

for(let record of listData)
  for(let key of Object.keys(record))
      {
        var prop =   keys[key] ?? {  name: key}
          var value = record[key]

           if(Array.isArray(value))   prop.type_nombre = Array.isArray(value) ? "I":"F"
          var _value = prop.type_nombre === "I" ? value[0] : value

          var type = typeof _value 
          if(type==="string")
        {
          prop.schema_id =    prop.schema_id ?? ( _value.length<40 ? 42 :   43)
          //object
          //canbenumber
          if(  isNumber(_value)  )  prop.schema_id = 44
          if(isFrenchDate(_value) || isDate(_value)) prop.schema_id = 49 
          if(isTime(_value)) prop.schema_id = 239
          //canbedate
         


      }
      if(type==="number")  prop.schema_id = 44

      keys[key]= prop  
    }
var old_schema = (content.content.schema??[])
    var schema = [...old_schema]
for (let prop of Object.values(keys)){
if(old_schema.find(p=>p.name===prop.name)) continue;
schema.push(prop)
}

if(schema.length>old_schema.length)
 _setContent({
  ...content,
  content: { ...content.content, schema },
})
console.log("newContents",content.content_id)

if(content.content_id){

var newContents = listData.map((c,i)=>(  {title:c.title ?? content.title + " " + i,schema_id: content.content_id,app_id:content.app_id,content:{...c,title:c.title ?? content.title + " " + i} } ))


console.log("newContents",newContents)
     await data.save_contents(newContents)
}
     }
     catch(e){
console.log(e)
window.alert("ERREUR")

     }


  }
  const updateSchema = async (_template) => {
    if (!_template) return;
    var _data_schema = await getProps(
      _template.structure,
      cache,
      templates,
      project
    );

    if (
      JSON.stringify(_template.data_schema ?? []) !==
      JSON.stringify(_data_schema)
    ) {
      //   await project.api("/set_template", {   body: { template_id: _template.template_id, data_schema },   });
      _template.data_schema = _data_schema;
    }
    return _template;
  };

  async function initContent() {
    var _template;
    var _app;

    // Chargement du content
    var _content = {};
    if (defaultContent) {
      _content = defaultContent;
    } else if (content_id) {
      _content = await data.getContent(parseInt(content_id));

    }
    if (!_schemas || _schemas.length === 0) {
      if (_content.app_id)
        _schemas = await data.getSchemasByApp(_content.app_id);
      else _schemas = await data.getSchemas();

      setSchemas(_schemas);
    }

    /// Chargement du template
    if (_content.template_id || template_id) {
      _template = await loadTemplate(
        template_id ?? _content.template_id,
        templates,
        project
      );
      _template = await updateSchema(_template);
      setTemplate(_template);
    }

    //Chargement de l'app
    if ((_content && !_content.is_schema) || !is_schema) {
      if (defaultApp) {
        _app = defaultApp;
      } else if (_content.app_id || app_id) {
        _app = await getRecord(parseInt(_content.app_id ?? app_id), "app");
      }
    }

    if (_app) {
      setApp(_app);
    } else {
    }

    if (template_id) {
      schema_id = _schemas.filter((s) => s.content.code === "bloc").content_id;
    }
    // Nouveau content
    if (!_content.content_id) {
      _content = {
        template_id,

        schema_id,
        is_schema: is_schema,
        app_id: _app?.app_id ?? app_id,
        title: _template ? _template.title + " - Data" : "x",
        content: {
          title: _template ? _template.title + " - Data" : "",
        },
      };
    }
    if (!_content.schema_id && !_content.is_schema) setShowSettings(true);
    if (template_id && _content) _content.template_id = template_id;
    if (_app && _content && _content.content)
      _content.content.domain = _app.domain; // à enlever lorsque les contenus auront tous un domaine
    if (_content.content_id)
      cache.set("content_" + _content.content_id, _content);
    setContent(_content);
  }

  const modele = useMemo(() => {
    if (data_schema) return data_schema;
    if (template) return template.data_schema;
    if (content && schemas) {
      var schema = schemas.find(
        (c) => c.content_id + "" === "" + content.schema_id
      );
      if (schema) return schema.content.schema;
    }
  }, [template, content, schemas]);
  const schema = useMemo(() => {

    if (content && schemas) {
      var schema = schemas.find(
        (c) => c.content_id + "" === "" + content.schema_id
      );
      if (schema) return schema;
    }
  }, [content, schemas]);





  useEffect(() => {
    async function getOptionValues() {
      if (!modele || !content || schemas.length === 0) return;

      var update = false;
      var _optionValues = { ...optionValues };

      for (var i = 0; i < modele.length; i++) {
        var _schema_id = modele[i].schema_id;
        console.log(" _sub_schema modele", i, modele, _schema_id);
        if (!_schema_id) continue;
        var _sub_schema = schemas.find(
          (s) => parseInt(s.content_id) === parseInt(_schema_id)
        );
        console.log(" _sub_schema modele", i, modele, _sub_schema);

        if (
          _schema_id &&
          (_optionValues[_schema_id] || _sub_schema?.content.is_value)
        )
          continue;
      

        var _values = await data.getContents(
          (c) =>
            (c.is_public || content.app_id === "all" ||
              c.app_id === parseInt(content.app_id)) &&
            parseInt(c.schema_id) === parseInt(_schema_id),
          true
        );
        //      project.api("/get_schema_contents", {
        //     body: { app_id: parseInt(content.app_id), schema_id: _schema_id },
        //   });
        if(_values.length>0)  {
          update = true;
            _optionValues[_schema_id] = _values;
        }
        console.log("_optionValues " +_schema_id, _optionValues);

      }
      if (update) setOptionValues(_optionValues);
    }

    getOptionValues();
  }, [content, modele, schemas, app_id, optionValues, project]);

  useEffect(() => {
    initContent();
  }, [data_schema]);

  const _setContent = (c) => {
    setContent(c);
 
    if (updateLiveContent) updateLiveContent(c);
    if (c.content_id) updateContent(c);
  };

  const cancel = () => {
    setContent(null);

    if (content.content_id) {
      setTimeout(() => {
        var cached = cache.get("content_" + content.content_id);
        console.log("Restore ", cached);
        _setContent(cached);
      }, 10);
    }
    if (cancelContent) cancelContent();
  };

  const save = async (ctnt) => {
    console.log("Saving content");
    var cleanContent = { ...ctnt.content };
    var keys = Object.keys(cleanContent);
    keys.forEach((k) => {
      console.log(k);
      var value = cleanContent[k];
      if (value && typeof value === "object" && value.content_id)
        cleanContent[k] = { content_id: value.content_id };
      else if (Array.isArray(value)) {
        cleanContent[k] = value.map((v) => {
          if (typeof v === "object" && v.content_id)
            return { content_id: v.content_id };
          return v;
        });
      } else cleanContent[k] = value;
    });

    var c = { ...ctnt, content: cleanContent };
    if (ctnt.content_id) cache.set("content_" + ctnt.content_id, ctnt, true);

    data.init("content", c.content_id, null, project);

    var _content = await data.save_content(c);
    //  await project.api("/set_content", { body: c });

    data.close("content", c.content_id);

    _content = { ..._content, ...c }; // recuperer le id et garder les sous-contenus
    updateContent(_content);

    if (saveContent) saveContent(_content);
  };

  return (
    content && (
      <div className=" w-full text-zinc-600 text-sm mb-2">
        <div className="z-1 w-full mt-1  p-1">
          {!dataOnly && (
            <>
              {" "}
              <div className="w-full flex items-center">
                {" "}
                <InputText
                  label={"Titre"}
                  className={" my-2 w-full"}
                  value={content.content.title}
                  onChange={(value) => {
                    _setContent({
                      ...content,
                      content: { ...content.content, title: value },
                    });
                  }}
                />
                <FontAwesomeIcon
                  icon={fa.faEllipsisV}
                  className="p-2 text-lg shadow-lg rounded-full w-4 h-4 ml-1 appbutton"
                  onClick={() => setShowSettings(!showSettings)}
                />
              </div>

{ showSettings && <div className="flex flex-col w-full">

              {content.template_id && (
                <InputSelect 
                  className={" my-2 w-full"}
                  label={"Schéma de données"}
                  options={schemas
                    .filter((s) => s.content.code === "bloc")
                    .map((t) => {
                      return { value: t.content_id, label: t.title };
                    })}
                  value={content.schema_id}
                  //  trueValue={true}
                  placeholder={"Choisis le schéma de données"}
                  onChange={(value) => {
                    var schema = schemas.find(
                      (s) => s.content_id + "" === value
                    );
                    var schema_tag =
                      schema.schema_tag ?? "*" + schema.content_id + "*";

                    _setContent({
                      ...content,
                      schema_id: schema.content_id,
                      schema_tag,
                    });
                  }}
                />
              )}
              {!content.template_id && (
                <div className="flex flex-row w-full">
                  <InputBoolean
                    className={"p-0 m-2 flex-col"}
                    labelTrue={"Schéma"}
                    labelFalse={"Contenu"}
                    value={content.is_schema}
                    onChange={(value) =>
                      _setContent({ ...content, is_schema: value })
                    }
                  />
                  {!content.is_schema && (
                    <InputSelect
                      className={" my-2 w-full"}
                      label={"Schéma de données"}
                      options={schemas.map((t) => {
                        return { value: t.content_id, label: t.title  };
                      })}
                      value={content.schema_id}
                      //  trueValue={true}
                      placeholder={"Choisis le schéma de données"}
                      onChange={(value) => {
                        var schema = schemas.find(
                          (s) => s.content_id + "" === value
                        );
                        var schema_tag =
                          schema.schema_tag ?? "*" + schema.content_id + "*";

                        _setContent({
                          ...content,
                          schema_id: schema.content_id,
                          schema_tag,
                        });
                      }}
                    />
                  )}

                  {!content.is_schema && (
                    <InputBoolean
                      className={"p-0 m-2 flex-col"}
                      labelTrue={"Requête"}
                      value={content.is_query}
                      onChange={(value) =>
                        _setContent({ ...content, is_query: value })
                      }
                    />
                  )}
                  <InputBoolean
                    className={"p-0 m-2 flex-col"}
                    labelTrue={"Public"}
                    labelFalse={"Privé"}
                    value={content.is_public}
                    onChange={(is_public) =>
                      _setContent({
                        ...content,
                        is_public,
                      })
                    }
                  />
                  {(content.is_schema === 1 || content.is_schema === true) && ( /// Modèle de donnée
                    <>
                      <InputIcon
                        className={"p-4 m-2 flex-col"}
                        label={"Icone"}
                        value={content.content.icone}
                        onChange={(value) =>
                          _setContent({
                            ...content,
                            content: { ...content.content, icone: value },
                          })
                        }
                      />
                      <InputBoolean
                        className={"p-0 m-2 flex-col"}
                        labelTrue={"Valeur"}
                        labelFalse={"Object"}
                        value={content.content.is_value}
                        onChange={(value) =>
                          _setContent({
                            ...content,
                            content: { ...content.content, is_value: value },
                          })
                        }
                      />
                      {content.content.is_value && (
                        <InputBoolean
                          className={"p-0 m-2 flex-col"}
                          labelTrue={"Enumération"}
                          labelFalse={"Saisie"}
                          value={content.content.is_enum}
                          onChange={(is_enum) =>
                            _setContent({
                              ...content,
                              content: { ...content.content, is_enum },
                            })
                          }
                        />
                      )}
                      {content.content.is_value !== 1 &&
                        content.content.is_value !== true && (
                          <InputBoolean
                            className={"p-0 m-2 flex-col"}
                            labelTrue={"Ajoutable"}
                            labelFalse={"Statique"}
                            value={content.content.is_addable}
                            onChange={(value) =>
                              _setContent({
                                ...content,
                                content: {
                                  ...content.content,
                                  is_addable: value,
                                },
                              })
                            }
                          />
                        )}
                      {content.is_schema && <div onClick={ importData }  className="button appbutton" > Importer des données</div>    }
                    </>
                 
                 )}

                </div>
              )}
                               { content.is_schema   &&               <InputMultiSelect
                label={"Template Size"}
                value={content.content.sizes}
                onChange={(sizes) =>
                  _setContent({
                    ...content,
                    content: {
                      ...content.content,
                      sizes,
                    },
                  })}
                values={["full","fit","screen","xs", "sm","lg","xl",
                  "w-full","w-fit","w-screen","w-xs", "w-sm","w-lg","w-xl",
                "h-full","h-fit","h-screen","h-xs", "h-sm","h-lg","h-xl"].map((size) => ({
                  key: size,
                  label:size,
                }))}
              />}
              <InputSelect
                className={" my-2 w-full"}
                label={"Application"}
                options={apps.map((app) => {
                  return { value: app.app_id + "", label: app.name };
                })}
                placeholder={"Choisir l'application"}
                value={content.app_id}
                //  trueValue={true}

                onChange={(app_id) => {
                  _setContent({
                    ...content,
                    app_id,
                  });
                }}
              />
              </div>}

              {content.is_schema && (
                <>
                  {!content.content.is_enum && (
                    <InputSelect
                      value={content.content.display_template_id}
                      onChange={(display_template_id) => {
                        _setContent({
                          ...content,
                          content: { ...content.content, display_template_id },
                        });
                      }}
                      options={templates
                        .filter(
                          (t) => t.schema_id + "" === "" + content.content_id
                        )
                        .map((template) => ({
                          label: template.title,
                          value: template.template_id,
                        }))}
                      label={"Template d'affichage"}
                      placeholder={"Template d'affichage"}
                    />
                  )}
                  {!content.content.is_enum && (
                    <div className="flex flex-col md:flex-row w-full gap-1">
                      <InputText
                        label={"Code"}
                        className={" my-2 w-full"}
                        value={content.content.code}
                        onChange={(code) => {
                          _setContent({
                            ...content,
                            content: { ...content.content, code },
                          });
                        }}
                      />
                      <InputText
                        label={"Entité"}
                        className={" my-2 w-full"}
                        value={content.content.entity}
                        onChange={(entity) => {
                          _setContent({
                            ...content,
                            content: { ...content.content, entity },
                          });
                        }}
                      />
 <InputText
                        label={"Display Name"}
                        className={" my-2 w-full"}
                        value={content.content.displayName}
                        onChange={(displayName) => {
                          _setContent({
                            ...content,
                            content: { ...content.content, displayName },
                          });
                        }}
                      />
                      {content.content.is_value && (
                        <InputSelect
                          label={"DB Format"}
                          className={"p-0 m-2 flex-col w-full"}
                          value={content.content.formatdb}
                          onChange={(formatdb) =>
                            _setContent({
                              ...content,
                              content: { ...content.content, formatdb },
                            })
                          }
                          options={[
                            "-",
                            "VARCHAR",
                            "TEXT",
                            "NUMERIC",
                            "BOOLEAN",
                            "JSON",
                            "FLOAT",
                            "DATE",
                            "GEOHASH",
                            "OBJECT",
                          ]}
                        />
                      )}
                    </div>
                  )}

                  {!content.content.is_enum && (
                    <InputData
                      className={"w-full"}
                      prop={{
                        name: "Définition",
                        schema_id: 124,
                        code: "definition",
                        type_nombre: "I",
                      }}
                      value={content.content.schema ?? []}
                      schema={schemas.find(
                        (s) => s.content.code === "definition"
                      )}
                      onChange={(schema) =>
                        _setContent({
                          ...content,
                          content: { ...content.content, schema },
                        })
                      }
                    />
                  )}

                  {content.content.is_enum && (
                    <InputData
                      className={"w-full"}
                      prop={{
                        name: "Valeur",
                        schema_id: 42,
                        code: "text",
                        type_nombre: "I",
                      }}
                      value={content.content.values ?? []}
                      schema={schemas.find((s) => s.content.code === "text")}
                      onChange={(values) =>
                        _setContent({
                          ...content,
                          content: { ...content.content, values },
                        })
                      }
                    />
                  )}
                    <InputTextArea 
                                        className={" my-2 w-full"}
                                        label={"Description"}
                                        placeholder={"Saisir la description du schéma"}
                                        value={content.content.description}
                                        onChange={(description) => {
                                          _setContent({
                                            ...content,
                                        content :{...content.content, description},
                                          });
                                        }}
                         />
                </>
              )}
            </>
          )}
          {modele && ( //// Contenu de Modele
            <>
              {content.is_query && (
                <InputText
                  label={"Query_Id"}
                  className={" my-2 w-full"}
                  value={content.content.query_id}
                  onChange={(query_id) => {
                    _setContent({
                      ...content,
                      content: { ...content.content, query_id },
                    });
                  }}
                />
              )}
              {modele.map((s, i) => (
                <div className="w-full flex flex-col " key={s.name + i}>
                 <div className="flex w-full gap-1 items-center justify-start">
                {  (!s.is_optional || content.content["option_"+s.name]  ) && <InputData
                    updateContent={updateLiveContent}
                    id={s.name + "-"}
                    prop={s}
                    schema={
                      schemas.find((c) => c.content_id +""===""+ s.schema_id) ?? {
                        content: { code: "long text" },
                      }
                    }
                    className={"w-full my-2"}
                    defaultValue={s.defaultValue}
                    value={content.content[s.name]}
                    onChange={async (value) => {
                      var _content = content.content;
let  schema =  schemas.find((c) => c.content_id +""===""+ s.schema_id)
                      console.log("a charger !!!", value);
                      if (
                        value &&
                        typeof value === "object" &&
                        value.content_id
                      ) {
                        console.log("a charger !!!", value);
                        // Charge le contenu de l'object par son id
                        value = await getContent(value.content_id);
                      } else if (Array.isArray(value)) {
                        for (var j = 0; j < value.length; j++) {
                          var _v = value[j];

                          if (
                            _v &&
                            typeof _v === "object" &&
                            _v.content_id &&
                            Object.keys(_v).length === 1
                          ) {
                            if(schema.content.entity)
                              value[j] = _v
                              else
                            value[j] = await getContent(_v.content_id);
                          
                          
                          
                          }




                        }
                      }
                      _content[s.name] = value;
                      _setContent({ ...content, content: _content });
                    }}
                    options={optionValues[s.schema_id]}
                    placeholder={s.defaultValue ?? "Choisir " + s.name}
                    domain={app?.domain ?? domain}
                    context={{ codeSchema : schema?.content?.code }}
                  />}
                  { s.is_optional &&
                  <InputBoolean  value={ content.content["option_"+s.name]   }  labelTrue={content.content["option_"+s.name] ? "" :s.name }     onChange={ (value)=>
                    _setContent({ ...content, content:{...content.content,["option_"+s.name] :!content.content["option_"+s.name] }} ) }
                        />
                }
                </div>
                  {s.defaultValue && (
                    <div className="text-xs italic -mt-2  mx-2 p-2 border border-t-0  border-dashed rounded-b-xl border-zinc-400 text-zinc-500">
                      {s.defaultValue}
                    </div>
                  )}
                </div>
              ))}

              {false && (
                <InputBoolean
                  labelTrue={"Visible pour tous"}
                  labelFalse={"Visible avec authentification"}
                  value={content.is_public}
                  onChange={(value) => {
                    _setContent({ ...content, is_public: value });
                  }}
                />
              )}
            </>
          )}
        </div>{template &&    <div className="p-2 bg-white w-full flex flex-col"> 
    {     template.props_from_templates &&
    template.props_from_templates[template.template_id].props && 
Object.values(template.props_from_templates[template.template_id].props).filter(prop=>   prop.schema &&  !prop.schema.content.is_value).map(prop=>

<div className="w-full border-t border-black flex flex-col items-start" key={prop.name}>
 <InputBoolean  value={ !!content.content["data_"+prop.name ]   }  labelFalse={prop.name+" -No DataSource"  }  labelTrue={prop.name +" -DataSource" }   onChange={ (value)=>_setContent( {...content,content:{...content,["data_"+prop.name ]:  content.content["data_"+prop.name ] ? null:{}   }  }   )  }      />
{content.content["data_"+prop.name ]  &&
<InputDataSource value={ content.content["data_"+prop.name ]  } onChange={ (value)=>_setContent( {...content,content:{...content,["data_"+prop.name ]: value   }  }   )  } className={"bg-green-100"}  context={{prop,props:template.props_from_templates[template.template_id].props }} label={prop.name} key={"ds_" +prop.name} /> 
}

</div>



)
}</div>}
    

        {!nosave && !content.test && (
          <div className="p-2  w-full flex flex-col rounded-t-xl overflow-hidden">
            <div className=" w-full flex justify-end">
              <button
                type="button"
                className="appbutton border border-1 p-2 m-1 first-line:rounded"
                onClick={cancel}
              >
                Annuler
              </button>
              <button
                type="button"
                className="appbutton  border border-1 p-2 m-1 first-line:rounded"
                onClick={() => save(content)}
                id="upload"
              >
                Enregistrer{" "}
                {(content && content.is_schema) || is_schema
                  ? "Schéma"
                  : "sContenu"}
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default ContentEdition;
