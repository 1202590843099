import React, { useEffect, useMemo, useState,useContext } from 'react';


import { Link } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";

function Meet() {

const [status,setStatus]=useState();

const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);




  return (
    <div className="App"> 
      <div className='flex flex-row'> 
      <Link to="/"> <FontAwesomeIcon  icon={fa.faArrowLeft} className='mx-4'></FontAwesomeIcon> </Link> 
       
       Rendez-vous</div>
      
        <p>
     </p>
      <div className='flex flex-col text-xs'>

     
     </div>

        
    
   
    </div>
  );
}

export default Meet;
