
import InputData from '../inputs/InputData';




function TemplateInput({ template, setInternal,
    content,
    id,
    element,
    className,
    style,
    onClick,
    
domain,
    safeDisplay,
  self
  }) {





    return   <div 
    id={id}
    className={ className  }
    onClick={onClick}
    >
            <InputData   
placeholder={  template.schema_prop }
prop={   content[ template.schema_prop +"_input_prop" ] ??
  {  name:template.schema_prop  ?? template.schema_label ?? "x" ,
    schema_id: template.schema_id, type_nombre:  template.isList ?  "I"  :null  }  }
      domain={domain}
  defaultValue={element ??  content[ template.schema_prop+".content_id"] ??  content[ template.schema_label+".content_id"] }
  value={element ??  content[ template.schema_prop+".content_id"] ??  content[ template.schema_label+".content_id"] }
  className={ className  }
  onChange={ (value)=>{
    if(self && template.schema_prop) {

      var obj= content[ self] ?? {} 
      obj[ template.schema_prop]=value;
      setInternal(self,obj)
    }
else
   if(template.schema_prop) setInternal(template.schema_prop,value) 
else
   if(template.schema_label)setInternal(template.schema_label,value) 


}}
  schema={  content[ (template.schema_prop??template.schema_label ??"") +"_input_schema" ] ?? { content:{code:"text"}} }
   
     element={element}
    
     style={style}
   
   /></div>


}

export default TemplateInput;
