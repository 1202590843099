import { useEffect } from "react";



const InputText = ({id,label,onChange,value,className,infos,context}) => {
const blur= ()=>{



}
useEffect(()=>{
 var obj = document.getElementById(id ?? "input_"+label)
 if(obj.value!==(value??""))obj.value=(value??"")

},[value,id,label])



if(context?.minimized)
    return (
    <>
    <div className={"relative " +className } >
    <input type="text" id={id ?? "input_"+label} onBlur={blur}  onChange={(e)=>onChange(e.target.value)}   defaultValue={value}    className="block p-1 w-fit text-sm text-gray-900 bg-transparent  border-b border-b-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />

</div> 

</> 
)


return (
    <>
    <div className={"relative " +className } >
    <input type="text" id={id ?? "input_"+label} onBlur={blur}  onChange={(e)=>onChange(e.target.value)}   defaultValue={value}    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
    <label htmlFor={ id ?? "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
    {infos &&  <div className="italic w-full text-xs p-1 text-left whitespace-pre-line">{infos}</div>}
</div> 

</> 
)
}
export default InputText




