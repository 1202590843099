import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect ,useRef} from "react";



const InputDomain = ({id,label,onChange,value,className,infos,context}) => {
const blur= ()=>{



}

const getWidth =(target)=>{
      // Créer un span temporaire pour mesurer la largeur du texte
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.whiteSpace = 'pre';
      span.style.fontSize = getComputedStyle(target).fontSize;
      span.style.fontFamily = getComputedStyle(target).fontFamily;
      span.innerText = target.value || target.placeholder;
      
      document.body.appendChild(span);
      const textWidth = span.offsetWidth;
      document.body.removeChild(span);
      
      // Ajuster la largeur de l'input
   return Math.max(32,textWidth);
}




 const inputRef = useRef(null);
  
    useEffect(() => {
      if (context?.minimized && inputRef.current) {

        inputRef.current.style.width = `${getWidth ( inputRef.current) }px`;
        console.log("resizing " +  inputRef.current.style.width )
      }
    }, [value,context]);
  


useEffect(()=>{
 var obj = document.getElementById(id ?? "input_"+label)
 if(obj.value!==(value??""))obj.value=(value??"")

},[value,id,label])


if(context?.minimized)
return (
    <>
    <div className={"inline-flex   items-center justify-center text-xs " +className } >
    <FontAwesomeIcon icon={faLink}  className="p-2 m-0 "  onClick={()=>{

if(value) window.open(`https://${value}`)
}}  />
    <input ref={inputRef} type="text" id={id ?? "input_"+label} onBlur={blur}  onChange={(e)=>onChange(e.target.value)}   defaultValue={value}    className="mx-1 text-sm text-gray-900 bg-transparent  border-b border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
   

</div> 

</> 
)

    return (
    <>
    <div className={"relative flex items-center justify-center " +className } >
    <input type="text" id={id ?? "input_"+label} onBlur={blur}  onChange={(e)=>onChange(e.target.value)}   defaultValue={value}    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
    <FontAwesomeIcon icon={faLink}  className="p-2 m-2 "  onClick={()=>{

        if(value) window.open(`https:\\${value}`)
    }}  />
    <label htmlFor={ id ?? "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
    {infos &&  <div className="italic w-full text-xs p-1 text-left whitespace-pre-line">{infos}</div>}
</div> 

</> 
)
}
export default InputDomain




