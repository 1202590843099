

const asyncLoadCompleteContent = async (content, project,getContentActivated) => {
    var keys = Object.keys(content.content);
    var contents = { content_id: content.content_id, Titre: content.title,activated:true };
    for (var i = 0; i < keys.length; i++) {
      if (
        typeof content.content[keys[i]] === "object" &&
        content.content[keys[i]]?.content_id
      ) {
        var _c = await getContentActivated(  parseInt(content.content[keys[i]].content_id));

         if(_c) {
          contents[keys[i]] = _c;
          // Ajout des sous-proprietes
          var sub_keys = Object.keys(_c.content);
          sub_keys.forEach((sk) => {
            // Pour l'instant uniquement les objets
            if (typeof _c.content[sk] !== "object")
              contents[keys[i] + "." + sk] = _c.content[sk];
          });
        }
      }
      }
      if (Array.isArray(content.content[keys[i]])) {
        contents[keys[i]] = content.content[keys[i]];
        for (var j = 0; j < content.content[keys[i]].length; j++) {
          var value = content.content[keys[i]][j];
          if (typeof value === "object" && value?.content_id) {
            contents[keys[i]][j] = await  getContentActivated(  parseInt(value.content_id));
  
           
           
          }
        }
      }
      return contents
    }




export { asyncLoadCompleteContent}