import React, { useEffect, useMemo, useState,useContext } from 'react';


import { Link,  useNavigate } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa0, faBorderNone, faCheck } from '@fortawesome/free-solid-svg-icons';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";


function LandingPage() {
  const navigate = useNavigate();
const [showHowToInstalliOS,setShowHowToInstalliOS]=useState(false);
const [connectWindow,setConnectWindow]=useState(null)


const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);




  return (
<>
<div  className='safearea z-10 flex md:flex-row flex-col text-white w-full md:min-h-screen items-center justify-center md:-mt-16 ' >
  <div  className=' flex flex-col p-4 w-1/2 md:items-end  items-center justify-center ' >
   <div className='flex-flex-col w-96  '>
    <h1 className='md:text-6xl  text-4xl font-extrabold p-4'> App Is You</h1>
    <p className='p-6'>L'app c'est vous !  <br/>
      Créez une app desktop/mobile qui vous ressemble, pour être directement joignable par vos clients.
      Avec toutes les fonctionnnalités d'un réseau social, chat, visio, réel.
    </p>
    </div>
  </div>
  <div   className='flex flex-col p-4 w-1/2 md:items-end items-center overflow-hidden' >
  <img src={"/images/laptop.png"} className='md:-mr-28' />
  </div>



    </div>
<div className='flex md:flex-row flex-col w-full  md:-mt-24   md:items-start items-center justify-center mb-32 px-6' >

<div className=' bg-white p-6 flex flex-col text-zinc-900 m-1 md:w-96 w-full justify-between'>
  <h1  className='md:text-4xl text-3xl font-extrabold p-4' >Starter Pack</h1>
  <span> <span  className='text-2xl font-extrabold p-1'>5.90€</span>/ mois </span>
  <div className='flex flex-col  items-start justify-start p-2 text-sm w-full'>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Une application personnalisable</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Jusquà 500 clients</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Chat, Visio </span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Gestion de prise de rendez-vous</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Blog</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Notifications</span>

    <div className='p-2 py-2 pt-2 bg-white  sticky bottom-0 top-0 w-full'>
     <a href={"https://buy.stripe.com/4gw6r0e088u4gWA6oo"}><button className='mt-10 p-4 text-white bg-blue-700 sticky  w-full' >Démarrer</button></a>
      </div>
        </div>

</div>

<div className=' bg-white p-6 flex flex-col text-zinc-900 m-1 md:w-96 w-full justify-between'>
  <h1  className='md:text-4xl text-3xl f font-extrabold p-4' >Pro Pack</h1>
  <span> <span  className='text-2xl font-extrabold p-1'>19.90€</span>/ mois </span>
  <div className='flex flex-col  items-start justify-start p-2 text-sm w-full'>
    <span className='p-2 text-left'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Jusqu'à 10 applications personnalisables</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Jusqu'à 10000 clients</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Chat, Visio, Live</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Gestion de prise de rendez-vous</span> 
    <span  className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Paiement en ligne</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Blog,Forum, Boutique en ligne</span>

    <div className='p-2 py-2 pt-2 bg-white  sticky bottom-0 top-0 w-full'>
    <a href={"https://buy.stripe.com/cN23eOf4caCc7m0cMN"}>  <button className='mt-10 p-4 text-white bg-blue-700 sticky  w-full' >Démarrer</button></a>
      </div>
        </div>

</div>

<div className=' bg-white p-6 flex flex-col text-zinc-900 m-1 md:w-96 w-full  justify-between relative'>
  <h1  className='md:text-4xl text-3xl f font-extrabold p-4' >Enterprise Pack</h1>
  <span> <span  className='text-2xl font-extrabold p-1'>99.90€</span>/ mois </span>

  <div className='flex flex-col p-2  items-start justify-start text-sm relative w-full  h-5/6'>
    <span className='p-2 text-left'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> applications personnalisables illimitées</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> clients illimité</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Chat, Visio, Live</span>
    <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Gestion de prise de rendez-vous</span> 
    <span  className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Paiement en ligne</span>
     <span className='p-2'><FontAwesomeIcon icon={faCheck}  ></FontAwesomeIcon> Notifications</span>
    <div className='p-2 py-2 pt-2 bg-white  sticky bottom-0 top-0 w-full'>
     <a href={"https://buy.stripe.com/7sI9Dcf4caCc0XC3ce"}> <button className='mt-10 p-4 text-white bg-blue-700 sticky  w-full' >Démarrer</button></a>
      </div>
      </div>

</div>
</div>



    <div  className='  flex flex-row bg-black text-white w-full p-4 h-28' ></div>
  </>  
  );
}

export default LandingPage;
