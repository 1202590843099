import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useParams,useSearchParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputText from "../../../../components/inputs/InputText";
import InputSelect from "../../../../components/inputs/InputSelect";

import "../../../../App.css";

import InputBoolean from "../../../../components/inputs/InputBoolean";
import InputIcon from "../../../../components/inputs/InputIcon";
import TemplateEdition from "../../../../components/edition/TemplateEdition";
import ContentEdition from "../../../../components/edition/ContentEdition";
import TemplateTree from "../../../../components/templates/TemplateTree";
import { getProps, loadTemplate, structureClean, structurePutIn, structureReplace } from "../../../../tools/tpl";
import Loader from "../../../../components/Loader";
import { MfctContext } from "../../../../context";
import { data } from "../../../../service/data";
import { setRecord } from "../../../../service/localdata";
import { parse } from "@fortawesome/fontawesome-svg-core";
import VarContent from "../../../../components/VarContent";



//var backupTemplate 
function PopupPageEditor() {



  const {page_id} =useParams()



  const [baseContent, setBaseContent] = useState({
    "App.Name": "...",
  });
  const [page, setPage] = useState();

  const [app, setApp] = useState();




  const [contents, setContents] = useState([]);
  const [templates, setTemplates] = useState( []);
  const [selectedBloc, setSelectedBloc] = useState();
  const [selected, setSelected] = useState();
  const [pages, setPages] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState();

  const { project,cache,authUser,getSchemas} = useContext(MfctContext);
  const [schemas,setSchemas]= useState([]);
  const [dragged,setDragged]= useState()
 const [dragOverZone,setDragOverZone] = useState();  

  const [explodeTree, setExplodeTree] = useState();
  const [showVariables, setShowVariables] = useState();
  



  const [saveAll, setSaveAll] = useState({})
  const [loading, setLoading] = useState(false);


  useEffect(()=>{

    const dataInit=async()=>{
    
      await data.init("page", page_id,true,project, {setApp,setPage,setTemplates,setContents ,setUser,setSaveAll ,setSchemas},{session_id:"S"+ authUser.user_id},true)
    
      // Recuperer la page * 
      let _page = await data.getPage(page_id)
      document.title = "⚙PGE " + _page.title;
      // Recuperer l'app 
      let _app = await data.getApp(_page.app_id)  

      document.title = "⚙PGE " + _page.title + (_app.name ? " -  " + _app.name :"");
      // Recuperer les templates
     let _templates = await data.getTemplates()
      // Recuperer les contents 
     let _contents = await data.getContents()
      // Recuperer les schemas 
      let _schemas = await data.getSchemasByApp(_app.app_id)
  
      
    }
      
    dataInit();
    return async ()=>{
      await data.close()
    }
    
    }, [])



    const replaceBloc = (pos) => {


 var i = parseInt( dragged.id.split("_")[1])
      //if(  dragged) window.alert("addChildrenAfter")
  var body = [ ...page.content.body.filter( (_,index) => index<pos && i !== index  ),
    page.content.body[i],
    ...page.content.body.filter( (_,index) => index>=pos && i !== index  ),
            ]; 
  
      data.updatePage({ ...page,content : { ...page.content, body} });

      setDragged()
      setDragOverZone()
    };
  

const cancelling =async ()=>{


     data.cancelPage(page)
    
      
      }
      
  const saving =async ()=>{
    setLoading(true);
      await data.saveAll()
          setLoading(false);
          toast( "Successfully saved '" );
        }
        
      



const _setDragged=(obj,template_id,id)=>{


  setDragged(obj)
}


const [propsTemplates,setPropsTemplates] = useState()




useEffect(()=>{


const   computeProps = async()=>{
  if(!page) return 
console.log("Recalculating props...")

var tpl_ids = []

if( page.content.header && parseInt("0" + page.content.header)>0) tpl_ids.push(parseInt(page.content.header))
if(  page.content.footer && parseInt("0" + page.content.footer)>0) tpl_ids.push(parseInt(page.content.footer))
 page.content.body.forEach(body=>{if(body.template_id  && parseInt("0" +body.template_id)>0) tpl_ids.push(parseInt(body.template_id )) })

var _propsTemplates ={}
 for(var i=0; i<tpl_ids.length; i++){
  console.log("getting  template " + tpl_ids[i])
var _template = await loadTemplate( tpl_ids[i],templates,project)
console.log(_template)
_propsTemplates[tpl_ids[i]]= await getProps(_template.structure,cache ,templates,project,tpl_ids[i]);
 }
console.log("_propsTemplates",_propsTemplates )
 setPropsTemplates(_propsTemplates)
}


computeProps()

},[page])


const addTemplateContent =async (template_id,onChange)=>{


var content =  await project.api("/set_content", { body: {
  title : "Data Bloc Template - " + template_id,
  template_id , app_id :page.app_id,content:{}
} });
await setRecord(  content,"content")
await data.updateContent(content)
onChange(content.content_id);

}


const dragging= ( pos)=>{


  if(pos.end)
  {   
  
window.alert("Please")
setDragged()

     // C'est fini


  }

}




  const setInternal = (key, value) => {

  };
  

  const cancelPage = () => {

  };






  const [explode, setExplode] = useState();

  const [tabShow, setTabShow] = useState("template");



  const cleanStructure = (structure,id) => {


if("children" in structure)  structure.children =  structure.children.filter(child=>{
  
  return !child.dragged} ).map((child,i) =>{


return  cleanStructure(child,id + "." + i);
}) 
 
return structure

  }

  const selectTpl = (id, tpl,noEmit) => {
  

    if (tpl) {


      /****     RQajouter dans le receive message */
      if (tpl.schema_label) {
        var obj2 = document.getElementById(tpl.schema_label + "-schema_label");
        if (obj2) {
          console.log("Scroll to", obj2.id, obj2);
          obj2.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          obj2.focus();
        }
      }
      setSelectedTemplate(tpl)
    }

setSelectedBloc(  id.split(".")[0]+"-edit"  )
setSelected(id)

 if(!noEmit)   data.emit({ user:{  selected: id,   selectedBloc: id.split(".")[0] + "-edit",selectedTemplate: tpl}})
  };
  const setUser = async(user)=>{

    if(user.session_id==="S"+ authUser.user_id)
{

  if(   user.selected) selectTpl(user.selected,user.selectedTemplate,true)

}

  }

const addBloc= (pos)=>{




    data.updatePage({
      ...page,
      content: {
        ...page.content,
        body: [
          ...page.content.body.filter((c,i)=>i<pos),
          { template_id: 0 },
          ...page.content.body.filter((c,i)=>i>=pos),
        ],
      },
    });

    setTimeout(()=> { selectTpl("bloc_"+ pos);  setSelectedBloc("bloc_"+ pos + "-edit")},500);
}
const footerTemplates = useMemo(() => {
  if (templates.length === 0 || !page) return [];

  return templates
    .filter(
      (t) =>
      t.layout==="bottom"  &&

        (!t.app_id ||
          t.app_id === page.app_id )
    )
    .map((t) => {
      return {
        value: t.template_id + "",
        label: t.title + (t.app_id ? "*" : ""),
      };
    });
}, [templates, page]);

const headerTemplates = useMemo(() => {
  if (templates.length === 0 || !page) return [];

  return templates
    .filter(
      (t) =>
      t.layout==="top"  &&
  
        (!t.app_id ||
          t.app_id === page.app_id )
    )
    .map((t) => {
      return {
        value: t.template_id + "",
        label: t.title + (t.app_id ? "*" : ""),
      };
    });
}, [templates, page]);

const contentTemplates = useMemo(() => {
  if (templates.length === 0 || !page) return [];

  return templates
    .filter(
      (t) =>
      t.layout==="bloc"  &&
        (!t.app_id ||
          t.app_id === page.app_id )
    )
    .map((t) => {
      return { value: t.template_id, label: t.title + (t.app_id ? "*" : "") };
    });
}, [templates, page]);


  const navigate = useNavigate();

const [ draggedZone,setDraggedZone]=useState()

  return (
    <div className="App  text-zinc-600 text-sm mb-10">
 { loading && <Loader />   }
 {(!page ||  !headerTemplates ||
        !footerTemplates ||
        !contentTemplates ) && 
        
        
        <div>{ JSON.stringify(page)} </div>
        }
      {
        page &&
        headerTemplates &&
        footerTemplates &&
        contentTemplates && (
          <header className=" ">
            <div className="flex flex-col w-screen">
            <div className="p-8  w-full  flex justify-end z-50">
                </div>

              <div className="w-full flex flex-row    bg-zinc-100 rounded-t-xl  z-50 px-2 ">
                <div
                  id="page-edit"
                  className={
                    " flex border-x-2 p-2 border-zinc-600 my-2  flex-col hidden "
                  }
                >
                  <div
                    className="font-bold p-1 text-xl text-left w-full sticky top-0  "
                    onClick={() => {
                      setSelectedBloc();
                      setExplode();
                    }}
                  >
                    Page
                  </div>
                  {!selectedBloc && (
                    <>
                      <div className=" flex w-full flex-row items-center ">
                        <div className=" flex    gap-2  flex-col grow">
                          <InputText
                            label={"Titre"}
                            className={" my-2 w-full"}
                            value={page.title}
                            onChange={(value) => {
                              data.updatePage({ ...page, title: value });
                            }}
                          />

                          <InputText
                            label={"Type"}
                            className={" my-2 w-full"}
                            value={page.type}
                            onChange={(value) => {
                              data.updatePage({ ...page, type: value });
                            }}
                          />
                        </div>
                        <FontAwesomeIcon
                          icon={fa.faDownLong}
                          className="mx-4 text-green-700 "
                          onClick={() => setExplode(!explode)}
                        ></FontAwesomeIcon>
                      </div>

                      <div className="w-full flex    gap-2  flex-col grow">
                        <InputText
                          label={"Label"}
                          className={" my-2 w-full"}
                          value={page.content.label}
                          onChange={(value) => {
                            data.updatePage({
                              ...page,
                              content: { ...page.content, label: value },
                            });
                          }}
                        />
                        <InputText
                          label={"Slug"}
                          className={" my-2 w-full"}
                          value={page.slug}
                          onChange={(value) => {
                            data.updatePage({ ...page, slug: value });
                          }}
                        />
                        <InputBoolean
                          labelTrue={"Authentification requise"}
                          labelFalse={"Authentification non requise"}
                          className={" my-2 w-full text-black"}
                          value={page.is_auth}
                          onChange={(value) => {
                            data.updatePage({ ...page, is_auth: value });
                          }}
                        />
                        <InputIcon
                          label={"Icone"}
                          className={" my-2 "}
                          value={page.content.icone ?? "faQuestion"}
                          onChange={(value) => {
                            data.updatePage({
                              ...page,
                              content: { ...page.content, icone: value },
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={
                    "  border-x-2 p-2 border-zinc-600 my-2    h-screen overflow-y-auto relative pb-12"
                  }
                >
                 
                  <div className={" flex   flex-col items-center grow  relative  "}   >
                  <div className="font-bold p-1 text-xl text-left w-full sticky top-0  ">
                    Tree   {draggedZone    }  {dragged?.id} {dragOverZone}
                  </div>
                    <TemplateTree
                      selectTpl={selectTpl}
                      id={"header"}
                      hue={0 }
                      explodeTree={explodeTree}
                      setDraggedZone={setDraggedZone}
                      setDragOverZone={setDragOverZone}
                      dragOverZone={dragOverZone}
                      draggedZone={draggedZone}
                      schemas={schemas}
                      selected={selected}
                      template={
(
                        templates.find(
                          (t) =>
                            t.template_id + "" === "" + page.content.header
                        ))?.structure ?? {}
                      }
                
                      setSelected={selectTpl}
                      setStructure={  (structure,parentStructure,dropped) =>{
                        if(dropped) structure = cleanStructure(structure,"header");
                        data.updateTemplate(
                         { ...templates.find((t) =>   t.template_id ===parseInt( page.content.header)),   structure} )
                            }}
                        dragged={dragged}
                        setDragged={(_id)=>_setDragged(_id, page.content.header,"header" )}
                        dragging={dragging }
                    />
{    

<div className="text-center w-full flex items-center transition-all duration-500 justify-center rounded-full font-bold opacity-100   text-white overflow-hidden "
  style={{
  height:(selected==="header" ||  selected==="bloc_0"  ) && !dragged? "40px":"0px"
  }}
   onClick={()=>addBloc(0)}
  >
 <FontAwesomeIcon icon={fa.faPlus}
 
 className={   "  p-2 text-white   rounded-full "  }
                 
 style={{ backgroundColor: "hsl(0 50% 50%)" ,  pointerEvents: 'none'}}/> 
   </div> }
   {
                 // Zone de drop 
                   dragged &&    dragged.id!=="bloc_0"  &&
              dragged.id.split("-").length===1   
               && (
                  <div  className={"flex w-full transition-all duration-500 items-center justify-center overflow-hidden  "     }
                   style={{ backgroundColor:draggedZone==="bloc_0.addChildren" ?"hsl(0 50% 50%)" :"transparent",
                   height:  "bloc_0"===dragOverZone     ?( draggedZone==="bloc_0.addChildren" ?"120px" :"40px"   ) :"0px" ,}} 
                   onDragEnter={()=>setDraggedZone("bloc_0.addChildren")}
                   onDragLeave={()=>{  setTimeout( ()=>{ if("bloc_0.addChildren"===draggedZone) setDraggedZone()   }  ,200)   }}
                   onDrop={(e)=>{ e.preventDefault();replaceBloc(0);}}
                   onDragOver={(e) =>{  e.preventDefault();  }}
                   ><FontAwesomeIcon
                    icon={fa.faArrowsUpDownLeftRight}
                    className={   "  p-2 text-white   rounded-full "  }
                 
                    style={{ backgroundColor: "hsl(0 50% 50%)" ,  pointerEvents: 'none'}}
                  
                  /></div>
                )}   
                    {page.content.body.map((body, i) => (
                      <div className="flex flex-col w-full items-center" key={i}>
                        <TemplateTree
                          selectTpl={selectTpl}
                          id={"bloc_" + i}
                          
                          setDraggedZone={setDraggedZone}
                          draggedZone={draggedZone}
                          setDragOverZone={setDragOverZone}
                          dragOverZone={dragOverZone}
                          explodeTree={explodeTree}
                          schemas={schemas}
                          selected={selected}
                          hue={0 }
                          template={
(
                   
                          templates.find(
                              (t) =>
                             parseInt( t.template_id) === parseInt(  body.template_id)
                            ))?.structure ?? {}
                          }
                          setSelected={selectTpl}
                          setStructure={  (structure,parentStructure,dropped) =>{
                            if(dropped) structure = cleanStructure(structure,"bloc_" + i);
                            data.updateTemplate(
                             { ...templates.find((t) =>   t.template_id ===parseInt( body.template_id)),   structure} )
                                }}

                            dragged={dragged}
                            setDragged={(_id)=>_setDragged(_id, body.template_id,"bloc_" + i)}
                            dragging={dragging }
                        />{    
                        
            
                          <div className="text-center w-full flex items-center transition-all duration-500 justify-center rounded-full font-bold opacity-100   text-white overflow-hidden "
                          style={{
                          height:  ((selected==="footer" && i=== page.content.body.length-1 ) ||  selected==="bloc_"+i ||   selected==="bloc_"+(i+1)) && !dragged? "40px":"0px"
                          }}
                          onClick={()=>addBloc(i+1)}>
                        <FontAwesomeIcon icon={fa.faPlus}
 
 className={   "  p-2 text-white   rounded-full "  }
                 
 style={{ backgroundColor: "hsl(0 50% 50%)" ,  pointerEvents: 'none'}}/> 
                             </div> }
                             {
                 // Zone de drop 
                   dragged &&   (dragged.id !== "bloc_"+ (i+1) )  &&   (dragged.id !== "bloc_"+ (i) ) &&
              dragged.id.split("-").length===1   
               && (
                  <div  className={"flex w-full transition-all duration-500 items-center justify-center overflow-hidden  "     }
                   style={{ backgroundColor:draggedZone==="bloc_"+(i+1)+".addChildren" ?"hsl(0 50% 50%)" :"transparent",
                   height:  "bloc_"+(i+1)===dragOverZone  ||   "bloc_"+(i)===dragOverZone  ?( draggedZone==="bloc_"+(i+1)+".addChildren" ?"120px" :"40px"   ) :"0px" ,}} 
                   onDragEnter={()=>setDraggedZone("bloc_"+(i+1)+".addChildren")}
                   onDragLeave={()=>{  setTimeout( ()=>{ if("bloc_"+(i+1)+".addChildren"===draggedZone) setDraggedZone()   }  ,200)   }}
                   onDrop={(e)=>{ e.preventDefault();replaceBloc(i+1);}}
                   onDragOver={(e) =>{  e.preventDefault();  }}
                   ><FontAwesomeIcon
                    icon={fa.faArrowsUpDownLeftRight}
                    className={   "  p-2 text-white   rounded-full "  }
                 
                    style={{ backgroundColor: "hsl(0 50% 50%)" ,  pointerEvents: 'none'}}
                  
                  /></div>
                )}   



                      </div>
                    ))}


                    <TemplateTree
                      selectTpl={selectTpl}
                      id={"footer"}
                      hue={0 }
                      schemas={schemas}
                      selected={selected}
                      setDraggedZone={setDraggedZone}
                      draggedZone={draggedZone}
                      setDragOverZone={setDragOverZone}
                      dragOverZone={dragOverZone}
                      template={
(
                   

                        templates.find(
                          (t) =>
                            t.template_id + "" === "" + page.content.footer
                        ))?.structure ?? {}
                      }
                      setSelected={selectTpl}
                      setStructure={  (structure,parentStructure,dropped) =>{
                        if(dropped) structure = cleanStructure(structure,"footer");
                        data.updateTemplate(
                         { ...templates.find((t) =>   t.template_id ===parseInt( page.content.footer)),   structure} )
                            }}
                        dragged={dragged}
                        setDragged={(_id)=>_setDragged(_id, page.content.footer,"footer" )}
                        dragging={dragging }
                    />


                  </div>
                </div>

                <div className="flex flex-col  items-start justify-start" style={{maxWidth:"500px"}}>
                  {true && (
                    <div
                      id="header-edit"
                      className={
                        " flex border-x-2 p-2 border-zinc-600 my-2  flex-col  relative" +
                        (selectedBloc === "header-edit"
                          ? " bg-slate-200 "
                          : "") + 
                          ( selectedBloc !== "header-edit" && " hidden")
                      }
                      onClick={() => {
                        if(selectedBloc !== "header-edit" ){
                        setSelectedBloc("header-edit");
                
                        }
                      }}
                    >
                      <div className="font-bold p-1 text-xl text-left  sticky top-0  ">
                        Header
                      </div>
                      {selectedBloc === "header-edit" && (
                        <>
                          <div className=" flex ">
                            <div className="flex flex-col w-1/2 border-r border-black p-2">
                              <div className="flex -flex-row w-full">
                             
                                <InputSelect
                                  className={" my-2 w-full"}
                                  label={"header"}
                                  options={headerTemplates}
                                  value={page.content.header}
                                  trueValue={true}
                                  placeholder={"Choisis ton entête de page"}
                                  onChange={(value) => {
                                    data.updatePage({
                                      ...page,
                                      content: {
                                        ...page.content,
                                        header: value,
                                      },
                                    });
                                  }}
                                />
                                <a
                                  href={`/template/${page.content.header}`}

                                  className="p-2 text-xl"
                                  target="_blank"
                                  rel="noreferrer"
                                  
                                >
                                  <FontAwesomeIcon icon={fa.faEdit} />
                                </a>
                              </div>
{ parseInt(page.content.header)>0 &&
                              <TemplateEdition
                                id={"header"}
                                simulatedContent={baseContent}
                                key={page.content.header +"head"}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={selected}
                                template_id={page.content.header}
                                liveTemplate={  templates.find((t) =>
                                  t.template_id ===page.content.header)  }
                                setSelected={selectTpl}
                                updateLiveTemplate={(updatedTemplate) =>
                                  data.updateTemplate( updatedTemplate )
                                  
                                }
                                templates={templates}
                         
                              />}
                            </div>
                           {
                           page.content.header && (propsTemplates && propsTemplates[parseInt(page.content.header)]) && propsTemplates[parseInt(page.content.header)].length>0 && 
                           
                           <div className="flex flex-col  w-1/2 p-2">
                         
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Data Header"}
                                  options={contents.filter(c=> parseInt(c.template_id)===parseInt(page.content.header) || c.content_id+""===""+page.content.headerContent_id).map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.title,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={page.content.headerContent_id}
                                  onChange={(headerContent_id) => {
                                    data.updatePage({
                                      ...page,
                                      content: {
                                        ...page.content,
                                        headerContent_id,
                                      },
                                    });
                                  }}
                                />

                                {page.content.headerContent_id && (
                                 
                                    <FontAwesomeIcon  onClick={() => {
                                      data.updatePage({
                                        ...page,
                                        content: {
                                          ...page.content,
                                          headerContent_id:null,
                                        },
                                      });
                                    }}  className="p-2 text-xl" icon={fa.faClose} />
                                 
                                )}
                                {!page.content.headerContent_id && (

                                    <FontAwesomeIcon
                                    onClick={ ()=>addTemplateContent(page.content.header, (headerContent_id) => {
                                      data.updatePage({
                                        ...page,
                                        content: {
                                          ...page.content,
                                          headerContent_id,
                                        },
                                      });
                                    } )}
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                           
                                )}
                              </div>

                              {parseInt(page.content.headerContent_id)>0 && (
                                <ContentEdition
                                key={ page.content.headerContent_id}
                                defaultContent={ contents.find(c=>parseInt(c.content_id)===parseInt(page.content.headerContent_id))}
                             
                                  defaultApp={data.app}
                                  dataOnly={true}
                                  content_id={
                                    page.content.headerContent_id
                                  }
                                  updateLiveContent={data.updateContent}
                                _schemas={schemas}
                                  data_schema={propsTemplates[parseInt(page.content.header)]}
                                />
                              )}
                            </div>


                      }
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {page.content.body.map((body, i) => (
                    <div
                      key={i}
                      id={"bloc_" + i + "-edit"}
                      className={
                        " grow flex   flex-col  items-start " +
                        (selectedBloc === "bloc_" + i + "-edit"
                          ? " bg-slate-200 "
                          : "") + 
                         ( selectedBloc !== "bloc_" + i + "-edit" && " hidden")
                      }
                      onClick={() => {
                        if(selectedBloc !== "bloc_" + i + "-edit"){
                     
                        setSelectedBloc("bloc_" + i + "-edit");
                        }
                      }}
                    >
                      <div className="font-bold p-1 text-xl text-left flex justify-between w-full">
                      <span>Bloc {i}</span>
                      <FontAwesomeIcon className=" bg-red-400 text-white p-2 h-4 w-4 rounded-full "     icon={fa.faMinus} 
                      
                      onClick={  ()=>{
                        data.updatePage({
                          ...page,
                          content: { ...page.content, body: page.content.body.filter((c,index)=>i!==index ) },
                        })
                      } }
                      />   
                      </div>

                      {selectedBloc === "bloc_" + i + "-edit" && (
                        <div className=" flex  flex-col  border-x-2 p-2 border-zinc-600 my-2 w-full ">
                          <InputText
                            className={"mt-2 "}
                            label={"label"}
                            value={body.label}
                            onChange={(label) => {
                              var _body = [...page.content.body];
                              _body[i].label = label;

                              data.updatePage({
                                ...page,
                                content: { ...page.content, body: _body },
                              });
                            }}
                          />

                          <div className="flex ">
                            <div className="flex flex-col w-1/2">
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={" mt-2 w-full"}
                                  label={"Bloc " + (i + 1)}
                                  options={contentTemplates}
                                  placeholder={"Choisir Template"}
                                  value={body.template_id}
                                  onChange={(value) => {
                                    var _body = [...page.content.body];
                                    if (value !== "-- Supprimer --") {
                                      _body[i].template_id = value;
                                    } else {
                                      _body = _body.filter(
                                        (b, idx) => i !== idx
                                      );
                                    }
                                    data.updatePage({
                                      ...page,
                                      content: {
                                        ...page.content,
                                        body: _body,
                                      },
                                    });
                                  }}
                                />

                                <a
                                  href={`/template/${body.template_id}`}
                                  className="p-2 text-xl"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <FontAwesomeIcon icon={fa.faEdit} />
                                </a>
                              </div>
{   parseInt( body.template_id)>0 &&
                              <TemplateEdition
                                id={"bloc_" + i}
                                key={body.template_id + "_" + i}
                                simulatedContent={baseContent}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={selected}
                                template_id={body.template_id}
                                setSelected={selectTpl}
                                liveTemplate={  templates.find((t) =>
                                  t.template_id ===body.template_id)  }
                                updateLiveTemplate={(updatedTemplate) =>
                                  data.updateTemplate( updatedTemplate  )
                                  
                                }
                                templates={templates}
                              />}
                            </div>
                            {body.template_id && (propsTemplates && propsTemplates[parseInt(body.template_id)]) &&  propsTemplates[parseInt(body.template_id)].length>0 &&
                            <div className="flex flex-col w-1/2">
        
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Contenu de Données " + (i + 1)}
                                  options={contents.filter(c=> parseInt(c.template_id)===parseInt(body.template_id)  || c.content_id+""===""+body.content_id).map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.title ,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={body.content_id}
                                  onChange={(value) => {
                                    var _body = [...page.content.body];
                                    _body[i].content_id = value;
                                    data.updatePage({
                                      ...page,
                                      content: {
                                        ...page.content,
                                        body: _body,
                                      },
                                    });
                                  }}
                                />
                                {body.content_id && (

                                                                      <FontAwesomeIcon  onClick={() =>{
                                                                        var _body = [...page.content.body];
                                                                        _body[i].content_id = null;
                                                                        data.updatePage({
                                                                          ...page,
                                                                          content: {
                                                                            ...page.content,
                                                                            body: _body,
                                                                          },
                                                                        });
                                                                      }}  className="p-2 text-xl" icon={fa.faClose} />
                                )}
                                {!body.content_id && (
                                 
                                    <FontAwesomeIcon
                                    onClick={ ()=>addTemplateContent(body.template_id,
                                      (value) => {
                                        var _body = [...page.content.body];
                                        _body[i].content_id = value;
                                        data.updatePage({
                                          ...page,
                                          content: {
                                            ...page.content,
                                            body: _body,
                                          },
                                        });
                                      }
                                    )}
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                                 
                                )}
                              </div>

                              {parseInt(body.content_id)>0 && (
                                <ContentEdition
                                key={ body.content_id}
                                defaultContent={ contents.find(c=>parseInt(c.content_id)===parseInt(body.content_id))}
                                _schemas={schemas}
                                dataOnly={true}
                                  defaultApp={data.app}
                                  content_id={body.content_id}
                                  updateLiveContent={data.updateContent}
                            
                                  data_schema={propsTemplates[parseInt(body.template_id)]}
                                />
                              )}
                            </div>
}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  {true && (
                    <>

                      <div
                        id={"footer-edit"}
                        className={
                          " flex  border-x-2 p-2 border-zinc-600 my-2 flex-col w-full" +
                          (selectedBloc === "footer-edit"
                            ? " bg-slate-200 "
                            : "")+ 
                            ( selectedBloc !== "footer-edit" && " hidden")
                        }
                        onClick={() => {
                          if(selectedBloc !== "footer-edit"){
                          
                          setSelectedBloc("footer-edit");
                          }
                        }}
                      >
                        <div className="font-bold p-1 text-xl text-left w-full">
                          Footer
                        </div>

                        {selectedBloc === "footer-edit" && (
                          <>
                            <div className=" flex ">
                            <div className="flex flex-col">
                            {(
                              <div className="flex flex-row w-full">
                                <InputSelect
                                  className={" my-2 w-full"}
                                  label={"footer"}
                                  options={footerTemplates}
                                 value={page.content.footer}
                                  trueValue={true}
                                  placeholder={"Choisis ton pied de page"}
                                  onChange={(value) => {
                                    data.updatePage({
                                      ...page,
                                      content: {
                                        ...page.content,
                                        footer: value,
                                      },
                                    });
                                  }}
                                />
                                <a
                                  href={`/template/${page.content.footer}`}
                                  className="p-2 text-xl"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <FontAwesomeIcon icon={fa.faEdit} />
                                </a>
                              </div>
                            )}
                 
                            {
                              parseInt(page.content.footer)>0 &&   <TemplateEdition
                                id={"footer"}
                                key={page.content.header +"foot"}
                                simulatedContent={baseContent}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={selected}
                                template_id={page.content.footer}
                                setSelected={selectTpl}
                                liveTemplate={  templates.find((t) =>
                                  t.template_id ===page.content.footer)  }
                                updateLiveTemplate={(updatedTemplate) =>
                                  data.updateTemplate( updatedTemplate     )
                                }
                                templates={templates}
                              />}
                            </div>
                            { page.content.footer && (propsTemplates && propsTemplates[parseInt(page.content.footer)]) && propsTemplates[parseInt(page.content.footer)].length>0 &&
                           <div className="flex flex-col">
                        
                           { (
                              <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Data Footer"}
                                  options={contents.filter(c=> parseInt(c.template_id)===parseInt(page.content.footer) || c.content_id+""===""+page.content.footerContent_id).map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.title,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={page.content.footerContent_id}
                                  onChange={(value) => {
                                    data.updatePage({
                                      ...page,
                                      content: {
                                        ...page.content,
                                        footerContent_id: value,
                                      },
                                    });
                                  }}
                                />
                                {page.content.footerContent_id && (
                             
                                    <FontAwesomeIcon icon={fa.faClose}   
                                    onChange={(value) => {
                                      data.updatePage({
                                        ...page,
                                        content: {
                                          ...page.content,
                                          footerContent_id: null,
                                        },
                                      });
                                    }}
                                      />
                            
                                 
                                )}
                                {!page.content.footerContent_id && (
                
                                    <FontAwesomeIcon
                                    onClick={ ()=>addTemplateContent(page.content.footer,
                                      (value) => {
                                        data.updatePage({
                                          ...page,
                                          content: {
                                            ...page.content,
                                            footerContent_id: value,
                                          },
                                        });
                                      }


                                    )}
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                              
                                )}
                              </div>
                            )}
                            {
                              parseInt(page.content.footerContent_id)>0 && (
                                <ContentEdition dataOnly={true}
                               key={page.content.footerContent_id}
                               _schemas={schemas}
                                  defaultApp={app}
                                  defaultContent={ contents.find(c=>parseInt(c.content_id)===parseInt(page.content.footerContent_id))}
                                  content_id={
                                    page.content.footerContent_id
                                  }
                                  updateLiveContent={data.updateContent}
                                
                                  data_schema={propsTemplates[parseInt(page.content.footer)]}
                                />
                              )}</div>
                                }
                              </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-col  h-full overflow-auto items-start justify-start mb-40 p-2" style={{maxWidth:"500px",}}>       
         
                <div className="font-bold p-1 text-xl text-left  sticky top-0 left-0 " onClick={  ()=>setShowVariables(!showVariables)  }>
                        Variables 
                      </div>
                      { showVariables &&             
<div className='flex flex-col items-start justify-start' >
  <h1>baseContent</h1>
<VarContent data={baseContent} />

<h1>contents    {selectedBloc}</h1> 
{  contents.map(content => {

if ((selectedBloc === "footer-edit" && parseInt(page.content.footerContent_id)===parseInt(content.content_id)) ||
(selectedBloc === "header-edit" && parseInt(page.content.headerContent_id)===parseInt(content.content_id)) || 
( selectedBloc?.split( "bloc_").length>1 &&    parseInt(page.content.body[parseInt(selectedBloc?.split( "bloc_")[1].split("-edit" )[0])].content_id)=== parseInt(content.content_id)  )

||false
)



return <VarContent  data={content} className=" py-1 border-t border-zinc-500" />
else return <></>
})}

</div>
      }

                </div>
              </div>

              <div className="p-2 bg-white w-full  fixed top-0 left-0 flex justify-between z-50   ">
<div className="flex">
Edition


</div>
            
                <div  className="flex " >{
             Object.values(saveAll).length>0 &&  <div
                  type="button"
                  className=" bg-red-600 text-white text-xs rounded-full  p-2 m-1 first-line:rounded relative"
                  onClick={cancelling}
                  id="upload"
                >
                  Abandonner Brouillon 
                  <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                </div>
}
                {
             Object.values(saveAll).length>0 &&  <div
                  type="button"
                  className=" bg-black text-white text-xs rounded-full  p-2 m-1 first-line:rounded relative"
                  onClick={saving}
                  id="upload"
                >
                  Enregistrer Page
                  <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                </div>
}
   
<Link to={`/app/${page.app_id}/page/${page.page_id}`} target={"viewpage" +page.page_id}   >  
              
              <FontAwesomeIcon
                      icon={fa.faEye}
                      className=" bg-blue-600 text-xs text-white rounded-full  p-2 m-1 first-line:rounded relative"
                    /> 
    
</Link>
          
                  <FontAwesomeIcon
                      icon={fa.faClose}
                      onClick={()=>window.close()}
                      className="bg-zinc-900 text-white rounded-full text-xs p-2 m-1"
                    />


              </div>
              </div>
            </div>
          </header>
        )}
    </div>
  );
}

export default PopupPageEditor;
