
import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";
import availableClasses from "./tailwind_classes/allClasses.js";

const categoryClasses = [];
let classesBuffer = [];


Object.keys(availableClasses).forEach((category) => {
  categoryClasses.push(category);
  categoryClasses.sort();
});


const ToolStyle = ({ updateStructure, structure, idObj, selectTpl }) => {
  const [show, setShow] = useState();
  const [showBis, setShowBis] = useState();
  const [sousCategoryClasses, setSousCategoryClasses] = useState([]);
  const classes = useMemo(() => {
    console.log(structure, idObj);
    var _structure = getStructure(structure, idObj);

    var parts = _structure.className
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [structure, idObj]);

  const [filter, setFilter] = useState();

  const removeClasse = (classe) => {
    var _classes = classes.filter((_classe) => _classe !== classe);

    updateStructure({ className: _classes.join(" ") }, idObj);
  };

  const addClasse = (classe) => {
    var _classes = classes;

    console.log(classes)

    _classes = _classes.filter((c) => !availableClasses[sousCategoryClasses].includes(c));

    _classes.push(classe);

    updateStructure({ className: _classes.join(" ") }, idObj);
  };


  const openCat = (classe) => {
    setShowBis(!showBis);
    setFilter("");
    if(classe !== sousCategoryClasses){
      setSousCategoryClasses(classe);
    }
    else{
      setSousCategoryClasses([]);
    }
  }


  const hoverStart = (classe) => {
    var obj = document.getElementById(idObj);
    console.log("hoverStart", obj, classe);
    if (obj) classesBuffer = obj.classList;
    if (obj) obj.classList.add(classe);
  };

  const hoverEnd = (classe) => {
    var obj = document.getElementById(idObj);
    console.log("hoverEnd", obj, classe);
    if (obj) obj.classList.remove(classe);
    if (obj) obj.classList = classesBuffer;
    classesBuffer = [];
  };

  return (
    <>
      {show && (
        <div
          className={
            "w-full h-full justify-between flex flex-col relative  mr-14"
          }
        >
          <div className="w-full flex-nowrap text-start ">
            {" "}
            {classes &&
              classes.map((classe, i) => (
                <div
                  key={i}
                  onClick={() => removeClasse(classe)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-green-500 border-2"
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + classe}
                </div>
              ))}
          </div>
          
          <div
            className="w-full flex-nowrap text-start grow overflow-y-scroll "
            style={{ maxHeight: "40vh" }}
          >

            <div className=" w-full flex-nowrap text-start ">
              {categoryClasses &&
                categoryClasses
                .filter(sousCategoryClasses[1] ? (c) => c === sousCategoryClasses : (c) => c !== " ")
                .filter(
                  (classe) =>
                    !classes.find((c) => c === classe) &&
                    (!filter || classe.toLowerCase().includes(filter)))
                  .map((classe, i) => (
                    <div
                      key={i}
                      onClick={() => openCat(classe)}
                      className={" appbutton inline-block text-xs p-1 px-2 m-1 mb-1 rounded-full border-gray-500 border-2" +
                      (classe === sousCategoryClasses ? " bg-gray-500 text-white" : " bg-white")}
                    >
                      {classe}
                    </div>
                  ))}
            </div>

            <div className=" w-full flex-nowrap text-start ">
              {availableClasses[sousCategoryClasses] &&
                availableClasses[sousCategoryClasses]
                  .filter(
                    (classe) =>
                      !classes.find((c) => c === classe) &&
                      (!filter || classe.includes(filter))
                  )
                  .map((classe, i) => (
                    <div
                      key={i}
                      onClick={() => addClasse(classe)}
                      onMouseOver={() => hoverStart(classe)}
                      onMouseOut={() => hoverEnd(classe)}
                      className=" appbutton inline-block text-xs p-1 px-2 m-1 mb-1 bg-white rounded-full border-gray-500 border-2"
                    >
                      {classe}
                    </div>
                  ))}
            </div>
          </div>

          {!showBis && (
         <div className="flex flex-row  w-full p-1">
            <input
              type="text"
              onChange={(e) => setFilter(e.target.value)}
              className="   grow rounded-lg text border border-slate-300 text-xs p-1"
              placeholder="Search/Add TailWind Category"
            />
            {filter &&
              filter.length > 2 && (
                <FontAwesomeIcon
                  icon={fa.faPlus}
                  className="-ml-6 bg-green-500 text-white p-2 appbutton rounded-full"
                  onClick={() => addClasse(filter)}
                  onMouseOver={() => hoverStart(filter)}
                  onMouseOut={() => hoverEnd(filter)}
                />
            )}
            </div>)}
          
          {showBis && (
         <div className="flex flex-row  w-full p-1">
            <input
              type="text"
              onChange={(e) => setFilter(e.target.value)}
              className="   grow rounded-lg text border border-slate-300 text-xs p-1"
              placeholder="Search/Add TailWind Class"
            />
            {filter &&
              filter.length > 2 && (
                <FontAwesomeIcon
                  icon={fa.faPlus}
                  className="-ml-6 bg-green-500 text-white p-2 appbutton rounded-full"
                  onClick={() => addClasse(filter)}
                  onMouseOver={() => hoverStart(filter)}
                  onMouseOut={() => hoverEnd(filter)}
                />
            )}
          </div>
          )}
        </div>
      )}
      <div
        className={
          " inline-flex appbutton border-2  border-white  p-2  m-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
        TW Styles{'>'} 
      </div>
    </>
  );
};

export default ToolStyle;

