


class PageClass {
    static  classe_id= 9;
    static props =[
       /* {
            "name": "App",
            "type": "classe",
            "classe_id": "4"
        },
        {
            "name": "Contenu",
            "type": "classe",
            "classe_id": "7",
            "type_nombre": "I"
        },
        {
            "name": "Header",
            "type": "classe",
            "classe_id": "10"
        },
        {
            "name": "Footer",
            "type": "classe",
            "classe_id": "11"
        },
        */
        {
            "name": "Url",
            "type": "link",
        },
        {
            "name": "slug"
        },
        {
            "name": "label"
        },
        {
            "name": "icone",
            "type": "icone",
        }
    ];
    
    
    constructor({page,id,project,setContent,loadRequired}
       )

    {
       this.page=page;
       this.page_id=id;
        this.project = project;
        this.content= {};
        this.setContent = setContent;
        this.loadRequired = loadRequired;
      
    }


     async init(){
      if(!this.page)
      {
      
        var _page = await this.project.api("/get_page_todisplay/", {
            body: { page_id: this.page_id },
          });
      
          this.page = _page;
      }
          this.content= {
          /*  "App": null,
            "Contenu":[],
            "Template":[],
            "Header" : null,
            "Footer":null,
        */
            "slug":this.page.slug ,
            "label":this.page.content.label ,
            "icone":this.page.content.icone,
            "Url": "/page/" + (this.page.slug ?? this.page.page_id)};

    /*     if(this.loadRequired?.includes("App")) await this.AppLoad();
         if(this.loadRequired?.includes("Contenu")) await this.ContenuLoad();
        // if(this.loadRequired?.includes("Template")) await this.TemplateLoad();
         if(this.loadRequired?.includes("Header")) await this.HeaderLoad();
         if(this.loadRequired?.includes("Footer")) await this.FooterLoad();
*/
        if( this.setContent)  this.setContent(this.content)
     }

     addContent(prefix,content,loadRequired=[]){
      for (const [key, value] of Object.entries(content)) {
        var parts = key.split(".");
        if(parts.length>1 &&  !loadRequired.includes(parts[0] )) return;
        this.content[ `${prefix}.${key}`  ]=value
    }
    }

/* Loaders */

async AppLoad(){
var loadRequired= ["User","Produit"] ;
var appObject = await  this.project.getObject({className: "AppClass",id:this.page.app_id, loadRequired});

this.content["App"]= appObject
this.addContent(`App`, appObject.content,loadRequired)


}
async ContenuLoad(){
  
}
async TemplateLoad(){
  
}
async HeaderLoad(){

}

async FooterLoad(){ 

}
/* Actions */



    getContent(){

    }

} 



export default{ PageClass};