import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as fa from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import LoaderSmall from "./LoaderSmall";


const  Contents ={}
const ClosingModes = {
  NONE: 0,
  BUTTON: 1,
  SCREEN: 2,
};

const ModalPositions = {
  CENTER: " items-center justify-center  ",
  LEFTTOP: " items-start justify-start  let-2 top-2 ",
  RIGHTTOP: " items-start justify-end  right-2 top-2",
  RIGHTBOTTOM: " items-end justify-end  right-2 bottom-2",
  LEFTBOTTOM: " items-end justify-start  left-2 bottom-2",
};
const AnimatePositions = {
  NONE: null,
  CENTER: [["scale-0"], ["scale-1"]],
  LEFT: [["-ml-96"], ["ml-0"]],
  TOP: [["-mt-96"], ["mt-0"]],
  LEFTTOP: [
    ["-mt-96", "-ml-96"],
    ["mt-0", "ml-0"],
  ],
  RIGHTTOP: [
    ["-mt-96", "-mr-96"],
    ["mt-0", "mr-0"],
  ],
  RIGHT: [["-mr-96"], ["mr-0"]],
  RIGHTBOTTOM: [
    ["-mb-96", "-mr-96"],
    ["mb-0", "mr-0"],
  ],
  BOTTOM: [["-mb-96"], ["mb-0"]],
  LEFTBOTTOM: [
    ["-mb-96", "-ml-96"],
    ["mb-0", "ml-0"],
  ],
};

const closeModal = (key = "default") => {
  console.log("clOSING mODAL ", key);
  const modalRoot = document.getElementById("root");
  const modalContainer = document.getElementById("modal-" + key);
  if (modalContainer) {
    modalContainer.style.opacity = 0;

    setTimeout(() => modalRoot.removeChild(modalContainer), 500);
  }
};

const showModalOK = () =>
  showModal("", {
    closeDelay: 1000,
    darkMode: false,
    position: ModalPositions.CENTER,
    Content: (
      <FontAwesomeIcon
        icon={fa.faCheck}
        className=" text-3xl text-green-500 "
      />
    ),
  });
  const showModalError = (err) =>
    showModal("", {
      closeDelay: 1000,
      className:"bg-red-500 ",
      darkMode: false,
      position: ModalPositions.CENTER,
      Content: (
        <div className="flex flex-col items-center p-2 gap-3 justify-center "> <FontAwesomeIcon
          icon={fa.faWarning}
          className=" text-3xl text-white "
        />
       {JSON.stringify(err)}</div>
      ),
    });
const showModalLoading = (close = false) =>
  showModal("", {
    key: "loading",
    close,
    darkMode: false,
    position: ModalPositions.CENTER,
    Content: <LoaderSmall />,
  });

const showModal = (
  message,
  {
    key = "default",
    Content,
    content_id="default",
    closeDelay = 0,
    closingMode = ClosingModes.BUTTON,
    position = ModalPositions.CENTER,
    addContent = false,
    darkMode = false,
    standalone = false,
    preventAction = true,
    className = "p-4 bg-white shadow-xl rounded-xl text-black text-sm ",
    animation = AnimatePositions.NONE,
    close = false,
  }
) => {
  const modalRoot = document.getElementById("root");
  var modalContainer ;
  if (document.getElementById("modal-" + key) ) {
    modalContainer=document.getElementById("modal-" + key)
    if (close) {
      const modalClose = document.getElementById("modal-" + key);
      modalClose.style.opacity = 0;

      setTimeout(() =>  {  if( modalClose.parentNode) modalClose.parentNode.removeChild(modalClose)
   }, 500);
    }

    if(!addContent)    return;
  }
  else{
   modalContainer = document.createElement("div");
  modalContainer.style.opacity = 0;
  modalContainer.style.transition = "all 0.5s ease-in";

  modalContainer.id = "modal-" + key;
  modalRoot.appendChild(modalContainer);
  setTimeout(() => {
    modalContainer.style.opacity = 1;

    if (animation) {
      document
        .getElementById("modal-" + key + ".content")
        .classList.remove(...animation[0]);

      document
        .getElementById("modal-" + key + ".content")
        .classList.add(...animation[1]);
    }
  }, 10);
}
  const handleClose = () => {
    modalContainer.style.opacity = 0;
    delete Contents[modalContainer.id]
    setTimeout(() =>{ if( modalContainer.parentNode) modalContainer.parentNode.removeChild(modalContainer)}, 500);
  };


  if (closeDelay) setTimeout(handleClose, closeDelay);



const root = modalContainer.root ?? createRoot(modalContainer)     ;

  root.render(
    <Modal
      id={"modal-" + key + ".content"}
      key={"modal-" + key + ".content"}
      handleClose={
        !closeDelay && closingMode !== ClosingModes.NONE && handleClose
      }
      message={message}
      closingMode={!closeDelay ? closingMode : ClosingModes.NONE}
      darkMode={darkMode}
      preventAction={preventAction}
      content_id={content_id}
      Content={Content}
      position={position}
      className={
        className +
        (animation
          ? " transition-all delay-100 duration-500  " + animation[0].join(" ")
          : "")
      }
      standalone={standalone}
      animation={animation}
    />
  );
};




function Modal({
  id,
  handleClose,
  Content,
  darkMode,
  preventAction,
  message,
  content_id,
  closingMode,
  position,
  className,
  standalone,
}) {

  if( Content)
    {
  if (!Contents[id]) Contents[id]={[content_id]:Content}
  else Contents[id][content_id]=Content

    }

  if (preventAction)
    return (
      <>
        <div
          className={
            "  fixed h-screen w-screen top-0 left-0  bg-opacity-90 text-2xl font-bold flex flex-col justify-start items-center  " +
            (darkMode ? "bg-black" : "") +
            (!standalone ? " p-4 " : "")
          }
          style={{ zIndex: 5000000 }}
        >
          {closingMode !== ClosingModes.NONE && (
            <div
              className={
                "absolute top-0 left-0 w-full p-4 text-white flex flex-row justify-between  " +
                (closingMode === ClosingModes.SCREEN ? " h-screen " : "")
              }
              onClick={
                closingMode === ClosingModes.SCREEN ? handleClose : () => {}
              }
            >
              {closingMode === ClosingModes.BUTTON && (
                <>
                  <span></span>{" "}
                  <FontAwesomeIcon
                    className="appbutton"
                    onClick={handleClose}
                    icon={faClose}
                  ></FontAwesomeIcon>
                </>
              )}
            </div>
          )}
          <div
            className={
              " w-full h-full  flex " + position + (!standalone ? " p-4 " : "")
            }
          >
            <div id={id} className={className}>
              {message && <div>{message}</div>}
              {Contents[id] && Object.values( Contents[id]).map((Content,i)=>Content)}
            </div>
          </div>
        </div>
      </>
    );
  else
    return (
      <div
        className={"fixed  flex " + position + (!standalone ? " p-4 " : "")}
        style={{ zIndex: 5000 }}
      >
        <div id={id} className={className}>
          {message && <div>{message}</div>}
         <div className="flex flex-col gap-2 ">{Contents[id] && Object.values( Contents[id]).map((Content,i)=>Content)}</div>
        </div>
      </div>
    );

}

export {
  showModal,
  showModalOK,
  showModalError,
  closeModal,
  ModalPositions,
  ClosingModes,
  AnimatePositions,
  showModalLoading,
};
