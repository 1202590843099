import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContext } from "react";
import { MfctContext } from "../context";

import { Link } from 'react-router-dom';

import { data } from '../service/data';



function ContentAction({ content, deleteContent ,duplicateContent, isEditable }) {

  const {authUser} = useContext(MfctContext);

    return <div className='flex w-full flex-col p-2 border-t  bg-white   border-zinc-500 shadow text-sm '>
      <div className=' flex  items-start justify-start w-full  font-bold '>
      <div> <FontAwesomeIcon  className=" " icon={ content.content.icone  ? fa[ content.content.icone]: fa.faPlay} /></div>
     <div className='w-full text-left px-2'>{  isEditable &&   content.content.title  + `(${content.content_id})`}</div>
     <div
    className="text-white appbutton mx-2 bg-green-700  p-1 px-3 rounded-full"
    onClick={() => duplicateContent(content)}
  >
    <FontAwesomeIcon icon={fa.faPaste} />
  </div>
   { data.isGranted( authUser, content,"content","edit")   && isEditable &&    <div className=' flex flex-row justify-end items-center text-xs text-white '>
    <Link to={`/content/${content.content_id}`} className='mx-2 bg-blue-700 p-1 px-3 rounded-full' ><FontAwesomeIcon icon={fa.faEdit} /> </Link>
      { data.isGranted( authUser, content,"content","delete")   &&  deleteContent && <div className='mx-2 bg-red-700  p-1 px-3 rounded-full' onClick={() => deleteContent(content)}><FontAwesomeIcon icon={fa.faRemove} /></div>}
    </div> }
  </div>
  {content.content.Description &&
  <div className='w-full text-left pl-4 italic'>{content.content.Description}</div>
}
  </div>

}

export default ContentAction;
