import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";
import InputData from "../../../../../components/inputs/InputData";

const availableClasses = [

];
availableClasses.sort((a, b) => (a < b ? -1 : 1));

const ToolConditonStyles = ({ updateStructure, structure, idObj, selectTpl,key_schemas }) => {

  const [show, setShow] = useState();

  
  const cssConditions = useMemo(() => {
 
    var _structure = getStructure(structure, idObj);

   return  _structure.cssConditions ?? []

  }, [structure, idObj]);

  useEffect(()=>{

    if(!show && cssConditions.length > 0) setShow(true)
  },[cssConditions,show])
const setCssConditions= (cssConditions)=>{
  updateStructure({ cssConditions}, idObj);
}

  return (
    <>
      {show && (
        <div
          className={
            "bg-white w-full h-full justify-between flex flex-col relative  mr-14"
          }
        >
    <InputData
                    id={"css_condition"}
                  className={"w-full"}
                  prop={{ name: "Conditions CSS", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 451, content:{code:"css_condition"} }}
                  value={cssConditions }
                  onChange={setCssConditions}
                  context={{key_schemas}}
                />
        </div>
      )}
      <div
        className={
          "   appbutton border-2  border-white  p-2  m-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
        Conditions CSS
      </div>
    </>
  );
};
export default ToolConditonStyles;
