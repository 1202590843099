import * as fa from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getStructure } from "../../../../../tools/tpl"




const AddToolWrap = ({id,structure, updateStructure, selectTpl ,idObj}) => {


const selectTool=()=>{
  hoverEnd()
   var _structure = getStructure(structure,idObj) 
   console.log("select ", structure,_structure,idObj)

   var newid = idObj + ".0" 
   var new_struct ={ tag:"div",className:" p-2 " ,children:[{..._structure}]}
  
   updateStructure(new_struct,idObj,idObj )


}

const hoverStart = () => {
   
  hoverEnd(idObj);
    var obj = document.getElementById(idObj);
if(obj){
  var   newdiv = document.createElement("div");

    newdiv.className= " p-2  border-6 ";
    newdiv.style.borderColor="orange"
    newdiv.style.borderWidth="10px"
    newdiv.style.borderStyle="solid"
    newdiv.id = "newObj-_AddToolWrap";
   // newdiv.innerHTML ="+"
    obj.parentNode.insertBefore(newdiv,obj)
    obj.parentNode.removeChild(obj)
    newdiv.appendChild(obj)
}
  };

  const hoverEnd = (idObj) => {
    
    var newdiv = document.getElementById( "newObj-_AddToolWrap");

     

    if(newdiv &&(!idObj || newdiv.idObj!==idObj))  {
      var obj = newdiv.firstChild
      obj.parentNode.removeChild(obj)
      newdiv.parentNode.insertBefore(obj,newdiv)
      newdiv.parentNode.removeChild(newdiv)
    }

  };


return (
    
<div className="inline-flex flex-row  p-2">
<div className="appbutton border-4 border-white  bg-black bg-opacity-30 p-1"    
onClick={()=>selectTool()}  
onMouseOver={()=>hoverStart()}
onMouseOut={()=>hoverEnd()}

>
<FontAwesomeIcon  icon={fa.faPlus} className="border border-white  bg-black  bg-opacity-30 text-white w-3 h-3 p-1 rounded-sm" 



/>

</div>
</div>)

}
export default AddToolWrap