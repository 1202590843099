import { useContext, useEffect,useMemo } from "react"
import { useState } from "react"
import { MfctContext } from "../../context"
import InputSelect from "./InputSelect"
import InputText from "./InputText"
import { parse } from "@fortawesome/fontawesome-svg-core"
import { data } from "../../service/data"



const InputSchema = ({label,onChange,value,className,placeholder,trueValue}) => {

   const [schemas,setSchemas] =useState([])
 //    const {getSchemas} = useContext(MfctContext)
//const schemas =getSchemas();

useEffect (()=>{

const getSchemas =async()=>{
   var s= await data.getSchemas()


s.sort((a,b)=>   a.title > b.title?1:-1)

   setSchemas(s)
}

getSchemas()
},[])

return (
 
 <InputSelect label={label} className={className}  placeholder={placeholder} value={value?.content_id} onChange={schema_id=>{
       
    var schema = null
      
         if(schema_id){  


                
            schema_id =parseInt(schema_id);
         schema = schemas.find(s=>s.content_id===schema_id)
        
        }
       
onChange(schema)
     }}   options={  schemas.map(s=>{ return {label : s.title,value:s.content_id}   }  )   } /> 

)
}
export default InputSchema