import { useEffect } from "react"
import { useState } from "react"



const InputSelect = ({id, label,onChange,value,className,options,placeholder,trueValue, valueType,context}) => {
    const [_value,setValue]=useState(value)

    useEffect(()=>{

        setValue( value)
    },[value,trueValue])
return (
    <>
    <div className={"relative " +className } >
    <select  type="text" id={id ?? "input_"+label} onChange={(e)=>{
     
        onChange(e.target.value===`-- ${placeholder} --` ? null :   (valueType==="num"?   parseInt(e.target.value) :  e.target.value) )} }    value={  _value  }  className="block px-2.5 pb-2.5 pt-4 w-full text-sm  bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" >
   
    {placeholder && <option value={null} className="   text-opacity-50   text-zinc-500    italic"   >-- {placeholder} --</option>  }
   {options && options.map((option,i)=>  <option key={i} value={option.value?? option}>{option.label?? option.value?? option}</option>)}
    </select>
   { !context?.minimized &&  <label htmlFor={ "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
}
</div>
</> 
)
}
export default InputSelect