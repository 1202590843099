
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import InputText from "../../../../../components/inputs/InputText";
import InputSelect from "../../../../../components/inputs/InputSelect";
import InputMultiSelect from "../../../../../components/inputs/InputMultiSelect";
import InputBoolean from "../../../../../components/inputs/InputBoolean";
import { Link } from "react-router-dom";
const TemplateParams = ({template,setShow,schemas,apps,roles,conditions,internalContent,setInternalContent,deleteTemplate,key_schemas}) => {

    if(!template ) return <></>  
    return    <div id={"TemplateParams"}className=" flex-none p-2 w-80  bg-white m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faGear}></FontAwesomeIcon>
        Paramètres Template 
        <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Gear: !show.Gear })) }  className="appbutton"  ></FontAwesomeIcon>
        </div>
    
          {
            <>
              <InputText
                value={template.title}
                label={"Titre"}
                className="text-black w-full my-1"
                onChange={(title) => {
                  data.updateTemplate({ ...template, title });
                }}
              />
              <div className="flex flex-row w-full">
                <InputSelect
                  label={"Schéma"}
                  value={template.schema_id}
                  className="text-black w-full my-1"
                  onChange={(schema_id) => {
                    data.updateTemplate({ ...template, schema_id });
                  }}
                  options={schemas.map((c) => {
                    return { value: c.content_id + "", label: `<${c.title}>` };
                  })}
                  placeholder={"Choisir le schéma"}
                />
                <InputSelect
                  label="Layout"
                  placeholder={"Bloc Page"}
                  value={template.layout}
                  onChange={(layout) => {
                    data.updateTemplate({ ...template, layout });
                  }}
                  options={["in", "bloc", "top", "bottom", "left", "right","list","input","select","view"]}
                />
              </div>
              <InputSelect
                label={"Application"}
                value={template.app_id}
                className="text-black w-full my-1"
                onChange={(app_id) => {
                  data.updateTemplate({ ...template, app_id: parseInt(app_id) });
                }}
                options={apps.map((app) => {
                  return { value: app.app_id + "", label: app.name };
                })}
                placeholder={"Choisir l'application"}
              />
              <InputMultiSelect
                label={"Template Size"}
                value={template.structure.sizes}
                onChange={(sizes) => {
                  data.updateTemplate({
                    ...template,
                    structure: { ...template.structure, sizes },
                  });
                }}
                values={["full","fit","screen","xs", "sm","lg","xl",
                  "w-full","w-fit","w-screen","w-xs", "w-sm","w-lg","w-xl",
                "h-full","h-fit","h-screen","h-xs", "h-sm","h-lg","h-xl"].map((size) => ({
                  key: size,
                  label:size,
                }))}
              />
              <InputMultiSelect
                label={"Roles"}
                value={template.structure.roles}
                onChange={(roles) => {
                  data.updateTemplate({
                    ...template,
                    structure: { ...template.structure, roles },
                  });
                }}
                values={roles.map((role) => ({
                  key: role.role_id,
                  label: role.content.title ?? "role " + role.role_id,
                }))}
              />
              {conditions.map((condition, index) => (
                <InputBoolean
                  key={index}
                  labelFalse={condition + " NO"}
                  labelTrue={condition + " OK"}
                  onChange={(value) => {
                    var c = { ...internalContent };
                    c["_" + condition] = value;
                    setInternalContent(c);
                  }}
                />
              ))}
            </>
          }
       <Link
              to={`/edittemplate/${template.template_id}`}
              target={`editor_template_${template.template_id}`}
              className="w-full appbutton p-2 underline"
            >
       Aller sur ancien éditeur  </Link>
       {deleteTemplate &&   <div className="rounded-xl my-1 w-full bg-red-600 text-white p-2 appbutton " onClick={deleteTemplate}> Supprimer Template  
          
          
   <FontAwesomeIcon   icon={fa.faTrash}/>

            </div>}
        </div>
      };
  


      export default TemplateParams