import React, { useEffect, useMemo, useState,useContext } from 'react';
import { Link,useParams } from 'react-router-dom';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Content from '../../../../components/Content';

function AppContents({app}) {


const [contents,setContents]= useState([])
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);
const [schema,setSchema]=useState();
const [schemas, setSchemas] = useState([]);

const getContents = async()=>{
  var _schemas=  await project.api("/get_schemas");
  _schemas = _schemas.filter(t=>t.content.is_addable);
  _schemas.sort((a,b)=> a.title < b.title ? -1 : 1);
  const tout ={title:"Tout",content_id:0}
  _schemas.unshift(tout)
setSchemas(_schemas);
setSchema(tout);
  var _contents = await project.api("/app/:app_id/get_contents", { args:{app_id:app.app_id} })
  console.log( _contents)
    setContents(_contents);
}

const  deleteContent = async(content)=>{
  if (!window.confirm("Are you sure you want to delete '" + content.name+"'?")) return
   await project.api("/delete_content/:content_id",{args:{content_id:content.content_id}})
await    getContents();
}

useEffect(()=>{
if(project)
  getContents()
 
},[project])
 return (
    <div className="App w-full"> 
 
          <div className='block w-full text-black justify-start overflow-x-auto overflow-y-hidden'>
            <div  className='inline-flex  justify-start relative '>            {
              schemas.map((t,i)=><div key={i} onClick={()=>setSchema(t)} className={"m-2 h-16 w-24  shadow-lg border border-zinc-300 rounded-lg p-2 text-xs font-bold flex flex-col items-center justify-center " +(schema?.content_id===t.content_id ? "bg-zinc-300 sticky left-0 right-0 ":"bg-white")}>
              <FontAwesomeIcon icon={t.content?.icone ? fa[t.content.icone]: fa.faQuestionCircle} />   {t.title}{t.content_id>0  &&  "s"}
              </div>)
            }
            </div>

            </div>
            <div  className='w-full my-2 flex flex-wrap py-2  rounded-xl ' >
   {  contents.filter(c => !schema?.content_id || c.schema_id===schema.content_id).map((content,i)=>
  <div className="w-1/2 lg:w-1/3  xl:w-1/4 p-2 " >
     <Content className={"w-full text-black  border-none "} content={content }  key={i} deleteContent={deleteContent} isEditable={true}   schema={schemas.find(s=>s.content_id===content.schema_id)   } />
</div>
   ) 
     }
     </div>   
      <Link to={   `/app/${app.app_id}/content/add`   +(!schema?.content_id ? "":"/"+ schema.content_id) }>
      <div  className=' text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer {  schema?.content_id>0 ? schema.title :" contenu"  }</div></Link>     
      
    </div>
  );
}

export default AppContents;