import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { showModal, closeModal } from "../modal";
import { useState, useRef,useEffect } from "react";
import InputText from "./InputText";
import L, { Icon } from "leaflet";
import { encodeGeohash } from "../../tools/geo";
import { retrieveProject } from "../../service/mftc-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

//    format  value : { adresse; "sefzefef", lat:0,lon:0,geohash:"xxx"    }

const markerIcon = new Icon({
  iconUrl: "https://img.icons8.com/color/48/marker--v1.png",
  iconSize: [35, 35], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -5], // point from which the popup should open relative to the iconAnchor
});

const MapSearch = ({ _value, onChange }) => {
  const mapRef = useRef();
  const [value, setValue] = useState(_value??{});
  const [suggestions, setSuggestions] = useState([]);
  const [modifyValue, setModifyValue] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);

  var timerSearch;
  const suggestionsByAdresse = async (address) => {
    if (timerSearch) clearTimeout(timerSearch);

    timerSearch = setTimeout(() => {
      requestSuggestions(address);
    }, 500);
  };

  const requestSuggestions = async (address) => {
    timerSearch = null;
    const project = retrieveProject();
    var result = await project.api("/get_suggestions", { body: { address } });

    if (result.suggestions[0]) {
      mapRef.current.setView([
        result.suggestions[0].lat,
        result.suggestions[0].lon,
      ]);
    }

    setSuggestions(result.suggestions);
  };

  const hoverSuggestion = (suggestion) => {
    mapRef.current.setView([suggestion.lat, suggestion.lon]);
  };

  const validAdress = (suggestion) => {
    onChange(suggestion);
    setValue(suggestion);
    setModifyValue(false);
  };

  const closeMap = () => {
    closeModal();
  };

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    getAdresseByLatLon(lat, lng);
    setMarkerPosition([lat, lng]);
  };

  const MapEventsHandler = ({ handleMapClick }) => {
    useMapEvents({
      click: (e) => handleMapClick(e),
    });
    return null;
  };

  const getAdresseByLatLon = async (lat, lon) => {
    var project = retrieveProject();
    var result = await project.api("/get_address", { body: { lat, lon } });
    onChange(result.address);
    setValue(result.address);
    setModifyValue(false);

    console.log("148", result.address);
  };



useEffect(()=>{

  function getCurrentLocation() {
    // Vérifie si le navigateur supporte la géolocalisation
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            // Fonction de succès
            function(position) {
                const lat = position.coords.latitude;
                const lon = position.coords.longitude;
           //     window.alert(lat +" " +lon )
                setValue({lat,lon})
            },
            // Fonction d'erreur
            function(error) {
                console.error("Erreur lors de la récupération des coordonnées : ", error);
            }
        );
    } else {
        console.error("La géolocalisation n'est pas supportée par ce navigateur.");
    }
}

// Appel de la fonction pour obtenir la position
if(!_value) getCurrentLocation();



},[_value])


//

  return (
    <div
      style={{ width: "90vw", height: "90vh" }}
      className="rounded-3xl   shadow relative"
    >
      <MapContainer
        ref={mapRef}
        style={{ height: "100%", minHeight: "100%", zIndex: 10 }}
        className="w-full h-full"
        center={[
          suggestions[0]?.lat ?? value?.lat ?? 54.505,
          suggestions[0]?.lon ?? value?.lon ?? -0.09,
        ]}
        zoom={16}
        scrollWheelZoom={false}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomright" />
        <MapEventsHandler handleMapClick={handleMapClick} />
        {markerPosition && (
          <Marker position={markerPosition} icon={markerIcon}>
            <Popup>
              {value.address} <br /> test
            </Popup>
          </Marker>
        )}
        {
          <Marker
            key={_value?.id}
            position={[_value?.lat ?? 51.505, _value?.lon ?? -0.09]}
            icon={markerIcon}
          >
            <Popup>
              {_value?.address} <br /> Yélélélé.
            </Popup>
          </Marker>
        }
        {suggestions &&
          suggestions.map((marker) => (
            <Marker
              key={marker.id}
              position={[marker.lat, marker.lon]}
              icon={markerIcon}
            >
              <Popup>
                {marker.address} <br /> Easily customizable.
              </Popup>
            </Marker>
          ))}{" "}
      </MapContainer>

      <div className=" z-30   absolute  top-0 left-0 p-4 w-1/4  flex flex-col ">
        {value && !modifyValue && (
          <div
            className="p-2 w-full border-t border-black bg-white"
            onClick={() => setModifyValue(true)}
            onMouseOver={() => hoverSuggestion(value)}
          >
            <FontAwesomeIcon icon={faLocation} /> {value.address}
          </div>
        )}
        <button type="button" onClick={closeMap}>
          Valider Adresse
        </button>
        {(!value || modifyValue) && (
          <>
            <input
              id={"Search"}
              onChange={(e) => {
                suggestionsByAdresse(e.target.value);
              }}
              className=" z-30  rounded-sm  p-2 w-full"
              placeholder="Saisir blabla"
            />
            {
              <div className="relative z-30 w-full flex flex-col bg-white">
                {suggestions &&
                  suggestions.map((marker) => (
                    <div
                      className="p-2 w-full border-t border-black"
                      key={marker.id}
                      onMouseOver={() => hoverSuggestion(marker)}
                      onClick={() => validAdress(marker)}
                    >
                      <FontAwesomeIcon
                        icon={faLocation}
                        onClick={() => hoverSuggestion(marker)}
                      />{" "}
                      {marker.address}
                    </div>
                  ))}
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

const InputMap = ({ id, label, onChange, value, className }) => {
  const changeByLatLon = async (lat, lon) => {
    // var adresse = await getAdresseByLatLon(lat,lon)
    var geohash = encodeGeohash(lat, lon);

    // onChange({  adresse,geohash,lat,lon })
  };

  const selectSuggestion = async (suggestion) => {
    var geohash = encodeGeohash(suggestion.lat, suggestion.lon);
    onChange({
      adresse: suggestion.adresse,
      geohash,
      lat: suggestion.lat,
      lon: suggestion.lon,
    });
  };

  const showMap = () => {
    console.log(value);
    showModal(null, {
      Content: <MapSearch _value={value} onChange={onChange} />,
    });
  };

  return (
    <>
      <div className={"relative " + className}>
        <div
          id={id ?? "input_" + label}
          onClick={showMap}
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
        >
          {value?.address}
        </div>
        <label
          htmlFor={id ?? "input_" + label}
          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          {label}
        </label>
      </div>
    </>
  );
};
export default InputMap;
