import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { getStructure, loadTemplate } from "../../../../../tools/tpl";
import { data } from "../../../../../service/data";
import TemplateX from "../../../../../components/templates/TemplateX";
import UserThumb from "../../../../../components/thumbs/UserThumb";

const ToolWizard = ({ updateStructure, structure, idObj, selectTpl }) => {
  const [show, setShow] = useState();

  const getTemplates = async () => {
    var _templates = await data.getTemplates();
    setTemplates(_templates);
  };

  

  const showForce = useMemo(() => {
    if(show ) return true
    var _structure = getStructure(structure, idObj);
    if(_structure)
    return _structure.className.startsWith("new ");
  }, [show,structure, idObj]);

  useEffect(() => {
    if (showForce) getTemplates();
  }, [showForce]);
  
  const [templates, setTemplates] = useState([]);

  const _MAX_ = 100000000;
  const [searchValue, setSearchValue] = useState("");

  const selectTemplate = (template) => {
    updateStructure(template.structure, idObj);
    setShow(false);
  };
  const close = () => {
    var _structure = getStructure(structure, idObj);
    if (_structure.className.startsWith("new ")) {
      _structure.className = _structure.className.substr(4);
      updateStructure({ className: _structure.className }, idObj);
    }
    setShow(false);
  };

  const normalSort= (a, b) => {
    if (a.schema_id ?? _MAX_ !== b.schema_id ?? _MAX_)
      return (a.schema_id ?? _MAX_) > (b.schema_id ?? _MAX_) ? 1 : -1;

    return a.template_id > b.template_id ? -1 : 1;
  }

  const sortedTemplates = useMemo(()=>{
var timeSort = false
    var _templates = templates.filter(t=> t.schema_id + "" === "285")
    .map(t=>t)
    _templates.sort(timeSort ? (a,b) => ( a.updated_at > b.updated_at ? -1 :1    ) :normalSort ) 
    return _templates;
    },[templates]);

  return (
    <>
      {(showForce || show) && (
        <div className="z-50   fixed h-screen w-screen p-4 top-0 left-0 bg-black bg-opacity-90 text-xl font-bold flex flex-col justify-start items-center  ">
          <div className="w-full p-4 text-white flex flex-row justify-between ">
            {" "}
            <span> Choisir un modèle de template </span>
            <input
            type="text"
            id={"searchBar"}
           
            onChange={(e) => setSearchValue(e.target.value)}
            className=" px-4  rounded-3xl text-zinc-700 p-2"
          />

            <FontAwesomeIcon
              onClick={close}
              icon={fa.faClose}
            ></FontAwesomeIcon>{" "}
          </div>
          <div className="w-full  overflow-y-scroll h-full"> 
          <div className="w-full  flex flex-wrap  p-4 ">
            {
                    sortedTemplates
                    .filter(
                      (t) =>
                      (
                        !searchValue ||
                        searchValue === "" ||
                        (t.title &&
                        t.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                      )
                    ).map((template, i) => (
              <div
                key={i}
                className="appbutton w-40 h-40 overflow-hidden  flex flex-col m-2"
                onClick={() => {
                  selectTemplate(template);
                }}
              >
                <div className="text-white text-sm"> {template.title}</div>
                <TemplateX
                  content={{}}
                  template={template.structure}
                  templates={templates}
                  safeDisplay={true}
                />
                <UserThumb
                  key={template.owner_id }
                  className={"text-xs"}
                  user_id={template.owner_id}
                />
              </div>
            ))}
          </div>
          </div>
        </div>
      )}
      <div
        className={
          " inline-flex appbutton border-2  border-white  p-2  m-2 text-xs rounded-full " +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
        <FontAwesomeIcon icon={fa.faMagic} />
      </div>
    </>
  );
};
export default ToolWizard;
