const permissions =[   "geolocation",
"notifications",
"push",
"midi",
"camera",
"microphone",
// "speaker",
// "device-info",
"background-fetch",
"background-sync",
// "bluetooth",
"persistent-storage",
"ambient-light-sensor",
"accelerometer",
"gyroscope",
"magnetometer",
// "clipboard",
"screen-wake-lock",
"nfc",
"display-capture"];



const requestPermissions = async (permissions)=>{
 var granted= true;
    for(let i=0; i<permissions.length; i++){

     var result  = await   navigator.permissions.query({ name:permissions[i] })
     console.log(result);
     
            if (result.state === "granted") {
           granted = true;
            } else if (result.state === "prompt") {
             
            }
            else 
            {
                granted = false;
            }
            // Don't do anything if the permission was denied.
     
    }
   
      return granted
}



export  {requestPermissions};