
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState, useRef } from 'react';







function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const InputPeriodTime = ({ id, label, onChange, value, className }) => {
    let ref1 = useRef();
    let ref2 = useRef();
    let [selectedHour, setSelectedHour] = useState()


    let [start, setStart] = useState();
    let [end, setEnd] = useState();

    const [lastSelectedInput, setLastSelectedInput] = useState(null);


    const [showHourPicker, setShowHourPicker] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);


    const handleTimeClick = (time) => {

        if (time === start) {
            setStart('');
            setLastSelectedInput(ref1);
            ref1.current.value = '';
            return;
        }
        if (time === end) {
            setEnd('');
            setLastSelectedInput(ref2);
            ref2.current.value = '';
            return;
        }

        if (lastSelectedInput === ref1) {
            if (start && end) {
                setEnd('');
                ref2.current.value = '';
            }
            setStart(time);
            ref1.current.value = time;
            setLastSelectedInput(ref2);
        } else if (lastSelectedInput === ref2) {
            if (time < start) {
                setEnd(start);
                ref2.current.value = start;
                setStart(time);
                ref1.current.value = time;
            } else {
                setEnd(time);
                ref2.current.value = time;
            }
            setLastSelectedInput(ref1);
        }
    };

    function handleSelecting() {
        setShowHourPicker()
        setLastSelectedInput(null);
    }

    const creneaux = [...Array(24 * 4)].map((_, step) => {
        var hour = Math.floor(step / 4) % 24;
        var minute = Math.floor(step % 4) * 15
        return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
    })




    return (
        <>

            <div className={"relative justify-center  items-center flex flex-col " + className}>
                <div className='flex flex-row w-full gap-2 '>
                    <input
                        ref={ref1}
                        type="time"
                        id={id ?? "input_" + label}
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={value}
                        className={classNames(
                            "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer",
                            (lastSelectedInput === ref1 || start) && "border border-green-500 text-green-500",
                            (!showHourPicker && !start) && "border border-gray-400"
                        )}
                        placeholder=" "
                        onClick={() => {
                            setShowHourPicker(true);
                            setLastSelectedInput(ref1)

                        }} />
                    <label
                        htmlFor={id ?? "input_" + label}
                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                        {label}
                    </label>
                    <input
                        ref={ref2}
                        type="time"
                        id={id ?? "input_" + label}
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={value}
                        className={classNames(
                            "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer",
                            (lastSelectedInput === ref2 || end) && "border border-green-500 text-green-500",
                            (!showHourPicker && !end) && "border border-gray-400"
                        )}
                        placeholder=" "
                        onClick={() => {
                            setShowHourPicker(true);
                            setLastSelectedInput(ref2);
                        }}
                    />
                    <label
                        htmlFor={id ?? "input_" + label}
                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                        {label}
                    </label>

                </div>
                {(showHourPicker || inputFocused) && false && (
                    <div className="relative ">

                        <FontAwesomeIcon icon={faClose} className='p-1 -mr-3 text-lg absolute top-1 right-3 ' onClick={handleSelecting} />

                        <div className=" top-full m-6 w-fit bg-white border border-gray-300 rounded-md shadow-md   h-60 overflow-y-scroll relative ">
                            <div className="p-2">

                                <h3 className="text-lg font-semibold mb-2 ">Select Time</h3>
                                <div className="grid grid-cols-4  md:grid-cols-4   lg:grid-cols-4  ">
                                    {creneaux.map((time) => (
                                        <div
                                            key={time}
                                            onClick={() => handleTimeClick(time)}
                                            className={classNames(
                                                time === start && 'text-white bg-green-600 rounded-l-full',
                                                time === end && 'text-white bg-green-600 rounded-r-full',
                                                time === selectedHour && 'text-red-500',
                                                'text-center p-2 my-1 cursor-pointer',
                                                time === start && time === end && 'bg-green-600', // Surligner toute la plage horaire sélectionnée
                                                time > start && time < end && 'bg-green-100', // Surligner les heures entre le début et la fin
                                            )}

                                        >
                                            {time}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                )}
                {(true || inputFocused) && (
                    <div className="relative w-full ">

                        <FontAwesomeIcon icon={faClose} className='p-1 -mr-3 text-lg absolute top-1 right-3 ' onClick={handleSelecting} />

                        <div className=" top-full w-full bg-white   overflow-x-auto relative p-2">



                            <div className="w-full flex flex-row relative " _className="grid grid-cols-4  md:grid-cols-4   lg:grid-cols-4  ">
                                {creneaux.map((time) => (
                                    <div
                                        key={time}
                                        onClick={() => handleTimeClick(time)}
                                        className={classNames(
                                            time === start && 'text-white bg-green-600 rounded-l-full',
                                            time === end && 'text-white bg-green-600 rounded-r-full',
                                            time === selectedHour && 'text-red-500',
                                            'text-center p-2 my-1 cursor-pointer',
                                            time === start && time === end && 'bg-green-600', // Surligner toute la plage horaire sélectionnée
                                            time > start && time < end && 'bg-green-100', // Surligner les heures entre le début et la fin
                                            start && end && time > start && time < end && ' hidden', // enleve les heures entre le début et la fin
                                            start && time < start && "hidden",  // enleve ce qu'il y a avant le debut
                                            end && time > end && "hidden",     // enleve ce qu'il y a apres la fin
                                            start === time && " sticky left-0 top-0",
                                            end === time && " sticky right-0 top-0"
                                        )}

                                    >
                                        {time}
                                    </div>
                                ))}

                            </div>
                        </div>
                        <label
                            htmlFor={id ?? "input_" + label}
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                            {label}
                        </label>
                    </div>

                )}


            </div>
        </>
    )
}



export default InputPeriodTime

