import React, { useEffect, useMemo, useState,useContext } from 'react';


import '../../../../App.css';
import { Link } from 'react-router-dom';
import { MfctContext } from '../../../../context';


function Users() {

const [users,setUsers]= useState([])
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const [filteredUsers, setFilteredUsers] = useState([]);
const [filterRole, setFilterRole] = useState(''); // Ajout du filtre par rôle


const getUsers = async () => {
  try {
    const _users = await project.api('/get_users', { body: {} });
    setUsers(_users);
    // Initialisation des utilisateurs filtrés avec tous les utilisateurs
    setFilteredUsers(_users);
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

// Fonction pour filtrer les utilisateurs par rôle
const filterUsers = () => {
  if (filterRole) {
    const filtered = users.filter((user) => user.role === filterRole);
    setFilteredUsers(filtered);
  } else {
    // Si aucun filtre n'est sélectionné, afficher tous les utilisateurs
    setFilteredUsers(users);
  }
};

// Réinitialiser le filtre
const resetFilter = () => {
  setFilterRole('');
  setFilteredUsers(users);
};

const refreshUsers = () => {
  getUsers();
};


useEffect(()=>{
if(project)
  getUsers()
 
},[project])
 return (
  <div className="App ">
  <header className="App-header text-sm ">
    Users
    <div className="w-full flex flex-col p-2 text-sm ">
      {/* Filtres */}
      <div className="mb-2">
        <label className="mr-2">Filter by Role:</label>
        <select
          value={filterRole}
          onChange={(e) => setFilterRole(e.target.value)}
        >
          <option value="">All</option>
          <option value="admin">Admin</option>
          <option value="pro">Pro</option>
          <option value="regular">Regular</option>
        </select>
        <button onClick={filterUsers}>Apply </button>
        <button onClick={resetFilter}>Reset </button>
        <button onClick={refreshUsers}>Refresh </button>
      </div>
      {/* Tableau des utilisateurs */}
      <div className="w-full ">
            <div className="flex justify-between border-b border-zinc-500">
            <div className="flex-grow p-2">Email</div>
              <div className="w-16 p-2">Pro</div>
              <div className="w-16 p-2">Admin</div>
            </div>
            {filteredUsers.map((user, i) => (
              <div
                className="flex justify-between items-center border-t border-zinc-500 w-full"
                key={i}
              >
                <div className="flex-grow p-2">{user.email}</div>
                <div className="w-16 p-2">
                  {user.is_pro ? (
                    <span className="text-green-500">&#x2713;</span>
                  ) : (
                    <span className="text-red-500">&#x2717;</span>
                  )}
                </div>
                <div className="w-16 p-2">
                  {user.is_admin ? (
                    <span className="text-green-500">&#x2713;</span>
                  ) : (
                    <span className="text-red-500">&#x2717;</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        
    </div>
  </header>
</div>
  );
}

export default Users;
