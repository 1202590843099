import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useNavigate ,useParams} from "react-router-dom";
import * as fa from "@fortawesome/free-solid-svg-icons";




function    HeaderBack ({  title, iconRight,linkRight, linkLeft,classRight})  {


  const navigate = useNavigate();

  return         <div className="flex flex-row w-full justify-between items-center p-4 pb-0 text-white ">
  <div onClick={()=>navigate(linkLeft ??  -1)}>
    {" "}
    <FontAwesomeIcon
      icon={fa.faArrowLeft}
      className={"mx-4  "}
    ></FontAwesomeIcon>{" "}
  </div>
  <span className="text-2xl font-extrabold">{title} </span>
{
linkRight ?

  <Link to={linkRight}  >
    <FontAwesomeIcon
      icon={iconRight}
      className={ "p-2 " + classRight}
    ></FontAwesomeIcon>
  </Link>
  :
  <FontAwesomeIcon
      icon={fa.fa1}
      className="opacity-0  p-2"
    ></FontAwesomeIcon>
}

</div>


} 

export default HeaderBack;
