import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  version,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";
import {
  _GetSetTers,
  arrayMerge,
  contentConvert,
  createClass,
  getConditions,
  getProps,
  getPropsFromTemplates,
  getStructure,
  setPropInputs,
  updateStructure,
} from "../../../../tools/tpl";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { captureContent } from "../../../../service/background";

import "../../../../App.css";
import { MfctContext } from "../../../../context";

import UserPin from "../../../../components/UserPin";

import { data } from "../../../../service/data";

import TemplateTree from "./TemplateTree";
import TemplateParams from "./sideBars/TemplateParams";
import ShowTagStructure from "./sideBars/ShowTagStructure";
import ShowTemplateStructure from "./sideBars/ShowTemplateStructure";
import ShowTemplateEvent from "./sideBars/ShowTemplateEvent";
import ShowComponentTools from "./sideBars/ShowComponentTools";
import ShowComponentAddTools from "./sideBars/ShowComponentAddTools";
import ShowTestingContent from "./sideBars/ShowTestingContent";

import ButtonTool from "../../../../components/ButtonTool";
import { ModalPositions, showModal } from "../../../../components/modal";
import {
  getActions,
  loadAction,
  loadActions,
  runAction,
  runActionSync,
} from "../../../../tools/actions";
import ShowTemplateProps from "./sideBars/ShowTemplateProps";
import ShowLocalContent from "./sideBars/ShowLocalContent";
import { addWorkingStone } from "../../../../service/milestone";
import ShowDataSource from "./sideBars/ShowDataSource";
import { loadAppFonts, loadFontAsync } from "../../../../tools/fonts";
import InputSelect from "../../../../components/inputs/InputSelect";

var userActives = [];
var globaltestContent;
function SetTemplate() {
  const {
    project,

    authUser,

    cache,
    getApps,
  } = useContext(MfctContext);
  const { template_id } = useParams();

  const session_id = "S" + authUser.user_id;
  const [dragged, setDragged] = useState();
  const [dragOverZone, setDragOverZone] = useState();
  const [templateVersions, setTemplateVersions] = useState();
  const [localContent, _setLocalContent] = useState({});
  const setLocalContent = (c) => {
    if (
      show.localContent &&
      JSON.stringify(localContent) !== JSON.stringify({ selected, ...c })
    ) {
      _setLocalContent({ selected, ...c });
      console.log({ selected, localContent });
    }
  };

  const [draggedZone, setDraggedZone] = useState();

  const randomColor = () => {
    // Générer trois composants de couleur RGB aléatoires
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Convertir les composants RGB en chaîne hexadécimale et les concaténer
    const couleurHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return couleurHex;
  };
  const [users, setUsers] = useState([
    {
      session_id,
      imageUrl: authUser.profile.imageUrl,
      bgColor: randomColor(),
    },
  ]);

  const [baseContent, setBaseContent] = useState({
    "App.Name": "...",
  });
  const [page, setPage] = useState();
  const apps = getApps ? getApps() : [];
  const [app, setApp] = useState();

  const [explode, setExplode] = useState();

  const [contents, setContents] = useState([]);

  const setCompleteContents = setContents;
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState();
  const [selectedBloc, setSelectedBloc] = useState();
  const [selected, setSelected] = useState();
  const [schemas, setSchemas] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState();

  const [saveAll, setSaveAll] = useState({});
  const [loading, setLoading] = useState(false);
  const [internalContent, setInternalContent] = useState({});
  const [isMod, setIsMod] = useState();
  const [show, setShow] = useState({ showHidden: true });
  const [showTree, setShowTree] = useState();
  const [showProps, setShowProps] = useState();
  const [roles, setRoles] = useState([]);
  const [scale, setScale] = useState(0.9);

  const [ComponentAddTools, setComponentAddTools] = useState({});
  const [ComponentStructTools, setComponentStructTools] = useState({});
  const [ComponentTools, setComponentTools] = useState({});
  const setInternal = (key, value) => {
    setInternalContent((_content) => ({ ..._content, [key]: value }));
  };
  const loadTools = async () => {
    const addtoolfiles = require.context("./addTools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire addTools
    const addcomponents = {};

    addtoolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      addcomponents[componentName] = addtoolfiles(key).default;
    });

    setComponentAddTools(addcomponents);

    const toolfiles = require.context("./tools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire tools
    const toolcomponents = {};

    toolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      toolcomponents[componentName] = toolfiles(key).default;
    });

    setComponentTools(toolcomponents);

    const structToolfiles = require.context("./structTools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire tools
    const structToolcomponents = {};

    structToolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      structToolcomponents[componentName] = structToolfiles(key).default;
    });

    setComponentStructTools(structToolcomponents);
  };

  useEffect(() => {
    loadTools();
  }, []);

  const getRoles = async (app_id) => {
    var _roles = await project.api("/get_myroles/:app_id", {
      args: { app_id },
    });
    console.log(_roles);
    setRoles(_roles);
  };

  const cancelling = async () => {
    setTemplateVersions();
    localStorage.removeItem("templateVersions_" + template_id);

    data.cancelPage(page);
  };

  const undoing = async () => {
    var versions = templateVersions;
    versions.pop();
    var newtemplate = versions[versions.length - 1];

    data.updateTemplate(newtemplate);
  };

  const saving = async () => {
    setLoading(true);
    localStorage.removeItem("templateVersions_" + template_id);
    await data.saveAll();
    setLoading(false);

    setTimeout(captureTemplate, 100);
  };
  const [testContent, setTestContent] = useState();
  const [propsTemplates, setPropsTemplates] = useState();
  const [propsFromTemplates, setPropsFromTemplates] = useState();

const ScreenSizes =[

  {deviceName: "iPhone 13/14", style: {width: "390px", minHeight: "844px"}},

  {deviceName: "Samsung Galaxy S23", style: {width: "412px", minHeight: "915px"}},
  {deviceName: "Google Pixel 7", style: {width: "412px", minHeight: "915px"}},
  {deviceName: "Google Pixel 6", style: {width: "411px", minHeight: "823px"}},
  {deviceName: "OnePlus 10 Pro", style: {width: "412px", minHeight: "920px"}},
  {deviceName: "iPad Pro 12.9\"", style: {width: "1024px", minHeight: "1366px"}},
  {deviceName: "iPad Air", style: {width: "820px", minHeight: "1180px"}},
  {deviceName: "Surface Pro 9", style: {width: "912px", minHeight: "1368px"}},
  {deviceName: "MacBook Pro 16\"", style: {width: "1536px", minHeight: "960px"}},
  {deviceName: "MacBook Air M2", style: {width: "1280px", minHeight: "832px"}},
  {deviceName: "iMac 24\"", style: {width: "1280px", minHeight: "720px"}},
  {deviceName: "Dell XPS 13", style: {width: "1920px", minHeight: "1200px"}},
  {deviceName: "Surface Laptop 4", style: {width: "1536px", minHeight: "1024px"}},
  {deviceName: "1920x1080 Desktop", style: {width: "1920px", minHeight: "1080px"}},
  {deviceName: "1366x768 Desktop", style: {width: "1366px", minHeight: "768px"}},
  {deviceName: "2560x1440 Desktop", style: {width: "2560px", minHeight: "1440px"}}
]


var sc = localStorage.getItem("ScreenSize")
if(sc) sc= JSON.parse(sc)
const [ScreenSize,setScreenSize ] = useState( sc   ??{ width:"390px",minHeight:"844px"})



const changeDevice= (deviceName)=>{

var s = ScreenSizes.find(s => s.deviceName === deviceName);
setScreenSize( s.style   )
localStorage.setItem("ScreenSize",JSON.stringify(s.style))

}

  const key_schemas = useMemo(() => {
    var result = {};
    if (template) {
      if (template.schema_id)
        result[
          template.schema_id + (template.layout === "list" ? "_list" : "")
        ] = ["self"];

      if (propsFromTemplates && propsFromTemplates[template.template_id]) {
        for (let prop of Object.values(
          propsFromTemplates[template.template_id].props
        )) {
          if (!prop.schema_id) continue;
          var s =
            prop.schema_id +
            (prop.type_nombre === "I" || prop.type_nombre === "F"
              ? "_list"
              : "");
          result[s] = arrayMerge(result[s] ?? [], [prop.name]);
        }
      }

      var parts = (   (selected + "").replace("-root.","") ).split(".");
      var structure = template.structure;
      console.log("STARTING  key_schemas "  + parts.join(".") , { structure } );
      while (structure) {
        let label = structure.schema_prop ?? structure.schema_label;

        var index = parts.shift();
        if(!index) break;
        var intIndex = parseInt(index.split(":"));
        console.log(" key_schemas "  + parts.join(".") , {label,intIndex, structure , result} );

        

        if (structure.schema_id && label) {
          var key = structure.schema_id + (structure.isList ? "_list" : "");

          if (structure.isList &&   !isNaN( intIndex)) {    // && !structure.template_id
            key = structure.schema_id + "";

            result[key] = arrayMerge(result[key], [label]);
            result[key+"_list"]=  result[key+"_list"].filter(c=>  c!== label)
            console.log(selected + " ADD  ->  key_schemas", result);

          } else {
            result[key] = arrayMerge(result[key], [label]);
            console.log(selected + " ADD  ->  key_schemas", result);
          }
        }

        if (  !isNaN( intIndex) && structure.children[intIndex]) {
          structure = { ...structure.children[intIndex] };
        } else structure = null;
      }

      console.log(selected + " key_schemas !!! ", {
        template,
        propsFromTemplates,
        result,
      });
    }

    return result;
  }, [template, selected, propsFromTemplates]);

  const convertContent = useMemo(() => {
    var ccv = contents.find(
      (c) => c.content_id === "content_template_test_" + template_id
    );
    let ccv0 = contentConvert(ccv);
    console.log("Go ContentConverting ", { ccv0, ccv });

    if (ccv0 && propsTemplates && propsTemplates.length > 0) {
      propsTemplates.forEach((p) => {
        if (p.self) {
          var newccv = {};
          Object.keys(ccv0).forEach((key) => {
            var newkey = key.replace(p.name + ".", "");
            if (newkey) newccv[newkey] = ccv0[key];
          });
          ccv0 = newccv;
        }
      });
    }
    return ccv0;
  }, [contents, propsTemplates]);

  const updateTestContent = async (tc, _schemas) => {
    globaltestContent = tc;
    console.log("vidange x", { globaltestContent, tc });
    await data.updateContent(tc);
    await data.getCompleteContents([tc.content_id], _schemas);
  };

  const GetActions = useMemo(() => {
    var values = [];
    if (template && templates.length > 0)
      values = getActions(template, templates);

    return () => values;
  }, [template, templates]);

  const GetSetTers = (template, getSelf, setSelf, infos) => {
    return {
      ..._GetSetTers(
        template,
        getSelf,
        setSelf,
        "content_template_test_" + template_id,
        contents,
        propsFromTemplates,
        schemas,
        infos
      ),
      GetSetTers,
    };
  };

  const GetSetTersProps = (template, getSelf, setSelf, infos) => {
    return {
      ..._GetSetTers(
        template,
        getSelf,
        setSelf,
        "content_template_test_" + template_id,
        contents,
        { ...propsFromTemplates, ...template.propsFromTemplates },
        schemas,
        infos
      ),
      GetSetTers: GetSetTersProps,
    };
  };

  useEffect(() => {
    const computeProps = async () => {
      if (!template) return;

      var _propsFromTemplates = await getPropsFromTemplates(
        template.template_id,
        cache,
        templates,
        project
      );

      for (let prop of Object.values(
        _propsFromTemplates[template.template_id].props
      )) {
        if (prop.computed) {
          console.log("Loading Action" + prop.name, prop.computed);
          await loadAction(prop.computed);
        }
      }

      if (
        JSON.stringify(_propsFromTemplates) !==
        JSON.stringify(propsFromTemplates)
      )
        setPropsFromTemplates(_propsFromTemplates);

      var _propsTemplates = [];
      _propsTemplates = await getProps(
        template.structure,
        cache,
        templates,
        project,
        template.template_id
      );

      console.log("Recalculating props...", _propsTemplates);
      if (template.schema_id) {
        var sch = schemas.find(
          (s) => s.content_id + "" === "" + template.schema_id
        );
        if (sch) {
          if (sch.content.is_value)
            _propsTemplates = arrayMerge(
              _propsTemplates,
              [
                {
                  name: "self",
                  self: true,
                  schema_id: template.schema_id,
                },
              ],
              "name",
              true
            );
          else
            _propsTemplates = arrayMerge(
              _propsTemplates,
              [
                {
                  name: "self", // sch?.title ?? "S " + template.schema_id,
                  self: true,
                  schema_id: template.schema_id,
                },
              ],
              "name",
              true
            );
        }
      }

      console.log("_propsTemplates", schemas, _propsTemplates);
      if (JSON.stringify(_propsTemplates) !== JSON.stringify(propsTemplates))
        setPropsTemplates(_propsTemplates);
    };

    computeProps();
  }, [template, schemas]);
  useEffect(() => {
    const dataInit = async () => {
      console.log("dataInit ", template_id);
      await data.init(
        "template",
        template_id,
        true,
        project,
        {
          setApp,
          setTemplates,
          setTemplate,
          setContents,
          setUser,
          setSchemas,
          setSaveAll,
          setCompleteContents,
        },
        { session_id: "S" + authUser.user_id },
        true
      );

      // Recuperer la page *
      let template = await data.getTemplate(template_id);

      if (!template) navigate("/404");

      // Recuperer l'app
      document.title = "✎TPL " + template.title;
      // Recuperer les schemas

      await   addWorkingStone( template,"Edition" ,"template"  )   
      console.log("dataInit ok", template);
    };

    dataInit();
    return async () => {
      await data.close();
    };
  }, [template_id]);

  var app_id;


 useEffect(()=>{

  if(template?.app_id) loadAppFonts(template.app_id)


},[template])


  const getAppData = async () => {
    var _schemas;
    if (template.app_id) {
      let _app = await data.getApp(template.app_id);

      await getRoles(_app.app_id);
      _schemas = await data.getSchemasByApp(_app.app_id);

      if (!globaltestContent)
        globaltestContent = {
          content_id: "content_template_test_" + template_id,
          template_id,
          test: true,
          app_id: _app.app_id,
          content: { domain: _app.app_id },
        };
    } else {
      _schemas = await data.getSchemas();

      if (!globaltestContent)
        globaltestContent = {
          content_id: "content_template_test_" + template_id,
          template_id,
          test: true,
          content: {},
        };
    } // Recuperer les templates
    let _templates = await data.getTemplates();
    // Recuperer les contents
    let _contents = await data.getContents();
    app_id = _templates.app_id;
    setTestContent(globaltestContent);
    await updateTestContent(globaltestContent, _schemas);
  };

  useEffect(() => {
    if (!template) return;
    var versions = localStorage.getItem("templateVersions_" + template_id);
    if (!versions) versions = [];
    else versions = JSON.parse(versions);

    var index = versions.findIndex(
      (t) => JSON.stringify(t) === JSON.stringify(template)
    );
    if (index !== -1) {
      if (index === versions.length - 1) return;
      versions = versions.filter((_, i) => i <= index);
      setTemplateVersions(versions);
      localStorage.setItem(
        "templateVersions_" + template_id,
        JSON.stringify(versions)
      );
      return;
    }
    versions.push(JSON.parse(JSON.stringify(template)));
    setTemplateVersions(versions);
    localStorage.setItem(
      "templateVersions_" + template_id,
      JSON.stringify(versions)
    );
  }, [template]);

  useEffect(() => {
    if (template && app_id !== template.app_id) getAppData();
  }, [template]);

  const isEditable = useMemo(() => {
    return template && data.isGranted(authUser, template, "template", "update");
  }, [template]);





  useEffect(() => {
    var baseContent = {};
    if (app) {
      baseContent = {
        Titre: template.title,
        domain: app.domain,
        "App.Name": app.name,
        "App.Logo":
          process.env.REACT_APP_MFTC_SERVER_URL_FILES +
          `profiles/${app.domain}/user.png`,
        "App.Domain": app.domain,
        "App.Description": app.description,
        "App.primary_color": app.profile.primary_color,
        "App.secondary_color": app.profile.secondary_color,
        "App.login": `window.alert("Login!!")`,
        "App.logout": `window.alert("Logout")`,
        "App.goBack": `window.alert("navigate(-1)")`,
      };

      createClass(
        "bg-primary_color",
        ` background-color :${app.profile.primary_color} ;  `
      );
      createClass(
        "border-primary_color",
        ` border-color :${app.profile.primary_color} ;   `
      );
      createClass(
        "text-primary_color",
        ` color :${app.profile.primary_color}  ;  `
      );
      createClass(
        "border-secondary_color",
        ` border-color :${app.profile.secondary_color} ;   `
      );
      createClass(
        "bg-secondary_color",
        ` background-color :${app.profile.secondary_color}  ;  `
      );
      createClass(
        "text-secondary_color",
        `color :${app.profile.secondary_color}  ;  `
      );
    }

    baseContent = {
      ...baseContent,
      ...setPropInputs(template, schemas, templates),
    };
    console.log("baseContent", { template, baseContent, templates, schemas });
    setBaseContent(baseContent);

    const loadAndRunActions = async () => {
      var actions = GetActions();
      await loadActions(actions);

      var onStartActions = actions.filter(
        (action) => action.Event === "onStart"
      );
      for (let startAction of onStartActions) {
        if (startAction.asynchrone)
          await runAction(
            null,
            startAction,
            internalContent,
            setInternal,
            true
          );
        else
          runActionSync(null, startAction, internalContent, setInternal, true);
      }
    };

    loadAndRunActions();
  }, [template, app, schemas, templates]);

  const _setSelectedBloc = (selectedBloc) => {
    setSelectedBloc(selectedBloc);
  };

  const [schema_id, setSchema_id] = useState();
  useEffect(() => {
    if (template?.schema_id !== schema_id) {
      console.log("setSchema_id", template.schema_id, schema_id);
      setSchema_id(template.schema_id);
    }
  }, [template]);

  const globalSchema = useMemo(() => {
    if (schemas && schema_id) {
      // console.log("recalculate globalSchema ", schema_id,schemas)
      var r = schemas.find((s) => s.content_id === parseInt(schema_id));
      if (r) {
        r = {
          ...r,
          content: {
            ...r.content,
            schema: [
              { name: "Titre", realName: "title", schema_id: 42, code: "text" },
              ...(r.content.schema ?? []),
            ],
          },
        };
      }
      //    console.log("recalculate globalSchema ", schema_id,r)
      return r;
    }
  }, [schemas, schema_id]);
  const _setSelectedTemplate = (selectedTemplate) => {
    setSelectedTemplate(selectedTemplate);
  };
  const _setSelected = (selected) => {
    setSelected(selected);
  };

  const showWireframe = (show) => {
    if (show) {
      document.body.classList.add("wireframe");
    } else {
      document.body.classList.remove("wireframe");
    }
  };

  const selectTpl = (id, tpl, noEmit, focus) => {
    // console.log("selectTpl to", id, tpl);
    var obj = document.getElementById(id);

    if (selected !== id) {
      var objFormer = document.getElementById(selected);
      if (objFormer) objFormer.classList.remove("selectedElement");
    }

    if (obj) {
      obj.classList.add("selectedElement");
      if (selected === id || focus)
        obj.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    }
    // if (selected === id) return;
    if (tpl) {
      _setSelectedTemplate(tpl);
      if (tpl.schema_label) {
      }
    }

    _setSelected(id);

    if (!isMod && id) {
      var _structure = getStructure(template.structure, id);
      if (_structure.className?.startsWith("new ") && !isMod) {
        setIsMod(true);
        showModal("Brand New", {
          closeDelay: 5000,
          position: ModalPositions.RIGHTBOTTOM,
        });
      }
    }

    //    console.log("userActives", userActives, users);
    if (userActives) {
      var _user = userActives.find((user) => user.session_id === session_id);
      if (_user && _user.selected !== id) {
        _user.selected = id;

        setUser(_user, null, true);
      }
    }

    if (!noEmit && id)
      data.emit({
        user: {
          selected: id,
          selectedBloc: id.split(".")[0] + "-edit",
          selectedTemplate: tpl,
        },
      }); //,
  };

  const setUser = (_user, quit, yesEmit) => {
    console.log("UserpINS Start", userActives, _user, session_id);
    var newusers = [];
    if (quit) {
      newusers = userActives.filter(
        (user, i) => user.session_id !== _user.session_id
      );
    } else {
      if (userActives.find((user) => user.session_id === _user.session_id))
        newusers = userActives.map((user, i) =>
          user.session_id === _user.session_id ? _user : user
        );
      else {
        newusers = [...userActives, _user];
        sendMsg({});
      }
      console.log("UserpINS Before", newusers);
    }
    userActives = newusers.map((user, i) => ({
      ...user,
      pos: newusers.filter(
        (user0, i0) => i0 < i && user.selected === user0.selected
      ).length,
    }));
    console.log("UserpINS ", userActives);

    setUsers(userActives);
  };

  const sendMsg = (props) => {
    var _user = userActives.find((user) => user.session_id === session_id);
    props = { user: _user, ...props, template_id };

    data.emit(props);
  };

  const self = useMemo(() => {
    if (!template?.schema_id || !schemas) {
      return {};
    }
    var schema = schemas.find(
      (s) => s.content_id === parseInt(template?.schema_id)
    );
    if (!schema) return null;
    var props = schema.content.schema;
    let result;
    if (schema.content.is_value) {
      result = { ...testContent, ...internalContent, ...globaltestContent }[
        "self"
      ];
    } else {
      result = { schema_id: template.schema_id };

      props?.forEach((val) => {
        result[val.name] = { ...testContent, ...internalContent }[val.name];
      });
    }
    console.log("VIDANGE", {
      self: result,
      testContent,
      internalContent,
      globaltestContent,
    });
    return JSON.stringify(result);
  }, [template, schemas, testContent, internalContent, globaltestContent]);

  const conditions = useMemo(() => {
    console.log("Init conditions");
    if (!template) return;
    var c = getConditions(template.structure);
    console.log("Nouvelles conditions", c);
    return c;
  }, [template]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    //  setBackground();
  }, []);

  useEffect(() => {
    console.log("template ", template);
    if (template && template["structure.json"]) {
      window.alert(
        "Le JSON Du template n'est pas controlable à corriger dans bdd !!!"
      );
      window.alert(template["structure.json"]);
      navigate("/templates");
      return;
    }
    if (template && !("children" in template.structure)) {
      window.alert("Structure mal formée :  absence de children");
      navigate("/templates");
      return;
    }
    if (!selected && template) selectTpl("-root", null, null, true);
  }, [template, selected]);

  const navigate = useNavigate();

  const captureTemplate = () => {
    selectTpl();
    setTimeout(() => {
      captureContent(
        "-root",
        `users/${template.owner_id}`,
        `template_${template_id}_thumb.png`,
        project
      );
    }, 20);
  };

  const dragging = (pos) => {
    if (pos.end) {
      setDragged();

      // C'est fini
    }
  };

  const cleanStructure = (structure, id) => {
    if ("children" in structure)
      structure.children = structure.children
        .filter((child) => {
          return !child.dragged;
        })
        .map((child, i) => {
          return cleanStructure(child, id + "." + i);
        });

    return structure;
  };

  const deleteTemplate = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete '" + template.title + "'?"
      )
    )
      return;
    await project.api("/delete_template/:template_id", {
      args: { template_id: template.template_id },
    });

    navigate("/templates");
  };

  const duplicateTemplate = async () => {
    var _template = JSON.parse(JSON.stringify(template));
    delete _template.template_id;
    _template.title = window.prompt(
      "Saisir le nom du nouveau template ",
      template.title + "-Copy"
    );
    if (!_template.title) return;
    await data.close();
    await data.init("template", "", false, project);
    _template = await data.newTemplate(_template);
    await data.close();

    navigate(
      "/template/" + _template.template_id,
      "viewtpl" + _template.template_id,
      "menubar=no,toolbar=no,location=no,address=no,"
    );
  };

  const addAfter = async (tpl, id, prop, structure) => {
    var parts = id.split(".");
    var childId = parseInt(parts.pop());
    var parentId = parts.join(".");
    if (!parentId) {
      parentId = id;
      childId = 0;
    }
    if (!structure) structure = tpl.structure;
    if (prop) {
      structure = {
        template_id: tpl.template_id,
        className: " ",
        tag: "template",
        schema_id: prop.schema_id,
        isList: prop.type_nombre === "F" || prop.type_nombre === "I",
        listLength:
          prop.type_nombre === "F" || prop.type_nombre === "I" ? 2 : null,
        schema_prop: prop.name,
        children: [],
      };
    } else {
      if (tpl.schema_id) {
        structure = {
          template_id: tpl.template_id,

          className: " ",
          tag: "template",
          schema_id: tpl.schema_id,
          isList: tpl.layout === "list",
          listLength: tpl.layout === "list" ? 2 : null,
          schema_label: schemas.find(
            (s) => s.content_id + "" === "" + tpl.schema_id
          ).title,
          children: [],
        };
      }
    }
    var str = getStructure(template.structure, parentId);

    let children = [
      ...str.children.filter((child, i) => i <= childId),
      { children: [], ...structure },
      ...str.children.filter((child, i) => i > childId),
    ];

    console.log({ children });

    await updateTemplateStructure({ children }, parentId);
  };

  const updateTemplateStructure = async (props, id, newSelected) => {
    var newStructure = updateStructure(template.structure, props, id);
    console.log({ newStructure });

    setTemplate({
      ...template,
      structure: newStructure,
      props_from_templates: propsFromTemplates,
    });

    await data.updateTemplate({
      ...template,
      structure: newStructure,
      props_from_templates: propsFromTemplates,
    });

    if (newSelected)
      setTimeout(() => {
        selectTpl(newSelected);
      });
  };

  const getTemplateStructure = (id) => {
    return getStructure(template.structure, id);
  };

  const scrollIntoView = (id, block = "center") => {
    if (document.getElementById(id))
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block,
        inline: "start",
      });
  };




  const contentWithFuncs = useMemo(()=>{

    return {  self,
      ...baseContent,
      ...convertContent,
      ...internalContent,
      ...GetSetTers(template, null, null, { content: {} }),
      GetSetTers,}
    
    
    },[self,baseContent,convertContent,internalContent,template,GetSetTers])
    
  return (
    <div className="App  text-zinc-600 text-sm">
      <div
        className="fixed top-4 right-4 flex flex-row"
        style={{ zIndex: 60 }}
      ></div>

      <div
        className="safearea-bottom fixed flex flex-row items-start justify-start bottom-2 left-2  p-1 rounded-full "
        style={{ zIndex: 62 }}
      >
        <div className="flex flex-col  items-end justify-end h-screen">
          <ButtonTool
            icon={fa.faCodeBranch}
            onClick={() => setShowTree(!showTree)}
            onMouseOver={() => scrollIntoView("Hiérarchie du template")}
            active={showTree}
            label={"Hiérarchie du template"}
            side="right"
          />
          {template && (
            <ButtonTool
              icon={fa.faAlignLeft}
              onMouseOver={() => scrollIntoView("ShowTemplateProps", "start")}
              onClick={() => setShowProps(!showProps)}
              active={showProps}
              label={"Propriétes"}
              side="right"
            />
          )}
        </div>
        <div className="flex flex-col  items-end justify-end  max-h-screen ">
          <div
            className="    overflow-y-scroll bg-black bg-opacity-10"
            style={{ height: "100vh" }}
          >
            <div className=" flex flex-col items-center justify-end  ">
              {showTree && (
                <TemplateTree
                  selectTpl={selectTpl}
                  id={"-root"}
                  hue={0}
                  schemas={schemas}
                  selected={selected}
                  setDraggedZone={setDraggedZone}
                  draggedZone={draggedZone}
                  setDragOverZone={setDragOverZone}
                  dragOverZone={dragOverZone}
                  template={template?.structure ?? {}}
                  setSelected={selectTpl}
                  setStructure={(structure, parentStructure, dropped) => {
                    if (!isEditable) return;
                    if (dropped)
                      structure = cleanStructure(structure, "footer");
                    data.updateTemplate({ ...template, structure });
                  }}
                  dragged={dragged}
                  setDragged={(_id) =>
                    setDragged(_id, template.template_id, "-root")
                  }
                  dragging={dragging}
                  isEditable={isEditable}
                />
              )}

              {showProps && (
                <ShowTemplateProps
                  id={"ShowTemplateProps"}
                  key={"ShowTemplateStructure" + selected}
                  content={{
                    self,
                    ...baseContent,
                    ...convertContent,
                    ...internalContent,
                    ...GetSetTers(template),
                  }}
                  GetSetTersProps={GetSetTersProps}
                  setIsMod={setShowProps}
                  isMod={showProps}
                  selected={selected}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  addAfter={addAfter}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="safearea-bottom fixed  w-full flex flex-row items-center justify-center top-1 left-0    p-1 rounded-full"
        style={{ zIndex: 60 }}
      >
        <InputSelect 
        className={"text-white"}
        label={""}  value={ ScreenSizes.find(s=> JSON.stringify( s.style   )===JSON.stringify(ScreenSize) ).deviceName }    onChange={ (deviceName)=>changeDevice(deviceName) }  options={ScreenSizes.map(s=>({label:s.deviceName,value:s.deviceName }))   }  />
        <ButtonTool
          icon={fa.faDesktop}
          side="bottom"
          label={"Version Desktop"}
          onClick={() => {
            setShow((show) => ({ ...show, Desktop: !show.Desktop }));
          }}
          active={show.Desktop}
          nolabelActive={true}
        />
        <ButtonTool
          icon={fa.faMinus}
          onClick={() => setScale(scale * 0.9)}
          onMouseOver={() => setScale(scale * 0.98)}
          side="bottom"
          label={"Dézoomer"}
        />
        <div className="flex flex-col items-center justify-start ">
          {" "}
          <ButtonTool
            icon={fa.faExpand}
            onClick={() => {
              setScale(0.9);
              selectTpl("-root");
            }}
            side="bottom"
            label={"Center Grandeur réelle "}
          />
          <div className="text-sm text-white ">{Math.round(scale * 100)} %</div>
        </div>
        <ButtonTool
          icon={fa.faPlus}
          onClick={() => setScale(scale / 0.9)}
          onMouseOver={() => setScale(scale / 0.98)}
          side="bottom"
          label={"Zoomer"}
        />
        <ButtonTool
          icon={fa.faTableCells}
          side="bottom"
          label={" Wireframe"}
          onClick={() => {
            showWireframe(!show.Wireframe);
            setShow((show) => ({ ...show, Wireframe: !show.Wireframe }));
          }}
          active={show.Wireframe}
          nolabelActive={true}
        />
      </div>
      <div
        className="safearea-bottom fixed flex flex-col items-end justify-end bottom-2 right-2  h-screen p-1 rounded-full"
        style={{ zIndex: 60 }}
      >
        <div className="flex flex-row items-end justify-end h-full ">
          <div className=" p-1  h-full overflow-y-scroll ">
            <div className=" flex flex-col items-center justify-end min-h-full pt-14  ">
              {show.Tag && (
                <ShowTagStructure
                  key={"ShowTagStructure" + selected}
                  content={baseContent}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}
                />
              )}
              {isMod && selected && (
                <ShowTemplateStructure
                  key={"ShowTemplateStructure" + selected}
                  content={baseContent}
                  setIsMod={setIsMod}
                  isMod={isMod}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}
                />
              )}
              {show.Plus && (
                <ShowComponentAddTools
                  key={"ShowComponentAddTools" + selected}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  ComponentAddTools={ComponentAddTools}
                  selectTpl={selectTpl}
                  key_schemas={key_schemas}
                />
              )}
              {show.Code && (
                <ShowComponentTools
                  key={"ShowComponentTools" + selected}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  ComponentTools={ComponentTools}
                  selectTpl={selectTpl}
                  key_schemas={key_schemas}
                />
              )}
              {show.Event && (
                <ShowTemplateEvent
                  key={"ShowTemplateEvent" + selected}
                  content={baseContent}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  key_schemas={key_schemas}
                />
              )}
              {show.Gear && (
                <TemplateParams
                  key={"TemplateParams" + selected}
                  template={template}
                  setShow={setShow}
                  schemas={schemas}
                  apps={apps}
                  roles={roles}
                  conditions={conditions}
                  internalContent={internalContent}
                  setInternalContent={setInternalContent}
                  deleteTemplate={
                    data.isGranted(authUser, template, "template", "delete") &&
                    deleteTemplate
                  }
                  key_schemas={key_schemas}
                />
              )}
                            {show.DataSource &&  (
                <ShowDataSource
                 
                  content={contentWithFuncs}
                  setShow={setShow}
               
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}
                />
              )}
              {show.Data && (
                <ShowTestingContent
                  setShow={setShow}
                  schemas={schemas}
                  apps={apps}
                  testContent={testContent}
                  conditions={conditions}
                  internalContent={internalContent}
                  setInternalContent={setInternalContent}
                  setInternal={setInternal}
                  updateTestContent={updateTestContent}
                  propsTemplates={propsTemplates}
                  infos={{
                    self,
                    ...baseContent,
                    ...convertContent,
                    ...internalContent,
                    ...GetSetTers(template),
                    GetSetTers,
                  }}
                  GetActions={GetActions}
                  key_schemas={key_schemas}
                />
              )}
              {show.localContent && (
                <ShowLocalContent
                  setShow={setShow}
                  localContent={localContent}
                  structure={template.structure}
                  selectTpl={selectTpl}
                  key_schemas={key_schemas}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col items-end justify-end ">
            {isEditable && selected && (
              <>
                <ButtonTool
                  onMouseOver={() => scrollIntoView("ShowTemplateStructure")}
                  icon={fa.faPen}
                  onClick={() => setIsMod(!isMod)}
                  active={isMod}
                  label={"Mode Édition"}
                />

                <ButtonTool
                  onMouseOver={() => scrollIntoView("ShowTagStructure")}
                  icon={fa.faHashtag}
                  onClick={() => {
                    setShow((show) => ({ ...show, Tag: !show.Tag }));
                  }}
                  active={show.Tag}
                  label={"Tag Attributes"}
                />

                <ButtonTool
                  onMouseOver={() => scrollIntoView("ShowComponentAddTools")}
                  icon={fa.faPlus}
                  onClick={() => {
                    setShow((show) => ({ ...show, Plus: !show.Plus }));
                  }}
                  active={show.Plus}
                  label={"Ajouter/Insérer"}
                />

                <ButtonTool
                  icon={fa.faCode}
                  onMouseOver={() => scrollIntoView("ShowComponentTools")}
                  onClick={() => {
                    setShow((show) => ({ ...show, Code: !show.Code }));
                  }}
                  active={show.Code}
                  nolabelActive={true}
                  label={"Style CSS / Classes "}
                />

                <ButtonTool
                  icon={fa.faPlay}
                  onMouseOver={() => scrollIntoView("ShowTemplateEvent")}
                  onClick={() => {
                    setShow((show) => ({ ...show, Event: !show.Event }));
                  }}
                  active={show.Event}
                  label={"Click / Lien / Evènements"}
                />
                {selected !== "-root" && (
                  <ButtonTool
                    icon={fa.faTrash}
                    onClick={() => {
                      var parts = selected.split(".");
                      var index = parseInt(parts.pop());
                      var idParent = parts.join(".");

                      var _structure = getStructure(
                        template.structure,
                        idParent
                      );

                      var newid = idParent;
                      _structure.children = _structure.children.filter(
                        (_, i) => i !== index
                      );

                      updateTemplateStructure(_structure, idParent, newid);
                    }}
                    defaultClass=" text-red-600  bg-black border-red-600 "
                    inactiveClass=" text-red-600  bg-black border-red-600"
                    label={"Supprimer l'élément sélectionné"}
                  />
                )}
                <div className="w-12 border-t border-white m-1 p-2 border-opacity-40 mb-10"></div>
              </>
            )}

            {isEditable && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("TemplateParams")}
                icon={fa.faGear}
                onClick={() => {
                  setShow((show) => ({ ...show, Gear: !show.Gear }));
                }}
                active={show.Gear}
                label={"Paramètres du template"}
              />
            )}
                        {  (
              <ButtonTool
                onMouseOver={() => scrollIntoView("ShowDataSource")}
                icon={fa.faDatabase}
                onClick={() => {
                  setShow((show) => ({ ...show, DataSource: !show.DataSource }));
                }}
                active={show.Data}
                label={"Data Source"}
              />
            )}
            {testContent && propsTemplates && propsTemplates.length > 0 && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("ShowTestingContent")}
                icon={fa.faNoteSticky}
                onClick={() => {
                  setShow((show) => ({ ...show, Data: !show.Data }));
                }}
                active={show.Data}
                label={"Données Test"}
              />
            )}
            {localContent && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("ShowLocalContent")}
                icon={fa.faBug}
                onClick={() => {
                  setShow((show) => ({
                    ...show,
                    localContent: !show.localContent,
                  }));
                }}
                active={show.localContent}
                label={"Données Element"}
              />
            )}

            <ButtonTool
              icon={fa.faPaste}
              onClick={duplicateTemplate}
              label={"Dupliquer le template "}
              inactiveClass=" bg-green-500 text-white  bg-opacity-100 "
            />

          </div>
        </div>
        <div className="flex flex-row ">
          {
            <>
              {isEditable && Object.values(saveAll).length > 0 && (
                <>
                  {templateVersions && templateVersions.length - 1 > 0 && (
                    <ButtonTool
                      icon={fa.faChevronLeft}
                      onClick={undoing}
                      defaultClass=" text-red-600  bg-black border-red-600 "
                      inactiveClass=" text-red-600  bg-black border-red-600"
                      side="top"
                      label={"Annuler l'action précédente"}
                      badge={templateVersions && templateVersions.length - 1}
                    />
                  )}
                  <ButtonTool
                    icon={fa.faAngleDoubleLeft}
                    onClick={cancelling}
                    defaultClass=" text-red-600  bg-black border-red-600 "
                    inactiveClass=" text-red-600  bg-black border-red-600"
                    side="top"
                    label={"Abandonner le brouillon"}
                  />
                  <ButtonTool
                    icon={fa.faCheck}
                    onClick={saving}
                    side="top"
                    defaultClass="text-green-600  bg-black border-green-600 "
                    inactiveClass=" text-green-600 bg-black border-green-600 "
                    label={"Enregistrer les modifications"}
                  />
                </>
              )}
            </>
          }
          <ButtonTool
            icon={fa.faClose}
            onClick={() => {
              navigate(-1);
            }}
            side="topleft"
            label={"Fermer le template"}
          />
        </div>
      </div>

      {project && template && conditions && (
        <header className=" relative w-screen h-screen overflow-scroll ">
          <div
            className="z-1 w-full  flex flex-col items-center p-4 min-h-screen justify-center transition-all "
            id="template-content"
            style={{
              transform: `scale(${scale})`,
              width: "200vw",
              height: "200vh",
            }}
          >
            <div className=" h-screen w-screen  flex flex-col items-center justify-center ">
              <div   className="flex flex-row items-start ">
              <div className={"  bg-black  items-center justify-center  border-black pt-14 pb-10 p-2 box-content rounded-xl "    }>
              <div className={ "bg-white " +  ( show.showScroll? "":" flex flex-row  items-center justify-start  "  )  }     style={{...ScreenSize, ...(show.showScroll? {height:ScreenSize.minHeight,overflowY:"auto"}:{})   }} >
                {template && (
                  <TemplateX
                    template={{ ...template.structure  } }
                    setInternal={setInternal}
                    setLocalContent={setLocalContent}
                    content={{
                    ...contentWithFuncs,
                      updateTemplateStructure,
                      getTemplateStructure,
                      dynamicsProps: {
                        onDragOver: (e) => {
                          //   e.stopPropagation();
                          e.preventDefault();
                        },
                        onDrop: async (e) => {
                          e.target.classList.remove(
                            "border-green-500",
                            "border-4",
                            "scale-2"
                          );
                          //  console.log(e.target)
                          var elt = e.target;

                          while (elt && !elt.id) elt = elt.parentNode;
                          var id = elt.id;
                          if (!id) return;

                          // e.preventDefault();
                          e.stopPropagation();
                          const data =
                            e.dataTransfer.getData("application/json");
                          console.log(data);
                          //   window.alert( e.target.id +  " " + template.schema_prop  +" : " +data)
                          var { tpl, prop, structure } = JSON.parse(data);

                          await addAfter(tpl, id, prop, structure);

                          //   window.alert(parentId +" " + JSON.stringify( children))
                        },
                        onDragEnter: (e) => {
                          e.target.classList.add(
                            "border-green-500",
                            "border-4",
                            "scale-2"
                          );
                        },
                        onDragLeave: (e) => {
                          e.target.classList.remove(
                            "border-green-500",
                            "border-4",
                            "scale-2"
                          );
                        },
                      },
                      showHidden: show.showHidden,
                    }}
                    templates={templates}
                    selectTpl={selectTpl}
                    id={"-root"}
                    selected={selected}
                    safeDisplay={true}
                  />
                )}
              </div>
              </div>

<div className="flex flex-col">
<ButtonTool
              icon={show.showHidden ? fa.faEye : fa.faEyeSlash}
              onClick={() => {
                setShow((show) => ({
                  ...show,
                  showHidden: !show.showHidden,
                }));
              }}
              active={show.showHidden}
              label={"Afficher les objets masqués"}
            />



            {isEditable && (
              <ButtonTool
                icon={fa.faPhotoFilm}
                onClick={captureTemplate}
                label={"Scrolling"}
                inactiveClass="  bg-yellow-500 text-white  bg-opacity-100 "
              />)}
              <ButtonTool
              icon={show.showScroll ? fa.faUpDown : fa.faUpDown}
              onClick={() => {
                setShow((show) => ({
                  ...show,
                  showScroll: !show.showScroll,
                }));
              }}
              active={show.showScroll}
              label={"Scrolling Activé"}
            />
</div>

            </div>
            </div>
            {users.map((user) => (
              <UserPin
                key={user.session_id}
                id={user.selected}
                user={user}
                root_id={"template-content"}
              />
            ))}
          </div>
        </header>
      )}
    </div>
  );
}

export default SetTemplate;
