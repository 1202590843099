import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { _GetSetTers, contentConvert, loadAllContentAsync, loadCompleteContentAsync } from "../../tools/tpl";
import TemplateX from "../templates/TemplateX";
import { MfctContext } from "../../context";
import { data } from "../../service/data";
import VarContent from "../VarContent";
import { retrieveProject } from "../../service/mftc-api";








const InputMultiSelectContent = ({   label,
    onChange,
    value,
    className,
    options,
    placeholder,
    trueValue,
    valueType,
    updateContent,
    domain,
    schema_id,
   context,
    
    id }) => {




  
        const { getTemplates,getSchemas } = useContext(MfctContext);
        const [_value, setValue] = useState(value);
const [values,setValues] = useState(options);
      
        const [displayTemplate, setDisplayTemplate] = useState();
      
        const [completeContents, setCompleteContents] = useState([]);
        const [contents, setContents] = useState([]); 
        var templates = getTemplates();
      

     
        var schemas =getSchemas();







const DisplayContent = ({content,onClick})=> <>{displayTemplate && 
 
      <div className="flex flex-row w-full items-center justify-center ">
      
       <TemplateX
          content={ 
            
            { ..._GetSetTers( displayTemplate,null,null,"global",[{content_id:"global",content:{self:content}},...contents],displayTemplate.props_from_templates ??{}  , schemas )
          ,GetSetTers:(template, getSelf, setSelf,content_id)=> _GetSetTers( template,getSelf,setSelf,"global",[{content_id:"global",content:{self:content}},...contents],displayTemplate.props_from_templates ??{}  , schemas )}
        //    {...GetSetTers  (displayTemplate, null, null,content.content_id)}
        }
          template={displayTemplate.structure}
          templates={templates}
          selectTpl={onClick}
          id={label +"." +content.content_id }
          safeDisplay={true}
        />
               
   
      </div>

  }
  {!displayTemplate && (
    <div className={" relative flex  items-center p-2" + className} onClick={onClick}>
   {  content.title}
    
    </div>
  )}</>



        
        useEffect(() => {
          setValue(value);
        }, [value, trueValue]);
      
      
      
        console.log(`ShowOptions For Label ${label}`, options);
      
        const getOptions = async () => {
            var app
               if(domain)
         { app = await data.getAppByDomain(domain);
          if (!app) {
            console.log("Couldn't find application", domain);
          //  return;
          
          }}
          var _values 
          var schema = await data.getSchema( schema_id  )
      if(schema.content?.entity){

        _values = await     data.loadEntities(schema.content?.entity,domain,context )

         
      }
          else { _values = await data.getContents(
            (c) =>
            (c.is_public ||  (app && c.app_id + "" === "" + app.app_id)) &&
          ( schema_id ===509 ||     c.schema_id + "" === "" + schema_id) ,
            true
          );
   
        }
          console.log(`Find Resutl $$$$$$$$$$$ ${label}`, _values, schema_id, app?.app_id);

       
          if (schema.content.display_template_id  ) {
      
       
            var _template = await data.getTemplate(parseInt(schema.content.display_template_id));
            setDisplayTemplate(_template);
            console.log(_template);
          }

          if(   !schema.content?.entity && schema.content.display_template_id ){

            var contents = [];
            var _completecontents =[]
            for (var i = 0; i < _values.length; i++) {
            
              contents.push(_values[i]);
              _completecontents = await loadAllContentAsync(_values[i],_completecontents,schemas)
            }
            contents.sort((a,b)=> ((a.title ?? a.label )+"").localeCompare(b.title ?? b.label, 'fr', { sensitivity: 'base' }));

            setContents(_completecontents)

            setValues(contents)
         
          } else {
            _values.sort((a,b)=> ((a.title ?? a.label )+"").localeCompare(b.title ?? b.label, 'fr', { sensitivity: 'base' }));
            setValues(_values)
            setContents(_values)
          }
        };
      
        useEffect(() => {
          if ( !values && domain) {
            getOptions();
            console.log("Get Options YESS",{label,values,domain})
          }
          else {
            console.log("No Options Already OK",{label,values,domain})
          }
        }, [values, domain]);
      










    const [visibleValues, setVisibleValues] = useState();


    const removeValue = (v) => {

        var _values = v.value ?   (value ?? []).filter(val => (v.value !== val.value)    )
 :  (value ?? []).filter(val => (    ("" +v.content_id).split("_").pop() !== (""+val.content_id).split("_").pop())    )
 onChange(_values.filter(v=>v))
    }

    const addValue = (v) => {

        var _values = value ?? []

        _values.push(!v.content_id  ? ({label:v.label,value:v.value}) :  ({content_id:v.content_id}))

        onChange(_values.filter(v=>v))
    }

    if (!values  || values.length === 0) return <>
    </>

    return (
        <>  
            <div className=' px-2 text-xs border-t w-full border-dotted border-zinc-500 py-2 text-start flex flex-wrap items-center'>
                <div className='appbutton p-2 font-bold '>{label}</div>
              
                {values.filter(v => value && value.find(r => (v.content_id && v.content_id === r.content_id)  ||   (v.content.entity_name && v.content_id && v.content.entity_name +"_"+r.content_id === v.content_id)  || (v.value && v.value === r.value)  )).map(content => (
                    <span key={content.content_id ?? content.value} className='appbutton mb-1 mr-2 inline-block' >
                        <DisplayContent  content={content}  onClick={() => removeValue(content)} />
                    </span>
                   
                ))}{
                    (value??[]).length===0 && 
                    <span  className='mb-1 mr-2 inline-block' >
                 Ajouter {label}
                </span>
                }
                <FontAwesomeIcon
                    icon={visibleValues ? fa.faChevronLeft : fa.faChevronRight}
                    onClick={() => setVisibleValues(!visibleValues)}
                    title={"Gerer " + label}
                    className=" tooltip  text-xs p-1 w-2 h-2  border border-gray-500 rounded-full mx-1  "
                />
                {visibleValues &&
                    <>
                        {values.filter(v => !value || !value.find(r =>  (v.content_id && v.content_id === r.content_id)  ||  (v.value && v.value === r.value)  )).map(content=> (
                            <span key={content.content_id ?? content.value} className='appbutton mb-1 mr-2 inline-block opacity-50 '>
                               <DisplayContent  content={content}  onClick={() => addValue(content)} />
                            </span>
                        ))}
                    </>
                }
            </div>
        </>
    )
}
export default InputMultiSelectContent