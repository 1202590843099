import { useContext, useEffect, useMemo, useState } from "react"
import PaymentIntent from "../payment/PaymentIntent"
import TemplateX from "../templates/TemplateX"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { MfctContext } from "../../context";



const Props = [

];

const ProductComponent= ({content ,template, internals,setInternal, parentContent,key, classeProps ,templates, id,selectTpl,selected ,safeDisplay}) => {


const [show,setShow ]=useState();



const purchase =async ()=>{
 /*   var result = await   project.api("/create-checkout-session",{body: {lookup_key:content.Stripe_Id}})
    if(result.redirect)
    window.open(result.redirect,"_blank")
else
window.alert("Produit non disponible")*/

    setShow(true)
}


const endPurchase = ()=>{
    setShow(false)
}


return (
    <>
      <TemplateX key={key} content={{...content,parentContent,
    "Acheter":purchase,
    "AnnulerAchat":endPurchase,
    "LinkAchat": "/payment_process?product_id=" +content.content_id
  
}} template={template} safeDisplay={safeDisplay} 
            setInternal={setInternal} internals={ internals } classeProps={classeProps} templates={templates} id={id} 
            
            selected={selected}
            selectTpl={selectTpl}/>
 { show && 
    <div className="fixed top-0 left-0 w-screen h-screen flex flex-row justify-center  items-center bg-black bg-opacity-50 z-50 ">
    <div className="text-white absolute top-2 right-2 font-bold" onClick={endPurchase} ><FontAwesomeIcon icon={faClose} className=" w-8 h-8" /></div>
    <div className="bg-opacity-100 bg-white">
        <PaymentIntent  items={  [content] } end={endPurchase} />
        </div>
        </div> }
</> 
)
}
export  {ProductComponent,Props}