let db;

const initDB = async () => {
  const dbName = "appisyou";
  const dbVersion = 1;

  if (db) return db;

  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onupgradeneeded = (event) => {
      const _db = event.target.result;

      // Création des tables
      const appStore = _db.createObjectStore("app", { keyPath: "app_id" });
      const pageStore = _db.createObjectStore("page", { keyPath: "page_id" });
      const templateStore = _db.createObjectStore("template", {
        keyPath: "template_id",
      });

      const contentStore = _db.createObjectStore("content", {
        keyPath: "content_id",
      });

      // userStore.createIndex('nom', 'nom', { unique: false });
    };

    request.onsuccess = (event) => {
      db = event.target.result;

      resolve(db);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });

  // Gérer les erreurs, etc.
  // Creation des 5 tables
};

const addRecord = async (data, table) => {
  const db = await initDB();
  const tx = db.transaction(table, "readwrite");
  const store = tx.objectStore(table);

  return new Promise((resolve, reject) => {
    const request = store.add(data);
    request.onsuccess = (event) => {
      console.log("Enregistrement ajouté avec succès");
      resolve();
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const updateRecord = async (data, table) => {
  const db = await initDB();
  const tx = db.transaction(table, "readwrite");
  const store = tx.objectStore(table);

  return new Promise((resolve, reject) => {
    const request = store.put(data);
    request.onsuccess = (event) => {
      console.log("Enregistrement mis à jour avec succès");
      resolve();
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};
//  page  data = {page_id : 56 , title: "Blabla", ...  }   data.page_id     data['page_id']

const setRecord = async (data, table) => {
  const record = await getRecord(data[table + "_id"], table);

  if (record) {
    return await updateRecord(data, table);
  } else {
    return await addRecord(data, table);
  }
};

const deleteRecord = async (id, table) => {
  const db = await initDB();
  const tx = db.transaction(table, "readwrite");
  const store = tx.objectStore(table);

  return new Promise((resolve, reject) => {
    const request = store.delete(id);
    request.onsuccess = (event) => {
      console.log("Enregistrement supprimé avec succès");
      resolve();
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const getRecord = async (id, table) => {
  const db = await initDB();
  const tx = db.transaction(table, "readonly");
  const store = tx.objectStore(table);
  if (!id) return;
  id = parseInt(id);
  //console.log("getRecord ",id, table)
  return new Promise((resolve, reject) => {
    const request = store.get(id);

    request.onsuccess = (event) => {
      const record = event.target.result;
      // console.log("Result ", event.target)
      if (record) {
        resolve(record);
      } else {
        resolve();
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// Toutes les pages dont l'id est > 35   getFilterRecord( (page) => page.page_id>35 , "page" )
const ObjectToFunc = (obstacles) => {
  return (record) => {
    for (let obstacle of obstacles) {
      var value = record[obstacle.prop.name];
      var _value = obstacle.value &&  obstacle.value.content_id ? obstacle.value.content_id :obstacle.value ;
      switch (obstacle.operator ?? "=") {
        case "=":
        default:
          if (value + "" !== "" + _value) return false;
      }
    }
    return true;
  };
};

const getFilterRecord = async (filter, table) => {
  const db = await initDB();
  const tx = db.transaction(table, "readonly");
  const store = tx.objectStore(table);
  const records = [];

  return new Promise((resolve, reject) => {
    const request = store.openCursor();

    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        if (filter && typeof filter === "object") filter = ObjectToFunc(filter);
        if (!filter || (typeof filter === "function" && filter(cursor.value))) {
          records.push(cursor.value);
        }
        cursor.continue();
      } else {
        resolve(records);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const getFilterOneRecord = async (filter, table) => {
  var result = await getFilterRecord(filter, table);
  if (result.length === 0) return;
  else return result[0];
};

const getAllRecords = async (table) => {
  return getFilterRecord(() => true, table);
};

const purgeDB = async () => {};

export {
  initDB,
  addRecord,
  updateRecord,
  setRecord,
  deleteRecord,
  getRecord,
  getFilterRecord,
  getFilterOneRecord,
  getAllRecords,
  purgeDB,
};
