import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";
var ClipBoardCss;

const ToolCopyBoard = ({ updateStructure, structure, idObj, selectTpl }) => {
  const [show, setShow] = useState();

  const [clipBoardCSS,setClipBoardCSS]=useState( localStorage.getItem("ClipBoardCSS") )

  const classes = useMemo(() => {
    console.log(structure, idObj);
    var _structure = getStructure(structure, idObj);

    var parts = _structure.className
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [structure, idObj]);

  const CopyCss = () => {
    var _structure = getStructure(structure, idObj);
    ClipBoardCss = _structure.className;
    localStorage.setItem("ClipBoardCSS",ClipBoardCss)
    setClipBoardCSS(ClipBoardCss);
  };

  const PasteCss = () => {
    updateStructure({ className: ClipBoardCss }, idObj);
  };

  const ResetCss = () => {
    // TODO une double variable d'état pour savoir if clipboard pour afficher <paste css> <reset css>
    ClipBoardCss = null;
    setClipBoardCSS(ClipBoardCss);
    localStorage.removeItem("ClipBoardCSS");
  };

  var paramsOriginals;

  const hoverStart = () => {
    hoverEnd();

    var obj = document.getElementById(idObj);

    if (obj) {
      paramsOriginals = { classes : obj.className};
      console.log('clipBoardCSS',obj,clipBoardCSS) ;
      obj.setAttribute('class', clipBoardCSS);
      console.log('hoverStart Col',paramsOriginals,obj.className) ;
      };


   // obj.className = clipBoardCSS;

    
   
  };

  const hoverEnd = () => {
    var obj = document.getElementById(idObj);

    if (obj && paramsOriginals) {
       //obj.className = paramsOriginals.classes;
       obj.setAttribute('class',  paramsOriginals.classes);
      paramsOriginals = null;
    }
  };

  return (
    <>
      <div
        className={
          " inline-flex appbutton border-2  border-white  p-2 ml-2 text-xs  rounded-l-full " +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => CopyCss()}
      >
        Copy CSS
      </div>
      {clipBoardCSS && (
        <>
          <div
            className={
              " text-xs inline-flex appbutton border-2  border-white p-2 " +
              (show
                ? " bg-white text-black bg-opacity-80 "
                : " bg-black text-white bg-opacity-30 ")
            }
            onClick={() => PasteCss()}
            onMouseOver={() => hoverStart()}
            onMouseOut={() => hoverEnd()}
          >
            Paste CSS
          </div>

          <div
            className={
              " text-xs inline-flex appbutton border-2  border-white  p-2  rounded-r-full" +
              (show
                ? " bg-white text-black bg-opacity-80 "
                : " bg-black text-white bg-opacity-30 ")
            }
            onClick={() => ResetCss()}
            onMouseOver={() => hoverStart()}
            onMouseOut={() => hoverEnd()}
          >
            Reset CSS
          </div>
        </>
      )}
    </>
  );
};
export default ToolCopyBoard;
