

import {  useNavigate } from "react-router-dom";

import { useContext } from "react";
import { MfctContext } from "../../context";

import { data } from '../../service/data';
import UserThumb from '../thumbs/UserThumb';
import AppThumb from '../thumbs/AppThumb';


function TemplateDisplay({ template}) {

const { authUser } = useContext(MfctContext);
const navigate = useNavigate()





    return <div className='w-full p-0 flex flex-col items-center justify-between h-full '>
      <div    className=' ml-4 items-center p-2 text-xs  flex w-full gap-2'>
        
      {
template.app_id && <AppThumb app_id={template.app_id} className={" inline shadow-sm"} size={ "w-5 h-5"} />

}{template.title}</div>
      <div  className='w-full grow overflow-scroll border-white border-y appbutton relative' style={{maxHeight:"200px"}} onClick={  ()=> data.isGranted( authUser, template,"template","edit")  && navigate(`/template/${template.template_id}`)  } > <img src={  process.env.REACT_APP_MFTC_HOST_FILES +"users/"+  template.owner_id +"/template_"+template.template_id+"_thumb.png"   }      className=' object-contain w-full h-full' alt='thumb'/>


</div>

<div className='flex flex-col w-full items-center justify-center'>

<UserThumb
                  key={template.owner_id }
                  className={"text-xs"}
                  user_id={template.owner_id}
                />
                </div>
  </div>


}

export default TemplateDisplay;
