import * as fa from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo, useState } from "react"
import { getStructure } from "../../../../../tools/tpl"




const AddToolAfter = ({id,structure, updateStructure, selectTpl ,idObj}) => {







    const selectTool = () => {
        hoverEnd()
        var parts = idObj.split(".");
        var index = parseInt(parts.pop());
        var idParent = parts.join(".");
        var _structure = getStructure(structure, idParent);
    
        if (!_structure.children) _structure.children = [];
        var newid = idParent + "." + (index );
        _structure.children = [
          ..._structure.children.filter((_, i) => i < index),
          { tag: "div", className: "new p-2 ", children: [] },
          ..._structure.children.filter((_, i) => i >= index),
        ];
        updateStructure(_structure, idParent,newid);

      };


      const hoverStart = () => {

     
        hoverEnd(idObj)
        var obj = document.getElementById(idObj);
   if(obj){
    var     newdiv = document.createElement("div");
      
        newdiv.id = "newObj-_AddTooBefore";
        newdiv.className= " m-0 p-0 bg-orange-400 text-white opacity-0 transition-all";
        newdiv.style.fontSize="0px"
        newdiv.innerHTML ="+"
       
    setTimeout(()=>{
      if(newdiv) {
        newdiv.style.fontSize="20px"
        newdiv.className= " m-2 p-2 bg-orange-400 text-white opacity-100 transition-all";}},10)
        obj.parentNode.insertBefore(newdiv,obj)
   }
      };
    
      const hoverEnd = (idObj) => {
        var newdiv = document.getElementById( "newObj-_AddTooBefore");
    
        if(newdiv &&(!idObj || newdiv.idObj!==idObj))  newdiv.parentNode.removeChild(newdiv)
    
    
      };


if(idObj==="-root") return <></>


return (
    
<div className="appbutton inline-flex flex-row  p-2 gap-1" 
onClick={()=>selectTool()}  
   
onMouseOver={()=>hoverStart()}
onMouseOut={()=>hoverEnd()}>
    <FontAwesomeIcon  icon={fa.faPlus} className=" bg-white text-black w-3 h-3 p-1 rounded-sm" 



/>
<div className=" border-2 border-white bg-black bg-opacity-30 p-2 "    


></div>



</div>)

}
export default AddToolAfter