import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { data } from "../../service/data";
import VarContent from "../VarContent";

const InputTemplate = ({
  label,
  onChange,
  value,
  className,
  placeholder,
  domain,
  filter,
}) => {
  const [templates, setTemplates] = useState();

  useEffect(() => {
    const getTemplates = async () => {
      const _templates = await data.getTemplates(filter);
      setTemplates(_templates);
    };

    getTemplates();
  }, [domain,filter]);

  if (!templates) return <></>;

  return (
    <>
      <InputSelect
        label={label}
        className={className}
        placeholder={placeholder}
        value={value?.template_id}
        onChange={(template_id) => {
          if (template_id) template_id = parseInt(template_id);
          var tpl = templates.find((p) => p.template_id === template_id);
          onChange({ template_id, content_id: null, typecontent: "template" });
        }}
        options={templates.map((p) => {
          return { label: p.title, value: p.template_id };
        })}
      />
   
    </>
  );
};
export default InputTemplate;
