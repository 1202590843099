import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";


import HeaderBack from "../../../../components/HeaderBack";

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import InputText from "../../../../components/inputs/InputText";
import InputData from "../../../../components/inputs/InputData";
import { data } from "../../../../service/data";


function SetRole() {
  const { project } = useContext(MfctContext);
  const { role_id } = useParams();
  const [role, setRole] = useState();
  const [autoristationSchema, setAutoristationSchema] = useState();

  const getRole = async () => {
    var _role = await project.api("/get_role/:role_id", { args: { role_id } })
    var _schema = await project.api("/get_schema/:content_id", { args: { content_id: 266 } })
    var __schemas = (await data.getSchemasByApp(_role.app_id)).filter(s => s.app_id + "" === "" + _role.app_id)

    __schemas.forEach(element => {
      _schema.content.schema[0].values.unshift(element.title)
    });

    setAutoristationSchema(_schema)

    setRole(_role)


  }



  useEffect(() => {

    if (project) console.log("Project Loaded", project.timestamp, project)

    getRole();


  }, [role_id, project]);

  const navigate = useNavigate();

  const saveRole = async () => {


    await project.api("/set_role", { body: role });



  };

  const cancelRole = async () => {
    if (!role_id) navigate(-1);
  };

  return (
    role && autoristationSchema &&
    <div className="App  text-zinc-600 text-sm mb-10">
      <HeaderBack title={'Edition Role ' + role?.content.title} />

      <div className="z-1 w-full mt-4 bg-white p-4">


        <InputText value={role.content.title} onChange={(title) => setRole(role => ({ ...role, content: { ...role.content, title } }))} />


        <InputData schema={autoristationSchema} prop={{ name: "Autorisation ", type_nombre: "I", define: true }} value={role.content.permissions} onChange={(permissions) => setRole(role => ({ ...role, content: { ...role.content, permissions } }))} />




      </div>

      <div className="flex w-full gap-4 p-2 ">

        <button onClick={cancelRole}    >Annuler</button>
        <button onClick={saveRole}    >Enregistrer</button>
      </div>
    </div>
  );
}

export default SetRole;
