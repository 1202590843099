import React, { useEffect, useMemo, useState ,useContext} from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../../../../App.css';
import { MfctContext } from '../../../../context';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/Loader';
import { setToken } from '../../../../service/mftc-api';
import Notifications from '../../../../components/permissions/Notifications';
import InputBoolean from '../../../../components/inputs/InputBoolean';
import HeaderBack from '../../../../components/HeaderBack';
import { setOptions } from 'leaflet';
import InputText from '../../../../components/inputs/InputText';
function Subscriptions() {

const { project, setAuthUser,authUser} = useContext (MfctContext);
const [urlPortal,setUrlPortal]=useState()
  const navigate = useNavigate();
const[progression,setProgression] = useState()

useEffect(() => {

  const initPortalSsession =async ()=>{
    var parts = window.location.href.split('/')
    parts.pop();
    parts.push('home');
  var result = await  project.api("/create-portal-session",{body:{ returnUrl:  parts.join("/") }});
  if(result.redirect) {
 // window.open(urlPortal,"portal-session")
   // setUrlPortal(result.redirect) 
   window.location.href=result.redirect;
   
  }
  }
if(project) initPortalSsession()

},[project]);




  return (  
    <div className=" text-zinc-600 text-sm flex flex-col w-full h-screen">

  
    <HeaderBack  title={"Abonnements & Achats"}   />
 {  !urlPortal ? <Loader />    :
  <iframe className='w-full grow bg-white '  title='Portal'    src={urlPortal} >   </iframe> 

 
     }
  


 </div>
  );
}

export default Subscriptions;
