import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";

const availableClasses = [
  "flex",
  "flex-row",
  "flex-col",
  "w-full",
  "py-40",
  "justify-center",
  "justify-end",
  "justify-start",
  "justify-between",
  "justify-around",
  "min-h-screen",
  "h-screen",
  "w-screen",
  "items-center",
  "items-end",
  "items-start",
  "items-between",
  "items-around",
  "text-xl",
  "text-xs",
  "text-sm",
  "p-1",
  "p-2",
  "p-3",
  "p-4",
  "font-bold",
  "italic",
  "m-1",
  "m-2",
  "m-3",
  "w-1",
  "w-2",
  "w-3",
  "border",
  "border-2",
  "border-3",
  "border-red-100",
  "border-red-200",
  "border-red-300",
  "border-red-400",
  "border-blue-100",
  "border-blue-200",
  "border-blue-300",
  "border-blue-400",
];
availableClasses.sort((a, b) => (a < b ? -1 : 1));

const ToolStyle = ({ updateStructure, structure, idObj, selectTpl }) => {
  const [show, setShow] = useState();

  const classes = useMemo(() => {
    console.log(structure, idObj);
    var _structure = getStructure(structure, idObj);

    var parts = _structure.className
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [structure, idObj]);

  const [filter, setFilter] = useState();

  const removeClasse = (classe) => {
    var _classes = classes.filter((_classe) => _classe !== classe);

    updateStructure({ className: _classes.join(" ") }, idObj);
  };

  const addClasse = (classe) => {
    var _classes = classes;

    _classes.push(classe);

    updateStructure({ className: _classes.join(" ") }, idObj);
  };

  const hoverStart = (classe) => {
    var obj = document.getElementById(idObj);
    console.log("hoverStart", obj, classe);
    if (obj) obj.classList.add(classe);
  };

  const hoverEnd = (classe) => {
    var obj = document.getElementById(idObj);
    console.log("hoverEnd", obj, classe);
    if (obj) obj.classList.remove(classe);
  };

  return (
    <>
      {show && (
        <div
          className={
            "bg-white w-full h-full justify-between flex flex-col relative  mr-14"
          }
        >
          <div className="w-full flex-nowrap text-start ">
            {" "}
            {classes &&
              classes.map((classe, i) => (
                <div
                  key={i}
                  onClick={() => removeClasse(classe)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-green-500 border-2"
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + classe}
                </div>
              ))}
          </div>

          <div
            className="w-full flex-nowrap text-start grow overflow-y-scroll "
            style={{ maxHeight: "40vh" }}
          >
            <div className=" w-full flex-nowrap text-start ">
              {availableClasses &&
                availableClasses
                  .filter(
                    (classe) =>
                      !classes.find((c) => c === classe) &&
                      (!filter || classe.includes(filter))
                  )
                  .map((classe, i) => (
                    <div
                      key={i}
                      onClick={() => addClasse(classe)}
                      onMouseOver={() => hoverStart(classe)}
                      onMouseOut={() => hoverEnd(classe)}
                      className=" appbutton inline-block text-xs p-1 px-2 m-1 mb-1 bg-white rounded-full border-gray-500 border-2"
                    >
                      {classe}
                    </div>
                  ))}
            </div>
          </div>

          <div className="flex flex-row  w-full p-1">
            <input
              type="text"
              onChange={(e) => setFilter(e.target.value)}
              className="   grow rounded-lg text border border-slate-300 text-xs p-1"
              placeholder="Rechercher"
            />
            {filter &&
              filter.length > 2 &&
              !availableClasses.find((c) => c === filter) && (
                <FontAwesomeIcon
                  icon={fa.faPlus}
                  className="-ml-6 bg-green-500 text-white p-2 appbutton rounded-full"
                  onClick={() => addClasse(filter)}
                  onMouseOver={() => hoverStart(filter)}
                  onMouseOut={() => hoverEnd(filter)}
                />
              )}
          </div>
        </div>
      )}
      <div
        className={
          " inline-flex appbutton border-2  border-white  p-2  m-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
        CSS...
      </div>
    </>
  );
};
export default ToolStyle;
