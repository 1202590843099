import React, { useEffect, useMemo, useState ,useContext} from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../../../../App.css';
import { MfctContext } from '../../../../context';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/Loader';
import { setToken, updateAuthuser } from '../../../../service/mftc-api';
import Notifications from '../../../../components/permissions/Notifications';
import InputBoolean from '../../../../components/inputs/InputBoolean';
import HeaderBack from '../../../../components/HeaderBack';
import { setOptions } from 'leaflet';
import InputText from '../../../../components/inputs/InputText';
import { initPortalSession } from '../../../../service/payment';
import ChooseImage from '../../../../components/inputs/ChooseImage';
import Header1 from '../../../../components/Header1';
function Parameters() {

const { project, setAuthUser,authUser} = useContext (MfctContext);
const [showNotif,setShowNotif] = useState(false) 
const [ subscription,setSubscription ] = useState( localStorage.getItem( 'subscription'))

const [profile,setProfile] = useState({})
  const navigate = useNavigate();


useEffect(() => {

if(authUser) {


setProfile(authUser.profile?? {})
}

},[authUser,project]);

const logout=async()=>{
setToken (null, null) 
setAuthUser()
navigate("/home")
}





const updateAccount=async (e)=>{
  e.preventDefault();

  
 var  p = await project.api("/set_profile", { body:{profile} }); 
 
var _authUser = {...authUser,profile:{...authUser.profile,...p}}
setAuthUser( _authUser )
updateAuthuser(_authUser)
}


  const switchNotification = (value)=>{

    if(value){
      setShowNotif(true)
    }
    else if(subscription)
    {
     
          project
        .api("/update_tokenpush", { body: { subscription:  JSON.parse(subscription) ,unsubscribe:true} })
        .then((result) => {
          console.log(result);
          localStorage.removeItem("subscription");
          setSubscription();
        
        });
      
      
    }
  }




  return (  !project || !authUser ? <Loader /> : 
    <div className=" text-zinc-600 text-sm">

     {  <header className=" min-h-screen relative">
    
    <Header1  title={"Profil"}  linkLeft="/home"  />
    
  <div className='z-1 w-full bg-white mt-10'>    

       <form id="uploadimage"  className="flex flex-col items-center p-4 w-full" onSubmit={updateAccount} action="" method="post" encType="multipart/form-data">
     
       <div className='w-full flex items-center flex-col justify-center'>


<div  className="   w-40 h-40  m-2 bg-slate-400 rounded-full" >
<ChooseImage   value={profile.imageUrl }    onChange={ (imageUrl)=>setProfile({...profile,imageUrl} )}  className={"w-full h-full object-cover rounded-full"} immersiveMode={true}/>
</div>
  </div>  
     
    

  
 <InputText label={"Prénom"} className={"w-full my-1"}  value={profile.first_name} onChange={(first_name)=> { setProfile({...profile,first_name})}  }    /> 
 <InputText label={"Nom"} className={"w-full my-1"}  value={profile.last_name} onChange={(last_name)=> { setProfile({...profile,last_name})}  }    /> 
        
    <div className="text-black border border-1 p-2 rounded m-1"  onClick={updateAccount} >Mettre à jour</div>

<InputBoolean labelTrue={"Notifications activées"} labelFalse={"Notifications désactivées"} value={subscription && subscription!=="NO"}  className={"w-full"}  onChange={  switchNotification}  />  
   
</form>
 <div className=' w-full flex flex-col p-4'> 

<div  className='py-4 px-8 border-b border-zinc-300 flex justify-between ' onClick={()=>initPortalSession(project) }> <div>Mes Abonnements et achats</div> <FontAwesomeIcon icon={fa.faArrowRight} /></div>
<Link  className='py-4 px-8 border-b border-zinc-300 flex justify-between ' to="/subscriptions" > <div>Mes paramètres</div> <FontAwesomeIcon icon={fa.faArrowRight} /></Link>

</div>
<div className={ "mt-20 bg-white text-red-900 p-2 my-3 w-full  border-t border-black text-sm text-center p-4"  }  onClick={logout}   > Déconnexion </div>

</div>
      </header> }

{ showNotif && <Notifications  isConnected={true} project={project} noTimeOut={true} close={()=>setShowNotif(false)}  />
}
 </div>
  );
}

export default Parameters;
