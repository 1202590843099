import React, { useEffect, useMemo, useState, useContext } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputText from "../../../../components/inputs/InputText";
import InputImage from "../../../../components/inputs/InputImage";

import InputSelect from "../../../../components/inputs/InputSelect";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import InputButton from "../../../../components/inputs/InputButton";

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import InputData from "../../../../components/inputs/InputData";
import ChooseImage from "../../../../components/inputs/ChooseImage";
import InputNum from "../../../../components/inputs/InputNum";
import { useNavigate } from "react-router-dom";
import InputColor from "../../../../components/inputs/InputColor";

function ModuleSettings({ module, setModules, setTab }) {
  const navigate = useNavigate();

  const [m, setM] = useState(module);
const [pages, setPages]=useState([]);
  const {
    project,
    getModules,
    updateModule,
  } = useContext(MfctContext);

  const modules = getModules();

  const deleteModule = async () => {
    if (!window.confirm("Are you sure you want to delete '" + m.name + "'?"))
      return;
    await project.api("/delete_module/:module_id", {
      args: { module_id: module.module_id },
    });

    updateModule(module, true);
    setTab();
    setModules((ms) => ms.filter((_m) => _m.module_id !== m.module_id));
    navigate("/modules");
    toast("Successfully deleted '" + m.name);
  };
  const cancelModule = () => {
    setModules((ms) => ms.filter((m) => m.module_id !== "new"));
    setTab();
    navigate("/modules");
  };
  const save = async (e) => {
    e.preventDefault();

    var _module = await project.api("/set_module", {
      body: { ...m, module_id: m.module_id !== "new" ? m.module_id : null },
    });
    setM(_module);
    updateModule(_module);

    if (m.module_id === "new") {
      setTab({ module: _module.module_id, what: "Settings" });
      setModules((ms) => [...ms, _module].filter((m) => m.module_id !== "new"));
    } else
      setModules((ms) =>
        ms.map((_m) => (m.module_id === _m.module_id ? _module : _m))
      );

    toast("Successfully saved '" + _module.name);
    console.log(_module);
  };

  const setModule = (_module) => {
    setM(_module);
  };


useEffect(()=>{
  const getPages = async()=>{
    var _pages = await project.api("/module/:module_id/pages",{args:{module_id:module.module_id}})
    console.log( _pages)
    setPages(_pages);
  }
  
 getPages()

},[])

  return (
    <div className="w-full flex flex-col py-4 bg-white px-4 rounded-xl">
      <div className="w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3  ">
        <ChooseImage
          domain={"appisyou"}
          className={"h-28 w-28 shadow rounded "}
          label="Illustration"
          value={m.content.imageUrl}
          onChange={(imageUrl) =>
            setModule({ ...m, content: { ...m.content, imageUrl } })
          }
        />

        <InputText
          label={"name"}
          value={m.name}
          onChange={(name) => setModule({ ...m, name })}
        />

        <InputTextArea
          id={"desc"}
          label={"description"}
          value={m.content.description}
          onChange={(description) =>
            setModule({ ...m, content: { ...m.content, description } })
          }
        />
        <InputNum
          id={"ordre"}
          label={"ordre"}
          value={m.content.ordre ?? m.module_id}
          onChange={(ordre) =>
            setModule({ ...m, content: { ...m.content, ordre } })
          }
        />
        <InputData
          placeholder={"- Ajouter module -"}
          prop={{ type_nombre: "I", name: "dépendances", isSpe: true }}
          schema={{ content: {} }}
          value={Array.isArray(m.dependencies) ? m.dependencies : []}
          options={modules.map((mdl, i) => {
            return { value: mdl.module_id, label: mdl.name };
          })}
          onChange={(dependencies) => setModule({ ...m, dependencies })}
        />
      </div>
      {m.app && m.app_id && (
        <div className="w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3  border-t border-zinc-500">
          <InputText
            label={"short_name"}
            value={m.app.short_name}
            onChange={(short_name) =>
              setModule({ ...m, app: { ...m.app, short_name } })
            }
          />
          <InputText
            label={"name"}
            value={m.app.name}
            onChange={(name) => setModule({ ...m, app: { ...m.app, name } })}
          />
          <InputSelect
            label={"display"}
            value={m.app.profile.display}
            options={[{ value: "fullscreen" }, { value: "standalone" }]}
            onChange={(display) =>
              setModule({
                ...m,
                app: { ...m.app, profile: { ...m.app.profile, display } },
              })
            }
          />
          <InputColor
            label={"primary_color"}
            value={m.app.profile.primary_color}
            onChange={(primary_color) =>
              setModule({
                ...m,
                app: { ...m.app, profile: { ...m.app.profile, primary_color } },
              })
            }
          />
          <InputColor
            label={"secondary_color"}
            value={m.app.profile.secondary_color}
            onChange={(secondary_color) =>
              setModule({
                ...m,
                app: {
                  ...m.app,
                  profile: { ...m.app.profile, secondary_color },
                },
              })
            }
          />
            <InputSelect
            label={"Landing Page Web Site"}
            value={m.app.profile.landing_page_id}
            options={pages.map(p=>({value:p.page_id ,label:p.title}))}
            onChange={(landing_page_id) =>
              setModule({
                ...m,
                app: { ...m.app, profile: { ...m.app.profile, landing_page_id } },
              })
            }
            placeholder={"- choisir une page -"}
          />
                      <InputSelect
            label={"Home Page  Web Site"}
            value={m.app.profile.home_page_id}
            options={pages.map(p=>({value:p.page_id ,label:p.title}))}
            onChange={(home_page_id) =>
              setModule({
                ...m,
                app: { ...m.app, profile: { ...m.app.profile, home_page_id } },
              })
            }
            placeholder={"- choisir une page -"}
          />
                      <InputSelect
            label={"Welcome Mobile Screen"}
            value={m.app.profile.welcome_screen_id}
            options={pages.map(p=>({value:p.page_id ,label:p.title}))}
            onChange={(welcome_screen_id) =>
              setModule({
                ...m,
                app: { ...m.app, profile: { ...m.app.profile, welcome_screen_id } },
              })
            }
            placeholder={"- choisir un screen  -"}
          />
                      <InputSelect
            label={"Home Mobile Screen"}
            value={m.app.profile.home_screen_id}
            options={pages.map(p=>({value:p.page_id ,label:p.title}))}
            onChange={(home_screen_id) =>
              setModule({
                ...m,
                app: { ...m.app, profile: { ...m.app.profile, home_screen_id } },
              })
            }
            placeholder={"- choisir un screen  -"}
          />
        </div>
      )}
      {true && (
        <div className="flex flex-col w-full">
          <InputButton label={"Enregistrer"} onClick={save} />
          {m.module_id === "new" ? (
            <div
              onClick={cancelModule}
              className="text-red-700 p-1 underline text-xs"
            >
              Annuler
            </div>
          ) : (
            <div
              onClick={deleteModule}
              className="text-red-700 p-1 underline text-xs"
            >
              Supprimer le module {m.name}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ModuleSettings;
