import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import {useEffect ,useMemo,useRef} from 'react'
import ReactDOM from 'react-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import UserThumb from '../thumbs/UserThumb';
import { getAuthUser } from '../../service/mftc-api';

const MapMarker = ({mapMarker}) => {
  const markerRef = useRef(null);
  const containerRef = useRef(document.createElement('div')); // Création du conteneur DOM

  useEffect(() => {
    if (markerRef.current) {
      console.log("MarkerCreate Custom")
      // Créer un conteneur HTML qui sera monté dans Leaflet
     
      ReactDOM.render(<UserThumb  size={" w-8 h-8 shadow-xl"} noname={true}  user_id={getAuthUser().user_id}/>, containerRef.current);


      // Créer un icône personnalisé avec le conteneur monté par React
      const customIcon = L.divIcon({
        
        html: '', // Utilise le HTML rendu
        className: 'custom-div-icon', // Optionnel : pour éviter les classes par défaut de Leaflet
      });
      markerRef.current.setIcon(customIcon);
       // On remplace l'élément enfant du marker par le composant rendu par React
       markerRef.current.getElement().appendChild(containerRef.current);

      // Met à jour l'icône du marqueur avec l'icône personnalisé
     // 

    }
  }, []);

  return (

      <><Marker ref={markerRef}  position={ [mapMarker.Objet[mapMarker.Prop].lat, mapMarker.Objet[mapMarker.Prop].lon]} >
      
      <Popup>
   
       <UserThumb   user_id={getAuthUser().user_id}/>
      </Popup>
    </Marker>


</>
  );
};



function MapContent({className,map={ Zoom:13, Center:{ lat: 51.505,lon:  -0.09 } ,Marker :[
  {Objet:{  Loc:{ lat: 51.505,lon:  -0.09 } ,title:"Test"    },Prop:"Loc",TemplateMarker:null,TemplatePopup:null  },
  {Objet:{  Loc:{ lat: 51.515,lon:  -0.09 } ,title:"Test"    },Prop:"Loc",TemplateMarker:null,TemplatePopup:null  }   ]       },onClick}) {

  const mapRef =useRef();











 useEffect(()=>{

  var c = { lat: 51.505,lon:  -0.09 }
    if(map?.Center) 
         c= map.Center 
  
    mapRef.current?.setView([c.lat, c.lon]);
    
      },[map])


return <div className={className}  onClick={onClick}>
  <MapContainer  ref={mapRef}  style={{ height: "100%", minHeight: "100%",zIndex:10 }}  className="w-full h-full"  center={    [map.Center.lat, map.Center.lon]} zoom={map.Zoom } scrollWheelZoom={false}>
<TileLayer
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
/>

{  
map?.Marker?.map( (mapMarker,i)=><MapMarker  key={i}   mapMarker={mapMarker} />)
}
</MapContainer></div>
  
  
  }
  
  export default MapContent;
  