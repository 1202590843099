import React, { useEffect, useMemo, useState,useContext } from 'react';

import { setToken } from '../../../../service/mftc-api';
import '../../../../App.css';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { MfctContext } from "../../../../context";
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderBack from '../../../../components/HeaderBack';
import PaymentIntent from '../../../../components/payment/PaymentIntent';
import Header1 from '../../../../components/Header1';
import { AddressElement, Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { initPortalSession } from '../../../../service/payment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentProcess() {
 
const [status,setStatus]= useState(0);
  const { project,  setAuthUser, authUser} =
    useContext(MfctContext);

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
 let[ product_id, setProductId]= useState();
 let[ product, setProduct]= useState();
let [billingAddress,setBillingAddress]=useState();
let [shippingAddress,setShippingAddress]=useState();


const connect=async()=>{
  var wconnect = window.open(  process.env.REACT_APP_URL_AUTH,"appisyou_auth")
  
  window.addEventListener('message', function(e) {
    // e.data hold the message from child
    console.log(e.data); 

    if(e.data.token && e.data.authUser)
    { 
      setToken(e.data.token,e.data.authUser) 
  setAuthUser(e.data.authUser)
      wconnect.close(); 
      
      }


  } , false);
}


const disconnect=async()=>{
  
  setToken (null, null) 
setAuthUser()
    

}


const createCheckoutSession = async(product)=>{
   var result = await   project.api("/create-checkout-session",{body: {lookup_key:product.Stripe_Id}})
   if(result.redirect)   window.open(result.redirect)
}



const InitiateDisplay = () => (product ? <section className="grow w-full bg-white text-black p-4 flex flex-col justify-between">
  <div className=' w-full flex  flex-col items-center justify-center grow '>
 
    
  
      <h3>{product.Titre    }</h3>
      <img src={product.Illustration[0]} className='w-60 h-60 p-2 object-cover' />
            <h5>{product.Prix} €/mois</h5>

    
  </div>
<div className='w-full flex flex-row items-center justify-center' >
    <button className='bg-green-500 flex items-center  justify-center' type="submit" onClick={()=>setStatus( status+1)}>    Continuer <FontAwesomeIcon  icon={fa.faArrowRight}    className={"mx-1"}/>   </button>
   
    </div>

</section> : <></>

);

const AuthDisplay = () =>{

  if(authUser) setStatus(status+1)
  return (product ? <section className="grow w-full bg-white text-black p-4 flex flex-col justify-between">
 <div className=' w-full flex  flex-col items-center justify-center grow '>
 
  <div>  Authentification </div>
   
    {  authUser &&  authUser.email }
{  authUser  &&  <button className='bg-red-500 m-2 flex items-center' type="submit" onClick={disconnect}><FontAwesomeIcon  icon={fa.faArrowLeft}    className={"mx-1 hidden"}/>  Deconnexion </button>  }
     {
    !authUser && 
    <button className='bg-blue-500 m-2 flex items-center' type="submit" onClick={connect}><FontAwesomeIcon  icon={fa.faArrowLeft}    className={"mx-1 hidden"}/>  Authentifiez-vous </button>  
     }
    
  </div>
  <div className='w-full flex flex-row items-center justify-center' >
  <button className='bg-green-500 m-2 flex items-center justify-center' type="submit" onClick={()=>setStatus( status-1)}><FontAwesomeIcon  icon={fa.faArrowLeft}    className={"mx-1"}/>  Précedent </button>  
   { authUser &&     <button className='bg-green-500 flex justify-center items-center' type="submit" onClick={()=>setStatus( status+1)}>    Continuer <FontAwesomeIcon  icon={fa.faArrowRight}    className={"mx-1"}/>   </button>}
    </div>

</section> : <></>

)
    };





const AddressDisplay=({options, setElements})=>{
  const elements = useElements();
setElements(elements);

const onChange=async ()=>{


 // setAddress(elements.getElement("address"))
}


return  <AddressElement options={options}  onChange={onChange}  />

}



const BillingAddressDisplay = () =>{
  const [elements,setElements] = useState();
  



  const listBillingAdress = useMemo (()=>{

    var result = []
     if(  billingAddress) result.push(billingAddress)
     if(  shippingAddress && JSON.stringify(shippingAddress)!==JSON.stringify(billingAddress) ) result.push(shippingAddress)
     return result
    },[])
    

  const Next = async ()=>{
    var objAdd = elements.getElement("address")
    console.log(objAdd)
      var adress = await  objAdd.getValue() 
      console.log(adress)
    if(adress.complete){
  
    setBillingAddress(adress.value)
    setStatus( status+1)
    }
    }


  return (product ? <section className="grow w-full bg-white text-black p-4 flex flex-col justify-between">
  <div >
    
    <div className="description">
   Adresse Facturation 
   <Elements options={ { appearance:  {
    theme: 'stripe',
  },
  //  mode:items[0].Abonnement ? 'subscription':'payment'
  }}   stripe={stripePromise}  >
   <AddressDisplay options={{ mode: 'billing',autocomplete: { mode:"automatic"}  ,  contacts:listBillingAdress }}  setElements={setElements}/>
        </Elements>


    </div>
  </div>
  <div className='w-full flex flex-row items-center justify-center' >
  <button className='bg-green-500 m-2 flex items-center justify-center' type="submit" onClick={()=>setStatus( status-1)}><FontAwesomeIcon  icon={fa.faArrowLeft}    className={"mx-1"}/>  Précedent </button>  

   { authUser &&     <button className='bg-green-500 flex items-center justify-center' type="submit" onClick={Next}>    Continuer <FontAwesomeIcon  icon={fa.faArrowRight}    className={"mx-1"}/>   </button>}
    </div>

</section> : <></>

)};


const ShippingAddressDisplay = () => { 
  const [elements,setElements] = useState();

  const listShippingAdress = useMemo (()=>{

    var result = []
     if(  shippingAddress) result.push(shippingAddress)
     if(  billingAddress && (!shippingAddress ||  JSON.stringify(billingAddress)!==JSON.stringify(shippingAddress)) ) result.push(billingAddress)
    return result
    },[])
  
const Next =async ()=>{
var objAdd = elements.getElement("address")
console.log(objAdd)
  var adress = await  objAdd.getValue() 
  console.log(adress)
  if(adress.complete){

setShippingAddress(adress.value)
  setStatus( status+1);
  }
// setStatus( status+1)
}


  return product ? <section className="grow w-full bg-white text-black p-4 flex flex-col justify-between">
  <div >
    
    <div className="description">
   Adresse Livraison 
   <Elements     options={ { appearance:  {
    theme: 'stripe',
  },
  //  mode:items[0].Abonnement ? 'subscription':'payment'
  }}   stripe={stripePromise}  >
<AddressDisplay options={{ mode: 'shipping' ,autocomplete:{mode:"automatic"}  ,contacts:listShippingAdress   }}   setElements={setElements}/>
   
        </Elements>


    </div>
  </div>
  <div className='w-full flex flex-row items-center justify-center ' >
  <button className='bg-green-500 m-2 flex items-center justify-center' type="submit" onClick={()=>setStatus( status-1)}><FontAwesomeIcon  icon={fa.faArrowLeft}    className={"mx-1"}/>  Précedent </button>  

   { authUser &&     <button className='bg-green-500 flex items-center justify-center' type="submit" onClick={Next }>    Continuer <FontAwesomeIcon  icon={fa.faArrowRight}    className={"mx-1"}/>   </button>}
    </div>

</section> : <></>

}

const PaymentDisplay = () => (product ? <section className="grow w-full bg-white text-black py-2 flex flex-col justify-between">
  <div className=' w-full flex items-center justify-center grow '>
    
    <div className="description">
   

    <PaymentIntent   items={[product]}   billingAddress={billingAddress} shippingAddress={shippingAddress} />
  </div>
  </div>
  <div className='w-full flex flex-row items-center justify-center' >
  <button className='bg-green-500 m-2 flex items-center justify-center' type="submit" onClick={()=>setStatus(status-1 )}><FontAwesomeIcon  icon={fa.faArrowLeft}    className={"mx-1"}/>  Précedent </button>  

    </div>

</section> : <></>

);


const SuccessDisplay = ({ sessionId }) => {

  var parts = window.location.href.split('/')
  parts.pop();
  parts.push('home');
 var returnUrl = parts.join('/');

return (
  <section className="grow w-full bg-white text-black p-4 flex flex-col justify-between">
    <div className="grow w-full flex flex-col items-center justify-center">
      <Logo />
      <div className="description Box-root">
        <h3>Subscription to starter plan successful!</h3>
      </div>
    </div>
    <div className='w-full flex flex-col items-center justify-center' >
    <Link className='my-1'  to="/"  ><button id="checkout-and-portal-button" className='my-1'   >
        Retour</button> 
      </Link>
      <button id="checkout-and-portal-button" className='my-1'   onClick={ ()=>initPortalSession(project,returnUrl)  }>
        Manage your billing information</button>
      
    </div>
  </section>
);
};
const ErrorDisplay = ({product}) => (product ? <section className="product bg-white text-black p-4">
  <div className="row w-full bg-white text-black p-4 flex flex-col justify-between">
    
    <div className="description">
   
      <h3>{product.Titre    }</h3>
      <h5>{product.Prix} / month</h5>
    </div>
  </div>
  <div className='w-full flex flex-row items-center justify-center' >
    <button className='bg-green-500' type="submit" onClick={createCheckoutSession}>
      Checkout
    </button>
    </div>

</section> : <></>

);


const statusDisplay=  [ InitiateDisplay,...(authUser?[]:[AuthDisplay] )  , BillingAddressDisplay, ShippingAddressDisplay,PaymentDisplay,SuccessDisplay,ErrorDisplay  ] 

const  initProduct= async  ()=> {
  if (product_id && project) {
    var _content = await project.api("/get_content/:content_id", {
      args: { content_id:product_id },
    });
    console.log(_content.content)
    setProduct( _content.content);
}
}

useEffect(()=>{

initProduct();

},[project,product_id])


useEffect(() => {
  // Check to see if this is a redirect back from Checkout
  const query = new URLSearchParams(window.location.search);

  
 
  if (query.get('product_id')) {

    setProductId(query.get('product_id'));
  }
  if (query.get('redirect_status')) {
    if(query.get('redirect_status')==="succeeded")
    {
    setStatus( authUser ? 4:5 );
    console.log('Checkout');
 
    // query.get("payment_intent_client_secret")
    // query.get("payment_intent")
    setSessionId(query.get('session_id'));
    }
    else {

    }
    
  }

  if (query.get('canceled')) {
    console.log('No Checkout');
    setSuccess(false);
    setMessage(
      "Order canceled -- continue to shop around and checkout when you're ready."
    );
  }
  }
, [ sessionId]);


var ComponentDisplay = statusDisplay[status]

return <div className=" text-white w-full  flex flex-col ">
<Header1   title={(product?.Abonnement ? "Abonnement": "Paiement") + (product ? " - " + product?.Titre:"") } linkLeft={"/"} classRight={"p-2 rounded-full border border-white "  } />


<div className="bg-white rounded-t-2xl w-full py-4 h-5/6 overflow-auto    grow flex flex-col  items-center justify-start fixed bottom-0 left-0">
{ project && <ComponentDisplay /> }

</div>
</div>
}




const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);
export default PaymentProcess;
