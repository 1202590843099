import {
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowDown,
  faArrowDownWideShort,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { useState } from "react";

import { getRecord } from "../../service/localdata";

import TemplateX from "../templates/TemplateX";
import { MfctContext } from "../../context";
import { _GetSetTers } from "../../tools/tpl";
import SetContent from "../../version/pro/pages/content/SetContent";
import VarContent from "../VarContent";

const EntityTemplateSelect = ({
  id,
  label,
  onChange,
  value,
  className,
  options,
  placeholder,
  trueValue,
  domain,
  context,
  template_id,
}) => {
  const { getTemplates ,getSchemas} = useContext(MfctContext);
  const [_value, setValue] = useState(value);
 
  const [displayTemplate, setDisplayTemplate] = useState();

const [ contents, setContents] = useState([])
  var templates = getTemplates();

  var schemas = getSchemas();






  useEffect(() => {
    setValue(value);
  }, [value, trueValue]);

  const getOptions = async () => {
 
    if (template_id ) {

 
      var _template = await getRecord(parseInt(template_id), "template");
      setDisplayTemplate(_template);
      console.log(_template);
    
    } 
 setContents(   options)
  };

  useEffect(() => {

      getOptions();
    
  }, [options, domain, template_id]);

  const [showSelection, setShowSelection] = useState();

  return  <>
   { !context?.minimized && label}
      {displayTemplate && 
        <div
          className={
            " relative flex flex-row items-start  justify-start w-full " +
            className + (!context?.minimized ? "  border border-slate-400  rounded-lg " : "")
          }
        //  onClick={() => !showSelection && setShowSelection(true)}
          onBlur={()=>setShowSelection(false) }
        >
          <div className="flex flex-col w-full ">

          <div className="flex flex-row w-full items-center justify-between ">
           <TemplateX
              content={

                { ..._GetSetTers( displayTemplate,null,null,"global",[{content_id:"global",content:{self:_value } }, ...contents], displayTemplate.props_from_templates ??{}  , schemas )
                ,GetSetTers:(template, getSelf, setSelf,content_id)=> _GetSetTers( template,getSelf,setSelf,"global", [{content_id:"global",content:{self:contents.find(v=> v.content_id ===_value)   }},...contents],displayTemplate.props_from_templates ??{}  , schemas )}
      

 
              }
              template={displayTemplate.structure}
              templates={templates}
              selectTpl={()=> setShowSelection(!showSelection)}
              id={"value." +_value }
              safeDisplay={true}
            />
                      <div className="p-2    ">
            <FontAwesomeIcon icon={    faChevronDown} className={" transition-all  duration-300 " + (!showSelection ? " rotate-0 " :  " rotate-180 "   )}   onClick={()=> setShowSelection(!showSelection)}/>
          </div>
          </div>

{
showSelection &&
    <div className="w-full flex flex-col  absolute left-0 bg-white top-full z-50  overflow-y-auto border border-slate-400 rounded-lg shadow-md"
style={{maxHeight:"50vh"}}
>{              contents.filter((c) => c.content_id + "" !== "" + _value)   
              
                .map((c, i) => (
                  <div
                    className="appbutton w-full flex flex-col border-t border-slate-200 "
                    key={i}
                    onClick={() => {
                      onChange(c.content_id);
                      setShowSelection(false);
                    }}
                  >
                    <TemplateX
                      content={


                        { ..._GetSetTers( displayTemplate,null,null,"global",[{content_id:"global",content:{self:c.content_id }},...contents],displayTemplate.props_from_templates ??{}  , schemas )
                        ,GetSetTers:(template, getSelf, setSelf,content_id)=> _GetSetTers( template,getSelf,setSelf,"global",[{content_id:"global",content:{self:c }},...contents],displayTemplate.props_from_templates ??{}  , schemas )}
              

                        }
                      template={displayTemplate.structure}
                      templates={templates}
                      selectTpl={()=>{
                        onChange(c.content_id);
                        setShowSelection(false);
                      }}
                      id={id + "select." +c.content_id }
                      safeDisplay={true}
                    />
                  </div>
                ))}
          
          </div>
          
          }

        </div>
        </div>
      }

    </>

};
export default EntityTemplateSelect;
