const genUid=(len)=>{
  const lettres = "abcdefghijklmnopqrstuvwxyz";
  let suid = "";
  for (let i = 0; i < len; i++) {
    const indexAleatoire = Math.floor(Math.random() * lettres.length);
    suid += lettres.charAt(indexAleatoire);
  }
return suid

}


const genSuid =  (objs, options = { len: 3, sub: null, objs: null }) => {
 
  let suids = getSuids(options.objs ?? objs, options);

  while (true) {
   let suid= genUid(options.len)
    if (!objs || !suids.includes(suid)) return suid;
  }
};

const getBySuid = (suid,objs, options) => {
  

  var obj = objs.find(o => o.suid===suid)
if( obj) return obj


  for (let i = 0; i < objs.length; i++) {

    if (options.sub && objs[i][options.sub])
     {
      obj =getBySuid(objs[i][options.sub],options)
      if (obj) return obj
     }
  }

  return null;
};
const setBySuid = (obj,objs, options) => {
  


if( objs.find(o => o.suid===obj.suid)) {

  return !options.returnStr ?  objs.map(o=> o.suid ===obj.suid ? obj : o  ) : 
  { objs :objs.map(o=> o.suid ===obj.suid ? obj : o  ), found :true }
}


  for (let i = 0; i < objs.length; i++) {

    if (options.sub && objs[i][options.sub])
     {
     let res =setBySuid(obj,objs[i][options.sub],{...options,returnStr:true})
     if(res.found  )
      {
        objs[i][options.sub] = res.objs
        return  !options.returnStr ?  objs : 
        { objs, found :true }
        
      }
  
     }
  }

return !options.returnStr ?  objs : { found :false }
};


const getSuids = (objs, options) => {
  let suids = [];

  for (let i = 0; i < objs.length; i++) {
    if (objs[i].suid) suids.push(objs[i].suid);

    if (options.sub && objs[i][options.sub])
      suids = [...suids, getSuids(objs[i][options.sub], options)];
  }

  return suids;
};

const suidize = (objs, options = { len: 3, sub: null, objs: null }) => {
  if( !Array.isArray(objs)) return objs

  for (let i = 0; i < objs.length; i++) {
    if( !objs[i] || (typeof objs[i] !== 'object')) continue
    if (!objs[i].suid ||   (typeof  objs[i].suid === 'object')) objs[i].suid = genSuid(objs, options);

    if (options.sub && objs[i][options.sub]) {
      objs[i][options.sub] = suidize(objs[i][options.sub], {
        ...options,
        objs: options.objs ?? objs,
      });
    }
  }

  return objs;
};

export {genUid, genSuid, suidize,getBySuid,getSuids, setBySuid };
