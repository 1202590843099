


class ContenuClass {

   static  classe_id= 7;
   static   props = [
            {
                "name": "Date",
                "type": "date",
                "type_nombre": "R"
            },
            {
                "name": "Titre",
                "type_nombre": "R"
            },
            {
                "name": "Texte",
                "type": "long text",
                "type_nombre": "I"
            },
            {
                "name": "Image",
                "type": "image",
                "type_nombre": "I"
            },
            {
                "name": "Editeur",
                "type": "classe",
                "classe_id": "3"
            },
            {
                "name": "App",
                "type": "classe",
                "classe_id": "4"
            },
            {
                "name": "Link",
                "type": "link",
                "classe_id": null,
                "type_nombre": "I"
            }
        ];



    constructor({contenu,id,project,setContent,loadRequired}
       )
    {
       this.contenu=contenu;
       this.contenu_id=id;

        this.project = project;
        this.content= {};
        this.setContent = setContent;
        this.loadRequired = loadRequired;
      
    }

     async init(){
      if(!this.contenu)
      {
      
        var _contenu = await this.project.api("/get_content/:content_id", {
            args: { content_id: this.content_id },
          });
      
          this.contenu = _contenu;
      }
          this.content= {
            "App": null,
            "Date":null,
            "Titre":this.contenu.content.title,
            "Texte" : this.contenu.content.textes,
            "Image":this.contenu.content.medias,
            "Editeur":null,
        };

         if(this.loadRequired?.includes("App")) await this.AppLoad();
         if(this.loadRequired?.includes("Editeur")) await this.EditeurLoad();

        if( this.setContent)  this.setContent(this.content)
     }

     addContent(prefix,content,loadRequired=[]){
      for (const [key, value] of Object.entries(content)) {
        var parts = key.split(".");
        if(parts.length>1 &&  !loadRequired.includes(parts[0] )) return;
        this.content[ `${prefix}.${key}`  ]=value
    }
    }

/* Loaders */

async AppLoad(){
var loadRequired= ["User"] ;
var appObject = await  this.project.getObject({className: "AppClass",id:this.contenu.app_id, loadRequired});

this.content["App"]= appObject
this.addContent(`App`, appObject.content,loadRequired)


}
async EditeurLoad(){
    var loadRequired= [] ;

    var editeurObject = await  this.project.getObject({className: "UserClass",id:this.contenu.owner_id, loadRequired});

    this.content["Editeur"]= editeurObject
    this.addContent(`Editeur`, editeurObject.content,loadRequired)
        

}

/* Actions */



    getContent(){

    }

} 



export default{ ContenuClass};