import { useContext, useEffect } from "react";
import { useState } from "react";
import { MfctContext } from "../../context";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputData from "./InputData";
import InputBoolean from "./InputBoolean";
import VarContent from "../VarContent";
import InputTextArea from "./InputTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { suidize } from "../../service/suid";

const InputDefinition = ({
  label,
  onChange,
  value,
  className,
  placeholder,
  trueValue,
  definitions,
  context
}) => {
  if( !value ) value ={}
  const [_value, setValue] = useState(value);
  const { getSchemas } = useContext(MfctContext);
  const [showSettings, setShowSettings] = useState(false);
  const schemas = getSchemas();

  useEffect(() => {
  if(JSON.stringify(_value)!==JSON.stringify(value))  {
    console.log("Warning value ",value,_value)
    setValue(value);}
  }, [_value,value, trueValue]);
  if(!value ) return <> NO VALue :{ label  }</>
  return (
    <div className="flex flex-col w-full border-t border-gray-400 mt-2 pt-1 ">
      <div className={" my-1 flex flex-row w-full items-center " + className}>
        <InputText
          className={"grow"}
          label={label}
          value={value.name}
          onChange={(name) => onChange({ ...value, name })}
        />
        <InputSelect
          placeholder={"Schéma"}
          value={value.schema_id}
          onChange={(schema_id) => {
            var code = null;
            var variant = null;

            if (schema_id) {
              if (schema_id.includes("variant:")) {
                variant = schema_id.replace("variant:", "");
              } else {
                schema_id = parseInt(schema_id);
                code = schemas.find((s) => s.content_id === schema_id).content
                  .code;
              }
            }

            onChange({ ...value, schema_id, code, variant });
          }}
          options={[
            ...(definitions
              ?.filter((v) => v.code === "schema")
              .map((v) => {
                return {
                  label: "*" + v.name + "*",
                  value: "variant:" + v.name,
                };
              }) ?? []),
            ...schemas.map((s) => {
              return { label: s.title, value: s.content_id };
            }),
          ]}
        />

        <InputSelect
          className={"  "}
          placeholder={"--Nombre--"}
          value={value.type_nombre}
          onChange={(type_nombre) => onChange({ ...value, type_nombre })}
          options={[
            { value: "", label: "Required" },
            { value: "O", label: "Optional" },
            { value: "I", label: "Liste" },
          ]}
        />
        <FontAwesomeIcon
                  icon={fa.faEllipsisV}
                  className="p-2 text-lg shadow-lg rounded-full w-4 h-4 ml-1 appbutton"
                  onClick={() => setShowSettings(!showSettings)}
                />
                </div>
         { showSettings && <div className="flex w-full my-1" >      
          {
          
           <InputBoolean
              className={"p-0 m-2 flex-col"}
              labelTrue={"Sous-contenu"}
     
              value={value.define}
              onChange={(define) => onChange({ ...value, define })}
              enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value }
            />
          }       {
            value.define && 
            <InputBoolean
               className={"p-0 m-2 flex-col"}
               labelTrue={"Ouvert"}
      
               value={value.define_open}
               onChange={(define_open) => onChange({ ...value, define_open })}
               enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.define }
             />
           }
           {
            
            <InputBoolean
               className={"p-0 m-2 flex-col"}
               labelTrue={"Filtre"}
      
               value={value.is_filter}
               onChange={(is_filter) => onChange({ ...value, is_filter })}
               enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value }
             />
           }
                  {
           <InputBoolean
             className={"p-0 m-2 flex-col"}
             labelTrue={"Calcul"}
    
             value={value.calcul}
             onChange={(calcul) => onChange({ ...value, calcul })}
             //enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value }
           />
         }                  {
          <InputBoolean
            className={"p-0 m-2 flex-col"}
            labelTrue={"Front"}
   
            value={value.front}
            onChange={(front) => onChange({ ...value, front })}
            //enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value }
          />
        }        {( value.type_nombre==="I" || value.type_nombre==="F") && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value &&
          <InputBoolean
            className={"p-0 m-2 flex-col"}
            labelTrue={"Inline"}
   
            value={value.is_inline}
            onChange={(is_inline) => onChange({ ...value, is_inline })}
            //enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value }
          />
        }
          {
          <InputBoolean
            className={"p-0 m-2 flex-col"}
            labelTrue={"Optional"}
   
            value={value.is_optional}
            onChange={(is_optional) => onChange({ ...value, is_optional })}
            //enabled={_value?.schema_id && !schemas.find(c=> c.content_id+""===""+ _value.schema_id).content.is_value }
          />
        }
                    {
          (  context?.codeSchema==="ihm_action") &&
            <InputBoolean
               className={"p-0 m-2 flex-col"}
               labelTrue={"NoLabelSet"}
      
               value={value.is_noset_label}
               onChange={(is_noset_label) => onChange({ ...value, is_noset_label })}
             
             />
           }
        
      </div>}
      {value.calcul  && (
        <div className="w-full pl-4 ">
          <InputTextArea
            className={"flex-row w-full "}
 label={"Script - " + value.name}       
         
   value={value.script}
            onChange={(script) => onChange({ ...value, script })}
          />
        </div>
      )}
      {value?.code === "enum" && (
        <div className="w-full pl-4 ">
          <InputData
            className={"flex-row"}
            prop={{ name: "Valeur", type_nombre: "I" }}
            schema={{ content: { code: "text" } }}
            value={value?.values ?? []}
            onChange={(values) => onChange({ ...value, values })}
          />
        </div>
      )}
      {value.is_filter &&    
       <InputData
               
                  className={"w-full"}
                  prop={{ name: "Filtre", type_nombre: "I", define: true,define_open:true  }}
               schema={{ content_id: 455 , content: { code: "filtre" }}}
                  value={value.filter ??[]}
                  onChange={(filter) => onChange({ ...value, filter })}
                  context={  value}
                />}

      {value?.code === "sub_schema" && (
        <div className="w-full pl-4 ">
          <InputData
            className={""}
            prop={{ name: "Définition " + value.name, type_nombre: "I" }}
            schema={{ content: { code: "definition" } }}
            value={value?.values ?? []}
            onChange={(values) => onChange({ ...value,  values })}
          />
        </div>
      )}

{ showSettings && (
        <div className="w-full pl-4 ">
          <InputText
            className={""}
         
            label={"Groupe"}       
         
   value={value.group}
            onChange={(group) => onChange({ ...value, group })}
           
          />
        </div>
      )}
            { showSettings && (
        <div className="w-full pl-4 ">
          <InputTextArea
            className={""}
         
            label={"Infos"}       
         
   value={value.infos}
            onChange={(infos) => onChange({ ...value, infos })}
           
          />
        </div>
      )}
       { showSettings && (
        <div className="w-full pl-4 ">
          <InputTextArea
            className={""}
         
            label={"Structure"}       
         
   value={JSON.stringify( value)}
                  disabled={true}
           
          />
        </div>
      )}
    </div>
  );
};
export default InputDefinition;
