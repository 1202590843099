import React, { useEffect, useMemo, useState ,useContext} from 'react';
import { Link, useNavigate ,useParams} from "react-router-dom";


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";


function NewContent() {
  const { app_id } = useParams();
const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);
  const [files, setFiles] = useState([]);
  const [texts, setTexts] = useState([""]);
  const [previews, setPreviews] = useState([]);
  const [urlApp,setUrlApp] = useState();   
  const [templates, setTemplates] = useState([]);



  async function getTemplates() {
      var _templates = await project.api("/templates")
   
    setTemplates( _templates)
  }

useEffect(()=>{

if( project)
{
  getTemplates()
}

},[project]);

function handleTexte(i){

  if( !document.getElementById(`text-${i}`) || document.getElementById(`text-${i}`).value==texts[i]) return;
var  _texts= texts.map( (text,index)=> i===index ?   document.getElementById(`text-${i}`)?.value??text :  text)
if(i==_texts.length-1)
{ 
  _texts=([..._texts,""]);
}

setTexts(_texts)
}
  
  function handleImgChange(e) {
      console.log(e.target.files);

      var _files = files
      var _previews = []
console.log( files);
      for(var i=0;i<e.target.files.length;i++)
{     
  _files.push(e.target.files[i]) 
}

      for(var i=0;i<_files.length;i++)
{     
  _previews.push( URL.createObjectURL(_files[i]) )
}

      setPreviews(_previews)
      setFiles(_files);

  }


  const navigate = useNavigate();
const handleImgUpload=async (e)=>{
  e.preventDefault();

const formData = new FormData()

formData.append('title',document.getElementById('title').value);
files.forEach((file,i)=>{
  formData.append('file-'+i ,file,file.name);
})
formData.append('images-length' ,files.length);

formData.append('texte', texts[0]);
formData.append('texts' ,texts);

formData.append('is_public',    document.getElementById('is_public').value+""=="1");
formData.append('template_id',    document.getElementById('template_id').value);
formData.append('app_id',app_id)
var  url_server = process.env.REACT_APP_MFTC_SERVER_URL;
var parts =  window.location.href.split(":");
if(   parts[0]=="http"  && parts[1]!=="//localhost") url_server = url_server.replace("//localhost",parts[1]);

var newcontent =   await project.api("/app/:app_id/new_content", {args:{app_id}, body:formData }); 

console.log(newcontent)

navigate(`/app/${app_id}/contents`)

}




  return (
    <div className="App  text-zinc-600 text-sm">
     { !urlApp &&  <header className=" min-h-screen relative">

  <div className='z-1 w-full mt-4'>     Nouveau contenu

       <form id="uploadimage"  className="flex flex-col items-start p-4 w-full" onSubmit={handleImgUpload} action="" method="post" encType="multipart/form-data">
    
       <label>Titre</label>
   
   <input  type="text"  className='text-black w-full border-b border-zinc-700' name="title" id="title"    placeholder='Saisissez votre titre' />
   
      
     <label>Textes</label>
   {   texts.map((text,i)=> 
    <textarea id={`text-${i}`} defaultValue={text} className='text-black  w-full border  border-zinc-700 m-2'  placeholder='Saisissez votre texte'  onChange={()=>handleTexte(i)}/>
 ) 
   }  
  


  

   <select   className='p-2 m-2 text-black  w-full border-b border-zinc-700' name="is_public" id="is_public"    defaultValue={0} 
    >
      <option value={0} >visible avec authentification</option>
      <option value={1}>visible pour tous</option>
   </select>

       <div className='w-full p-2 relative flex-wrap flex'>

          {
        previews.map((file,i)=>
          <img  className="w-1/2 h-40 my-2 p-2 object-cover rounded-xl" src={file} key={i} />
          )
      }  
         <div  className=" w-1/2 h-40  bg-slate-400 rounded-xl p-2 "  onClick={()=>{ document.getElementById("click-upload").click()}} >

<div  className='w-full h-full p-2  flex flex-row items-center justify-center '>  <label  >Ajouter Image</label></div>
   
      <input type="file" name="image" id="click-upload" className="hidden" multiple={true} onChange={handleImgChange}  />

</div>
  </div>      
  <select   className='p-2 m-2 text-black  w-full border-b border-zinc-700' name="template_id" id="template_id"  placeholder='Choisissez le thème'  defaultValue={""}  
    >
       <option disabled={true} value="" >Choisissez le template</option>
   {
    templates.map((tpl,i)=>
    <option value={tpl.template_id}  key={i}>{tpl.title}</option>
    )
   }  
   </select>
    <div className="w-full flex justify-end">

    <button type="button" className="border border-1 p-2 m-1 first-line:rounded" onClick={()=>navigate(-1)}  >Annuler</button>
    <button type="submit" className="border border-1 p-2 m-1 first-line:rounded" id="upload">Créer contenu</button>
        </div>
</form>
</div>
      </header> }

   
    </div>
  );
}

export default NewContent;
