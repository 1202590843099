import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";

import * as fa from "@fortawesome/free-solid-svg-icons";




function    Header1 ({  title, iconRight,iconLeft, clickRight, linkLeft,linkRight,classLeft,classRight, children,logo})  {

  const navigate = useNavigate();
  return         <div className="w-full relative mb-20 "><div className="   w-full    bg-slate-50 text-gray-700 fixed top-0  z-50 safearea-top">
    <div className="p-4  flex flex-row w-full justify-between items-center   bg-slate-50 text-gray-700">
  
  { logo ??   <div onClick={()=>navigate(linkLeft ??  -1)}>
    {" "}
    <FontAwesomeIcon
      icon={iconLeft ?? fa.faArrowLeft}
      className={"mx-4  " +classLeft}
    ></FontAwesomeIcon>{" "}
  </div>

}
  <span className="text-2xl font-extrabold">{title} </span>
  <div className="flex ">
  <div  className="md:flex hidden " >  {children}</div>
{
linkRight ?
  <Link to={linkRight}  >
    <FontAwesomeIcon
      icon={iconRight}
      className={ "p-2 " + classRight}
    ></FontAwesomeIcon>
  </Link>
  
  :
  clickRight  ?

<FontAwesomeIcon     onClick={ clickRight}
      icon={iconRight }
      className={"  p-2 "+ classRight}
    ></FontAwesomeIcon>

  :
  <FontAwesomeIcon    
      icon={ fa.fa1}
      className="opacity-0  p-2"
    ></FontAwesomeIcon>
}</div>

</div>
</div>
<div  className="flex md:hidden w-full" >  {children}</div>
</div>

} 

export default Header1;
