import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";

const ToolFlexRow = ({ updateStructure, structure, idObj, selectTpl }) => {
  const [show, setShow] = useState();

  const classes = useMemo(() => {
    console.log(structure, idObj);
    var _structure = getStructure(structure, idObj);

    var parts = _structure.className
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [structure, idObj]);

  const setTool = () => {
    hoverEnd();
    var _classes = classes;

    _classes = _classes.filter(
      (classe) =>
        classe !== "flex" && classe !== "flex-row" && classe !== "flex-col"
    );

    updateStructure(
      { className: [..._classes, "flex", "flex-row"].join(" ") },
      idObj
    );
  };

  var paramsOriginals;

  const hoverStart = () => {
    hoverEnd();

    var obj = document.getElementById(idObj);

    if (obj) {
      paramsOriginals = {
        flex: obj.classList.contains("flex"),
        flexRow: obj.classList.contains("flex-row"),
        flexCol: obj.classList.contains("flex-col"),
      };
      if (!paramsOriginals.flex) obj.classList.add("flex");
      if (paramsOriginals.flexCol) obj.classList.remove("flex-col");
      if (!paramsOriginals.flexRow) obj.classList.add("flex-row");
      console.log("hoverStart Row", paramsOriginals, obj.className);
    }
  };

  const hoverEnd = () => {
    var obj = document.getElementById(idObj);

    if (obj && paramsOriginals) {
      if (!paramsOriginals.flex) obj.classList.remove("flex");
      if (paramsOriginals.flexCol) obj.classList.add("flex-col");
      if (!paramsOriginals.flexRow) obj.classList.remove("flex-row");
      console.log("hoverEnd Row", paramsOriginals, obj.className);
      paramsOriginals = null;
    }
  };

  return (
    <>
      <div
        className={
          "inline-flex flex-row appbutton border-2  border-white  p-1  mx-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={setTool}
        onMouseOver={() => hoverStart()}
        onMouseOut={() => hoverEnd()}
      >
        <div
          className="p-1 py-3  bg-white"
          style={{ marginRight: "2px" }}
        ></div>
        <div
          className="p-1 py-3  bg-white"
          style={{ marginRight: "2px" }}
        ></div>
        <div
          className="p-1 py-3  bg-white"
          style={{ marginRight: "2px" }}
        ></div>
      </div>
    </>
  );
};
export default ToolFlexRow;
