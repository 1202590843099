import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SocketContext } from "./Context"
import { useContext,useState } from 'react';


const Options = () => {
    const { me, callAccepted, name, setName, callEnded, leaveCall, callUser } = useContext(SocketContext);
    const [idToCall, setIdToCall] = useState('');
return
return (
    <div maxW="1200px" m="35px 0" p="0">
        <div p="10px" border="2px" borderColor="black" borderStyle="solid">
            <div display="flex" flexDirection="column" noValidate aria-autocomplete="none">
                <div templateColumns='repeat(2, 1fr)' mt="12">
                    <div colSpan={1} p="6">
                        <div as="h6"> Account Info </div>
                        <label>Username</label>
                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} width="100%" />
                        <CopyToClipboard text={me} mt="20">
                            <button leftIcon={<div />} colorScheme='teal' variant='solid'>
                                Copy ID
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div colSpan={1} p="6">
                        <div as="h6"> Make a Call </div>
                        <label> User id to call </label>
                        <input type='text' value={idToCall} onChange={(e) => setIdToCall(e.target.value)} width="100%" />
                        {
                            callAccepted && !callEnded ? (
                                <button leftIcon={<div />} onClick={leaveCall} mt="20" colorScheme='teal' variant='info'>
                                    Hang up
                                </button>
                            ) : (
                                <button leftIcon={<div />} onClick={() => callUser(idToCall)} mt="20" colorScheme='teal' variant='solid'>
                                    Call
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}
export default Options