import { useEffect, useMemo } from "react"
import { useState } from "react"



const InputSelectPropSchema = ({label,onChange,value,className,options,placeholder,trueValue,criteria_schema ,globalSchema,schemas,parentSchema_prop}) => {
  
  

  const [_value,setValue]=useState(value)


    const [propExplode,setPropExplode]= useState({})


    const propsBySchema  = (schema,preValue="")=>{
        var _result = []
        schema.content.schema.forEach(c=>{
      

      if( (!criteria_schema|| criteria_schema( c) ))  _result.push({schema_id:c.schema_id, value: preValue+c.name  , label:  `<${preValue+ c.name}>` })
         

           var c_schema =schemas && schemas.find(s=>s.content_id===c.schema_id)
         if(c_schema)
           { 
         
             if(!c_schema.content.is_value){
         if(!propExplode[ preValue + c.name ])
         {
             _result.push({...c ,value: preValue+   c.name+".*",label :   "<" + preValue+""+ c.name+ ">.*" })
           //  console.log(" Hola ",   schema.content.Titre, schema.content.schema)
         }
         else
         { 
            _result = [..._result, ...propsBySchema(c_schema, preValue+ c.name+"."  )  ] 
         }
           }
          }  })
      
      return _result;
      }

    const schemaProps = useMemo(()=>{

        var _result = []
        if( globalSchema )_result = propsBySchema(globalSchema);
       
       
       if( value)
       {
         if( !options.find(c=>c.value=== value) && !_result.find( r=> r.value === value)){
       
           _result.push({ value,label: "<"+ value.split(".").join(">.<")  + ">" })
         }
       }
       
       return [..._result,...options];
       
       
       
       },[globalSchema,propExplode])





    useEffect(()=>{

        setValue( value)
    },[value,trueValue])
return (
    <>
    <div className={"relative " +className } >{ parentSchema_prop && "parentProp : "+ parentSchema_prop }
    <select  onChange={(e)=>{
          var schema_prop = e.target.value

            var pl = schema_prop.split(".");
            var ml= pl.pop()
            if(ml==="*"){
              var _p = {...propExplode}
            _p[pl.join(".") ] = true
              setPropExplode(_p)
             // setStructure({...template,schema_prop:null})
            }
            else
            {
console.log( "$$$$$$$$ schemaprop", schema_prop, schemaProps)
              var c =schemaProps.find(s=>s.value ===schema_prop)
              var schema_id = c?.schema_id

              if(schema_prop===`-- ${placeholder} --`) schema_prop = null

                onChange(schema_prop,schema_id)
                       }
                        
                        
          }}
        
        
        
        
        
        
        
        
        value={  _value  } id={"input_"+label} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" >
   
    {placeholder && <option value={null} className="   text-opacity-50   text-zinc-500    italic"   >-- {placeholder} --</option>  }
   {schemaProps && schemaProps.map((option,i)=>  <option key={i} value={option.value}>{option.label?? option.value}</option>)}

    </select>
    <label htmlFor={ "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
</div>
</> 
)
}
export default InputSelectPropSchema