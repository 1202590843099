import React, { useEffect, useMemo, useState, useContext } from 'react';


import { Link } from 'react-router-dom';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import LoaderSmall from '../../../../components/LoaderSmall';

function AppNewIds({ app }) {

  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const classTypes = { error: 'text-red-500 font-bold', warning: 'text-yellow-500', green: 'text-green-500', bold: 'font-bold' }

  const ReceiveMessage = (strMsgs) => {
    const sep = "@*_*@"
    var msgsLines = [];
    var parts = strMsgs.split(sep);
    parts.shift();
    var newmsgs = parts.map(strMsg => JSON.parse(strMsg))
    newmsgs.forEach(message => {
      var lines = message.text.split("Line ");
      if (lines.length == 1) {
        if (message.text === 'yIds créées'||message.text === 'yIds supprimées') setIsLoading(false)
        msgsLines.push(message);
      }
      else msgsLines = [...msgsLines, ...lines.map(line => {
        return ({ text: line, type: message.type })
      }
      )];
    })
    setResult((msgs) => [...msgs, ...msgsLines])

  }

  const CreateNewIds = async () => {
    setResult([]);
    setIsLoading(true)
    await project.api("/create_new_ids", { body: { domain: app.domain }, eventStreamListener: ReceiveMessage })
  }

  const DeleteNewIds = async () => {
    setResult([]);
    setIsLoading(true)
    await project.api("/delete_yids", { body: { domain: app.domain }, eventStreamListener: ReceiveMessage })
  }

  return (
    <>
      <div className='w-full flex flex-col  py-4 bg-white px-4 rounded-xl text-black gap-3  text-sm' >
        <> Création de nouvelles identifications </>
        {!isLoading && <button onClick={CreateNewIds}> Create new Ids </button>}
        {!isLoading && <button onClick={DeleteNewIds}> Delete yids </button>}
        {isLoading && <LoaderSmall />}
        {result &&
          <div className='flex flex-col w-full'>  Résultat : {result.map((message, i) =>
            <div className={'w-full p-1 text-sm ' + (message.type && classTypes[message.type])} key={i}>
              {message.text}
            </div>)}   </div>
        }
      </div>

    </>
  );
}

export default AppNewIds;
