import { data } from "./data";
import { retrieveProject } from "./mftc-api";

var dataSources = [];

const getSourceParamNames = (dataSource) => {
  var names = [];
//todo
  return names;
};

const getParamsContent = (content, dataSource) => {
  var params = {};

  return params;//todo
};

const getDataSource = async (dataSource_id, content, template_id) => {
  var dataSource;
  if (dataSources[dataSource_id]) dataSource = dataSources[dataSource_id];
  else {
    dataSource = await data.getContent(dataSource_id, true);

    dataSource.names = getSourceParamNames(dataSource);
  }
  var params = getParamsContent(dataSource, content);

  var result = await retrieveProject().api("/get_data_source", {
    body: { dataSource_id, template_id, params },
  });

 data.setContents(result.contents);

  return result.data;
};

export { getDataSource };
