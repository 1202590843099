import React, { useEffect, useMemo, useState,useContext } from 'react';

import { setToken } from '../../../../service/mftc-api';
import '../../../../App.css';
import { Link,useNavigate } from 'react-router-dom';
import { MfctContext } from "../../../../context";

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpdateSW from '../../../../UpdateSW';
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';

function Home() {

  const navigate = useNavigate ();

const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const [app,setApp]= useState()
const [contents,setContents]=useState()
const getApp= async()=>{
  if(!project) return
  var result = await project.api("/get_app", { body:{} })
  console.log( result)
    setApp(result.app);
    setContents(result.contents);
}


useEffect(()=>{

  if(authUser)
 {

  navigate("/dashboard")
 } 
},[authUser])


useEffect(()=>{
  getApp()
},[project])

const connect=async()=>{
  var wconnect = window.open(  process.env.REACT_APP_URL_AUTH,"appisyou_auth")
  
  window.addEventListener('message', function(e) {
    // e.data hold the message from child
    console.log(e.data); 

    if(e.data.token && e.data.authUser)
    { setToken(e.data.token,e.data.authUser)
     
  setAuthUser(e.data.authUser)
      wconnect.close(); 
      navigate("/")
        }


  } , false);
}

const logout=async()=>{

   setToken()
   
setAuthUser()
}


  


  return ( !project ? <Loader /> : 
    <div className="App mb-20">
      <div className='p-2 flex flex-row  justify-between items-center'>  <img src={ process.env.REACT_APP_MFTC_SERVER_URL_FILES+ "profile/user.png"} className="w-8 h-8 " alt="logo" /> {project && project.status && project.status.name}
      
      <span className={ "bg-white text-zinc-900 p-2 my-3 rounded-md mx-2 font-bold text-xs "  }   onClick={connect}   > Connexion </span>
     </div>
       
      
 
<div className='flex flex-col  justify-center items-center'>

<img src={ process.env.REACT_APP_MFTC_SERVER_URL_FILES+ "profile/user.png"} className="w-40 h-40 rounded-full " alt="logo" /> 
<div className='text-sm'> salut </div>
{
  contents && contents.map((content,i)=> <Content content={content} key={i}/>)
  }



</div>
        
    <div className='fixed bottom-0 w-full flex flex-row justify-center items-end px-4'>
      <div className='text-xl rounded-full p-4 m-4 bg-green-600 text-white hiden'><a href="mailto:philippe.hoton@gmail.com" target='_blank'><FontAwesomeIcon  icon={fa.faEnvelope} className=''></FontAwesomeIcon></a>  </div>
      </div>
   
 <UpdateSW />
    </div>
  );
}

export default Home;
