import React, { useEffect, useMemo, useState,useContext } from 'react';


import '../../../../App.css';
import { Link ,useNavigate} from 'react-router-dom';
import { MfctContext } from "../../../../context";
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import Notifications from '../../../../components/permissions/Notifications';
function DashBoard() {


  const navigate = useNavigate ();
const [status,setStatus]=useState();

const { project, getProject,setAuthUser,authUser,account} = useContext (MfctContext);

const [app,setApp]= useState()
const [contents,setContents]=useState()
const getApp= async()=>{
  if(!project) return
  var result = await project.api("/get_app", { body:{} })
  console.log( result)
    setApp(result.app);
    setContents(result.contents);
}



useEffect(()=>{

  if(!authUser)
 {

  navigate("/home")
 } 
},[authUser])


useEffect(()=>{
  getApp()
},[account])

  

  // show the modal only once


 

  return ( !account ? <Loader /> : 
    <div className="App   mb-20 "> 
  
  
  <div className='p-2 flex flex-row  justify-between items-center'> 
   <img src={ process.env.REACT_APP_MFTC_SERVER_URL_FILES+ "profile/user.png"} className="w-8 h-8 " alt="logo" /> {project && project.status && project.status.name}
      

      <Link to="/account"><span className={ "bg-white text-zinc-900   mx-2 font-bold text-xs "  }    > 
      <img src={  account.profile?.imageUrl  ?? null} className="w-8 h-8 rounded-full" alt="logo" /> 
       </span></Link>
     
      </div>
      <div className='flex flex-col text-xs'>
      <div className='flex flex-row items-center justify-center'>
 </div>
    
        </div>
      <div className='flex flex-col  justify-center items-center '>
       
<img src={ process.env.REACT_APP_MFTC_SERVER_URL_FILES+ "profile/user.png"} className="w-40 h-40 rounded-full " alt="logo" /> 


{
  contents && contents.map((content,i)=> <Content content={content} key={i}/>)
  }


 <div className='my-2 w-full text-sx p-6 font-bold shadow-md rounded-lg bg-green-700 text-zinc-100 relative'> Rendez-vous le 13/12/2023 à 15h 

<div className='absolute -bottom-6 right-0 flex-row flex'>
   <div className='p-2 rounded-full  border-white border-2  bg-red-500 text-white mx-2 '>Annuler</div>
   <div className='p-2 rounded-full border-white border-2  bg-blue-500  text-white mx-2 '>Modifier</div>
</div>
</div>
</div>
        
    <div className='fixed bottom-0 w-full flex flex-row justify-end items-end'>
    <div className='text-2xl rounded-full bg-gray-500 bg-opacity-50 p-2 m-2 h-12 w-12 '> 
      <Link to="/meet"  ><FontAwesomeIcon  icon={fa.faCalendar} className=' text-green-600 '></FontAwesomeIcon></Link>
       </div>
         <div className='text-2xl rounded-full bg-gray-500  bg-opacity-50 p-2 m-2 h-12 w-12 '> 
      <Link to="/chat"  ><FontAwesomeIcon  icon={fa.faMessage} className=' text-orange-600 '></FontAwesomeIcon></Link>
       </div>

      </div> 

     
    
        
      
    </div>
  );
}

export default DashBoard;
