import React, { useEffect, useMemo, useState,useContext } from 'react';

import { Link } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Image from '../../../../components/Image';

function ModuleTemplates({module}) {



const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

const [templates,setTemplates]= useState([])
const getTemplates = async()=>{

  var _templates = await project.api("/module/:module_id/templates",{args:{module_id:module.module_id}})
  console.log( _templates)

  setTemplates(_templates);
}

useEffect(()=>{

  getTemplates()


},[])
 return (

      <>   <div className='flex flex-row w-full py-4 bg-white px-4 rounded-xl text-black justify-between items-center border-b border-zinc-700 pb-2'><span>{ templates.filter.length } Templates</span>
      <Link to={`/module/${module.module_id}/template/add`}>
      <div  className='  mx-2 p-1 px-2 shadow-xl rounded-full ' >Créer <FontAwesomeIcon icon={fa.faPlusCircle} /></div></Link>   
      </div> 
     <div  className=' py-4 bg-white px-4 rounded-xl text-black w-full flex flex-col p-4  text-sm md:grid md:grid-cols-2 xl:grid-cols-4  gap-3  ' >
    
   { templates && templates.map((template,i)=>
     <div className='rounded-sm border-b bg-zinc-100 w-full  text-black flex flex-col  justify-between items-start p-4  '   key={i} >
      <div className='flex flex-row justify-start items-start' > <Image src={ module.content.imageUrl }  className=' m-1 w-10 h-10 rounded-sm'/> 
      <div  className='w-full flex flex-col items-start'>
      <div  className='  font-extrabold'> {template.title}</div>
      <div className='text-left text-sm'>{ template.description}</div> 
</div>
</div>
    <div   className='w-full flex flex-row items-center justify-end '> 


      { 
template.template_id && <><Link to={`/module/${module.module_id}/template/${template.template_id}`}>
<div  className=' bg-green-500 text-white mx-2 p-1 px-2 shadow-xl rounded-full ' >Consulter <FontAwesomeIcon icon={fa.faEdit} /></div></Link>
</>
      }
    </div></div> ) 
     }
     
    </div>
    </>
  );
}

export default ModuleTemplates;
