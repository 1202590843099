import {useEffect, useState} from 'react';


const media_query = {
    sm: " (min-width: 640px)",
    md: " (min-width: 768px)",
    lg: " (min-width: 1024px)",
    xl: " (min-width: 1280px)",
    "2xl": " (min-width: 1536px)"

}

const useMediaQuery = (media) => {
    if(!media_query[media])  media="md";
  const mediaMatch = window.matchMedia(media_query[media]);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change",handler);
    return () => mediaMatch.removeEventListener("change",handler);
  });
  return matches;
};



export default useMediaQuery;