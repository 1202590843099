


const InputTitreText = ({id,label,onChange,value,className}) => {
return (
    <div className="flex flex-col w-full">
    <div className={"relative " +className } >
    <input type="text" id={id ?? "input_"+label} onChange={(e)=>onChange(    {...(value??{}),titre:e.target.value})}   defaultValue={value?.titre}   className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
    <label htmlFor={ id ?? "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
  
</div>
<textarea  id={(id ?? "input_"+label)+"area"} onChange={(e)=>onChange(    {...(value??{}),text:e.target.value})}  rows={Math.max(1, (value?.text??"")?.split("\n").length+1 ?? 0 )  } value={value?.text}  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-inherit rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
  
</div> 
)
}
export default InputTitreText




/*

const InputText = ({label,onChange,value,className,placeholder}) => {
return (
    <>
    <div className={"relative " +className } >
    <input type="text"  onChange={(e)=>onChange(e.target.value)}   defaultValue={value}  id={"input_"+label} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
 <label htmlFor={ "input_"+label } className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{ value ?  label : (placeholder??label) }</label>
</div>
</> 
)
}
export default InputText


*/