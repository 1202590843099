import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../../App.css";

import TemplateEdition from "../../../../components/edition/TemplateEdition";

import TemplateTree from "../../../../components/templates/TemplateTree";
import { structureClean, structurePutIn, structureReplace } from "../../../../tools/tpl";
import Loader from "../../../../components/Loader";

function PopupTemplateEditor() {
  const [dragged,setDragged]= useState()
  const [data, setData] = useState();
  const [saveAll, setSaveAll] = useState({})
  const [backupTemplate,setBackupTemplate]=useState()
  const [explodeTree, setExplodeTree] = useState(true);
const [loading, setLoading] = useState(false);


  const setTemplate = (template) => {

    window.opener.postMessage({ editor: true, setTemplate: true, template });
  };

  const setSelected = (selected) => {
    window.opener.postMessage({ editor: true, setSelected: true, selected });
  };


const _setDragged=(obj,id)=>{

if(backupTemplate){
console.log("backupTemplate already here !!", backupTemplate)
return 
  }  
setBackupTemplate ( {
 template : JSON.parse(JSON.stringify(data.template)),
 id,
 obj,
 pos:{cancel:true}
})
console.log("backupTemplate", backupTemplate)

  setDragged(obj)
}



const dragging= ( pos)=>{



  if(!backupTemplate || JSON.stringify(backupTemplate.pos)===JSON.stringify(pos)) return 
  console.log("dragging",pos );
 
  if(pos.cancel)
  { 
    setBackupTemplate ( b=>({...b,pos}))
    console.log("Cancel ",pos );
    //On remet comme au debut
                        setTemplate( backupTemplate.template
                           
                        
                        ) 
                   
  
  }
  else  if(pos.end)
  {   
     selectTpl(backupTemplate.new_id )
     setBackupTemplate ( )
    console.log("End of backupTemplate ")
    setDragged()

     // C'est fini




  }
  else if (pos.parent  || pos.sibling)
  {
      // On bouge l'element
     
      setBackupTemplate ( b=>({...b,new_id: pos.data_id+(pos.parent ?".0":"")  }))

       pos.data_id=pos.data_id.split(backupTemplate.id+".").join("").split(backupTemplate.id).join("") 
      var structure = structurePutIn( 
        structureReplace(
        backupTemplate.template.structure,{empty:true},backupTemplate.obj.id.split(backupTemplate.id+".").join("").split(backupTemplate.id).join("") ) , backupTemplate.obj.structure, pos)
    
        setTemplate({...backupTemplate.template,structure: structureClean(structure )}   ) 
  }
}

const updateLiveTemplate = (template) => {
  console.log("mod",template )
  setData(data=>({...data,template}))
  window.opener.postMessage({
    editor: true,
    updateLiveTemplate: true,
    template,
  });
};

  const setBaseContent = (simulatedContent) => {
    window.opener.postMessage({
      editor: true,
      setBaseContent: true,
      simulatedContent,
    });
  };

  const setInternal = (key, value) => {
    window.opener.postMessage({ editor: true, setInternal: true, key, value });
  };
  const setTemplates = (templates) => {
    window.opener.postMessage({ editor: true, setTemplates: true, templates });
  };


  const cancelTemplate = () => {
    window.opener.postMessage({ editor: true, cancelTemplate: true });
  };

  const saveTemplate = (template) => {

    window.opener.postMessage({
      editor: true,
      saveTemplate: true,
      template:  template
        ,
    });
    updateLiveTemplate(template);

  };



  const saving =async ()=>{
setLoading(true);
    var keys =  Object.keys(saveAll)
    for(var i= 0; i<keys.length; i++)
    {
   
      const { func ,obj} = saveAll[keys[i]]
      console.log("Enrgistrement " + keys[i], obj)
      await func(obj)
    }
    
      setSaveAll({})
      setLoading(false);
      toast( "Successfully saved '" );
    }
    
    const saver =(key,func,obj)=>{
    if( !func)
    {
        var  v= saveAll;
        delete v[key];
        setSaveAll(v)
    }
    else
    setSaveAll(v=>({...v, [key]:{func,obj}  }))

    }




  const selectTpl = (id, tpl) => {
    var post = { editor: true };

    post = {
      ...post,

    };

    if (tpl) {
      post = { ...post, setSelectedTemplate: true, selectedTemplate: tpl };

      /****     RQajouter dans le receive message */
      if (tpl.schema_label) {
        var obj2 = document.getElementById(tpl.schema_label + "-schema_label");
        if (obj2) {
          console.log("Scroll to", obj2.id, obj2);
          obj2.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          });
          obj2.focus();
        }
      }
    }

    post = { ...post, setSelected: true, selected: id };
    window.opener.postMessage(post);
  };




  useEffect(() => {
    const getData = async function (e) {
      if (!e.data.editor) return;
      console.log("getData    ******   ", e.data);

      setData((d) => ({ ...d, ...e.data }));
    };
    window.addEventListener("message", getData);
    window.opener.postMessage({ editor: true, getData: true });

    return () => {
      window.removeEventListener("message", getData);
    };
  }, []);



  return (
    <div className="App  text-zinc-600 text-sm mb-10 relative">
 { loading && <Loader />   }
      {data && data.template &&
     (
          <header className=" ">
            <div className="flex flex-col w-screen">
              <div className="w-full flex flex-row    bg-zinc-100 rounded-t-xl  z-50 px-2 ">






                <div
                  className={
                    "  border-x-2 p-2 border-zinc-600 my-2    h-screen overflow-y-auto relative pb-12"
                  }
                >
                 
                  <div className={" flex   flex-col items-start    relative w-80 "}>
                  <div className="font-bold p-1 text-xl text-left w-full sticky top-0  ">
                    Tree
               
                  </div>
                    <TemplateTree
                      selectTpl={selectTpl}
                      id={"-root"}
                      explodeTree={explodeTree}
                      setStructure={ (structure)=> updateLiveTemplate({...data.template,structure}) }
                      schemas={data.schemas}
                      selected={data.selected}
                      template={
                        data.template.structure ?? {}
                      }
                      setSelected={selectTpl}
                      dragged={dragged}
                  
                      setDragged={(_id)=>_setDragged(_id, "-root" )}
                      dragging={dragging }
                    />
                  </div>
                </div>

                <div className="flex flex-col grow  items-start justify-start">
                                     
                        <div className=" flex  flex-col  border-x-2 p-2 border-zinc-600 my-2 w-full ">

            
                              <TemplateEdition
                                id={"-root"}
                                simulatedContent={data.baseContent}
                                setSimulatedContent={setBaseContent}
                                setInternal={setInternal}
                                selected={data.selected}
                                defaultTemplate={data.template}
                                template_id={data.template_id}
                                setSelected={selectTpl}
                                updateLiveTemplate={updateLiveTemplate  }
                                setTemplates={setTemplates}
                                saveTemplate={ saveTemplate }
              saver={saver}
                              />
                            </div>
                         
                     
                    
                      
                    </div>
              

                 
                </div>
              </div>

              <div className="p-4 bg-white w-full fixed bottom-0 left-0 z-40 flex justify-end z-50">
                <div
                  type="button"
                  className="border border-1 p-2 m-1 first-line:rounded"
                  onClick={cancelTemplate}
                >
                  Annuler
                </div>
                {
             Object.values(saveAll).length>0 &&  <div
                  type="button"
                  className=" bg-black text-white rounded  p-2 m-1 first-line:rounded relative"
                  onClick={saving}
                  id="upload"
                >
                  Enregistrer Template
                  <div className="absolute -top-2 -right-2 p-1 text-xs bg-green-900 text-white rounded-full h-5 w-5 flex justify-center items-center" >{Object.values(saveAll).length}</div>
                </div>
}
              </div>
            
          </header>
        )}
    </div>
  );
}

export default PopupTemplateEditor;
