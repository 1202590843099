import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";




const InputFile = ({label,onChange,value,className,domain}) => {
    const [file, setFile] = useState();
    
    
    function handleImgChange(e) {
        console.log(e.target.files);

        setFile(URL.createObjectURL(e.target.files[0]));
        onChange(   e.target.files[0])
    }
  


return (
    <>
    <div   onClick={ onChange  ? ()=>{ document.getElementById("image-upload-" + label ).click()}:null}  className={"  " +className +"  flex flex-col items-center  justify-center  text-sm text-gray-900 bg-transparent rounded-xl appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"  } >
    {  !onChange && <FontAwesomeIcon icon={faFile}   className="h-20 my-14" />  }

   { ((typeof value) === "string") && value }

   { ((typeof value )=== "object") && value.name }

{!value && <div className=" p-4 ">Ajouter </div>}
     <input type="file" accept={"*/*"} name="image" id={"image-upload-"+label} className="hidden" onChange={handleImgChange} required />
    
</div>
</> 
)
}
export default InputFile