import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { InputPhoneNumberPrefixes } from './InputPhoneNumberPrefixes';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const countryCodes = InputPhoneNumberPrefixes


const InputPhoneNumber = ({ id, label, onChange, value, className }) => {


    useEffect(()=>{
        var obj = document.getElementById(id ?? "input_"+label)
        if(obj.value!==(value?.replace(countryCode,'') ??""))obj.value=value?.replace(countryCode,'') ?? null
       
       },[value,id,label])

    const extractCountryCode = (phoneNumber) => {

        const indexCountry = countryCodes.findIndex(country => phoneNumber.startsWith(country.code));
        if (indexCountry !== -1) {
            return countryCodes[indexCountry].code;
        }
    };




    const [countryCode, setCountryCode] = useState(() => extractCountryCode(value || '') ?? "+33")
    const [phoneNumber, setPhoneNumber] = useState((value?.replace(countryCode,'') ?? null))

    const [isVisible, setIsVisible] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountryList, setFilteredCountryList] = useState(countryCodes);

    const [isDivClicked, setIsDivClicked] = useState(false);
    const [isLabelUp, setIsLabelUp] = useState(false);

    const toggleDropdown = () => {
        setIsVisible(!isVisible);
    };

    const handleDivClick = () => {
        setIsDivClicked(true);
        toggleDropdown();
     
    };


    useEffect(() => {
        // Recherche de pays avec le clavier dans la liste des pays
        const handleKeyDown = (e) => {
            if(!e.key) return
             const newSearchTerm = searchTerm + e.key.toLowerCase();
            setSearchTerm(newSearchTerm);
            const filteredList = countryCodes.filter(country => country.name && country.name.toLowerCase().includes(newSearchTerm));
            setFilteredCountryList(filteredList);
        };

        if (isVisible) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isVisible, searchTerm]);


    // useEffect(() => {
    //     // Mise à jour du numéro de téléphone lors du changement du code de pays


    //     setCountryCode(countryCode);
    //     setPhoneNumber(phoneNumber);

    // }, [countryCode, phoneNumber]);



    const handleCountryCodeChange = (code) => {
        setCountryCode(code);
        setIsVisible(false)
        //setIsLabelUp(true);

    };

    const handlePhoneNumberChange = (e, code) => {
        let formattedValue = e.target.value.replace(/\D/g, '').slice(0, 13);
        if (formattedValue.startsWith('0') && /^\d/.test(formattedValue.substring(1))) {
            formattedValue = formattedValue.substring(1); // Supprime le '0' du début du numéro s'il est suivi d'un autre chiffre
        }
        setPhoneNumber(formattedValue);


        const phoneNumberWithPrefix = countryCode + formattedValue;
        console.log("Numéro de téléphone avec préfixe :", phoneNumberWithPrefix);
        onChange(phoneNumberWithPrefix);

    };
    const handleKeyPress = (e) => {
        // Empêche la saisie de caractères autres que les chiffres
        if (e.key !== 'Enter' && isNaN(parseInt(e.key))) {
            e.preventDefault();
        }
    };


    const countryList = filteredCountryList.map((country) => (
        <li key={country.code} onClick={() => handleCountryCodeChange(country.code)} className="cursor-pointer flex items-start text-start align-middle">
            <img src={country.suffix} alt={country.name} className="w-5 h-5 mr-2" />
            {country.name} ({country.code})
        </li>
    ));

    const togglePrefixList = () => {
        setIsVisible(!isVisible);
      //  setIsLabelUp(true);
        setSearchTerm('');
        setFilteredCountryList(countryCodes);
    };

    const country = countryCodes.find(country => country.code === countryCode);
    let suffix = '';

    if (country) {
        suffix = country.suffix;
    }


    return (
        <div className={"w-full relative flex flex-row gap-1" + className}>
            <div onClick={handleDivClick} className="relative cursor-pointer flex flex-row items-center px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-l-xl border border-r-1 border-gray-400 appearance-none focus:outline-none focus:ring-0  ">
                {/* Afficher le préfixe sélectionné */}
                <img src={countryCode ? countryCodes.find(country => country.code === countryCode)?.suffix : ""} alt={''} className="w-6 h-auto " />
                <span className="mx-1">{countryCode}</span>
                <FontAwesomeIcon icon={faAngleDown} className='mr-2' />
                {/* <span >{countryCode}</span> */}
            </div>
            {isVisible && (
                <div className="w-full absolute top-14 z-50 left-0 px-2 ">
                    <ul className="w-full  z-50 left-0 max-h-40 overflow-y-scroll scrollbar-thin scrollbar-webkit overflow-hidden px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-white rounded-b-xl border border-r-1 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ">
                    {countryList}
                </ul>
                </div>
            )}
 <div className={"relative flex flex-row w-full" }>
            <input
                type="tel"
                onChange={handlePhoneNumberChange}
                defaultValue={phoneNumber}
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-r-lg border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer " 

               id={ id ?? "input_"+label }
                maxLength={15}
                inputMode="numeric"
            />
            <label
            
                htmlFor={id ?? "input_" + label}

                className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >        {label}
            </label>
            </div>
        </div>
    );
};

export default InputPhoneNumber;

