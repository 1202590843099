import React, { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import TemplateStructure from "../templates/TemplateStructure";
import { createClass, getClasseProps, getConditions } from "../../tools/tpl";
import { setBackground } from "../../service/background";
import InputBoolean from "../inputs/InputBoolean";

import "../../App.css";
import { MfctContext } from "../../context";
import InputText from "../inputs/InputText";
import InputSelect from "../inputs/InputSelect";
import InputMultiSelect from "../inputs/InputMultiSelect";

function TemplateEdition({
  liveTemplate,
  defaultTemplate,
  template_id,
  modele_template_id,
  sub_id,
  id,
  selected,
  setSelected,
  cancelTemplate,
  setTemplates,
  templates,
  updateLiveTemplate,
  saveTemplate, simulatedContent, setSimulatedContent, setInternal,
  saver

}) {
  const {
    project,
    getTemplates,
    getSchemas,
    getSchemasByApp,
    getApps,
    updateTemplate,
    cache,
  } = useContext(MfctContext);



  const apps = getApps();
  //const templates= getTemplates();


  //var schemas=getSchemas();
  const [schemas, setSchemas] = useState(getSchemas());
  console.log("Define schemas");

  const classes = []//getClasses();
  const [template, setTemplate] = useState(
    liveTemplate ?? defaultTemplate ?? {
      title: null,
      class_id: null,
      structure: { className: "w-full ", children: [] },
    }
  );



  var showVars = ["Name", "Labels", "Style", "Click", "Vars", "Enfants"];

  const [shows, setShows] = useState({ Name: true, Labels: true, Style: true, Click: true, Vars: true });
  const [roles, setRoles] = useState([])
  const getRoles = async (app_id) => {

    var _roles = await project.api("/get_myroles/:app_id", { args: { app_id } })
    console.log(_roles)
    setRoles(_roles);
  }

  async function initTemplate() {
    var _template

    console.log("initTemplate", liveTemplate, templates, classes, sub_id);
    if (liveTemplate) {
      if (liveTemplate.app_id) {
        await getRoles(liveTemplate.app_id)
      }


      return
    };
    if (defaultTemplate) {
      _template = defaultTemplate
      setTemplate(defaultTemplate);
      cache.set("template_" + defaultTemplate.template_id, defaultTemplate);
    } else if (classes && templates.length > 0) {
      if (template_id) {
        _template = templates.find(
          (t) => t.template_id + "" === template_id + ""
        );


        cache.set("template_" + _template.template_id, _template);
      }
      if (modele_template_id) {
        var _modele_template = templates.find(
          (t) => t.template_id + "" === modele_template_id + ""
        );
        console.log(modele_template_id, _modele_template, templates);
        _modele_template = { ..._modele_template };
        if (_modele_template) {
          delete _modele_template["template_id"];
          delete _modele_template["title"];
        }

        if (sub_id) {

          var parts = sub_id.split(".");
          parts.shift();
          var structure = _modele_template.structure;

          while (parts.length > 0)
            structure = structure.children[parseInt(parts.shift())];
          _modele_template = {
            title: null,
            class_id: null,
            structure: structure,
          };
        }
        _template = _modele_template

      }
    }
    if (_template.app_id) {

      await getRoles(_template.app_id)
      //schemas = await getSchemasByApp(_template.app_id)
      var _schemas = await getSchemasByApp(_template.app_id)
      setSchemas(_schemas)
      console.log("Define complete schemas!!!!");
    }

    setTemplate(_template);
  }

  const _setTemplate = (tpl) => {
    if (tpl.template_id) cache.remove("template_props_" + tpl.template_id)
    setTemplate(tpl);
    if (updateLiveTemplate) updateLiveTemplate(tpl) // pour les nouveaux surtout
    if (tpl.template_id) updateTemplate(tpl);
    if (saver) saver("tpl_" + tpl.template_id, save, tpl)
  };

  const cancel = () => {

    if (saver && ("tpl_" + template.template_id in saver)) saver("tpl_" + template.template_id)
    var cached = cache.get("template_" + template.template_id);
    console.log("Restore ", cached);

    if (template.template_id) _setTemplate(cached);
    if (cancelTemplate) cancelTemplate();
  };




  const save = async (tpl) => {

    console.log("Saving template")
    if (tpl.template_id) cache.set("template_" + template.template_id, tpl, true)

    var _template = await project.api("/set_template", { body: tpl });

    updateTemplate(_template);
    if (saveTemplate) saveTemplate(_template);
  };


  const globalSchema = useMemo(() => {
    if (template.schema_id) {
      var r = schemas.find((s) => s.content_id === template.schema_id);
      if (r) {
        r = {
          ...r,
          content: {
            ...r.content,
            schema: [
              { name: "Titre", schema_id: 42, code: "text" },
              ...(r.content.schema??[]) ,
            ],
          },
        };
      }

      return r;
    }
  }, [schemas, template]);

  const conditions = useMemo(() => {
    var c = getConditions(template.structure);
    console.log("Nouvelles conditions", c);
    return c;
  }, [template]);

  const createSubTemplate = (sub_id) => {
    if (
      window.confirm(
        " Voulez vous créer un nouveau modèle à partir de cet élément ?"
      )
    )
      navigate(`/template/addsub/${template_id}/${sub_id}`);
  };




  useEffect(() => {

    if (liveTemplate) setTemplate(liveTemplate);

  }, [liveTemplate])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setBackground();
  }, []);

  useEffect(() => {
    if (template && template.structure.children.length === 0 && (!id || id === "root"))
      setSelected("root");
  }, [template]);
  const selectTpl = (id) => {
    setSelected(id);
  };




  const app = useMemo(() => {
    if (apps.length > 0 && template && template.app_id) {
      var _app = apps.find((app) => app.app_id === template.app_id);
      return _app;
    }
  }, [apps, template]);
  useEffect(() => {
    if (apps.length > 0 && template && template.app_id) {
      var _app = apps.find((app) => app.app_id === template.app_id);

      if (_app) {
        setSimulatedContent({
          ...simulatedContent,
          "App.Name": _app.name,
          "App.Logo":
            process.env.REACT_APP_MFTC_SERVER_URL_FILES +
            `profiles/${_app.domain}/user.png`,
          "App.Domain": _app.domain,
          "App.Description": _app.description,
          "App.primary_color": _app.profile.primary_color,
          "App.secondary_color": _app.profile.secondary_color,
          "App.page.length": 4,
          "App.page[0].label": "Home",
          "App.page[0].icone": "faPlus",
          "App.page[0].slug": "landing",

          "App.page[1].label": "Chat",
          "App.page[1].icone": "faPlus",
          "App.page[1].slug": "landing",
          "App.page[2].label": "chat",
        });

        createClass(
          "bg-primary_color",
          ` background-color :${_app.profile.primary_color} ;  `
        );
        createClass(
          "border-primary_color",
          ` border-color :${_app.profile.primary_color} ;   `
        );
        createClass(
          "text-primary_color",
          ` color :${_app.profile.primary_color}  ;  `
        );
        createClass(
          "border-secondary_color",
          ` border-color :${_app.profile.secondary_color} ;   `
        );
        createClass(
          "bg-secondary_color",
          ` background-color :${_app.profile.secondary_color}  ;  `
        );
        createClass(
          "text-secondary_color",
          `color :${_app.profile.secondary_color}  ;  `
        );
      }
    }
  }, [apps, template]);

  useEffect(() => {
    if (project && templates) {
      getApps();
      initTemplate(template_id);
    }
  }, [template_id, modele_template_id, sub_id, project, templates]);

  const navigate = useNavigate();

  return (
    project &&
    template &&
    conditions && (
      <div id={id + "-edited"} className="w-full flex flex-col  ">
        <div>
          {(selected === "-root" || !selected) && (
            <>
              <InputText
                value={template.title}
                label={"Titre"}
                className="text-black w-full my-1"
                onChange={(title) => {
                  _setTemplate({ ...template, title });
                }}
              />
              <div className="flex flex-row w-full">
                <InputSelect
                  label={"Schéma"}
                  value={template.schema_id}
                  className="text-black w-full my-1"
                  onChange={(schema_id) => {
                    _setTemplate({ ...template, schema_id });
                  }}
                  options={schemas.map((c) => {
                    return { value: c.content_id + "", label: `<${c.title}>` };
                  })}
                  placeholder={"Choisir le schéma"}
                />
                <InputSelect

                  label="Layout"
                  placeholder={"Bloc Page"}
                  value={template.layout}
                  onChange={(layout) => {
                    _setTemplate({ ...template, layout });
                  }}
                  options={["in", "bloc", "top", "bottom", "left", "right"]}
                />
              </div>
              <InputSelect
                label={"Application"}
                value={template.app_id}
                className="text-black w-full my-1"
                onChange={(app_id) => {
                  _setTemplate({ ...template, app_id: parseInt(app_id) });
                }}
                options={apps.map((app) => {
                  return { value: app.app_id + "", label: app.name };
                })}
                placeholder={"Choisir l'application"}
              />
              <InputMultiSelect
                label={'Roles'}
                value={template.structure.roles}
                onChange={(roles) => {
                  _setTemplate({
                    ...template,
                    structure: { ...template.structure, roles },
                  });

                }}
                values={roles.map(role => ({ key: role.role_id, label: role.content.title ?? 'role ' + role.role_id }))}
              />
              {conditions.map((condition, index) => (
                <InputBoolean
                  key={index}
                  labelFalse={condition + " NO"}
                  labelTrue={condition + " OK"}
                  onChange={(value) => {
                    var c = { ...simulatedContent };
                    c["_" + condition] = value;
                    setSimulatedContent(c);
                  }}
                />
              ))}
            </>
          )}

          <TemplateStructure
            template={template.structure}
            globalSchema={globalSchema}
            schemas={schemas}
            domain={app?.domain}

            internals={template.structure.internals}
            createSubTemplateFunc={createSubTemplate}

            app_id={template.app_id}

            templates={templates}
            setStructure={(structure, parentStructure) => {
              if (parentStructure) {


                _setTemplate({
                  ...template,
                  structure: { ...structure, ...parentStructure },
                });
              } else _setTemplate({ ...template, structure });
            }}
            selectTpl={selectTpl}
            id={id ?? "-root"}
            selected={selected}
            shows={shows}
          />
          <div className="flex flex-row justify-center items-center w-full hidden">
            {showVars.map((c, i) => (
              <div
                key={i}
                className={
                  "p-1 m-1 rounded-xl text-black " +
                  (shows[c] ? "bg-zinc-200" : "")
                }
                onClick={() => {
                  var s = { ...shows };
                  s[c] = !s[c];
                  setShows(s);
                }}
              >
                {c}
              </div>
            ))}
          </div>
        </div>

        <div className=" w-full flex justify-end hidden">
          <button
            type="button"
            className="border border-1 p-2 m-1 first-line:rounded"
            onClick={cancel}
          >
            Annuler
          </button>
          <button
            type="button"
            className="border border-1 p-2 m-1 first-line:rounded"
            onClick={() => save(template)}
            id="upload"
          >
            Enregistrer
          </button>
        </div>
      </div>
    )
  );
}

export default TemplateEdition;
