import React, { useEffect, useMemo, useState,useContext } from 'react';

import { setToken } from '../../../../service/mftc-api';

import { Link,  useNavigate } from 'react-router-dom';

import * as serviceWorkerRegistration from '../../../../serviceWorkerRegistration';
import LandingPage from '../landingpage/LandingPage';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Notifications from '../../../../components/permissions/Notifications';

function Home() {
  const navigate = useNavigate();
const [showHowToInstalliOS,setShowHowToInstalliOS]=useState(false);
const [connectWindow,setConnectWindow]=useState(null)


const { project, getProject,setAuthUser,authUser} = useContext (MfctContext);

  const [ deferredPrompt,setDeferredPrompt]=useState();
useEffect(()=>{
  window.addEventListener('beforeinstallprompt', (e) => {
      setDeferredPrompt( e);
  });
},[]);

const install = async()=>{
 
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
        setDeferredPrompt();
    }
}

const connect=async()=>{

  var myUrl = window.location.href;
  var urlauth =process.env.REACT_APP_URL_AUTH;
  var parts = urlauth.split(":300");
  if( parts.length>1)
  {
    var parts0 = myUrl.split(":300");
    parts[0]=parts0[0];
   urlauth= parts.join(":300");
  }

  var wconnect = window.open( urlauth,"appisyou_auth")
  
  window.addEventListener('message', function(e) {
    // e.data hold the message from child
    console.log(e.data); 
if(e.data.token && e.data.authUser)
  { setToken(e.data.token,e.data.authUser)
   
setAuthUser(e.data.authUser)
    wconnect.close(); 
  }
  } , false);
}

  

const logout=async()=>{
  serviceWorkerRegistration.unregister();
   setToken()
   
setAuthUser()
//window.location.href ="/"
}

const showIosInstallModal=useMemo(()=> {
  // detect if the device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  
  // check if the device is in standalone mode
  const isInStandaloneMode = () => {
    return (
      "standalone" in window.navigator &&
      window.navigator.standalone
    );
  };
  
  // show the modal only once


  const shouldShowModalResponse =
    isIos() && !isInStandaloneMode() ;

  return shouldShowModalResponse;
},[]);

  return (
    <div className="App w-full text-white "> 

  <div className='w-full p-2 flex flex-row  justify-between items-center'> 
   <Image  type="logo" className="w-8 h-8 rounded-xl " alt="logo" /> 
<div className={ authUser? '':'text-white'  }>AppIsYou - Admin</div>
      
   { authUser ? <Link to="/parameters">  Profile  </Link>
   :   <button className={   "text-white border-zinc-200c-900 border p-2 my-3 rounded-md mx-2 font-bold text-xs "  }   onClick={connect}   > Login </button>}
      </div>
   

      {
      authUser &&  <div className='flex flex-col text-xs w-full'>
      <div className='flex flex-row items-center justify-center'>{authUser.email} 
 </div>
 <div className='p-2 flex flex-col w-full  justify-between items-center'> 
 <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '><Link to="/apps">Administration</Link></div>
 <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '>  <Link to="/users">Utilisateurs</Link></div>
 <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '><Link to="/modules">Modules</Link></div>
 <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '><Link to="/apps">Builder</Link></div>
 <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '><Link to="/apps">Applications</Link></div>


 <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '>  <Link to="/users">Abonnements / Achats </Link></div>
<div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '>  <Link to="/users">Clients</Link></div>



  <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '> <Link to="/schemas">Schéma des données</Link></div>
     

     <div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '>   <Link to="/templates">Templates</Link></div>
   
   
<div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '> <Link to="/chat">Conversations</Link></div>
<div className=' w-full m-1 p-1 border border-zinc-600 rounded bg-white text-zinc-900 text-2xl fond-bold p-2 text-left '>  <Link to="/webpush">WebPush</Link></div>
   
     </div>
     </div>
     }
     {
      !authUser && <LandingPage/>
     }
       
    </div>
  );
}

export default Home;
