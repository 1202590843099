class flowData {
  constructor(
    data,
    baseContent,
    contents,
    setContents,
    setBaseContent,
    schemas
  ) {
    this.data = data;
    this.baseContent = baseContent;
    this.setBaseContent = setBaseContent;
    this.contents = contents;
    this.setContents = setContents;
    this.schemas = schemas;
if(    this.setBaseContent)    this.setBaseContent (    this.baseContent)
  if(    this.setContents)       this.setContents (    this.contents)
  }

getContent(key,paths=[]){



}

setContentValue(key,value,paths=[]){



}

  get(relativeContent, key) {
    if (key in relativeContent) return this.flatContent(relativeContent[key]);

    var keyParts = key.split(".");

    var part = keyParts.shift();

    var cKey;
    var index = null;
    const match = part.match(/^(\w+)\[(\d+)\]$/);
    if (match) {
      cKey = match[1];
      index = parseInt(match[2], 10);
    } else {
      cKey = part;
    }

    if (cKey in relativeContent) {
      var value = relativeContent[cKey];
    } else {
      var prop = relativeContent._props[cKey];
      if (prop.isCalcul) {
        var content = relativeContent;
        // Le script doit donner value
        eval(prop.script);
      }
    }

    if (index !== null) {
      if (index < 0) index = value.length + index;

      if (value[index]) value = value[index];
      else value = null;
    }

    if (keyParts.length > 0) {
      return this.get(
        { ...relativeContent, ...this.flatContent(value) },
        keyParts.join(".")
      );
    } else return value;
  }

  flatContent(value) {
    if (Array.isArray(value)) {
    } else if (typeof value === "object" && value.content_id) {
      var valueComplete = this.contents.find(
        (c) => c.content_id + "" === "" + value.content_id
      );

      if (valueComplete) value = { ...valueComplete, ...valueComplete.content };
    }

    return value;
  }

  set(relativeContent, key, newValue, noBaseContent,actionArray) {
    if (!noBaseContent && key in this.baseContent) {
      this.baseContent[key] = newValue;
      this.setBaseContent({ ...this.baseContent, [key]: newValue });
      return;
    }
    var value;
    // A partir de là, c'est forécment un content qu'il faut mettre à jour
    var keyParts = key.split(".");

    var part = keyParts.shift();

    var cKey;
    var index = null;
    const match = part.match(/^(\w+)\[(\d+)\]$/);
    if (match) {
      cKey = match[1];
      index = parseInt(match[2], 10);
    } else {
      cKey = part;
    }
    
    if (cKey in relativeContent) {
      value = relativeContent[cKey];

      if (keyParts.length === 0) {
        this.setContent(relativeContent.content_id,cKey,newValue,index,actionArray )
        return 
      }
    } else {
      var prop = relativeContent._props[cKey];
      if (prop.isCalcul) {
        var content = relativeContent;
        // Le script doit donner value
        eval(prop.script);
      }
    }
      ///TODO GERER LES SOUS CONTENUS !!!  objet mais sans value.content

      this.set(
        { ...relativeContent, ...this.flatContent(value) },
        keyParts.join("."),
        newValue,
        true,actionArray
      );
   
  }




  setContent(content_id, propname, value, index, actionArray) {
    let _content = this.contents.find(
      (c) => c.content_id + "" === "" + content_id
    );

    if (index === null || actionArray) {
      if (value === null) delete _content.content[propname];
      else _content.content[propname] = value;
    } else {
      if (actionArray === "insert") {
        if (index)
          _content.content[propname] = [
            ..._content.content[propname].filter((_, i) => i < index),
            value,
            ..._content.content[propname].filter((_, i) => (i) => index),
          ];
        else _content.content[propname].push(value);
        
      } else if (actionArray === "remove") {
        _content.content[propname] = _content.content[propname].filter(
          (_, i) => i !== index
        );
      } else {
        _content.content[propname][index] = value;
      }
    }

       ///TODO GERER LES SOUS CONTENUS !!!  objet mais sans value.content
    var _contents = this.contents.map((c) =>
      c.content_id === _content.content_id ? _content : c
    );
    this.contents = _contents;
    this.setContents(_contents);
  }

  add(relativeContent, key, newValue) {
   this.set(relativeContent, key, newValue, false,"insert")
  }

  remove(relativeContent, key) {
 this.set(relativeContent, key, null, false,"remove")
  }

  async save(relativeContent, key) {

    // sauvegarder en base de données
    // sauvegarder en local DB
    // sauvegarder en localstorage 

  }

  async load(relativeContent, key) {}
}

// Exportation par défaut de la classe flowData
export default flowData;
