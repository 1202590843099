import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { data } from "../../service/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputText from "./InputText";
import { getAvailableActions } from "../../tools/actions";
import { api } from "../../service/mftc-api";
import { showModalOK } from "../modal";
import InputData from "./InputData";
import { getRecord } from "../../service/localdata";
import VarContent from "../VarContent";

var _schemas = {};

const InputDataSource = ({
  label,

  onChange,
  value,
  className,
  placeholder,
  domain,
  filter,
  id,
  context,
}) => {
  const [actions, setActions] = useState();
const [schemas,setSchemas]=useState(_schemas);
const key_schemas = context.key_schemas ??{}
const app_id = context.app_id
  const openAction = (action) => {
    window.open("/content/" + action.action_id, "_blank");
    // showModal( "Action ", {  Content:<ContentEdition   content_id={action.content_id} domain={domain  }  app_id={action.app_id}   />  }    )
  };

  const updateAction = async (action) => {
    await api("/update_action/:action_id", {
      args: { action_id: action.action_id },
    });

    var _action = actions.find((p) => p.content_id === action.action_id);

    var Arguments = {};
    var asynchrone;
    var Imports = [];
    var Sortie =   _action.content.Sortie
    if (_action) {
      action.Arguments = action.Arguments ?? {};

      _action.content.Arguments?.forEach((element) => {
        Arguments[element.name] = {
          ...(action.Arguments[element.name] ?? {}),
          ...element,
        };
      });
      asynchrone = _action.content.asynchrone;
      _action.content.Code.forEach((c) => {
        if (!c.back && c.import) Imports = [...Imports, ...c.import];
      });
    }
    action = {
      ...action,
      title: _action.title,
      Arguments,
      Sortie ,
      asynchrone,
      Imports,
    };
    window.alert(JSON.stringify(action));
    onChange(action);
    showModalOK();
  };

  useEffect(() => {
    const loadSchemas = async (schemas_to_load) => {
      for (let schema_id of schemas_to_load) {

        var _schema = await getRecord(parseInt(schema_id), "content");
      
        _schemas[schema_id] = _schema;
      }
      console.log({load:"errrr",_schemas})
     setSchemas({..._schemas}) 
    };

    if (value && value.Arguments) {
      var schemas_to_load = Object.keys(value.Arguments)
        ?.filter(
          (arg) =>
            value.Arguments[arg].is_noset_label &&
            !schemas[value.Arguments[arg].schema_id]
        )
        .map((arg) => value.Arguments[arg].schema_id);

      if (schemas_to_load.length > 0) loadSchemas(schemas_to_load);
    }
  }, [value]);

  useEffect(() => {
    const getActions = async () => {
if(actions)return 
      var _actions = await getAvailableActions( domain, (a)=>{ 
        if(filter && !filter(a)) return false;
 

        if(!a.content.Sortie ) return false
        if(!a.content.Arguments  ) return false
console.log("key_schemas Atesting Action ",{a,key_schemas}  )


      for(let Argument of  a.content.Arguments){

        if( !Argument.schema_id || !key_schemas[ Argument.schema_id +(Argument.isList? "_list":"" )]  ) return false
        }
        

      return true
      
      });

      _actions=_actions.filter((a)=>{ 
     
 
      if(!a.content.Arguments   ) return false

      if(!a.content.Sortie ) return false
      if(!a.content.Arguments  ) return false
console.log("key_schemas Btesting Action ",{a,key_schemas}  )

      for(let Argument of  a.content.Arguments){

      if( !Argument.schema_id || !key_schemas[ Argument.schema_id +(Argument.isList? "_list":"" )]  ) return false
      }

      return true
      
      })

  


var newActions = [];
   
_actions.forEach(action => {

   var Cactions = [action]

   for(let Argument of  action.content.Arguments){
     

      var listValues = key_schemas[ Argument.schema_id +(Argument.isList? "_list":"" )]
       var _CActions = []
         for(let value of listValues) {

            for(let laction of Cactions)
         {
         _CActions.push( {...laction, values: {...laction.values??{} ,[Argument.name]:value}} )
           }
         }
         Cactions=_CActions
    }

  newActions=[...newActions,...Cactions]

});

console.log("getActions key_schemas ", { newActions,key_schemas});


setActions(newActions);
    };

    if(key_schemas)    getActions();
  }, [domain, filter,key_schemas,actions]);

  if (!actions || !key_schemas) return <></>;

  return (
    <div className="w-full flex flex-col gap-1 py-1 items-start">
      <div className="w-full flex gap-1 py-1 items-center">

        <InputSelect
          label={label}
          className={className + " w-full"}
          placeholder={placeholder}
          value={value?.action_id + (value?.Arguments?  "(" + Object.values( value?.Arguments).map(arg=>arg.value).join(",")  +")":"") }
          onChange={async (action_id) => {
            var action;
            if (action_id === "new") {
              action = await data.save_content({
                title: "Computed" + Date.now(),
                schema_id: 428,
              
                app_id,
                content: { title: "Computed" + Date.now() , Arguments:[]
                  
                  
                  
         //         Object.keys( key_schemas).map(schema_id=>({name:key_schemas[schema_id][0],schema_id}))

                   ,Sortie:{name: "Output" + Date.now()}},
              });
              if (action) action_id = action.content_id;
              action.action_id = action.content_id;
              openAction(action);
            } else {
            //  if (action_id) action_id = parseInt(action_id);
              action =
                action_id && actions.find((p) => p.content_id +  (p.values?  "(" +  Object.values( p.values).join(",")  +")":"")  === ""+action_id);
            
                if(action)  action_id= action?.content_id 
                else  action_id=null
              }
            var Args = {};
            var Arguments = {};
            var asynchrone;
            var Sortie 
            var Imports = [];
            if (action) {
              Sortie = action.content.Sortie
              action.content.Args?.forEach((element) => {
                Args[element] = null;
              });
              action.content.Arguments?.forEach((element) => {
                element.value=  action.values[  element.name ]  
             
                Arguments[element.name] = element;
              });
              asynchrone = action.content.asynchrone;
              Imports = action.content.import;
            }
            onChange(
              action_id
                ? {
                    action_id,
                    title: action.title,
                    auth:action.content.auth,
                    Sortie,
                    Args,
                    Arguments,

                    asynchrone,
                    Imports,
                  }
                : null
            );
          }}
          options={[
            { label: "--", value: null },
            ...actions.map((action) => {
              return { label: action.content.Sortie.name + "(" +  Object.values( action.values).join(",")  +")", value: action.content_id +  (action.values?  "(" +  Object.values( action.values).join(",")  +")":"") };
            }),
            { label: "Créer Nouvelle Action", value: "new" },
          ]}
        />
        {value?.action_id && (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              className="text-lg text-black"
              onClick={() => openAction(value)}
            />
            <FontAwesomeIcon
              icon={fa.faRotateRight}
              className="text-lg text-green-500"
              onClick={() => updateAction(value)}
            />
          </>
        )}
      </div>
      {value &&
        value.Arguments &&
        Object.keys(value.Arguments)
          ?.filter((arg) => value.Arguments[arg].is_noset_label && schemas[parseInt(value.Arguments[arg].schema_id)])
          .map((arg) => (
            <InputData
              id={arg + "_" + id}
              className={"w-full"}
              key={arg}
              prop={value.Arguments[arg]}
              schema={schemas[parseInt(value.Arguments[arg].schema_id)]}
              label={arg + "*"}
              value={value.Arguments[arg].value}
              onChange={(argValue) =>
                onChange({
                  ...value,
                  Arguments: {
                    ...value.Arguments,
                    [arg]: { ...value.Arguments[arg], value: argValue },
                  },
                })
              }
              domain={domain}
            />
          ))}
              
      {value &&
        value.Arguments &&
        Object.keys(value.Arguments)
          ?.filter((arg) => !value.Arguments[arg].is_noset_label)
          .map((arg) => (
            <InputText
              id={arg + "_" + id}
              key={arg}
              prop={value.Arguments[arg]}
              label={arg + "*"}
              value={value.Arguments[arg].value}
              onChange={(argValue) =>
                onChange({
                  ...value,
                  Arguments: {
                    ...value.Arguments,
                    [arg]: { ...value.Arguments[arg], value: argValue },
                  },
                })
              }
              domain={domain}
            />
          ))}
      
    </div>
  );
};
export default InputDataSource;
