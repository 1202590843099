import React, { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";
import {
  _GetSetTers,
  activateContent,
  contentConvert,
  createClass,
  setPropInputs,
} from "../../../../tools/tpl";

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import { setBackground } from "../../../../service/background";
import { setToken } from "../../../../service/mftc-api";
import {
  AnimatePositions,
  ClosingModes,
  ModalPositions,
  closeModal,
  showModal,
} from "../../../../components/modal";
import VarContent from "../../../../components/VarContent";
import {
  getActions,
  loadActions,
  runAction,
  runActionSync,
} from "../../../../tools/actions";
import { data } from "../../../../service/data";
var old_slug;
function Page({ pageDefault }) {
  const { domain, slug } = useParams();
  const params = useParams();
  const { project, getApps, getContentsByApp, setAuthUser } =
    useContext(MfctContext);
  const [baseContent, setBaseContent] = useState({
    "App.Name": "CDesign",
    "App.Logo": "cdesign",
  });
  const [internalContent, setInternalContent] = useState({});
  const [page, setPage] = useState(pageDefault ?? { domain, slug });

  const [actions, setActions] = useState();
  const [selected, setSelected] = useState();
  const [contents, setContents] = useState([]);

  const setInternal = (key, value) => {
    console.log("SETTING set" + key, { value, internalContent });
    setInternalContent((_internalContent) => ({
      ..._internalContent,
      [key]: value,
    }));
  };

  const initBaseContent = async (page) => {
    var _app = project.app ?? page.app;
    console.log("page", page);

    if (!_app) window.alert("Please !!!");
    if (!_app && domain) {
      if (getApps) _app = getApps().find((app) => app.domain === domain);
      // _app =  await project.api("/get_app_domain/:domain",{args:{domain }})
    }

    const login = async () => {
      var wconnect = window.open(
        process.env.REACT_APP_URL_AUTH,
        "appisyou_auth"
      );

      window.addEventListener(
        "message",
        function (e) {
          // e.data hold the message from child
          console.log(e.data);

          if (e.data.token && e.data.authUser) {
            setToken(e.data.token, e.data.authUser);

            setAuthUser(e.data.authUser);
            wconnect.close();
            navigate("/");
          }
        },
        false
      );
    };
    const logout = async () => {
      setToken(null, null);
      setAuthUser();
      navigate("/");
    };

    console.log("App ", _app);

    var _baseContent = {
      TitrePage: page.title,
      domain: _app.domain,
      "App.Name": _app.name,
      "App.app_id": _app.app_id,
      "App.Logo":
        process.env.REACT_APP_MFTC_SERVER_URL_FILES +
        `profiles/${_app.domain}/user.png`,
      "App.Domain": _app.domain,
      "App.Description": _app.description,
      "App.primary_color": _app.profile.primary_color,
      "App.secondary_color": _app.profile.secondary_color,
      "App.login": () => login().then(() => {}),
      "App.logout": () => logout().then(() => {}),
      "App.goBack": () => navigate(-1),
      ...params,
    };

    setBaseContent((b) => ({ ...b, ..._baseContent }));
    createClass(
      "bg-primary_color",
      ` background-color :${_app.profile.primary_color} ;  `
    );
    createClass(
      "border-primary_color",
      ` border-color :${_app.profile.primary_color} ;   `
    );
    createClass(
      "text-primary_color",
      ` color :${_app.profile.primary_color}  ;  `
    );
    createClass(
      "border-secondary_color",
      ` border-color :${_app.profile.secondary_color} ;   `
    );
    createClass(
      "bg-secondary_color",
      ` background-color :${_app.profile.secondary_color}  ;  `
    );
    createClass(
      "text-secondary_color",
      `color :${_app.profile.secondary_color}  ;  `
    );

    setBackground(_app.profile.primary_color);

    return _baseContent;
  };

  async function initPage(force) {

    if (force || !page.isReady) {
      var _page = await project.api("/get_page_todisplay", {
        body: { ...(domain ? { domain, slug } : page), refresh: false },
        // cache : "page_" +(slug?? page_id)
      });
      if (!_page || !_page.isReady) {
        //  navigate("/")
        return;
      }

      if (_page.error) {
        navigate(_page.to);

        return;
      }

      old_slug = slug;
      _page.isReady = true;
      _page = { ...page, ..._page };

      var _baseContent = await initBaseContent(_page);
      var loadedContents={};

      const getSubC =(c)=>{
        if(!c ||   (typeof c !=="object")   || !c.content_id || !c.content || loadedContents[c.content_id])return
        loadedContents[c.content_id]=c
       
          for(let key of Object.keys(c.content) )  getSubC(c.content[key])
          
      }

  
      if (_page.contents) {
        _page.contents.forEach(c=> getSubC (c))
        _page.contents =   Object.values(loadedContents)
        console.log(" contentsAll Contents !!!  ",loadedContents);


        
        setContents(   _page.contents );
        
        console.log(" contentsAll Contents !!!  _page.contents = ", _page.contents);
      }

      if ( _page.templates) {

        var allPropsFromTemplates = {};

        _page.templates.forEach((t) => {
          if (t.props_from_templates) {
            for (let template_id of Object.keys(t.props_from_templates)) {
              allPropsFromTemplates[template_id] =
                t.props_from_templates[template_id];
            }
          }
        });
        _page.allPropsFromTemplates = allPropsFromTemplates;
      }


      await data.init(
        "page",
        _page.page_id,
        false,
        project,
        { setContents  },
       null,
        true, loadedContents
      );

      setPage(_page);
      await activateContent(
        _page,
        _baseContent,
        project,
        setInternal,
        null,
        setContents,
        _page.contents
      );

      if (_page.templates) {
        setTimeout(async () => {
          var _actions = getActions(
            [_page, _page.app ?? project.app],
            _page.templates
          );

          await loadActions(_actions);

          var onStartActions = _actions.filter(
            (action) => action.Event === "onStart"
          );
          for (let startAction of onStartActions) {
            if (startAction.asynchrone)
              await runAction(null, startAction, baseContent, setInternal);
            else runActionSync(null, startAction, baseContent, setInternal);
          }
        }, 500);
      }
    }
  }

  // cookies
  const initConsent = () => {
    var template = page.templates.find(
      (t) =>
        t.template_id + "" ===
        "" + (page.app ?? project.app).profile.popup_consent
    );
    var contentActions = {};

    if (template && template.schema_id) {
      var schema = page.schemas.find(
        (t) => t.content_id + "" === "" + template.schema_id
      );
      if (schema) {
        schema.content.schema.forEach((element) => {
          if (element.script) {
            contentActions[element.name] = element.script;
          }
        });
      }
    }
    var result = false;
    if (contentActions.showConsent) {
      eval(contentActions.showConsent);

      console.log(
        "contentActions.showConsent",
        contentActions.showConsent,
        result
      );
    }

    if (result)
      showModal(null, {
        key: "consent",
        position: ModalPositions.LEFTBOTTOM,
        animation: AnimatePositions.BOTTOM,
        standalone: true,
        Content: (
          <TemplateX
            template={template.structure ?? {}}
            content={{
              ...baseContent,
              ...contentActions,
              closePopupConsent: () => closeModal("consent"),
              openPopupConsentDetails: (page.app ?? project.app).profile
                .popup_consent_details
                ? openPopupConsentDetails
                : null,
            }}
            templates={page.templates}
            template_id={template.template_id}
            selectTpl={selectTpl}
            id={"consent_popup"}
            selected={selected}
            navigate={navigate}
            schemas={page.schemas}
          />
        ),
        closingMode: ClosingModes.NONE,
        className: " ",
      });
  };

  const openPopupConsentDetails = () => {
    var template = page.templates.find(
      (t) =>
        t.template_id + "" ===
        "" + (page.app ?? project.app).profile.popup_consent_details
    );
    var contentActions = {};
    console.log("Consent Page schemas", page.schemas);
    if (template && template.schema_id) {
      var schema = page.schemas.find(
        (t) => t.content_id + "" === "" + template.schema_id
      );
      if (schema) {
        schema.content.schema.forEach((element) => {
          if (element.script) {
            contentActions[element.name] = element.script;
          }
        });
      }
    }
    var bcontent = {
      ...baseContent,
      ...contentActions,
      ...(convertContents.find(
        (c) =>
          c.content_id + "" ===
          (page.app ?? project.app).profile.data_policy + ""
      ) ?? {}),
      ...setPropInputs(template, page.schemas, page.templates),
      closePopupConsent: () => closeModal("consent_details"),
    };

    showModal(null, {
      key: "consent_details",
      position: ModalPositions.CENTER,
      animation: AnimatePositions.CENTER,
      standalone: true,
      Content: (
        <TemplateX
          template={template.structure ?? {}}
          content={bcontent}
          templates={page.templates}
          template_id={template.template_id}
          selectTpl={selectTpl}
          id={"consent_popup_details"}
          selected={selected}
          navigate={navigate}
          schemas={page.schemas}
        />
      ),
      closingMode: ClosingModes.NONE,
      className: " ",
    });
  };


  useEffect(() => {
    if (
      page &&
      page.templates &&
      (page.app ?? project.app) &&
      (page.app ?? project.app).profile.popup_consent
    ) {
      setTimeout(initConsent, 2000);
    }
  }, [page]);



  const GetSetTers = (template, getSelf, setSelf, content_id) => {


return _GetSetTers( template,getSelf,setSelf,content_id,contents, page.allPropsFromTemplates, page.schemas  )

  };

  const selectTpl = () => {};

  const convertContents = useMemo(() => {
    var ccv = contents.map((c) => contentConvert(c));
    console.log("CCV", ccv);
    return ccv;
  }, [contents]);

  useEffect(() => {
    console.log("initPage ** ");
    initPage(old_slug !== slug);
    return async () => {
      await data.close();
    };
  }, [domain, slug, params]);

  const navigate = useNavigate();

  return (
    <>
      {page && page.isReady && page.templates && (
        <div className="z-1 w-full  flex flex-col items-start  bg-white">
          {parseInt("0" + page.content.header) > 0 && (
            <TemplateX
              template={
                page.templates.find(
                  (t) => t.template_id + "" === "" + page.content.header
                )?.structure ?? {}
              }
              template_id={page.content.header}
              palette={page.content.headerPalette}
              content={{
                ...baseContent,
                headerContent_id: page.content.headerContent_id,
                ...(convertContents.find(
                  (c) =>
                    c.content_id + "" === page.content.headerContent_id + ""
                ) ?? {}),

                ...internalContent,
                ...GetSetTers (page.templates.find(
                  (t) => t.template_id + "" === "" +   page.content.header
                ), null, null,page.content.headerContent_id ),
                GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, page.content.headerContent_id )
              }}
              navigate={navigate}
              templates={page.templates}
              selectTpl={selectTpl}
              id={"header"}
              selected={selected}
            />
          )}

          {page.content.body.map((body, i) => (
            <div key={i} className=" flex   flex-col w-full">
              {parseInt("0" + body.template_id) > 0 && (
                <TemplateX
                  template={
                    page.templates.find(
                      (t) => t.template_id + "" === "" + body.template_id
                    )?.structure ?? {}
                  }
                  palette={body.palette}
                  content={{
                    ...baseContent,
                    bodycontent_id: body.content_id,
                    ...(convertContents.find(
                      (c) => c.content_id + "" === body.content_id + ""
                    ) ?? {}),
                    ...(body.content ?? {}),
                    ...setPropInputs(
                      page.templates.find(
                        (t) => t.template_id === body.template_id
                      ),
                      page.schemas ?? [],
                      page.templates
                    ),
                    ...internalContent,
                    ...GetSetTers (page.templates.find(
                      (t) => t.template_id + "" === "" +  body.template_id
                    ), null, null,body.content_id ),
                    GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, body.content_id )
                  }}
                  navigate={navigate}
                  template_id={body.template_id}
                  templates={page.templates}
                  selectTpl={selectTpl}
                  id={"block" + i}
                  selected={selected}
                />
              )}
            </div>
          ))}

          {parseInt("0" + page.content.footer) > 0 && (
            <TemplateX
              template={
                page.templates.find(
                  (t) => t.template_id + "" === "" + page.content.footer
                )?.structure ?? {}
              }
              palette={page.content.footerPalette}
              navigate={navigate}
              content={{
                ...baseContent,
                footerContent_id: page.content.footerContent_id,
                ...(convertContents.find(
                  (c) =>
                    c.content_id + "" === page.content.footerContent_id + ""
                ) ?? {}),
                ...internalContent,
                ...GetSetTers (page.templates.find(
                  (t) => t.template_id + "" === "" + page.content.footer
                ), null, null,page.content.footerContent_id ),
                GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, page.content.footerContent_id)
              }}
              templates={page.templates}
              template_id={page.content.footer}
              selectTpl={selectTpl}
              id={"footer"}
              selected={selected}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Page;
