import React, { useEffect, useMemo, useState ,useContext} from 'react';
import { Link, useNavigate ,useParams} from "react-router-dom";


import ClasseStructure from '../../../../components/ClasseStructure';
import InputText from '../../../../components/inputs/InputText';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import HeaderBack from '../../../../components/HeaderBack';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";

function SetClasse() {
  const { classe_id } = useParams();
const { project, getProject,setAuthUser,authUser,getClasse} = useContext (MfctContext);
  
  const [classe, setClasse] = useState({name:null,description:null, content:{ objects:[]   } });
const [classes,setClasses]= useState([])



async function getClasses() {
  var _classes = await project.api("/classes")

  setClasses( _classes)
}

useEffect(()=>{

if( project)
{
getClasses()
}

},[project]);

  async function initClasse() {
      var _classe = await getClasse(classe_id);
      console.log(_classe);
       setClasse( _classe)
  }

useEffect(()=>{

if( project && classe_id)
{
  console.log("get classe")
  initClasse(classe_id)
}

},[project]);




  const navigate = useNavigate();
const saveClasse=async (e)=>{



var _classe =   await project.api("/set_classe", { body:classe }); 
 if(classe_id)await getClasse(classe_id,true);
console.log(_classe)

navigate(`/classes`)

}




  return (
    <div className="App  text-white text-sm">
   <HeaderBack  title={ classe_id ? "Classe - Edition": "Classe - Création"   }   />
   

     { project && classe &&  <header className=" min-h-screen relative">
  
  <div className='z-1 w-full mt-4 flex flex-col items-start p-4 bg-white text-black'>    

     
     
<InputText label={"Titre"}   value={classe.name}  className={"w-full my-1"} onChange={value => {
      setClasse({...classe, name:value});
    }}  />


<InputTextArea   className={"w-full my-1"} label={"Description"}   value={classe.description}
    onChange={value => {
      setClasse({...classe, description: value});
    }}     />



<div    className='w-full' >
<ClasseStructure classe={classe} setObjects={  (objects)=>setClasse({...classe,content:{...classe.content,objects}})  } classes={classes} />
</div>


    <div className="fixed bottom-0 left-0 bg-white    w-full flex justify-end">

    <button type="button" className="border border-1 p-2 m-1 first-line:rounded" onClick={()=>navigate(-1)}  >Annuler</button>
    <button type="button" className="border border-1 p-2 m-1 first-line:rounded"onClick={saveClasse} id="upload">Enregistrer classe</button>
        </div>

</div>
      </header> }

   
    </div>
  );
}

export default SetClasse;
