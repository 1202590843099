import { useState } from "react";




const InputVideo = ({label,onChange,value,className,domain}) => {

    const [file, setFile] = useState();
    
    
    function handleImgChange(e) {
        console.log(e.target.files);

        setFile(URL.createObjectURL(e.target.files[0]));
        onChange(   e.target.files[0])
    }
  


return (
    <>
    <div   onClick={ onChange  ? ()=>{ document.getElementById("image-upload-" + label ).click()}:null}  className={"  " +className +"  flex flex-row items-center  justify-center  text-sm text-gray-900 bg-transparent rounded-xl appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"  } >

    {value &&
   <video  id="previewing" className=" w-full object-contain rounded-xl  bg-black" controls  src={file ??    process.env.REACT_APP_MFTC_SERVER_URL_FILES +
    `profiles/${domain}/medias/${value}` } alt="media"/> }
{!value && <div className=" p-4 ">Ajouter </div>}
     <input type="file" accept={"video/*"} name="image" id={"image-upload-"+label} className="hidden" onChange={handleImgChange} required />
    
</div>
</> 
)
}
export default InputVideo