import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

import "./payment.css"
import { MfctContext } from "../../context";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function PaymentIntent({items ,shippingAddress,billingAddress}) {

  const { project } =useContext(MfctContext);
  const [clientSecret, setClientSecret] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  useEffect(() => {

const initPayment = async()=>{
      // Create PaymentIntent as soon as the page loads
  //    var data = await  project.api("/create_payment_intent", {
  //      body: {items} })
  //     setClientSecret(data.clientSecret);


       var data = await  project.api("/create_subscription", {
        body: { priceId: items[0].Stripe_Id ,shippingAddress,billingAddress} })
       setClientSecret(data.clientSecret);
     setSubscriptionId( data.subscriptionId);

}
if(project)  initPayment()
  }, [items,project,shippingAddress,billingAddress]);
//



  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  //  mode:items[0].Abonnement ? 'subscription':'payment'
  
  };

  return (
 <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise} >
          <CheckoutForm />
        </Elements>
      )}</>
    
  );
}