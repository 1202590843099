
import InputClassName from "./InputClassName";
import InputText from "./InputText";
import InputBoolean from "./InputBoolean";
import { useEffect, useState } from "react";
import InputSelect from "./InputSelect";




const InputCssCondition = ({id,label,onChange,value,className,bg="bg-white",context }) => {

const [values,setValues] =useState() 
useEffect(()=>{
var result 
if(context?.key_schemas)
    {

result =[]

for(let val of Object.values( context.key_schemas))
    {
        result = [...result,...val]
    }
     
    result = [...result,...result.map(v=> "-"+v)]
    }

    setValues( result)
},[])


    value=value??{}
return (
<div className="w-full">
{
values  
?
<InputSelect  value={value.etat}    onChange={(etat)=>onChange({...value,etat})} options={ ["",...values]  }   />

:<InputText className={"w-full"} label={"état"} id={id+"className"} onChange={(etat)=>onChange({...value,etat})}  value={value.etat}   />
}

<InputClassName   id={id+"className"} onChange={onChange}  value={value}   />

<InputBoolean  label={"ajout"}   labelTrue={"ajout"}  id={id+"className"} onChange={(ajout)=>onChange({...value,ajout})}  value={value.ajout}   />

</div>

)
}
export default InputCssCondition