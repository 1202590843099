import React, { useEffect, useMemo, useState, useContext } from "react";

import { Link } from "react-router-dom";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputSelect from "../../../../components/inputs/InputSelect";
import InputText from "../../../../components/inputs/InputText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Image from "../../../../components/Image";
import "../../../../App.css";
import { MfctContext } from "../../../../context";
import LoaderSmall from "../../../../components/LoaderSmall";
import InputButton from "../../../../components/inputs/InputButton";
import InputData from "../../../../components/inputs/InputData";
import { showModalError, showModalLoading, showModalOK } from "../../../../components/modal";

function AppBuild({ app }) {
  const deployment_server_Initial = app.profile.deployment_server;
  const adresses_inital = app.profile.adresses;

  const { project, getProject, setAuthUser, authUser } =
    useContext(MfctContext);
  const [result, setResult] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const classTypes = {
    error: "text-red-500 font-bold",
    warning: "text-yellow-500",
  };
  const [accounts, setAccounts] = useState(
    app.profile.deployment_server
      ? [{ name: app.profile.deployment_server }]
      : []
  );

  const [mods, setMods] = useState(app);

  const isMigratable =
    mods.profile.installed_server &&
    mods.profile.deployment_server &&
    mods.profile.installed_server !== mods.profile.deployment_server &&
    mods.profile.deployment_server !== "-- choisir serveur de déploiement --";

  const isUdpate =
    mods.profile.deployment_server &&
    mods.profile.installed_server &&
    mods.profile.deployment_server === mods.profile.installed_server;

  async function getGitBranches() {
    try {
      var _result = await project.api(`/hosting/action`, {
        body: { request: "get_branches_front" },
      });
      if (_result) setBranches(_result.branches);
    } catch (error) {}
  }

  const getAccounts = async () => {
    try {
      const _result = await project.api("/always/accounts");
      const filteredAccounts = _result.accounts.filter(
        (account) => ["appisyou", "LOCALHOST", "ph76"].indexOf(account.name) === -1
      );

      for (const account of filteredAccounts) {
        if (!account.sites) {
          const sitesResult = await project.api(
            "/always/account/:account/sites",
            {
              args: { account: account.name },
            }
          );
          const backendSites = sitesResult.filter(
            (site) => site.working_directory === "backend"
          );
          account.site = backendSites[0];
        }
      }
      setAccounts(filteredAccounts);
      await getGitBranches();
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setAccounts([]);
    }
  };

  // const handleSelectChange = (event) => {
  //   const selectedAccount = event.target.value;
  //   setUrlServer(`https://${selectedAccount}.appisyou.fr/api/v1/`);
  // };

  useEffect(() => {
    getAccounts();
    // getGitBranches();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    console.log(JSON.stringify(accounts));
  }, [accounts]);

  const goBuild = async () => {
    setIsLoading(true);
    var _result = await project.api("/build_app_client", {
      body: { domain: app.domain },
    });
    var _messages = [];
    _result.messages.forEach((message) => {
      var parts = message.text.split("Line ");
      if (parts.length == 1) _messages.push(message);
      else
        _messages = [
          ..._messages,
          ...parts.map((part) => ({ text: part, type: message.type })),
        ];
    });
    setResult(_messages);
    setIsLoading(false);
  };

  const ReceiveMessage = (strMsgs) => {
    const sep = "@*_*@";
    var msgsLines = [];
    var parts = strMsgs.split(sep);
    parts.shift();
    var newmsgs = parts.map((strMsg) => {
      try {
        return JSON.parse(strMsg);
      } catch (error) {
        return { text: strMsg + JSON.stringify(error) };
      }
    });
    newmsgs.forEach((message) => {
      var lines = message.text.split("Line ");
      if (lines.length === 1) {
        if (message.text === "Build terminé"){
           setIsLoading(false);
     //    setMods(mods=>({...mods,profile:{...mods.profile,installed_server:mods.profile.deployment_server }}))
         showModalOK()
          } msgsLines.push(message);
      } else
        msgsLines = [
          ...msgsLines,
          ...lines.map((line) => {
            return { text: line, type: message.type };
          }),
        ];
    });

    setResult((msgs) => [...msgs, ...msgsLines]);
  };

  const saveApp = async () => {
    // e.preventDefault();
    if (
      mods.profile.deployment_server === "-- choisir serveur de déploiement --"
    ) {
      mods.profile.deployment_server = null;
    }

    var newapp = await project.api("/set_app", { body: mods });
    toast("Successfully saved '" + app.name);

    // await getApps();
  };

  const deleteAppBuild = async () => {
    if (
      !window.confirm(
        `Are you sure you want to remove the  app of ${mods.profile.installed_server}?`
      )
    ) {
      return;
    }
    showModalLoading();
    try {
      await project.api("/delete_build_app_client_loading", {
        body: { domain: app.domain },

        // project:mods.profile.deployment_server,
        url_server: `https://${mods.profile.installed_server}.appisyou.fr/api/v1/`,
        // url_server: `https://localhost:3030/api/v1/`,
      });
     setMods(mods=>({...mods,profile:{...mods.profile,installed_server:null }}))
    } catch (err) {
      showModalError(err);
    }
    
    showModalLoading(true);
    showModalOK()
  };
  const goBuildLoading = async (params, url_server, withPull) => {
    if (
      !window.confirm(
        `Are you sure you want to deploy app on ${mods.profile.deployment_server}?`
      )
    ) {
      return;
    }
    // e.preventDefault();
    setResult([]);
    setIsLoading(true);

    // TODO : sauvegarder app.profile.deployment_server ; si app.profile.deployment_server existe on le migre vers le nouveau
    await saveApp();
    try {
      await project.api("/build_app_client_loading", {
        body: { domain: app.domain, withPull, ...(params ?? {}) },

        eventStreamListener: ReceiveMessage,
        // project:mods.profile.deployment_server,
        url_server:
          url_server ??
          `https://${mods.profile.deployment_server}.appisyou.fr/api/v1/`,
        // url_server: `https://localhost:3030/api/v1/`,
      });
    } catch (err) {
      showModalError(err);
      setIsLoading(false);
    }
  };

  const deleteBuild = async () => {
    // e.preventDefault();
    setResult([]);
    setIsLoading(true);
    if (
      !window.confirm(
        `Are you sure you want to delete app from ${mods.profile.installed_server}?`
      )
    ) {
      return;
    }
  };

  const migrateBuild = async () => {
    await saveApp();

    await goBuildLoading({ blnMigrate: true });
  };

  const updateBuild = async () => {
    await goBuildLoading({ blnUpdate: true });
  };

  const testMigrate = async () => {
    await project.api("/test_migration", {
      body: { domain: app.domain },

      // eventStreamListener: ReceiveMessage,
      // url_server: `https://${mods.profile.deployment_server}.appisyou.fr/api/v1/`,
    });
  };

  return (
    <>
      <div className="w-full flex flex-col py-4 bg-white px-4 rounded-xl text-black gap-3 text-sm">
        {!isLoading ? (
          <div>
            <div className="flex flex-row items-center justify-around">
              {/* colonne gauche adresse */}
              <div class="basis-1/4">
                <InputData
                  className={"flex-row"}
                  prop={{ name: "Adresses", type_nombre: "I" }}
                  schema={{ content: { code: "text" } }}
                  value={mods.profile.adresses ?? []}
                  onChange={(adresses) =>
                    setMods((m) => {
                      return {
                        ...m,
                        profile: { ...m.profile, adresses },
                      };
                    })
                  }
                />
              </div>
              {/* colonne droite serveurs */}
              <div class="flex basis-2/4 justify-around md:flex-row flex-col">
                {/* colonne choix serveurs */}
                <div>{mods.profile.installed_server}</div>
                <div class="mb-2 items-center flex flex-col">
                  <InputSelect
                    label={"deployement server"}
                    value={mods.profile.deployment_server}
                    placeholder={"choisir serveur de déploiement "}
                    options={accounts.map((account, i) => {
                      return { value: account.name };
                    })}
                    onChange={(deployment_server) =>
                      setMods((m) => {
                        return {
                          ...m,
                          profile: { ...m.profile, deployment_server },
                        };
                      })
                    }
                  />
                  <InputSelect
                    id="branch"
                    className={"w-full"}
                    label={"Choix de branche"}
                    onChange={(branch) =>
                      setMods((m) => {
                        return {
                          ...m,
                          profile: { ...m.profile, branch },
                        };
                      })
                    }
                    options={branches}
                    placeholder={"Changer de branche"}
                    value={mods.profile.branch ?? "main"}
                  ></InputSelect>
                </div>

                {/* colonne boutons */}
                <div class="flex flex-col basis-1/2">
                  <div class="mb-2">
                    <div
                      onClick={isUdpate ? () => goBuildLoading() : null}
                      className={isUdpate ? "button-active" : "button-inactive"}
                    >
                      {isUdpate
                        ? `Mettre à jour ${mods.profile.installed_server}`
                        : "Mettre à jour"}
                    </div>
                  </div>

                  {process.env.REACT_APP_MFTC_SERVER_URL.includes(":3030/") && (
                    <>
                      <div class="mb-2 flex w-full">
                        <div
                          onClick={() =>
                            goBuildLoading(
                              null,
                              process.env.REACT_APP_MFTC_SERVER_URL,
                              true
                            )
                          }
                          className={"button-active bg-blue-500 w-full"}
                        >
                          {" "}
                          Deployer en local (test) avec pull{" "}
                        </div>
                        <a
                          className="w-8"
                          href={`https://localhost:3030/?domain=${app.domain}`}
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            className="m-2 p-2 text-black bg-"
                            icon={fa.faLink}
                          />
                        </a>
                      </div>
                    </>
                  )}

                  <div class="mb-2 flex w-full">
                    <div
                      onClick={
                        mods.profile.deployment_server &&
                        !mods.profile.installed_server
                          ? () => goBuildLoading()
                          : null
                      }
                      className={
                        mods.profile.deployment_server &&
                        !mods.profile.installed_server
                          ? "button-active w-full"
                          : "button-inactive w-full"
                      }
                    >
                      {mods.profile.deployment_server &&
                      !mods.profile.installed_server
                        ? `Déployer sur ${mods.profile.deployment_server}`
                        : "Déployer sur ..."}
                    </div>
                    {mods.profile.adresses?.length > 0 && (
                      <a
                        className="w-8"
                        href={`https://${mods.profile.adresses[0]}`}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="m-2 p-2 text-black"
                          icon={fa.faLink}
                        />
                      </a>
                    )}
                  </div>
                  <div class="mb-2">
                    <div
                      onClick={isMigratable ? () => migrateBuild() : null}
                      className={
                        isMigratable ? "button-active" : "button-inactive"
                      }
                    >
                      {isMigratable
                        ? `Migrer de ${mods.profile.installed_server} vers ${mods.profile.deployment_server}`
                        : "Migrer"}
                    </div>
                  </div>
                  <div class="mb-2">
                    <div
                      onClick={
                        mods.profile.installed_server
                          ? () => deleteAppBuild()
                          : null
                      }
                      className={
                        mods.profile.installed_server
                          ? "button-active"
                          : "button-inactive"
                      }
                    >
                      {mods.profile.installed_server
                        ? `Supprimer de ${mods.profile.installed_server}`
                        : "Supprimer de ..."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div
                onClick={
                  JSON.stringify(mods) !== JSON.stringify(app)
                    ? () => saveApp()
                    : null
                }
                className={
                  JSON.stringify(mods) !== JSON.stringify(app)
                    ? "button-active"
                    : "button-inactive"
                }
              >
                Enregistrer
              </div>
            </div>
            <div class="mt-4">
              <div onClick={() => testMigrate()} className={"button-active"}>
                Test migration bdd
              </div>
            </div>
          </div>
        ) : (
          <LoaderSmall />
        )}

        {result.length > 0 && (
          <div className="flex flex-col w-full">
            Résultat :
            {result.map((message, i) => (
              <div
                className={
                  "w-full p-1 text-sm " +
                  (message.type && classTypes[message.type])
                }
                key={i}
              >
                {message.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default AppBuild;
