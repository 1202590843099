import React, { useEffect, useMemo, useState, useContext } from 'react';


import { Link, useParams } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socket } from '../../../../service/socket';
import Loader from '../../../../components/Loader';
import Visio from '../../../../components/visio/Visio';


import Image from '../../../../components/Image';
import '../../../../App.css';
import { MfctContext } from "../../../../context";
import HeaderBack from '../../../../components/HeaderBack';


function Chat() {
  const { account_id } = useParams();

  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  const [msgs, setMsgs] = useState([])
  const [accounts, setAccounts] = useState([])
  const [callActivated,setCallActivated] = useState();


    
  const getMsgs = async () => {
    var { messages, account } = await project.api("/get_appmsgs", { body: { account_id } })

    setMsgs(messages);
    setAccounts([account]
      .map(_account =>{
        if(_account.profile?.imageUrl )
        {
          var url_files =process.env.REACT_APP_MFTC_SERVER_URL_FILES;
          var myUrl = window.location.href;
  
          var parts = url_files.split(":30");
          if( parts.length>1)
          {
            var parts0 = myUrl.split(":30");
            parts[0]=parts0[0];
            url_files= parts.join(":30");
          }
              var url= url_files + _account.profile.imageUrl 
              url+=`?domain=${_account.app.domain}`
              _account.profile.imageUrl =url;
        }
        return _account;
      }))
      setTimeout(reScroll,10)
  }
const sortAccounts= (_accounts)=>{
  _accounts.sort((a, b) =>{
    if(a.last_message && !b.last_message) return -1;
    if(!a.last_message && b.last_message) return 1;
    if(!a.last_message ) 
    {
      if(a.account_id > b.account_id ) 
      return 1;
      else
      return -1
    }
    if(a.last_message ) 
    {
      if(a.last_message.message_id > b.last_message.message_id ) 
      return -1;
      else
      return 1;
    }
  })
}
  const getAccounts = async () => {
    var _accounts = await project.api("/get_accounts")
    console.log(_accounts)

    sortAccounts(_accounts)
    setAccounts(_accounts    
      .map(_account =>{
      if(_account.profile?.imageUrl )
      {
        var url_files =process.env.REACT_APP_MFTC_SERVER_URL_FILES;
        var myUrl = window.location.href;

        var parts = url_files.split(":30");
        if( parts.length>1)
        {
          var parts0 = myUrl.split(":30");
          parts[0]=parts0[0];
          url_files= parts.join(":30");
        }
            var url= url_files + _account.profile.imageUrl 
            url+=`?domain=${_account.app.domain}`
            _account.profile.imageUrl =url;
      }
      return _account;
    }));
  }


  const sendMsg = async () => {

    var text = document.getElementById("new_msg").value;

    if (text) {

      document.getElementById("new_msg").value = "";
      var result = await project.api("/add_msg", { body: { pro_id: authUser.user_id, app_id: accounts[0].app_id ,owner_id: authUser.user_id, account_id, content: { text } } })
      await getMsgs();
  
    }
  }
const reScroll= async () => {
var objScroll =     document.getElementById("scrollContainer")
  if(objScroll) objScroll.scrollTop = objScroll.scrollHeight/2;
 
}
  useEffect(() => {
    if (project) {
      if (account_id) {
        getMsgs();
        setAccounts([]);
       
       
      }
      else {
        setMsgs([]);
        getAccounts();
      }

    }
  }, [project, account_id]);

  const updateMsg = async (new_msg) => {
if(account_id  && new_msg.account_id === account_id) 
{

    setMsgs([...msgs.map(c => c), new_msg]);
    setTimeout(reScroll,10)
}
var _accounts =accounts.map(c => { if (c.account_id===account_id) c.last_message =new_msg ;return c;  }  );
sortAccounts(_accounts);
setAccounts(_accounts);

  }
  useEffect(() => {
socket.connect();
    socket.on('updateChatPro/' + authUser.user_id, updateMsg);

    return () => {
      socket.off('updateChatPro/' + authUser.user_id, updateMsg);
      socket.disconnect();
    };

  }, [authUser, msgs]);

  return (!project ? <Loader /> :
    <div   id="scrollContainer" className="App   h-screen overflow-y-auto relative">
        <HeaderBack   title={"Messagerie"}   iconRight={fa.faPlus} classRight={"p-2 rounded-full border border-white "  } />
    
      <div className={'flex flex-row w-full justify-start p-2 sticky top-0 left-0 z-10 items-center' + (callActivated? " bg-black bg-opacity-30 text-white ":" bg-white " )}>
   { !callActivated &&     <Link to="/"> <FontAwesomeIcon icon={fa.faArrowLeft} className='mx-4'></FontAwesomeIcon> </Link>}
      { account_id && accounts.length==1 ?  <Image src={accounts[0].profile?.imageUrl } className="w-8 h-8 mx-4 rounded-full border border-white" alt="logo" />
:
<Image src={process.env.REACT_APP_MFTC_SERVER_URL_FILES + "profile/user.png"}  type="logo" className="w-8 h-8 mx-4" alt="logo" />
}

{account_id && accounts[0]  &&  <div className='p-1'>{ accounts[0].profile.first_name  } { accounts[0].profile.last_name  }</div> }
{ !callActivated && account_id &&      <div className='p-1'>  <Link to="/chat"> Revenir au Chat Global<FontAwesomeIcon icon={fa.faMessage} className='text-2xl mx-4  text-orange-500'></FontAwesomeIcon> </Link> </div>}
{ !account_id &&      <div className='p-1'>  <Link to="/chat">Conversations <FontAwesomeIcon icon={fa.faMessage} className='text-2xl mx-4  text-orange-500'></FontAwesomeIcon>  </Link> </div>}
{ !callActivated && account_id && accounts[0]  &&  <FontAwesomeIcon icon={fa.faVideoCamera} className='mx-4' onClick={()=>setCallActivated(true)}></FontAwesomeIcon> }
      </div>


      {

        !account_id &&

        <div className='flex flex-col  justify-center items-center p-4  mt-20'>

          {
            accounts.map((account, i) => <div key={i} className='w-full'>

              <Link to={`/chat/${account.account_id}`}>
                <div className=' mt-1 flex flex-row items-start justify-start w-full text-sx p-4 f shadow-md rounded-xl bg-white text-zinc-900 relative'>

                  <Image src={account.profile?.imageUrl  } className="w-14 h-14  rounded-full -top-6 right-2" alt="logo" />
                  <div className='ml-3 flex flex-col items-start ' >
                    <span className='text-base font-bold' >{account.profile.first_name} {account.profile.last_name} {account.profile.username} ({account.app.name})</span>
                    <span className='text-sm' >
                      { account.last_message && 
                     <span>
                      {account.last_message.owner_id==authUser.user_id && "Vous : " }
                       {account.last_message.content.text}</span>
                       }
                 { !account.last_message && 
                     <span>Envoyer votre premier message</span>
                       }
                       </span>
                     </div>
                </div>
              </Link></div>
            )
          }
        </div>
      }
      {
        account_id && accounts[0] && !callActivated &&

        <div id="contentM" className='flex flex-col w-full justify-center items-center p-4  mt-20 mb-20 z-0'>
          <div className='flex flex-col w-full justify-start items-start '>
<Image src={accounts[0].profile?.imageUrl} className="w-20 h-20  rounded-full -top-6 right-2" alt="logo" />
<div   className='text-3xl font-bold'  >{accounts[0].profile.first_name}  </div>
</div>
          {
            msgs.map((msg, i) => <div key={i} className='w-full '>
              {
                msg.owner_id !== authUser.user_id ?
                <div className='  flex flex-col w-full justify-end items-end '>
                    <span className=' mt-8 text-right  text-sm p-4  shadow-md rounded-xl bg-white text-zinc-900 relative'>
                    {msg.content.text}
                    <Image src={accounts[0].profile?.imageUrl  } className="w-8 h-8 mx-4 absolute rounded-full -top-5 right-2 border-2 border-white" alt="logo" />
                  </span> 
                  </div>:
                   <div className='  flex flex-col w-full justify-start items-start '>
                  <div className='  text-left  text-sm p-4 shadow-md rounded-xl bg-white text-zinc-800 '>
                    {msg.content.text}</div>
                    </div>
              }
            </div>
            )
          }
          {msgs.length == 0 &&
            <div className=' flex flex-col text-center w-full text-sx p-4 shadow-md rounded-xl bg-white-500 text-zinc-800 relative'>
              Aucun message</div>}
        </div>
      }
{
        account_id && accounts[0] &&
        callActivated &&

        <div  id="Visio" className='flex flex-col  justify-center items-center p-4   mt-20 mb-20 z-10 '>
   <Visio        idToCall={`account_${accounts[0].account_id}`}  me={`pro_${authUser.user_id}`}   endCall={ ()=>setCallActivated(false)}/>
  
   </div>
   }
      {account_id && !callActivated && 
        <div className={" px-4 w-full flex flex-col  items-start  justify-center  fixed  bottom-0 p-4 bg-white"} >


          <div className={"  w-full flex flex-row  items-center  justify-center  "} >
            <input id="new_msg" placeholder='Envoie ton commentaire' className=' text-black flex-grow  m-2 rounded-lg bg-transparent border border-zinc-500 p-2' />
            <div className='m-2 p-2 font-bold bg-blue-800 text-white  rounded-md' onClick={sendMsg}  > Envoyer</div> </div>
        </div>
        
        }
 

    </div>
  );
}

export default Chat;
