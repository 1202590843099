import { useEffect, useState } from "react";

const convertedVapidKey = urlBase64ToUint8Array(
  process.env.REACT_APP_PUBLIC_VAPID_KEY
);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function Notifications({ isConnected  ,noTimeOut , project ,close}) {

  const [show, setShow] = useState(true);

  const [subscription,setSubscription] = useState( localStorage.getItem("subscription"));


function unSubscribe(subscription){
  if(subscription) 
  {
    localStorage.removeItem("subscriptionRefresh");

    setShow(false);
    project
  .api("/update_tokenpush", { body: { subscription:  JSON.parse(subscription) ,unsubscribe:true} })
  .then((result) => {
    console.log(result);
    localStorage.removeItem("subscription");
    setSubscription();
  
  });
}
}


  function saveSubscription(_subscription) {

    project
      .api("/update_tokenpush", { body: { subscription: _subscription , device:{userAgent:window.navigator.userAgent}} })
      .then((result) => {
        console.log(result);
        if(result.token){

        localStorage.setItem("subscription", JSON.stringify(_subscription));
        setSubscription(JSON.stringify(_subscription));
        }
      });
  }

  const Activate = async () => {
 //   window.alert("get Subscription")
 setShow(false);
    // Triggers popup to request access to send notifications
    const result = await window.Notification.requestPermission();

    // If the user rejects the permission result will be "denied"
    if (result === "granted") {
      console.log("Ok Granted ");
      registerUser();
    } else {
      setShow(true);

     // window.alert("NO " + result)

     // localStorage.setItem("subscription", "NO");
    } 
  };

  function registerUser() {
    console.log("Subscribe");

    // subscribeUser ne fonctionne pas si le manifest.json est invalide !!!
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          if (!registration.pushManager) {
            console.log("Push manager unavailable.");
            return;
          }

          registration.pushManager
            .getSubscription()
            .then(function (existedSubscription) {
              if (existedSubscription === null) {
                console.log("No subscription detected, make a request.");
                registration.pushManager
                  .subscribe({
                    applicationServerKey: convertedVapidKey,
                    userVisibleOnly: true,
                  })
                  .then(function (newSubscription) {
                    console.log("New subscription added.");
                    saveSubscription(newSubscription);
                  })
                  .catch(function (e) {
                    if (Notification.permission !== "granted") {
                      console.log("Permission was not granted.");
                    } else {
                      window.alert(JSON.stringify(e));
                      console.error(
                        "An error ocurred during the subscription process.",
                        e
                      );
                    }
                  });
              } else {
                console.log("Existed subscription detected.");
                saveSubscription(existedSubscription);
              }
            });
        })
        .catch(function (e) {
          window.alert(JSON.stringify(e));
          console.error(
            "An error ocurred during Service Worker registration.",
            e
          );
        });
    }
  }

useEffect(()=>{

var update = ()=>{   
var subscriptionRefresh =   localStorage.getItem("subscriptionRefresh");
if(subscription && subscriptionRefresh && project)
{

  unSubscribe(subscription);
 
}
}


update();
window.addEventListener("subscriptionRefresh",update)

return ()=>{

  window.removeEventListener("subscriptionRefresh",update)
}


},[project,subscription])




  if (!project || !show || !isConnected || (subscription && !noTimeOut)) return <></>; 


  if(!noTimeOut)
  setTimeout(() => {
  
    close ? close() :   setShow(false)
  }, 10000);

  return (
    <>
      <div  onClick={()=>{close ? close() :   setShow(false)}    }
        className={
          " flex flex-col items-center justify-center fixed top-0 left-0 z-30 bg-black w-screen h-screen transition-all   duration-500  bg-opacity-30 "
        }
      > { (!subscription || subscription==="NO") && 
        <button
          onClick={Activate}
          className=" z-40 p-8 flex flex-row items-center justify-center text-base font-bold  text-white bg-black rounded-full border-none  "
        >
          {" "}
          Activer les notifications{" "}
        </button>
        }
        { subscription && subscription!=="NO" && 
        <button
          onClick={unSubscribe}
          className=" z-40 p-8 flex flex-row items-center justify-center text-base font-bold  text-white bg-black rounded-full border-none  "
        >
          {" "}
          Désactiver les notifications{" "}
        </button>
        }
        {    JSON.stringify(subscription) }
      </div>
    </>
  );
}

export default Notifications;
