
import InputPropSchema from "./InputPropSchema";
import InputData from "./InputData";
import { useEffect, useState } from "react";
import { data } from "../../service/data";



const InputFiltre = ({id,label,onChange,value,className,infos,context }) => {

    value = value ?? {operator:"="}

const [schemaOp,setsSchemaOp]=useState()
const [schemaProp,setsSchemaProp]=useState()

 useEffect(()=>{
   
const init = async ()=>   { var  result = await data.getContent(457) ;
    setsSchemaOp(result);
}
init()
},[])

useEffect(()=>{
   
    const setProp = async (schema_id)=>   { var  result = await data.getContent(schema_id) ;
        setsSchemaProp(result);
    }
  if(value.prop?.schema_id)  setProp(value.prop?.schema_id)
    else setProp()
    },[value])



    return (
    <>
    <div className={"relative flex w-full items-center justify-start " +className } >
   {   <InputPropSchema  label={"Propriété"} 
   
   className={"w-1/2"}
   placeholder={"Saisir propiété"} value={value.prop}  onChange={(prop)=>onChange({...value,prop,value:null})} context={context} />
}
   { schemaOp && <InputData 
    prop={ {name:"operator" ,schema_id:457  }} 

     schema={schemaOp}
       label={"Opérateur"} value={value.operator}  onChange={(operator)=>onChange({...value,operator})} />

   }
   {schemaProp ? 
   <InputData 
   className={"w-1/2"}
   prop={ {name:"Valeur "    }} 
   schema={schemaProp} 
   label={"Valeur"} value={value.value}  onChange={(_value)=>{ 
    if(_value && _value.content_id) _value={content_id:_value.content_id}
    window.alert("Content ID: " + _value.content_id)
    onChange({...value,value:_value});}} />
: 
    <>Choisir Propriété</>
}
   </div> 

</> 
)
}
export default InputFiltre




