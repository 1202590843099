import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";





const ShowComponentTools = ({template,ComponentTools,setShow,updateTemplateStructure,selectTpl,selected,key_schemas}) => {


    if(!selected ) return <></>  

    console.log("Show Component Add Tools", template.structure)
  

    return (
      <div id={"ShowComponentTools"} className="flex-none   w-80 m-2   flex-nowrap text-start  border border-white rounded-xl shadow">
          <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
  <FontAwesomeIcon icon={fa.faCode}></FontAwesomeIcon>
  Style CSS / Classes 
    <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Code: !show.Code })) }  className="appbutton"  ></FontAwesomeIcon>
    </div>  {Object.values(ComponentTools).map((ComponentTool, i) => (
          <ComponentTool
            structure={ template.structure}
            updateStructure={updateTemplateStructure}
            selectTpl={selectTpl}
            idObj={selected}
            key={i}
            key_schemas={key_schemas}
          />
        ))}{" "}
      </div>
   
    );
  };
 
export default ShowComponentTools